'use strict';
import { get, pick } from 'lodash';
import update from 'immutability-helper';
import { createAction, handleActions, } from 'redux-actions';
import { setMenuIsVisible } from "../../realtime/redux/modules/app";

export const MODAL_TYPES = {
	AUTH_MODAL: 'AUTH_MODAL',
	REPORT_GAME: 'REPORT_GAME',
	REPORT_GAME_POST: 'REPORT_GAME_POST',
	REPORT_USER: 'REPORT_USER',
	GAME_POST: 'GAME_POST',
	GAME_VENUE: 'GAME_VENUE',
	GAME_VENUE_MANAGEMENT: 'GAME_VENUE_MANAGEMENT',
};

const SHOW_MODAL = 'modal/SHOW_MODAL';
const HIDE_MODAL = 'modal/HIDE_MODAL';
export const actions = {
	show: createAction(SHOW_MODAL),
	hide: createAction(HIDE_MODAL),
};
const initialState = {
	contentProps: {},
	modalType: null,
	modalProps: {},
};

export default handleActions({
	[SHOW_MODAL]: (state, action) => {
		const newState = pick(action.payload, ['contentProps', 'modalProps', 'modalType']);
		return update(state, {$set: newState});
	},
	[HIDE_MODAL]: (state, action) => {
		const type = get(action, 'payload.modalType');
		//modals can be closed by calling actions.close with no 'modalType' arg, or
		//you can call actions.close with a single arg with the signature {modalType: string}
		//to only close a modal of that type
		if (!type || type === get(state, 'modal.modalType')) {
			return initialState;
		}
		return state;
	}
}, initialState);

export function showModal ({contentProps, modalProps, modalType}) {
	return function dispatchShowModal (dispatch, getState) {
		const state = getState();
		if (get(state, 'app.menuIsVisible')) {
			dispatch(setMenuIsVisible(false));
		}

		dispatch(actions.show({contentProps, modalProps, modalType}));
	};
}

export function hideModal () {
	return dispatch => dispatch(actions.hide());
}
