import React from 'react';
import ReactDom from 'react-dom';
import { handleError } from "../../common/libs/errorHandler";
import getPortalContainer from '../../common/libs/getPortalContainer';
import MobileModal from '../app/components/header/notificationCenter/mobileWebNotificationModal.jsx';

let TheModal;
export function loadMobileModal(url) {
	if (TheModal) {
		TheModal.show(url);
	} else {
		const container = getPortalContainer();
		TheModal = ReactDom.render(<MobileModal url={url} />, container);
	}
}

const supportedRoutes = {
	game : {
		chat : true,
		fans : true,
	},
	user : {
		media : true,
		teams : true,
		following : true,
		followers : true,
	},
	team : {
		chat : true,
		media : true,
		games : true,
	},
	gamePost : {},
	teamGamePost : {},
	playlist : {},
	url : {},
};

export function canRoute(p) {
	if (!p) { return false; }

	const { route, subRoute } = p;

	const supObj = supportedRoutes[route];
	return (supObj && (!subRoute || supObj[subRoute]));
}

export function getRouteFromUserNotification(userNotification) {

	let relativeUrl;

	// route game
	if (userNotification.routeGameId) {
		relativeUrl = `/game/${userNotification.routeGameId}`;
	// route team
	} else if (userNotification.routeTeamId) {
		relativeUrl = `/team/${userNotification.routeTeamId}`;
	// route user
	} else if (userNotification.routeUserId) {
		relativeUrl = `/user/${userNotification.routeUserId}`;
	// route gamePost
	// route teamGamePost
	} else if (userNotification.routeGamePostId) {
		relativeUrl = `/gamePost/${userNotification.routeGamePostId}`;
	// route playlist
	} else if (userNotification.routePlaylistId) {
		relativeUrl = `/playlist/${userNotification.routePlaylistId}`;
	}

	if (!relativeUrl) {
		return handleError(`wth, missing relativeUrl even after noting that we should be able to route...`);
	}

	if (userNotification.subRoute) {
		relativeUrl += `/${userNotification.subRoute}`;
	}

	return relativeUrl;
}
