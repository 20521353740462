'use strict';
import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { getUrlForSize } from '../../libs/teams/pictures';

const styles = {
	innerFull : {
		width : '100%',
		height : '100%',
		position : 'absolute',
		top : '50%',
		left : '50%',
		transform: 'translate(-50%, -50%)',
	},
	inner : {
		width : '90%',
		height : '90%',
		position : 'absolute',
		top : '50%',
		left : '50%',
		transform: 'translate(-50%, -50%)',
	}
};

function Mascot (props) {
	const { width, mascotPicture, fullSize, dpiAdjust } = props;

	const imageUrl = getUrlForSize(mascotPicture, width, { dpiAdjust });

	return (
		<div style={{
			width: width,
			height: width,
			position: 'relative'
		}}>
			<div style={fullSize ? styles.innerFull : styles.inner}>
				<div style={{
					backgroundImage: `url(${imageUrl})`,
					backgroundSize: 'contain',
					backgroundPosition: 'center',
					backgroundRepeat: 'no-repeat',
					width : '100%',
					height : '100%',
				}} />
			</div>
		</div>
	);
}
Mascot.propTypes = {
	width: PropTypes.number.isRequired,
	mascotPicture: PropTypes.object.isRequired,
	fullSize : PropTypes.bool,
	dpiAdjust: PropTypes.bool,
};
export default Radium(Mascot);
