import React from 'react';
import PropTypes from 'prop-types';
import { SnackbarProvider, withSnackbar } from 'notistack';
import { registerErrorHandler, parseErrorIntoString, getErrorHandler } from "../../../common/libs/errorHandler";

class _Messages extends React.Component {
	constructor(props) {
		super(props);
		this.onError = this.onError.bind(this);
	}

	onError(err) {
		this.props.enqueueSnackbar(parseErrorIntoString(err), {
			variant: 'error'
		});
	}

	componentWillUnmount() {
		registerErrorHandler(this.initialErrorHandler);
	}

	componentDidMount() {
		this.initialErrorHandler = getErrorHandler();
		registerErrorHandler(this.onError);
	}

	render () {
		return null;
	}
}
_Messages.propTypes = {
	enqueueSnackbar: PropTypes.func,
};
const Messages = withSnackbar(_Messages);

export default function MessagesOuter() {
	return (
		<SnackbarProvider maxSnack={3}>
			<Messages />
		</SnackbarProvider>
	);
}