'use strict';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';


//--- For now we are going to assume we have all of our data
function mapStateToProps (state, ownProps) {
	const userNotificationId = ownProps.userNotificationId;

	// make full passed in, not just the ID, this for dual compatibility
	let userNotification = ownProps.userNotification;
	if (userNotificationId && !userNotification) {
		userNotification = state.collections.userNotification[userNotificationId];
	}

	// Ensure that we are only setting a single icon set of properties
	let iconImageUrl,
		iconGame,
			iconGameAwayTeam, iconGameAwayTeamColor1, iconGameAwayTeamColor2, iconGameAwayTeamMascotPicture,
			iconGameHomeTeam, iconGameHomeTeamColor1, iconGameHomeTeamColor2, iconGameHomeTeamMascotPicture,
		iconTeam, iconTeamColor1, iconTeamColor2, iconTeamMascotPicture,
		iconUser;

	let displayUser1, displayUser2, displayUser3, displayUserAdditionalCount;

	let displayGamePostComposite;

	if (userNotification) {
		if (userNotification.iconImageUrl) {
			iconImageUrl = userNotification.iconImageUrl;
		} else if (userNotification.iconUserId) {
			iconUser = state.collections.user[userNotification.iconUserId];
		} else if (userNotification.iconTeamId) {
			iconTeam = state.collections.team[userNotification.iconTeamId];
			iconTeamColor1 = state.collections.color[iconTeam.color1Id];
			iconTeamColor2 = state.collections.color[iconTeam.color1Id];
			iconTeamMascotPicture = state.collections.teamPicture[_.get(iconTeam, ['mascotTeamPictureIds', '0'])];
		} else if (userNotification.iconGameId) {
			iconGame = state.collections.game[userNotification.iconGameId];
			iconGameAwayTeam = state.collections.team[iconGame.awayTeamId];
			iconGameAwayTeamColor1 = state.collections.color[iconGameAwayTeam.color1Id];
			iconGameAwayTeamColor2 = state.collections.color[iconGameAwayTeam.color2Id];
			iconGameAwayTeamMascotPicture = state.collections.teamPicture[_.get(iconGameAwayTeam, ['mascotTeamPictureIds', '0'])];

			iconGameHomeTeam = state.collections.team[iconGame.homeTeamId];
			iconGameHomeTeamColor1 = state.collections.color[iconGameHomeTeam.color1Id];
			iconGameHomeTeamColor2 = state.collections.color[iconGameHomeTeam.color2Id];
			iconGameHomeTeamMascotPicture = state.collections.teamPicture[_.get(iconGameHomeTeam, ['mascotTeamPictureIds', '0'])];
		}


		if (userNotification.displayUserIds && userNotification.displayUserIds.length) {
			displayUser1 = state.collections.user[userNotification.displayUserIds[0]];
			displayUser2 = state.collections.user[userNotification.displayUserIds[1]];
			displayUser3 = state.collections.user[userNotification.displayUserIds[2]];
			if (userNotification.displayUserIds.length > 3) {
				displayUserAdditionalCount = userNotification.displayUserIds.length - 3;
			}
		}

		if (userNotification.displayGamePostId) {
			const gamePost = state.collections.gamePost[userNotification.displayGamePostId];
			if (gamePost) {
				displayGamePostComposite = state.collections.composite[gamePost.compositeId];
			}
		}
	}

	return {
		userNotification,
		iconImageUrl,
		iconUser,
		iconTeam, iconTeamColor1, iconTeamColor2, iconTeamMascotPicture,
		iconGame,
			iconGameAwayTeam, iconGameAwayTeamColor1, iconGameAwayTeamColor2, iconGameAwayTeamMascotPicture,
			iconGameHomeTeam, iconGameHomeTeamColor1, iconGameHomeTeamColor2, iconGameHomeTeamMascotPicture,
		displayUser1,
		displayUser2,
		displayUser3,
		displayUserAdditionalCount,
		displayGamePostComposite,
		isRouted: state.routing && state.routing.isRouted,
	};
}

// TODO - for now just assume that the userNotifications are always in there... and we don't need the get methods

export default connect(mapStateToProps);
