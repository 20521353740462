/**
 * Take userRankId and return the HEX color code associated with that rank
 * @param userRankId
 * @param isSuspended
 * @returns {string}
 */
export function getUserRankColor ({userRankId, isSuspended}) {
	if (isSuspended) {
		return '#bdbdbd';
	}

	if (userRankId >= 800) {
		// legend
		return '#b91616';
	} else if (userRankId >= 600) {
		// veteran
		return '#c57c1c';
	} else if (userRankId >= 500) {
		// mvp
		return '#89af37';
	} else if (userRankId >= 400) {
		// allstar
		return '#50aead';
	} else if (userRankId >= 300) {
		// pro
		return '#7864a9';
	} else if (userRankId >= 200) {
		// rookie
		return '#bdbdbd';
	} else {
		// recruit
		return '#bdbdbd';
	}
}

export function shouldShowSmallBadge (userRankId) {
	return (userRankId !== 100)	;
}

