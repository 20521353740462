import React from 'react';
import PropTypes from 'prop-types';
import NativeSelect from '@material-ui/core/NativeSelect';

const styles = {
	container : {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: `0px 15px 5px 0px`,
		background: `#f6f6f6`
	}
};

const SEARCH_MODE_LABELS = {
	team: 'Team Search',
	user: 'User Search',
};

class SearchModes extends React.Component {
	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
	}

	onChange(evt) {
		const { setActiveSearchMode } = this.props;

		setActiveSearchMode({
			searchMode: evt.target.value,
		});
	}

	render () {
		const { searchModes, activeSearchMode } = this.props;

		if (searchModes.length < 2) {
			return null;
		}

		return (
			<div style={styles.container}>
				<NativeSelect
					value={activeSearchMode}
					onChange={this.onChange}
					name="activeSearchMode"
				>
					{searchModes.map((searchMode) => {
						return (
							<option
								key={searchMode}
								value={searchMode}
							>
								{SEARCH_MODE_LABELS[searchMode] || searchMode}
							</option>
						);
					})}
				</NativeSelect>
			</div>
		);
	}
}

SearchModes.propTypes = {
	searchModes: PropTypes.array,
	activeSearchMode: PropTypes.string,
	setActiveSearchMode: PropTypes.func,
	classes: PropTypes.object,
};

export default React.memo(SearchModes);
