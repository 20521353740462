import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';

import MobileScreenShareIcon from '@material-ui/icons/MobileScreenShare';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import BoltIcon from '@material-ui/icons/FlashOn';
import LogoutIcon from '@material-ui/icons/PowerOff';
import SignUpIcon from '@material-ui/icons/HowToReg';
import GMIcon from '@material-ui/icons/Assessment';
import LogInIcon from '@material-ui/icons/ArrowRightAltOutlined';
import AboutIcon from '@material-ui/icons/Info';
import editIconSrc from '../../../../../common/images/icons/edit.svg';
import homeIconSrc from '../../../../../common/images/icons/home.svg';
import teamsIconSrc from '../../../../../common/images/icons/teams.svg';
import widgetsIconSrc from '../../../../../common/images/icons/widgets.svg';
import exploreIconSrc from '../../../../../common/images/icons/explore.svg';
import toolsIconSrc from '../../../../../common/images/icons/tools.svg';

import MenuListItem from './menuListItem.jsx';
import { Connected as ProfilePicture } from '../../../../../common/components/users/profilePicture.jsx';

const styles = {
	container : {
		width: 280,
	},
};

function MenuList (props) {
	const { authUser, hideMenu, showLogin } = props;
	if (authUser) {
		return (
			<div style={styles.container}>
				<List>
					<MenuListItem
						text={authUser.displayName || "View Profile"}
						noMargin={true}
						icon={<ProfilePicture size={32} userId={authUser.userId}/>}
						linkTo={authUser.relativeUrl}
						hideMenu={hideMenu}
					/>
					<MenuListItem
						text="Edit Profile"
						iconSrc={editIconSrc}
						linkTo={authUser.relativeUrl + "/edit"}
						hideMenu={hideMenu}
					/>
					<Divider/>

					<MenuListItem
						text="Home" 
						iconSrc={homeIconSrc}
						href="/dashboard"
					/>
					<MenuListItem
						text="My Teams" 
						iconSrc={teamsIconSrc}
						href="/dashboard/myTeams"
					/>
					<MenuListItem
						text="My Widgets" 
						iconSrc={widgetsIconSrc}
						href="/widgetManager"
					/>
					<MenuListItem
						text="Explore"
						iconSrc={exploreIconSrc}
						href="/explore"
					/>
					<MenuListItem 
						text="Tools" 
						iconSrc={toolsIconSrc} 
						href="/dashboard/tools"
					/>
					<MenuListItem 
						text="Advanced Tools" 
						icon={<BoltIcon />}
						href="/dashboard/advanced"
					/>
					<Divider/>

					<MenuListItem 
						text="Get the App" 
						icon={<MobileScreenShareIcon/>}
						href="/app"
					/>
					<MenuListItem 
						text="Contact ScoreStream" 
						icon={<MailOutlineIcon/>}
						href="mailto:info@scorestream.com"
					/>
					<Divider />

					<MenuListItem 
						text="Log Out"
						icon={<LogoutIcon/>}
						href="/logout"
					/>
				</List>
			</div>
		);
	} else {
		return (
			<div style={styles.container}>
				<List>
					<MenuListItem
						text="Explore"
						iconSrc={exploreIconSrc}
						href="/explore"
					/>
					<MenuListItem
						text={"Learn about Widgets"}
						iconSrc={widgetsIconSrc}
						href="/make-a-scoreboard-widget"
					/>
					<MenuListItem
						text="Become a GM"
						icon={<GMIcon/>}
						href="/become-a-team-general-manager"
					/>
					<MenuListItem
						text="About"
						icon={<AboutIcon/>}
						href="/about"
					/>
					<Divider />

					<MenuListItem
						text="Get the App"
						icon={<MobileScreenShareIcon/>}
						href="/app"
					/>
					<MenuListItem
						text="Contact ScoreStream"
						icon={<MailOutlineIcon/>}
						href="mailto:info@scorestream.com"
					/>
					<Divider/>

					<MenuListItem
						text="Signup"
						icon={<SignUpIcon/>}
						href="/simpleSignUp"
					/>

					<MenuListItem
						text="Login"
						icon={<LogInIcon/>}
						hideMenu={hideMenu}
						onClick={showLogin}
					/>
					<Divider />

				</List>
			</div>
		);
	}
}
MenuList.propTypes = {
	authUser: PropTypes.object,
	hideMenu: PropTypes.func,
	showLogin: PropTypes.func,
};

export default MenuList;