"use strict";
import _ from 'lodash';
import { handleApiError } from "../../../libs/errorHandler";
import { getApi } from '../../../libs/api';
const api = getApi({name: 'scoreStream'});
import * as listsRedux from '../lists';
import { actions as collectionActions } from '../../actions/collections';

export const propsToDiff = {};

export const getInitialState = function getInitialState() {
	return _.keyBy(_.get(window, ['gData', 'redux', 'collections', 'venueToTeamMappingCollection', 'list'], []), 'venueToTeamMappingId');
};

const addUpdateParams = [
	'venueToTeamMappingId',
	'venueId',
	'teamId',
	'sportIds',
];

export const add = function addVenueToTeamMapping (params, callback) {
	return dispatch => {
		api.post({
			method : 'teams.venueToTeamMappings.add',
			params : _.pick(params, addUpdateParams),
			callback : function (err, result) {
				if (err && callback) { return callback(err); }
				if (err && !callback) { return handleApiError(err); }

				const venueToTeamMappingId = _.get(result, ['venueToTeamMappingIds', '0']);

				dispatch(collectionActions.receiveCollections(result.collections));

				dispatch(listsRedux.actions.addToLists({
					list : 'venueToTeamMappingIdsForTeam',
					listId : params.teamId,
					itemId : venueToTeamMappingId
				}));

				if (callback) { return callback (null, result); }
			}
		});
	}
};

export const update = function updateVenueToTeamMapping (params, callback) {
	return (dispatch, getState) => {
		const state = getState();
		const mapping = _.get(state, ['collections', 'venueToTeamMapping', params.venueToTeamMappingId]);

		api.post({
			method : 'teams.venueToTeamMappings.update',
			params : _.pick(params, addUpdateParams),
			callback : function (err, result) {
				if (err && callback) { return callback(err); }
				if (err && !callback) { return handleApiError(err); }

				dispatch(collectionActions.receiveCollections(result.collections, {forceSet : true}));

				// if the mappings team has been changed
				if (mapping && params.teamId && params.teamId !== mapping.teamId) {
					// remove from the previous teams list of mappings
					dispatch(listsRedux.actions.removeFromLists({
						list: 'venueToTeamMappingIdsForTeam',
						listId: mapping.teamId,
						itemId: params.venueToTeamMappingId
					}));

					// add to the new teams list for mapping
					dispatch(listsRedux.actions.addToLists({
						list : 'venueToTeamMappingIdsForTeam',
						listId : params.teamId,
						itemId : params.venueToTeamMappingId
					}));
				}

				if (callback) { return callback (null, result); }
			}
		});
	}
};

export const remove = function removeVenueToTeamMapping(params, callback) {
	return (dispatch, getState) => {
		const state = getState();
		const mapping = _.get(state, ['collections', 'venueToTeamMapping', params.venueToTeamMappingId]);

		// XXX - remove from collections, updated to disabled? does it matter?
		api.post({
			method : 'teams.venueToTeamMappings.remove',
			params : _.pick(params, ['venueToTeamMappingId']),
			callback : function (err, results) {
				if (err && callback) { return callback(err); }
				if (err && !callback) { return handleApiError(err); }

				// then the lists
				dispatch(listsRedux.actions.removeFromLists({
					list: 'venueToTeamMappingIdsForTeam',
					listId: mapping.teamId,
					itemId: params.venueToTeamMappingId
				}));

				if (callback) { return callback(null, results); }
			}
		});
	}
};
