"use strict";
import _ from 'lodash';
import cached from '../../../../../cachedData/userRanks';

function getInitialState () {
	return _.keyBy(cached.collections.userRankCollection.list, 'userRankId');
}

export default {getInitialState};

