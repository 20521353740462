
/* 
	FILE AUTOMATICALLY GENERATED BY RUNNING npm run build buildCachedData
	DO NOT EDIT THIS FILE
	QUESTIONS: Ask Ryan
*/

module.exports = {
  "local": {
    "env": "local",
    "stream": {
      "httpHost": "http://localhost:8080",
      "httpsHost": "https://localhost:4443",
      "websocketHost": "ws://localhost:8080",
      "secureWebsocketHost": "wss://localhost:4443",
      "loginRedirect": "https://localhost:4443/loginPage",
      "cdnHttpHost": "http://localhost:8080",
      "cdnHost": "https://localhost:4443",
      "jsCdnDir": "filesForCdn",
      "specialCdnHost": ""
    },
    "tweetParser": {
      "httpHost": "http://localhost:7777",
      "httpsHost": "https://localhost:7878",
      "websocketHost": "wss://localhost:7878",
      "secureWebsocketHost": "wss://localhost:7878"
    },
    "graphicMaker": {
      "httpHost": "http://localhost:6543",
      "httpsHost": "https://localhost:7543"
    },
    "missionControl": {
      "httpHost": "http://localhost:4321",
      "httpsHost": "https://localhost:3210",
      "websocketHost": "wss://localhost:3210",
      "secureWebsocketHost": "wss://localhost:3210"
    },
    "notificationService": {
      "httpHost": "http://localhost:8765",
      "httpsHost": "https://localhost:9765",
      "secureWebsocketHost": "wss://localhost:9765"
    },
    "tracking": {
      "httpHost": "http://localhost:8282",
      "httpsHost": "https://localhost:9292"
    },
    "gsp": {
      "httpHost": "http://localhost:8383",
      "httpsHost": "https://localhost:9393"
    },
    "marketingUserProcessor": {},
    "userProcessor": {
      "httpHost": "http://localhost:8484",
      "httpsHost": "https://localhost:9494"
    },
    "lasso": {
      "httpHost": "http://localhost:8585",
      "httpsHost": "https://localhost:9595"
    },
    "staging": {
      "httpHost": "http://localhost:8686",
      "httpsHost": "https://localhost:9696"
    },
    "emailCampaigns": {
      "httpHost": "http://localhost:8787",
      "httpsHost": "https://localhost:9797"
    },
    "rumpus": {
      "httpHost": "http://localhost:8989",
      "httpsHost": "https://localhost:9999"
    },
    "rustler": {
      "httpHost": "http://localhost:8288",
      "httpsHost": "https://localhost:8289"
    },
    "bullhorn": {
      "httpHost": "http://localhost:8388",
      "httpsHost": "https://localhost:8389"
    },
    "scoreStream": {
      "httpHost": "http://localhost:8080",
      "httpsHost": "https://localhost:4443",
      "websocketHost": "ws://localhost:8080",
      "secureWebsocketHost": "wss://localhost:4443",
      "loginRedirect": "https://localhost:4443/loginPage",
      "cdnHttpHost": "http://localhost:8080",
      "cdnHost": "https://localhost:4443",
      "jsCdnDir": "filesForCdn",
      "specialCdnHost": ""
    }
  },
  "development": {
    "env": "development",
    "stream": {
      "httpHost": "http://dev.scorestream.com",
      "httpsHost": "https://dev.scorestream.com",
      "websocketHost": "ws://dev.scorestream.com",
      "secureWebsocketHost": "wss://dev.scorestream.com",
      "loginRedirect": "https://dev.scorestream.com:4443/loginPage",
      "cdnHttpHost": "http://dev.scorestream.com",
      "cdnHost": "https://dev.scorestream.com",
      "databaseReadReplica": {
        "host": "172.31.40.255"
      },
      "jsCdnDir": "cdn",
      "specialCdnHost": ""
    },
    "tweetParser": {
      "httpHost": "http://dev01.scorestream.com:7777",
      "httpsHost": "https://dev01.scorestream.com:7878",
      "websocketHost": "wss://dev01.scorestream.com:7878",
      "secureWebsocketHost": "wss://dev01.scorestream.com:7878"
    },
    "graphicMaker": {
      "httpHost": "http://dev01.scorestream.com:6543",
      "httpsHost": "https://dev01.scorestream.com:7543",
      "cookieDomain": ".scorestream.com"
    },
    "missionControl": {
      "httpHost": "http://dev01.scorestream.com:4321",
      "httpsHost": "https://dev01.scorestream.com:3210",
      "websocketHost": "wss://dev01.scorestream.com:3210",
      "secureWebsocketHost": "wss://dev01.scorestream.com:3210"
    },
    "notificationService": {
      "httpHost": "http://dev01.scorestream.com:8765",
      "httpsHost": "https://dev01.scorestream.com:9765",
      "secureWebsocketHost": "wss://dev01.scorestream.com:9765",
      "databaseReadReplica": {
        "host": "172.31.40.255"
      }
    },
    "tracking": {
      "httpHost": "http://dev01.scorestream.com:8282",
      "httpsHost": "https://dev01.scorestream.com:9292"
    },
    "gsp": {
      "httpHost": "http://dev01.scorestream.com:8383",
      "httpsHost": "https://dev01.scorestream.com:9393"
    },
    "marketingUserProcessor": {},
    "mupBraintree": {},
    "userProcessor": {
      "httpHost": "http://dev01.scorestream.com:8484",
      "httpsHost": "https://dev01.scorestream.com:9494"
    },
    "lasso": {
      "httpHost": "http://dev01.scorestream.com:8585",
      "httpsHost": "https://dev01.scorestream.com:9595"
    },
    "rumpus": {
      "httpHost": "http://dev01.scorestream.com:8989",
      "httpsHost": "https://dev01.scorestream.com:9999"
    },
    "rustler": {
      "httpHost": "http://dev01.scorestream.com:8288",
      "httpsHost": "https://dev01.scorestream.com:8289"
    },
    "bullhorn": {
      "httpHost": "http://bullhorn-dev01.scorestream.com:8388",
      "httpsHost": "https://bullhorn-dev01.scorestream.com:8389"
    },
    "scoreStream": {
      "httpHost": "http://dev.scorestream.com",
      "httpsHost": "https://dev.scorestream.com",
      "websocketHost": "ws://dev.scorestream.com",
      "secureWebsocketHost": "wss://dev.scorestream.com",
      "loginRedirect": "https://dev.scorestream.com:4443/loginPage",
      "cdnHttpHost": "http://dev.scorestream.com",
      "cdnHost": "https://dev.scorestream.com",
      "databaseReadReplica": {
        "host": "172.31.40.255"
      },
      "jsCdnDir": "cdn",
      "specialCdnHost": ""
    }
  },
  "production": {
    "env": "production",
    "stream": {
      "httpHost": "http://scorestream.com",
      "httpsHost": "https://scorestream.com",
      "websocketHost": "wss://scorestream.com",
      "secureWebsocketHost": "wss://scorestream.com",
      "cdnHttpHost": "http://cdn-scorestream-com.s3.amazonaws.com",
      "cdnHost": "https://cdn-scorestream-com.s3.amazonaws.com",
      "loginRedirect": "https://scorestream.com/loginPage",
      "databaseReadReplica": {
        "host": "scorestream-aurora-production-cluster.cluster-ro-cedjmufev6wl.us-west-2.rds.amazonaws.com"
      },
      "jsCdnDir": "cdn",
      "specialCdnHost": "//cdn-scorestream-com.s3.amazonaws.com"
    },
    "tweetParser": {
      "httpHost": "http://tweetparser.scorestream.com",
      "httpsHost": "https://tweetparser.scorestream.com",
      "websocketHost": "wss://tweetParser.scorestream.com",
      "secureWebsocketHost": "wss://tweetparser.scorestream.com",
      "proxyProtocolPort": 8888,
      "databaseReadReplica": {
        "host": "tweetparser-1.cluster-ro-cedjmufev6wl.us-west-2.rds.amazonaws.com"
      }
    },
    "graphicMaker": {
      "httpHost": "http://graphicmaker.scorestream.com",
      "httpsHost": "https://graphicmaker.scorestream.com",
      "cookieDomain": ".scorestream.com"
    },
    "missionControl": {
      "httpHost": "http://missioncontrol.scorestream.com",
      "httpsHost": "https://missioncontrol.scorestream.com",
      "websocketHost": "wss://missioncontrol.scorestream.com",
      "secureWebsocketHost": "wss://missioncontrol.scorestream.com"
    },
    "notificationService": {
      "httpHost": "http://notificationservice.scorestream.com",
      "httpsHost": "https://notificationservice.scorestream.com",
      "databaseReadReplica": {
        "host": "notificationservice-cluster.cluster-ro-cedjmufev6wl.us-west-2.rds.amazonaws.com"
      },
      "secureWebsocketHost": "wss://notificationservice.scorestream.com"
    },
    "tracking": {
      "httpHost": "http://tracking.scorestream.com",
      "httpsHost": "https://tracking.scorestream.com",
      "databaseReadReplica": {
        "host": "tweetparser-1.cluster-ro-cedjmufev6wl.us-west-2.rds.amazonaws.com"
      }
    },
    "gsp": {
      "httpHost": "http://gsp.scorestream.com",
      "httpsHost": "https://gsp.scorestream.com",
      "databaseReadReplica": {
        "host": "gsp-cluster.cluster-ro-cedjmufev6wl.us-west-2.rds.amazonaws.com"
      }
    },
    "marketingUserProcessor": {},
    "userProcessor": {
      "httpHost": "http://userprocessor.scorestream.com",
      "httpsHost": "https://userprocessor.scorestream.com",
      "databaseReadReplica": {
        "host": "userprocessor-cluster.cluster-ro-cedjmufev6wl.us-west-2.rds.amazonaws.com"
      }
    },
    "lasso": {
      "httpHost": "http://lasso.scorestream.com",
      "httpsHost": "https://lasso.scorestream.com",
      "databaseReadReplica": {
        "host": "tweetparser-1.cluster-ro-cedjmufev6wl.us-west-2.rds.amazonaws.com"
      }
    },
    "mup": {},
    "mupBraintree": {},
    "staging": {
      "httpHost": "http://staging.scorestream.com",
      "httpsHost": "https://staging.scorestream.com"
    },
    "emailCampaigns": {
      "httpHost": "http://emailCampaigns.scorestream.com",
      "httpsHost": "https://emailCampaigns.scorestream.com"
    },
    "rumpus": {
      "httpHost": "http://rumpus.scorestream.com",
      "httpsHost": "https://rumpus.scorestream.com",
      "databaseReadReplica": {
        "host": "tweetparser-1.cluster-ro-cedjmufev6wl.us-west-2.rds.amazonaws.com"
      }
    },
    "rustler": {
      "httpHost": "http://rustler.scorestream.com",
      "httpsHost": "https://rustler.scorestream.com"
    },
    "bullhorn": {
      "httpHost": "http://bullhorn.scorestream.com",
      "httpsHost": "https://bullhorn.scorestream.com"
    },
    "scoreStream": {
      "httpHost": "http://scorestream.com",
      "httpsHost": "https://scorestream.com",
      "websocketHost": "wss://scorestream.com",
      "secureWebsocketHost": "wss://scorestream.com",
      "cdnHttpHost": "http://cdn-scorestream-com.s3.amazonaws.com",
      "cdnHost": "https://cdn-scorestream-com.s3.amazonaws.com",
      "loginRedirect": "https://scorestream.com/loginPage",
      "databaseReadReplica": {
        "host": "scorestream-aurora-production-cluster.cluster-ro-cedjmufev6wl.us-west-2.rds.amazonaws.com"
      },
      "jsCdnDir": "cdn",
      "specialCdnHost": "//cdn-scorestream-com.s3.amazonaws.com"
    }
  }
};
