import isObject from 'lodash/isObject';
import { createAction } from 'redux-actions';
import { handleError } from '../../libs/errorHandler';

export const RECEIVE = 'collections/RECEIVE';
export const INVALIDATE = 'collections/INVALIDATE';

export const actions = {
	receiveCollections: createAction(RECEIVE, null, function metaCreator (payload, meta) {
		if (meta && isObject(meta)) {
			return meta;
		}

		if (meta && !isObject(meta)) {
			handleError("receiveCollections meta argument should only be an object");
		}
	}),
	invalidate : createAction(INVALIDATE)
};

export const receive = (collections, meta) => dispatch => dispatch(actions.receiveCollections(collections, meta));
