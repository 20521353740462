"use strict";
import _ from 'lodash';
import delayedGet from '../../helpers/delayedGet';

export const propsToDiff = [
	'status',
	'confirmedByUserIds',
];

export const getInitialState = function getInitialState () {
	return _.keyBy(_.get(window, ['gData', 'redux', 'collections', 'gameVenueStatusCollection', 'list'], []), 'gameVenueStatusId');
};

/**
 * Get gameVenueStatuses
 *
 * @param p.gameIds - gameVenueStatusCollection is a one to one mapping to the game object and serves as an addendum
 */
export const get = (p) => {
	return delayedGet({
		key: 'gameVenueStatus',
		method: 'games.venues.statuses.get',
		idsKey: 'gameIds',
		idsReceiveKey : 'gameVenueStatusIds',
		ids: p.gameIds
	});
};
