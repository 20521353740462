import React from 'react';
import moment from 'moment';
import memoize from 'memoize-one';

import '../../scss/fonts/collegiate.scss';

const styles = {
	svg: {
		display: 'inline-block',
		height: '1em',
		width: '1em',
		verticalAlign: 'middle',
		transform: 'rotate(-20deg)',
		marginLeft: '0.25em',
	},
	path: {
		fill: 'currentColor',
	},
	booster: {
		fontFamily: 'collegiatessinsideregular',
	},
};

const svg = (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		viewBox="0 0 26 26"
		version="1.1"
		width="26px"
		height="26px"
		style={styles.svg}
	>
		<g>
			<path style={styles.path} d="M 25 2 C 24.449219 2 24 2.449219 24 3 L 24 3.8125 L 2 10.34375 L 2 10 C 2 9.449219 1.550781 9 1 9 C 0.449219 9 0 9.449219 0 10 L 0 15 C 0 15.550781 0.449219 16 1 16 C 1.550781 16 2 15.550781 2 15 L 2 14.75 L 24 22.15625 L 24 23 C 24 23.550781 24.449219 24 25 24 C 25.550781 24 26 23.550781 26 23 L 26 3 C 26 2.449219 25.550781 2 25 2 Z M 23 6 L 23 9 L 3 12 L 3 11.5 Z M 6.46875 16.84375 C 6.4375 16.851563 6.40625 16.863281 6.375 16.875 C 6.179688 16.933594 6.027344 17.085938 5.96875 17.28125 L 5.71875 17.90625 C 5.253906 19.296875 6.015625 20.816406 7.40625 21.28125 L 9.90625 22.125 C 11.296875 22.589844 12.816406 21.828125 13.28125 20.4375 L 13.5 19.8125 C 13.59375 19.605469 13.5625 19.367188 13.421875 19.1875 C 13.28125 19.011719 13.054688 18.929688 12.832031 18.976563 C 12.609375 19.023438 12.433594 19.1875 12.375 19.40625 L 12.15625 20.03125 C 12.144531 20.042969 12.136719 20.050781 12.125 20.0625 C 11.867188 20.839844 11.058594 21.257813 10.28125 21 L 7.78125 20.15625 C 7.003906 19.898438 6.617188 19.089844 6.875 18.3125 L 7.09375 17.65625 C 7.167969 17.460938 7.136719 17.242188 7.007813 17.074219 C 6.882813 16.910156 6.675781 16.820313 6.46875 16.84375 Z "/>
		</g>
	</svg>
);

export function hasBoosterClub (user) {
	return user &&
			user.hasBoosterClubUntil &&
			moment.utc(user.hasBoosterClubUntil).isAfter(moment());
}

export default class MegaPhone extends React.Component {
	constructor (props) {
		super(props);
		this.hasBoosterClub = memoize(hasBoosterClub);
	}
	render () {
		const { user } = this.props;
		if (!user) { return null; }
		if (user && this.hasBoosterClub(user)) {
			return (
				<div style={styles.booster}>
					BOOSTER
					{svg}
				</div>
			);
		} else {
			return null;
		}

	}
}
