'use strict';
import PropTypes from 'prop-types';
import React from 'react';
import VarsityLetter from './varsityLetter.jsx';
import Mascot from './mascot.jsx';
import GmBadge from './gmBadge.jsx';
import teamContainer from '../../containers/team';

const styles = {
	wrapper : {
		position : 'relative',
	},
	badgePosition : {
		position : 'absolute',
		bottom : 0,
		right : -5,
	}
};

function MascotOrVarsityLetter (props) {
	let logo;
	if (props.mascotPicture) {
		logo = (
			<Mascot
				fullSize={props.fullSize}
				width={props.width}
				mascotPicture={props.mascotPicture}
				dpiAdjust={props.dpiAdjust}
			/>
		);
	} else if (props.team) {
		logo = (
			<VarsityLetter
				width={props.width}
				style={props.style}
				letter={props.team.varsityLetter}
				color1={props.color1}
				color2={props.color2}
				fullSize={props.fullSize}
			/>
		);
	}

	if (props.showGmBadge) {
		const badgeSize = Math.round(props.width / 2.25);

		return (
			<div style={styles.wrapper}>
				{logo}
				<div style={styles.badgePosition}>
					<GmBadge color1={props.color1} color2={props.color2} size={badgeSize} />
				</div>
			</div>
		);
	} else {
		return logo;
	}
}
MascotOrVarsityLetter.propTypes = {
	team : PropTypes.object.isRequired,
	mascotPicture: PropTypes.object,
	color1 : PropTypes.object,
	color2 : PropTypes.object,
	width : PropTypes.number,
	showGmBadge : PropTypes.bool,
	fullSize : PropTypes.bool,
	style : PropTypes.object,
	dpiAdjust: PropTypes.bool,
};
const PureMVL = React.memo(MascotOrVarsityLetter);
export default PureMVL;

class Connected extends React.Component {
	componentWillMount() {
		if (!this.props.team) { this.props.getTeam(); }
	}
	componentDidUpdate() {
		if (!this.props.team) { this.props.getTeam(); }
	}

	render () {
		if (!this.props.team) { return null; }
		return (
			<PureMVL {...this.props} />
		);
	}
}
Connected.propTypes = {
	teamId: PropTypes.number,
};
export const ConnectedMascotOrVarsityLetter = teamContainer(Connected);
