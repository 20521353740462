"use strict";
import _ from 'lodash';
import cached from '../../../../../cachedData/gameSegmentTypes';

function getInitialState () {
	return _.keyBy(cached.collections.gameSegmentTypeCollection.list, 'gameSegmentTypeId');
}

export default {getInitialState};

