import _get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import uniq from 'lodash/uniq';
import waterfall from 'async/waterfall';

import { getApi } from '../../../libs/api';
const api = getApi({name: 'scoreStream'});
import { actions as collectionActions } from '../../actions/collections';


export function getInitialState() {
	const teams = _get(window, 'gData.redux.collections.userTeamRelationshipCollection.list', []);
	return keyBy(teams, 'userTeamRelationshipId');
}

//wraps get and receive
let fetchingHash = {};
let queueTimer = null;
let queue = [];

export const get = teamIds => {
	return (dispatch, getState) => {
		// filter out ones we are already fetching
		teamIds = teamIds.filter(teamId => !fetchingHash[teamId]);
		if (!teamIds.length) { return; }
		const state = getState();
		if (!state.authUser.user) { return; }

		queue = queue.concat(teamIds);
		if (queueTimer) { return; }
		queueTimer = window.setTimeout(() => {
			queueTimer = null;
			waterfall([
				callback => {
					const teamIds = uniq(queue);
					queue = [];
					api.post({
						method: 'users.teams.relationships.get',
						params: {teamIds},
						callback
					});
				}
			], (err, result) => {
				if (err) {
					dispatch({
						type: 'collections/RECEIVE',
						error: true,
						payload: err
					});
				} else {
					dispatch(collectionActions.receiveCollections(result.collections));
				}
				result.collections
					.userTeamRelationshipCollection
					.list
					.forEach(utr => delete fetchingHash[utr.teamId]);
			});
		}, 20);
	};
};
