
import { importLevelsOfPlayAndOrgs } from '../../helpers/importCachedData';

function getInitialState () {
	return {};
}

export default {getInitialState};

export const loadOrganizations = function loadOrgs () {
	return function (dispatch) {
		return importLevelsOfPlayAndOrgs(dispatch);
	};
};
