"use strict";
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import UserRankIcon from './userRankIcon.jsx';
import * as userRankHelpers from '../../libs/users/userRanks';

const styles = {
	rightIcon: {
		left: 'auto',
		right: '-5%',
		transform: 'scaleX(-1)',
		'filter': 'FlipH'
	},
};

function getFontSize (props) {
	const scaling = (props.size >= 120) ? 0.125 : 0.15;
	return Math.round(props.size * scaling) - 1;
}

class UserRankForBadge extends React.Component {
	constructor (props) {
		super(props);
		const fontSize = getFontSize(props);
		this.state = {
			style: _.assignIn({
				width : props.size,
				textAlign: 'center',
				position: 'absolute',
				bottom: -5,
				backgroundColor : userRankHelpers.getUserRankColor({
					userRankId: props.userRank.userRankId,
					isSuspended: props.isSuspended
				}),
				borderRadius: Math.floor(fontSize),
				color: '#fff',
				lineHeight: '170%',
				fontWeight : 200,
				textTransform : 'uppercase',
				fontSize
			}, props.style)
		};
	}

	componentWillReceiveProps (nextProps) {
		if (nextProps.style !== this.props.style ||
				nextProps.size !== this.props.size ||
				nextProps.isSuspended !== this.props.isSuspended) {
			const fontSize = getFontSize(nextProps);
			this.setState({
				style: _.assignIn({
					width : nextProps.size,
					textAlign: 'center',
					position: 'absolute',
					bottom: -5,
					backgroundColor : userRankHelpers.getUserRankColor({
						userRankId: nextProps.userRank.userRankId,
						isSuspended: nextProps.isSuspended
					}),
					borderRadius: Math.floor(fontSize),
					color: '#fff',
					lineHeight: '170%',
					fontWeight : 200,
					textTransform : 'uppercase',
					fontSize
				}, nextProps.style)
			});
		}
	}

	render () {
		const { userRank, isSuspended } = this.props;
		if (!userRank) { return null; }

		const { style, } = this.state;
		const fontSize = getFontSize(this.props);

		const text = (isSuspended) ? 'Suspended' : userRank.display;

		return (
			<div style={style}>
				<UserRankIcon
					fontSize={fontSize}
					isSuspended={isSuspended}
					userRank={userRank}
				/>
				{text}
				{_.get(userRank, 'userRankId') > 800 &&
					<UserRankIcon
						fontSize={fontSize}
						isSuspended={isSuspended}
						style={styles.rightIcon}
						userRank={userRank}
					/>
				}
			</div>
		);
	}
}

UserRankForBadge.propTypes = {
	isSuspended: PropTypes.bool,
	size : PropTypes.number.isRequired,
	userRank : PropTypes.object,
	style: PropTypes.object,
};

export default UserRankForBadge;
