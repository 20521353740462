import { createAction, handleActions } from 'redux-actions';
import _ from 'lodash';
import update from 'immutability-helper';

import { getApi } from '../../../libs/api';

const ssApi = getApi({name: 'scoreStream'});
const SET = 'realtime/authUserLocation/SET';

export default handleActions({
	[SET] : (state, action) => {
		return update(state, {
			location : {$set: action.payload},
		});
	},
}, {
	location: undefined,
});

const actions = {
	set : createAction(SET),
};

let apiFetching = false;
let apiFetched = false;
export function loadAuthUserLocation () {
	return function dispatchLoadAuthUserLocation(dispatch, getState) {
		if (!apiFetched && !apiFetching) {
			apiFetching = true;
			ssApi.post({
				method: 'visitors.getLocation',
				params: {},
				callback: (err, r) => {
					apiFetching = false;
					if (err || !r || !r.latitude || !r.longitude) {
						if (err) {
							console.warn(`problem with visitors.getLocation`, err);
						}

						//- Fallback
						const state = getState();
						dispatch(actions.set(_.get(state, 'authUser.user.location')));
					} else {
						apiFetched = true;

						//- Got location from client, set lat/lng
						dispatch(actions.set({
							latitude: r.latitude,
							longitude: r.longitude,
						}));
					}
				}
			});
		}
	};
}
