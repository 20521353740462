/*global window:false*/
import _ from 'lodash';
const { useState, useEffect } = require('react');

/**
 * React hook that provides you with a windowSize object, only adds one listener per app and throttles the callback
 * for not over rendering
 * @type {Array}
 */

let cbs = [];
let listener;

function sub(cb) {
	cbs.push(cb);
	if (!listener) {
		start();
	}
}
function unsub(cb) {
	cbs = cbs.filter((savedCb) => savedCb !== cb);
	if (cbs.length === 0) {
		stop();
	}
}
function start() {
	listener = _.throttle(() => {
		cbs.forEach((cb) => {
			const size = getSize();
			cb(size);
		});
	}, 100);

	window.addEventListener('resize', listener);
}
function stop() {
	window.removeEventListener('resize', listener);
	listener = undefined;
}

function getSize() {
	return {
		innerHeight: window.innerHeight,
		innerWidth: window.innerWidth,
		outerHeight: window.outerHeight,
		outerWidth: window.outerWidth,
	};
}

function useWindowSize() {
	let [windowSize, setWindowSize] = useState(getSize());

	useEffect(() => {
		sub(setWindowSize);
		return () => {
			unsub(setWindowSize);
		};
	}, []);

	return windowSize;
}

export default useWindowSize;
