import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from '../../../../../common/libs/routing';

const styles = {
	iconContainer: {
		marginLeft: 4,
		marginRight: 4,
	},
	iconContainerNoMargin: {
		marginLeft: 0,
		marginRight: 0,
	},
	icon : {
		width: 24,
		height: 24,
	},
	link: {
		textDecoration: 'none',
	}
};

function MenuListItem(props) {
	const {
		text,
		icon,
		iconSrc,
		href,
		linkTo,
		onClick,
		noMargin,
		hideMenu,
	} = props;

	const content = (
		<ListItem button>
			<ListItemIcon style={noMargin ? styles.iconContainerNoMargin : styles.iconContainer}>
				{iconSrc ?
					<img style={styles.icon} src={iconSrc} alt={text} /> :
					icon ? icon :
						<div style={styles.icon} />
				}
			</ListItemIcon>
			<ListItemText>{text}</ListItemText>
		</ListItem>
	);

	if (href) {
		return (
			<a href={href} style={styles.link}>
				{content}
			</a>
		);
	} else if (linkTo) {
		return (
			<Link to={linkTo} onClick={hideMenu} style={styles.link}>
				{content}
			</Link>
		);
	} else if (onClick) {
		return (
			<div onClick={() => {
				if (hideMenu) { hideMenu(); }
				onClick();
			}}>
				{content}
			</div>
		);
	} else {
		return content;
	}
}
MenuListItem.propTypes = {
	text: PropTypes.string.isRequired,
	icon: PropTypes.node,
	iconSrc: PropTypes.string,
	href: PropTypes.string,
	onClick: PropTypes.func,
	linkTo: PropTypes.string,
	noMargin: PropTypes.bool,
	hideMenu: PropTypes.func,
};

export default React.memo(MenuListItem);