
/* 
	FILE AUTOMATICALLY GENERATED BY RUNNING npm run build buildCachedData
	DO NOT EDIT THIS FILE
	QUESTIONS: Ask Ryan
*/

module.exports = {
  "collections": {
    "trustLevelCollection": {
      "list": [
        {
          "trustLevelId": 1,
          "display": "New"
        },
        {
          "trustLevelId": 10,
          "display": "Low",
          "upgradeValue": 25
        },
        {
          "trustLevelId": 20,
          "display": "Medium",
          "upgradeValue": 100
        },
        {
          "trustLevelId": 30,
          "display": "High",
          "upgradeValue": 250
        },
        {
          "trustLevelId": 40,
          "display": "Very High",
          "upgradeValue": 1000
        },
        {
          "trustLevelId": 990,
          "display": "Questionable",
          "downgradeValue": -25
        },
        {
          "trustLevelId": 999,
          "display": "Blackballed",
          "downgradeValue": -100,
          "canOnlyChangeManually": true
        },
        {
          "trustLevelId": 500,
          "display": "Admin",
          "manualOnly": true,
          "canOnlyChangeManually": true
        },
        {
          "trustLevelId": 510,
          "display": "Ignored",
          "manualOnly": true,
          "canOnlyChangeManually": true
        }
      ]
    }
  }
};
