'use strict';
import isFunction from 'lodash/isFunction';

export function create (type) {
	let evt;
	if ('Event' in window && isFunction(Event)) {
		evt = new Event(type, {bubbles: true, cancelable: true});
	} else {
		//must be IE
		evt = document.createEvent('Event');
		evt.initEvent(type, true, true);
	}
	return evt;
}

export function trigger (elem, eventType) {
	elem && elem.dispatchEvent && elem.dispatchEvent(create(eventType));
}
