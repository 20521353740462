"use strict";
import _ from "lodash";
import parallel from "async/parallel";
import waterfall from "async/waterfall";

import { handleError, handleApiError } from "../../../libs/errorHandler";
import { getApi } from "../../../libs/api";
const api = getApi({ name: "scoreStream" });
import fileDownload from "../../../libs/fileDownload.js";
import genericActionCallback from "../../helpers/genericActionCallback";
import { actions as collectionActions } from "../../actions/collections";

function getInitialState() {
  const gamePosts = _.get(window, [
    "gData",
    "redux",
    "collections",
    "gamePostCollection",
    "list",
  ]);
  if (gamePosts) {
    return _.keyBy(gamePosts, "gamePostId");
  } else {
    return {};
  }
}

let fetchingHashes = {
  get: {
    regular: {},
    withSupplement: {},
  },
  search: {},
};
let queueTimers = {
  get: null,
  search: null,
};
let queues = {
  get: {
    regular: [],
    withSupplement: [],
  },
  search: [],
};

/**
 *
 * @param p - {gamePostIds : [ARRAY], includeGamePostSupplements : [BOOLEAN]}
 * When you pass in array of gamePostIds, they get pushed into a queue, if that queue is new it starts
 * a timer that waits 20ms and then fires off. This is so multiple components can request their data but
 * we can have fewer state updates
 * @returns {Function}
 */
export const get = function getGamePosts(p) {
  const fetchingHash = fetchingHashes.get;
  const queue = queues.get;
  let gamePostIds = p.gamePostIds;
  const includeGamePostSupplements = p.includeGamePostSupplements || false;

  return (dispatch) => {
    if (!gamePostIds) {
      return;
    }
    // filter out ones we are already fetching
    gamePostIds = gamePostIds.filter((gamePostId) => {
      if (!includeGamePostSupplements) {
        return (
          !fetchingHash.regular[gamePostId] &&
          !fetchingHash.withSupplement[gamePostId]
        );
      }
      return !fetchingHash.withSupplement[gamePostId];
    });
    if (!gamePostIds.length) {
      return;
    }

    //add to the appropriate fetching hash
    const fetchingKey = includeGamePostSupplements
      ? "withSupplement"
      : "regular";
    fetchingHash[fetchingKey] = gamePostIds.reduce((result, gamePostId) => {
      return _.assign(result, { [gamePostId]: true });
    }, fetchingHash[fetchingKey]);
    queue[fetchingKey] = queue[fetchingKey].concat(gamePostIds);

    if (queueTimers.get) {
      return;
    }
    queueTimers.get = window.setTimeout(function() {
      queueTimers.get = null;

      parallel(
        [
          (callback) => {
            const gamePostIds = _.uniq(
              _.difference(queue.regular, queue.withSupplement)
            );
            if (!gamePostIds || !gamePostIds.length) {
              return callback(null, null);
            }
            queue.regular = [];
            api.post({
              method: "games.posts.get",
              params: { gamePostIds },
              callback,
            });
          },
          (callback) => {
            const gamePostIds = _.uniq(queue.withSupplement);
            if (!gamePostIds || !gamePostIds.length) {
              return callback(null, null);
            }
            queue.withSupplement = [];
            api.post({
              method: "games.posts.get",
              params: { gamePostIds, includeGamePostSupplements: true },
              callback,
            });
          },
        ],
        function(err, results) {
          if (err) {
            dispatch({
              type: "collections/RECEIVE",
              error: true,
              payload: err,
            });
          } else {
            results.forEach((result) => {
              if (!result) {
                return;
              }
              dispatch(
                collectionActions.receiveCollections(result.collections)
              );
            });
          }
          results.forEach((result) => {
            if (!result) {
              return;
            }
            const fetchingKey = result.gamePostSupplementCollection
              ? "withSupplement"
              : "regular";
            result.gamePostIds.forEach((gamePostId) => {
              delete fetchingHash[fetchingKey][gamePostId];
            });
          });
        }
      );
    }, 20);
  };
};

/**
 * @param params
 * @param callback
 * @returns {Function}
 */
export const addCheers = function addCheers(params, callback) {
  const { showError = true } = params;
  return (dispatch) => {
    if (!params.cheers) {
      return;
    }
    waterfall(
      [
        (callback) =>
          api.post({
            method: "games.posts.cheers.add",
            params: _.pick(params, ["gamePostId", "cheers"]),
            callback: genericActionCallback(dispatch, callback),
          }),
      ],
      (err, result) => {
        if (err) {
          if (showError) {
            handleApiError(err);
          }
          return callback(err);
        }
        callback(null, result);
      }
    );
  };
};

export const addComment = function addComment(params, callback) {
  const { showError = true } = params;
  return (dispatch, getState) => {
    if (!params.userText) {
      return;
    }

    if (params.shareSettings) {
      params.teamSelection = params.shareSettings.teamSelection;
    }

    let apiParams;
    if (params.parentGamePostId) {
      const state = getState();
      const parentGamePost =
        state.collections.gamePost[params.parentGamePostId];
      apiParams = {
        gameId: parentGamePost.gameId,
        parentGamePostId: params.parentGamePostId,
        teamId: parentGamePost.teamId,
        teamSelection: params.teamSelection,
        userId: parentGamePost.userId,
        userText: params.userText,
      };
    } else {
      apiParams = _.pick(params, [
        "gameId",
        "teamId",
        "userId",
        "userText",
        "teamSelection",
      ]);
    }

    let apiMethod;
    if (apiParams.gameId) {
      apiMethod = "games.posts.add";
    }
    if (apiParams.teamId) {
      apiMethod = "teams.gamePosts.add";
    }
    if (apiParams.userId) {
      apiMethod = "users.gamePosts.add";
    }
    apiParams.userNeedsVerificationLevelToPost = 0;
    waterfall(
      [
        (callback) =>
          api.post({
            method: apiMethod,
            params: apiParams,
            callback: genericActionCallback(dispatch, callback),
          }),
      ],
      (err, result) => {
        if (err) {
          if (showError) {
            return handleError(err, {
              title: "Error submitting post",
            });
          }
        }
        callback(err, result);
      }
    );
  };
};

export const search = function searchGamePosts(p) {
  let fetchingHash = fetchingHashes.search;
  let queue = queues.search;
  let { gameIds } = p;

  return (dispatch) => {
    if (!gameIds) {
      return;
    }
    // filter out ones we are already fetching
    gameIds = gameIds.filter((gameId) => !fetchingHash[gameId]);
    if (!gameIds.length) {
      return;
    }

    //add to the appropriate fetching hash
    fetchingHashes.search = gameIds.reduce((result, gameId) => {
      return _.assign(result, { [gameId]: true });
    }, fetchingHash);
    queues.search = queue.concat(gameIds);

    if (queueTimers.search) {
      return;
    }
    queueTimers.search = window.setTimeout(function() {
      queueTimers.search = null;
      const gameIds = queues.search;

      waterfall(
        [
          (callback) => {
            if (!gameIds || !gameIds.length) {
              return callback(null, null);
            }
            queues.search = [];
            api.post({
              method: "games.posts.search",
              params: { gameIds },
              callback,
            });
          },
        ],
        function(err, result) {
          if (err) {
            dispatch({
              type: "collections/RECEIVE",
              error: true,
              payload: err,
            });
          } else {
            dispatch(collectionActions.receiveCollections(result.collections));
          }
          gameIds.forEach((id) => delete fetchingHashes.search[id]);
        }
      );
    }, 20);
  };
};

export const downloadPostComposite = function downloadPostComposite({
  gamePostId,
}) {
  return (dispatch, getState) => {
    const state = getState();
    const gamePost = _.get(state, ["collections", "gamePost", gamePostId]);

    if (!gamePost) {
      return handleError("missing gamePost in state for" + gamePostId);
    }

    const composite = _.get(state, [
      "collections",
      "composite",
      gamePost.compositeId,
    ]);

    if (!composite) {
      return handleError(
        "missing composite for gamePost in state for " + gamePost.compositeId
      );
    }

    const url = composite.compositePictureUrl || composite.videoCompositeUrl;
    const text = `gp-${gamePost.gamePostId}-c-${gamePost.compositeId}`;
    fileDownload(url, text);
  };
};

export const downloadPostOriginal = function downloadPostOriginal({
  gamePostId,
}) {
  return (dispatch, getState) => {
    const state = getState();
    const gamePost = _.get(state, ["collections", "gamePost", gamePostId]);
    if (!gamePost) {
      return handleError("missing gamePost in state for " + gamePostId);
    }
    const composite = _.get(state, [
      "collections",
      "composite",
      gamePost.compositeId,
    ]);
    if (!composite) {
      return handleError(
        "missing composite for gamePost in state for " + gamePost.compositeId
      );
    }

    const url = composite.backgroundPictureUrl || composite.videoUrl;
    const text = `gp-${gamePost.gamePostId}-c-${gamePost.compositeId}`;
    fileDownload(url, text);
  };
};

export const updateGamePost = function updateGamePost({ gamePostId, updates }) {
  return (dispatch) => {
    waterfall(
      [
        (callback) =>
          api.post({
            method: "games.posts.update",
            params: { ...updates, gamePostId },
            callback: genericActionCallback(dispatch, callback),
          }),
      ],
      (err) => {
        if (err) {
          return handleError(err, {
            title: "Error submitting updates",
          });
        }
      }
    );
  };
};

export { getInitialState };
export const propsToDiff = ["cheers", "childrenGamePostIds", "hideLevel"];
