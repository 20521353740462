"use strict";
import _get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import delayedGet from '../../helpers/delayedGet';

export const propsToDiff = [
	'rootingFor',
	'atTheGame',
	'gamePoints',
];

export const getInitialState = function getInitialState() {
	return keyBy(_get(window, ['gData', 'redux', 'collections', 'gameFanCollection', 'list'], []), 'gameFanId');
};

export const get = function getGameMetrics (p) {
	return delayedGet({
		key: 'gameFan',
		method: 'games.fans.get',
		idsKey: 'gameFanIds',
		ids: p.gameFanIds
	});
};

export const search = function getGameMetrics (p) {
	return delayedGet({
		key: 'gameFan',
		method: 'games.fans.search',
		idsKey: 'gameIds',
		ids: p.gameIds
	});
};
