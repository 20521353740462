import { createMuiTheme } from '@material-ui/core/styles';
import { vars } from "../libs/sharedStyles";

export default createMuiTheme({
	palette: {
		type: 'light',
		primary: {
			main: vars.colors.ssBlue,
			light: vars.colors.ssBlueHighlight,
			dark: vars.colors.ssDarkBlue,
			contrastText: '#FFF',
		},
		secondary: {
			main: vars.colors.ssYellow,
			light: vars.colors.ssLighterYellow,
			dark: vars.colors.ssDarkYellow,
			contrastText: '#000',
		},
	},
	typography: {
		useNextVariants: true,
		htmlFontSize: 10,
	},
	overrides: {
		MuiButton: {
			root: {
				textDecoration: 'none',
				'&:focus': {
					textDecoration: 'none',
				}
			},
			// If you make an "MuiButton" that has an href it is an anchor tag, but these styles are not explicit and they are being picked up by bootstrap
			containedPrimary: {
				//color: '#FFF',
				'&:focus': {
					color: '#FFF',
				},
				'&:hover': {
					color: '#FFF',
				}
			}
		},
		MuiDialog: {
			paperWidthSm: {
				maxWidth: 'initial',
				margin: `20px 15px`,
			}
		},
		MuiSwitch: {
			icon: {
				border: `1px solid rgba(125, 125, 125, .2)`,
			}
		}
	}
});