import React from 'react';
import PropTypes from 'prop-types';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hasError: false
		};
	}

	componentDidCatch(error, info) {
		this.setState({
			hasError: true,
			error,
			info
		});
	}

	render() {
		if (this.state.hasError) {
			const style = this.props.style || {
				zIndex: 0,
				padding: 10,
				margin: `70px 10px`,
				border: `1px solid rgba(255, 205, 205, .5)`,
				color: `rgba(25, 25, 25, .75)`,
				background: `rgba(255, 225, 225, .5)`
			};

			if (this.props.hidden) {
				return <div/>;
			} else {
				return (
					<div style={style}>
						{this.props.message}
					</div>
				);
			}
		}

		return this.props.children;
	}
}
ErrorBoundary.propTypes = {
	children: PropTypes.node,
	message: PropTypes.string,
	style: PropTypes.object,
	hidden: PropTypes.bool,
};
ErrorBoundary.defaultProps = {
	message: `There was a problem loading a component`,
	hidden: false,
};

export default ErrorBoundary;
