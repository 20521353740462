"use strict";
import { createAction, handleActions } from 'redux-actions';
import _ from 'lodash';
import update from 'immutability-helper';
import waterfall from 'async/waterfall';
import { handleError } from "../../../libs/errorHandler";
import { getApi } from '../../../libs/api';
const api = getApi({name: 'scoreStream'});
import { receive as receiveCollections } from '../../actions/collections';
import { getFollowers } from '../../../../realtime/redux/modules/routes/user';

const FOLLOW = 'realtime/following/FOLLOW';
const UNFOLLOW = 'realtime/following/UNFOLLOW';
const GET = 'realtime/following/FETCH';
const RECEIVE = 'realtime/following/RECEIVE';
let requestsPending = [];

function getInitialState() {
	const items = _.get(window, ['gData', 'authUserFollowing']);

	if (items) {
		return {
			fetching: false,
			hasReceived: true,
			items: items
		};
	} else {
		return {
			fetching: false,
			hasReceived: false,
			items: []
		};
	}
}

export default handleActions({
	[FOLLOW]: {
		next: (state, action) => update(state, {
			items: {$push: action.payload}
		}),
		throw: (state, action) => {
			handleError('Error following user', action.payload);
			return state;
		}
	},
	[UNFOLLOW]: {
		next: (state, action) => {
			return update(state, {
				items: {$set: _.differenceWith(
					state.items, action.payload, (item, id) => item.followingUserId === id
				)}
			});
		},
		throw: (state, action) => {
			handleError('Error unfollowing user', action.payload);
			return state;
		}
	},
	[GET]: state => _.assignIn({}, state, {
		fetching: true,
		hasReceived : false,
		items: []
	}),
	[RECEIVE]: {
		next: (state, action) => {
			const items = _.uniq(state.items.concat(action.payload));
			return update(state, {$set: {items, fetching: false, hasReceived: true}});
		},
		throw: (state, action) => {
			handleError('Problem getting following list', action.payload);
			return update(state, {$set: {fetching: false}});
		}
	}
}, getInitialState());

const addUsersToFollowing = createAction(FOLLOW);
const removeUsersFromFollowing = createAction(UNFOLLOW);
const getFollowingAction = createAction(GET);
const receiveFollowing = createAction(RECEIVE);

export const getFollowing = () => (dispatch, getState) => {
	const state = getState();

	if (state.authUser.following.hasReceived) { return console.info('already have the users following list'); }
	if (state.authUser.following.fetching) { return console.info('already in teh process of fetching followers'); }

	if (!_.get(state.authUser.user, 'userId')) {
		return dispatch(receiveFollowing(Error('Need to be logged in to get following')));
	}
	dispatch(getFollowingAction());
	
	waterfall([
		callback => api.post({
			method: 'users.followers.search',
			params: {userIds: [state.authUser.user.userId]},
			callback
		})
	], (err, result) => {
		if (err) {
			handleError(err);
			return dispatch(receiveFollowing(Error('Unable to get following')));
		}
		dispatch(receiveCollections(result.collections));
		dispatch(receiveFollowing(result.collections.userFollowerCollection.list));
	});
};

export const followUsers = ({ userIds, callback = _.noop }) => {
	const reqIds = userIds.filter(userId => !_.includes(requestsPending, userId));
	if (!reqIds.length) { return console.log('Requests are pending for all users'); }
	requestsPending = requestsPending.concat(reqIds);
	return (dispatch, getState) => waterfall([
		callback => api.post({
			method: 'users.followers.add',
			params: {userIds: reqIds},
			callback
		}),
		(result, callback) => {
			const list = result.collections.userFollowerCollection.list;
			dispatch(addUsersToFollowing(list));
			const state = getState();
			const currentUserPage = _.get(state, ['routes', 'user', 'currentUserId']);
			if (!currentUserPage) { return callback(); }
			if (_.some(list, f => f.followingUserId === currentUserPage)) {
				//I've clicked 'follow' while on the user's profile
				dispatch(getFollowers({
					stateKey: {path: 'routes/user/followers'},
					userId: currentUserPage
				}));
			}
			callback();
		}
	], (err, result) => {
		requestsPending = requestsPending.filter(userId => !_.includes(reqIds, userId));
		if (err) {
			dispatch(addUsersToFollowing(Error('Unable to follow user(s)')));
			return callback(err);
		}
		return callback(null, result);
	});
};

export const unfollowUsers = ({ userIds, callback = _.noop }) => dispatch => {
	const reqIds = userIds.filter(userId => !_.includes(requestsPending, userId));
	if (!reqIds.length) { return dispatch(addUsersToFollowing(Error('Requests are pending for all users'))); }
	requestsPending = requestsPending.concat(reqIds);
	waterfall([
		callback => api.post({
			method: 'users.followers.remove',
			params: {userIds: reqIds},
			callback
		}),
		(result, callback) => {
			dispatch(removeUsersFromFollowing(reqIds));
			return callback();
			/*
			Commented this out because it is jarring when you are on your own lists of things and start unfollowing people, it also doesn't
			give you a chance to "undo" this way if you click it, you can click it back

			const state = getState();
			const currentUserPage = _.get(state, ['routes', 'user', 'currentUserId']);
			if (!currentUserPage) { return callback(); }
			if (_.some(userIds, f => f === currentUserPage)) {
				//I've clicked 'unfollow' on a user somewhere
				dispatch(getFollowers({userId: currentUserPage}));
			}
			callback();
			*/
		}
	], (err, result) => {
		requestsPending = requestsPending.filter(userId => !_.includes(reqIds, userId));
		if (err) {
			dispatch(removeUsersFromFollowing(Error('Unable to unfollow user(s)')));
			return callback(err);
		}
		return callback(null, result);
	});
};
