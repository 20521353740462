import React from 'react';
import Button from '@material-ui/core/Button';

function SignupButton() {
	return (
		<Button
			variant="contained"
			color="secondary"
			href="/simpleSignup"
			style={{
				margin: `5px 4px`,
			}}
		>
			Signup
		</Button>
	);
}
export default SignupButton;
