import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from "@material-ui/core/styles/withStyles";
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import ToolTip from '@material-ui/core/Tooltip';
import SortIcon from '@material-ui/icons/Sort';
import ClearIcon from '@material-ui/icons/Clear';
import clsx from 'clsx';
import { SUGGESTIONS_MAX_HEIGHT } from "./getTheme";
import { vars } from '../../libs/sharedStyles';
import { applyColorLuminance } from "../../libs/colors";

function getStyles(theme) {
	return {
		container : {
			display: 'flex',
			flexDirection: 'row',
			fontSize: 15,
		},
		teamTierList: {
			opacity: 1,
			height: '100%',
			maxHeight: SUGGESTIONS_MAX_HEIGHT,
			background: 'rgba(125,125,125,.2)',
			overflowY: 'auto',
			transition: '.2s',
			width: 110,
			[theme.breakpoints.up('sm')] : {
				width: 130,
			}
		},
		mainList: {
			flex: 1,
			height: '100%',
			minHeight: 140,
			maxHeight: SUGGESTIONS_MAX_HEIGHT,
			overflowY: 'auto',
			borderLeft: `1px solid #e7e7e7`,
		},
		option: {
			display: 'flex',
			fontWeight: 100,
			letterSpacing: `-.5px`,
			textAlign: 'left',
			alignItems: 'center',
			padding: `8px 3px 8px 10px`,
			cursor: 'pointer',
			transition: `.2s ease`,

			fontSize: 12,
			[theme.breakpoints.up('sm')] : {
				fontSize: 14
			}
		},
		optionForward: {
			'&:hover': {
				background: 'rgba(125,125,125,.2)'
			}
		},
		optionIcon: {
			fontSize: 18,
			marginRight: 3,
		},
		optionSelected: {
			color: vars.colors.ssBlue,
			background: '#FFFFFF',
			'&:hover': {
				color: applyColorLuminance('#FFFFFF', 0.1),
				background: applyColorLuminance(vars.colors.ssBlue, 0.1),
			}
		},
		showTiersSide: {
			opacity: 1,
			marginTop: 8,
			transition: '.2s',
			overflow: 'hidden',
			//wider on mobile for an actual click target
			width: 30,
			[theme.breakpoints.up('sm')] : {
				width: 24,
			}
		},
		showTiersIcon: {
			cursor: 'pointer',
			color: vars.colors.ssBlue,
			transition: '.2s',
			'&:hover': {
				color: vars.colors.ssDarkBlue,
			},
			fontSize: 25,
			[theme.breakpoints.up('sm')] : {
				fontSize: 22,
			}
		},
		hiddenSide: {
			width: 0,
			opacity: 0,
		},
	};
}

const calcTierOptions = _.memoize(function getTierOptions(teamTierId, teamTierMap) {
	const tier = teamTierMap[teamTierId];
	if (!tier) {
		return [{
			display: 'All',
			teamTierId: 1,
		}];
	}

	const { childTeamTierIds, display } = tier;

	const mainTier = {
		display,
		teamTierId,
		isSelected: true,
	};

	let parentTeamTierId;
	if (teamTierId !== 1) {
		_.forEach(teamTierMap, (value) => {
			if (parentTeamTierId) { return; }

			if (value.childTeamTierIds && value.childTeamTierIds.indexOf(teamTierId) > -1) {
				parentTeamTierId = value.teamTierId;
			}
		});
	}

	if (parentTeamTierId) {
		mainTier.parentTeamTierId = parentTeamTierId;
	}

	const options = [mainTier];

	if (childTeamTierIds) {
		childTeamTierIds.forEach((childTeamTierId) => {
			const tier = teamTierMap[childTeamTierId];
			if (!tier) { return; }

			const { display, teamTierId } = tier;

			options.push({
				teamTierId,
				display
			});
		});
	}

	return options;
});

function getTierOptions(teamTierId, teamTierMap) {
	if (_.keys(teamTierMap).length === 0) {
		return [];
	}

	return calcTierOptions(teamTierId, teamTierMap);
}

const LOCAL_STORAGE_KEY = 'ss-omnisearch-teamtierfilters-visible';
const localStorage = window && window.localStorage;
function setStoredIsVisible(isVisible) {
	if (!localStorage) { return; }

	localStorage.setItem(LOCAL_STORAGE_KEY, isVisible);
}
function getStoredIsVisible() {
	if (!localStorage) { return false; }

	const res = localStorage.getItem(LOCAL_STORAGE_KEY);
	if (!res) {
		return false;
	} else {
		try {
			const parsed = JSON.parse(res);
			return parsed;
		} catch (e) {
			return false;
		}
	}
}


class TeamTierFilters extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isVisible: getStoredIsVisible(),
		};

		this.showTiers = () => {
			setStoredIsVisible(true);
			this.setState({isVisible: true});
		};
		this.hideTiers = () => {
			setStoredIsVisible(false);
			this.setState({isVisible: false});
		};

		this.onSelect = this.onSelect.bind(this);
	}

	onSelect(teamTierId) {
		this.props.setTeamTier({
			teamTierId
		});
	}

	render() {
		const {
			teamTierMap,
			teamTierId,
			children,
			classes,
		} = this.props;
		const { isVisible } = this.state;

		const tierOptions = getTierOptions(teamTierId, teamTierMap);

		return (
			<div className={classes.container}>
				<div
					className={clsx(classes.showTiersSide, {
						[classes.hiddenSide]: isVisible,
					})}
				>
					<ToolTip title="Show team filters" enterDelay={500}>
						<SortIcon
							onClick={this.showTiers}
							classes={{
								root: classes.showTiersIcon
							}}
						/>
					</ToolTip>
				</div>

				<div 
					className={clsx(classes.teamTierList, {
						[classes.hiddenSide]: !isVisible	
					})}
				>
					{tierOptions.map(({display, parentTeamTierId, teamTierId, isSelected}) => {
						const onClick = (teamTierId === 1) ? this.hideTiers :
							(parentTeamTierId) ?
								this.onSelect.bind(this, parentTeamTierId) :
								this.onSelect.bind(this, teamTierId);

						return (
							<div
								key={teamTierId}
								className={clsx(classes.option, {
									[classes.optionForward]: !isSelected && teamTierId,
									[classes.optionSelected]: isSelected,
								})}
								onClick={onClick}
							>
								{parentTeamTierId && <NavigateBeforeIcon classes={{root: classes.optionIcon}}/>}
								{teamTierId === 1 && <ClearIcon classes={{root: classes.optionIcon}}/>}
								{display}
							</div>
						);
					})}
				</div>

				<div className={classes.mainList}>
					{children}
				</div>
			</div>
		);
	}
}
TeamTierFilters.propTypes = {
	teamTierId: PropTypes.number,
	teamTierMap: PropTypes.object,
	children: PropTypes.node,
	classes: PropTypes.object,
	setTeamTier: PropTypes.func,
};
TeamTierFilters.defaultProps = {
	teamTierId : 1,
};

export default withStyles(getStyles)(TeamTierFilters);
