"use strict";
import _ from 'lodash';
import cached from '../../../../../cachedData/gameSegments';

function getInitialState () {
	return _.keyBy(cached.collections.gameSegmentCollection.list, 'gameSegmentId');
}

export default {getInitialState};

