exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Imports
var urlEscape = require("../../../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("./NimbusSanNovConBla/NimbusSanNovConBla.eot"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("./NimbusSanNovConBla/NimbusSanNovConBla.eot") + "?#iefix");
var ___CSS_LOADER_URL___2___ = urlEscape(require("./NimbusSanNovConBla/NimbusSanNovConDBla.ttf"));
var ___CSS_LOADER_URL___3___ = urlEscape(require("./NimbusSanNovConBla/NimbusSanNovConBla.woff"));

// Module
exports.push([module.id, "@font-face {\n  font-family: 'nimbusSanNovConBla';\n  font-weight: normal;\n  font-style: normal;\n  src: url(" + ___CSS_LOADER_URL___0___ + ");\n  src: url(" + ___CSS_LOADER_URL___1___ + ") format(\"embedded-opentype\"), url(" + ___CSS_LOADER_URL___2___ + ") format(\"truetype\"), url(" + ___CSS_LOADER_URL___3___ + ") format(\"woff\"); }\n\n.nimbus {\n  font-family: nimbusSanNovConBla; }\n", "",{"version":3,"sources":["/home/ec2-user/stagingProjects/ui-components/ui-components/src/common/scss/fonts/nimbusSanNovConBla.scss"],"names":[],"mappings":"AAAA;EACC,iCAAiC;EAC9B,mBAAmB;EACnB,kBAAkB;EACrB,kCAAuD;EACvD,8JAEyE,EAAA;;AAI1E;EACC,+BAA+B,EAAA","file":"nimbusSanNovConBla.scss","sourcesContent":["@font-face {\n\tfont-family: 'nimbusSanNovConBla';\n    font-weight: normal;\n    font-style: normal;\n\tsrc: url('./NimbusSanNovConBla/NimbusSanNovConBla.eot');\n\tsrc: url('./NimbusSanNovConBla/NimbusSanNovConBla.eot?#iefix') format('embedded-opentype'),\n\t\turl('./NimbusSanNovConBla/NimbusSanNovConDBla.ttf') format('truetype'),\n        url('./NimbusSanNovConBla/NimbusSanNovConBla.woff') format('woff');\n\n}\n\n.nimbus {\n\tfont-family: nimbusSanNovConBla;\n}\n"]}]);

