"use strict";
import _ from 'lodash';
import delayedGet from './../../helpers/delayedGet';

export const propsToDiff = {};

export const getInitialState = function getInitialState() {
	return _.keyBy(_.get(window, ['gData', 'redux', 'collections', 'locationSupplementCollection', 'list'], []), 'locationSupplementId');
};

export const get = (p) => {
	return delayedGet({
		key: 'locationSupplement',
		method: 'locations.get',
		idsKey: 'locationIds',
		ids: p.locationIds,
		extraParams : {
			includeLocationSupplements : true
		}
	});
};
