"use strict";
import { createAction, handleActions } from 'redux-actions';
import _ from 'lodash';
import update from 'immutability-helper';
import waterfall from 'async/waterfall';

import { handleError } from "../../../libs/errorHandler";
import { getApi } from '../../../libs/api';
const api = getApi({name: 'scoreStream'});

const RECEIVE = 'authUser/shareSettingsForGame/RECEIVE';
const actions = {
	receive: createAction(RECEIVE),
};

function getInitialState() {
	return _.keyBy(_.get(window, ['gData', 'redux', 'collections', 'userGameShareSettingCollection', 'list'], []), 'gameId');
}

export default handleActions({
	[RECEIVE]: {
		next: (state, action) => {
			return update(state, {
				[action.payload.gameId]: {$set: action.payload.item},
			});
		},
		throw: (state, action) => {
			handleError('Problem receiving game share settings', action.payload);
			return state;
		}
	},
}, getInitialState());

export const getShareSettings = p => dispatch => {
	const { callback = _.noop, gameId, } = p;
	waterfall([
		callback => api.post({
			method: 'users.games.getShareSettings',
			params: {gameId},
			callback,
		}),
	], (err, result) => {
		if (err) { return callback(err); }
		const item = _.get(result, ['collections', 'userGameShareSettingCollection', 'list', 0], null);
		dispatch(actions.receive({gameId, item}));
		callback(null, item);
	});
};

export const setShareSettings = p => (dispatch, getState) => {
	const {
		atTheGame,
		gameId,
		teamSelection,
		callback = _.noop,
	} = p;
	const shareSettings = _.get(getState(), ['authUser', 'shareSettingsForGames', gameId]);
	if (shareSettings &&
			shareSettings.teamSelection === teamSelection &&
			shareSettings.atTheGame === atTheGame) {
		//nothing changed, don't need to send
		return;
	}
	const method = (shareSettings) ? 'users.games.options.update' : 'users.games.share';
	waterfall([
		callback => api.post({
			method,
			params: {gameId, teamSelection, atTheGame},
			callback
		})
	], (err, result) => {
		if (err) { return callback(err); }
		const item = _.get(result, ['collections', 'userGameShareSettingCollection', 'list', 0], null);
		dispatch(actions.receive({gameId, item}));
		callback(null, result);
	});
};
