export default function downloadFile (url, text) {
	text = text || url;

	const element = document.createElement('a');

	const downloadUrl = '/ptDownload?ptUrl=' + encodeURIComponent(url);
	element.setAttribute('href', downloadUrl);
	element.setAttribute('download', text);
	element.style.display = 'none';
	document.body.appendChild(element);
	element.click();
	document.body.removeChild(element);
}
