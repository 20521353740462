'use strict';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';

function getInitialState () {
	const coll = get(window, 'gData.redux.collections.alternateTeamNameCollection');
	if (coll) {
		return keyBy(coll.list, 'alternateTeamNameId');
	} else {
		return {};
	}
}

const propsToDiff = {};
export default {getInitialState, propsToDiff};
