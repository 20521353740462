"use strict";
import _ from "lodash";
import moment from "moment";
import waterfall from "async/waterfall";

import { handleError, handleApiError } from "../../../libs/errorHandler";
import { getApi } from "../../../libs/api";
const api = getApi({ name: "scoreStream" });
import delayedGet from "../../helpers/delayedGet";
import genericActionCallback from "../../helpers/genericActionCallback";
import { actions as collectionActions } from "../../actions/collections";
import * as listsRedux from "../lists";

export const propsToDiff = [
  //ids (relations / mappings)
  "awayTeamId",
  "homeTeamId",
  "awaySquadId",
  "homeSquadId",
  "sportId",
  "venueId",
  "gameSegmentTypeId",
  // game props
  "startDateTime",
  "homeTeamCheers",
  "awayTeamCheers",
  "lastScore",
  "boxScores",
  "totalPosts", // encapsulates totalChats, totalVideos, etc...
];

function formatter(game) {
  const lastScore = game.lastScore;

  if (lastScore && lastScore.gameSegmentId === 19999) {
    if (lastScore.awayTeamScore > lastScore.homeTeamScore) {
      game.awayVictory = lastScore.awayVictory = true;
    } else if (lastScore.homeTeamScore > lastScore.awayTeamScore) {
      game.homeVictory = lastScore.homeVictory = true;
    }
  }

  if (game.startDateTime) {
    const startDateTimeMoment = moment.utc(game.startDateTime).local();
    game.minDate = startDateTimeMoment.calendar(null, {
      lastDay: "[Yesterday at] LT",
      sameDay: "[Today at] LT",
      nextDay: "[Tomorrow at] LT",
      nextWeek: "dddd [at] LT",
      sameElse: "MMM D 'YY",
    });

    //- Denote if this is an upcoming game
    if (lastScore) {
      if (
        startDateTimeMoment > moment() &&
        (!lastScore.stoppageStatus || !lastScore.stoppageStatus.isFinalStatus)
      ) {
        game.lastScore.isUpcoming = game.isUpcoming = true;
      }
    }
  }

  return game;
}
export { formatter };

export const getInitialState = function getInitialState() {
  const games = _.get(window, [
    "gData",
    "redux",
    "collections",
    "gameCollection",
    "list",
  ]);

  if (games) {
    return _.keyBy(games.map(formatter), "gameId");
  } else {
    return {};
  }
};

export const get = function getGames(p) {
  return delayedGet({
    key: "game",
    method: "games.get",
    idsKey: "gameIds",
    ids: p.gameIds,
    extraParams: p.extraParams,
    callback: p.callback,
  });
};

export const add = function addGame(params, callback) {
  return (dispatch) => {
    api.post({
      method: "games.update",
      params: params,
      callback: genericActionCallback(dispatch, callback),
    });
  };
};

export const addPost = function addPost(params, callback) {
  const apiParams = _.omit(params, ["showError"]);
  const { showError = true } = params;
  if (apiParams.backgroundPicture) {
    const formData = _.get(apiParams, "formData", new FormData());
    formData.append("backgroundPicture", apiParams.backgroundPicture);
    apiParams.formData = formData;
    delete apiParams.backgroundPicture;
  }
  if (apiParams.video) {
    const formData = _.get(apiParams, "formData", new FormData());
    formData.append("video", apiParams.video);
    apiParams.formData = formData;
    delete apiParams.video;
  }

  apiParams.userNeedsVerificationLevelToPost = 0;
  return (dispatch) => {
    waterfall(
      [
        (callback) =>
          api.post(
            _.assignIn(
              {
                method: "games.posts.add",
                params: apiParams,
                callback: genericActionCallback(dispatch, callback),
              },
              apiParams
            )
          ),
      ],
      (err, result) => {
        if (err) {
          if (showError) {
            handleError(err, {
              title: "Error Submitting Post",
            });
          }
          return callback(err);
        }
        callback(null, result);
      }
    );
  };
};

export const addCheers = function addCheers(params, callback = _.noop) {
  return (dispatch, getState) => {
    if (!params.awayTeamCheers && !params.homeTeamCheers) {
      return;
    }
    const curGame = _.get(getState(), ["collections", "game", params.gameId]);
    if (curGame) {
      //if we have an existing record, optimistically update it
      const awayTeamCheers =
        _.get(params, "awayTeamCheers", 0) +
        _.get(curGame, "awayTeamCheers", 0);
      const homeTeamCheers =
        _.get(params, "homeTeamCheers", 0) +
        _.get(curGame, "homeTeamCheers", 0);
      dispatch(
        collectionActions.receiveCollections({
          gameCollection: {
            list: [
              {
                gameId: params.gameId,
                awayTeamCheers,
                homeTeamCheers,
              },
            ],
          },
        })
      );
    }

    waterfall(
      [
        (callback) =>
          api.post({
            method: "games.cheers.add",
            params: _.pick(params, [
              "gameId",
              "awayTeamCheers",
              "homeTeamCheers",
            ]),
            callback: genericActionCallback(dispatch, callback),
          }),
      ],
      (err, result) => {
        if (err) {
          if (curGame) {
            dispatch(
              collectionActions.receiveCollections({
                gameCollection: {
                  list: [curGame],
                },
              })
            );
          }

          handleApiError(err);
          return callback(err);
        }
        callback(null, result);
      }
    );
  };
};

export const update = function updateGame(params, callback) {
  return (dispatch, getState) => {
    api.post({
      method: "games.update",
      params: params,
      callback: function(err, result) {
        if (err && callback) {
          return callback(err);
        }
        if (err && !callback) {
          return handleApiError(err);
        }

        const curGame = _.get(getState(), [
          "collections",
          "game",
          params.gameId,
        ]);
        const game = result.collections.gameCollection.list[0];

        // Game Collection
        dispatch(
          collectionActions.receiveCollections(result.collections, {
            forceSet: true,
          })
        );

        // Invalidate the venue assessments for the game, they are no longer valid because they aren't for the right venue
        if (!curGame || curGame.venueId !== game.venueId) {
          dispatch(
            listsRedux.actions.invalidate({
              list: "gameVenueAssessmentIdsForGame",
              listId: params.gameId,
            })
          );

          // invalidate the stauts for the game
          dispatch(
            collectionActions.invalidate({
              collection: "gameVenueStatus",
              ids: [params.gameId],
            })
          );
        }

        if (callback) {
          return callback(null, result);
        }
      },
    });
  };
};
