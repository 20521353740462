import { connect } from 'react-redux';

import { hideModal, showModal } from '../../common/redux/modal';

function mapStateToProps(state) {
	return {
		...state.modal
	};
}

function mapDispatchToProps(dispatch) {
	return {
		hideModal: () => dispatch(hideModal()),
		showModal: (p) => dispatch(showModal(p)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps);
