"use strict";
import React from 'react';
import { findDOMNode } from 'react-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Tip from 'rc-tooltip';
import getPortalContainer from '../libs/getPortalContainer';
import 'rc-tooltip/assets/bootstrap_white.css';
import '!!style-loader!css-loader!sass-loader!../scss/tooltip.scss';

const styles = {
	overlay: {
		boxShadow: '0 5px 10px rgba(0,0,0,.2)',
		opacity: 1,
		padding: 0,
	}
};

class Tooltip extends React.Component {
	constructor (props) {
		super(props);
		this.handlePopupAlign = this.handlePopupAlign.bind(this);
		this.handleVisibleChange = this.handleVisibleChange.bind(this);
		this.handleScroll = this.handleScroll.bind(this);
		this.state = {
			arrowStyle: null,
		};
	}

	hide () {
		if (this._internal) {
			this._internal.trigger.setPopupVisible(false);
		}
	}

	handleScroll () {
		if (this.props.hideOnScroll) {
			this.hide();
		}
	}

	handleVisibleChange (isVisible) {
		if (!this.props.hideOnScroll) { return; }
		if (isVisible) {
			window.addEventListener('scroll', this.handleScroll);
			window.addEventListener('proxyScroll', this.handleScroll);
		} else {
			window.removeEventListener('scroll', this.handleScroll);
			window.removeEventListener('proxyScroll', this.handleScroll);
		}
	}

	handlePopupAlign (popupDomNode, align) {
		if (align.overflow && align.overflow.adjustX) {
			const targetRect = this._child.getBoundingClientRect();
			const targetCenterX = targetRect.left + ((targetRect.right - targetRect.left) / 2);
			const popupRect = popupDomNode.getBoundingClientRect();
			const popupCenterX = popupRect.left + ((popupRect.right - popupRect.left) / 2);
			const offset = targetCenterX - popupCenterX;
			if (this.offset !== offset) {
				window.cancelAnimationFrame(this.raf);
				this.raf = window.requestAnimationFrame(() => {
					this.offset = offset;
					this.setState({arrowStyle: {
						transform: `translateX(${offset}px)`,
					}});
				});
			}
		}
	}

	componentWillUnmount () {
		window.cancelAnimationFrame(this.raf);
		window.removeEventListener('scroll', this.handleScroll);
		window.removeEventListener('proxyScroll', this.handleScroll);
	}

	render () {
		const {
			children,
			content,
			customComponent,
			destroyOnHide,
			placement,
			trigger,
			wrapperProps,
		} = this.props;
		const wrappedChildren = React.createElement(customComponent, _.assignIn({
			children,
			ref: c => this._child = findDOMNode(c),
		}, wrapperProps));
		if (this.context.skipToolTips) {
			//early exit for subtrees w/ tooltips disabled
			return wrappedChildren;
		}

		const arrow = (
			<div className="rc-tooltip-arrow" style={this.state.arrowStyle} key="arrow">
				<div className="rc-tooltip-arrow-inner"></div>
			</div>
		);
		return (
			<Tip
				align={{offset: [0, 0]}}
				arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
				destroyTooltipOnHide={destroyOnHide}
				getTooltipContainer={getPortalContainer}
				mouseEnterDelay={0.3}
				mouseLeaveDelay={0.1}
				onVisibleChange={this.handleVisibleChange}
				overlay={[arrow, React.cloneElement(content, {key: 'content'})]}
				placement={placement}
				onPopupAlign={this.handlePopupAlign}
				overlayStyle={styles.overlay}
				ref={c => this._internal = c}
				trigger={trigger}
			>
				{wrappedChildren}
			</Tip>
		);
	}
}
Tooltip.propTypes = {
	children: PropTypes.node,
	content: PropTypes.node,
	customComponent: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.func
	]),
	destroyOnHide: PropTypes.bool,
	placement: PropTypes.string,
	trigger: PropTypes.arrayOf(PropTypes.oneOf([
		'hover',
		'click',
		'focus'
	])),
	wrapperProps: PropTypes.object, /* used if you want to change the default dom position of element you are applying TT to*/
	hideOnScroll: PropTypes.bool,
};
Tooltip.defaultProps = {
	customComponent: 'span',
	destroyOnHide: true,
	placement: 'top',
	trigger: ['hover'],
	hideOnScroll: false,
};
Tooltip.contextTypes = {
	skipToolTips: PropTypes.bool,
};
export default Tooltip;
