"use strict";
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import AuthAction from '../authenticatedActions.jsx';
import BaseButton from './baseButton.jsx';
import * as sharedStyles from '../../libs/sharedStyles';

const buttonStyles = {
	'minimal' : {
		fontSize : 12,
		padding : '10px 12px',
	},
	'slim' : {
		fontSize : 12,
		padding : '5px 15px',
		whiteSpace: 'nowrap',
		minWidth : 100
	},
	'normal' : {
		fontSize: 16,
		padding: '15px 32px',
		whiteSpace: 'nowrap',
		minWidth: 160,
	}
};

class FollowButton extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			hover: false,
			pending : false
		};

		this.handleMouseEnter = this.handleMouseEnter.bind(this);
		this.handleMouseLeave = this.handleMouseLeave.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.followCallback = this.followCallback.bind(this);
	}

	followCallback () {
		this.setState({pending: false});
	}

	handleClick (evt) {
		if (this.state.pending) { return; }

		if (this.props.isFollowing) {
			this.props.unfollow({callback: this.followCallback});
		} else {
			this.props.follow({callback: this.followCallback});
		}

		// TODO more visually with the 'pending' state and success state when pending changes and we get a response
		this.setState({pending: true});

		evt.preventDefault();
	}

	componentWillMount() {
		const { canFollow, getAuthUserFollowing, hasReceivedFollowing, } = this.props;
		if (!hasReceivedFollowing && getAuthUserFollowing && canFollow) {
			this.props.getAuthUserFollowing();
		}
	}

	componentWillReceiveProps (nextProps) {
		if (!nextProps.hasReceivedFollowing && nextProps.canFollow) {
			this.props.getAuthUserFollowing();
		}
	}
	
	handleMouseEnter () {
		this.setState({hover: true});
	}
	
	handleMouseLeave () {
		this.setState({hover: false});
	}

	render () {
		const { canFollow, isFollowing, type, hasReceivedFollowing, } = this.props;

		// don't render until we have this list fetched... just to avoid blinking. It will be really fast :D
		if (!hasReceivedFollowing && canFollow) { return null; }

		const baseStyle = buttonStyles[type];

		const c1 = sharedStyles.vars.colors.ssBlue;
		const c2 = "#FFFFFF";

		// color schema is always the same (unless overloaded)
		// it extends the buttonStyle which is more about the shape of the thing
		const buttonStyle = _.assignIn({
			backgroundColor : (isFollowing) ? c1 : c2,
			color: (isFollowing) ? c2 : c1,
			borderColor : c1,
			':hover' : {
				backgroundColor: (isFollowing) ? sharedStyles.vars.colors.ssHoverBlue : "#E8E8E8",
				color : (isFollowing) ? c2 : c1
			},
			opacity : (this.state.pending) ? 0.8 : 1,
		}, baseStyle, this.props.style);

		let text;
		if (type !== 'minimal') {
			if (isFollowing) {
				text = (this.state.hover) ? 'Unfollow' : 'Following';
			} else {
				text = 'Follow';
			}
		}

		const icon = (isFollowing) ? 'star' : 'star-o';

		return (
			<AuthAction eventTypes={['onClick']}>
				<BaseButton
					style={buttonStyle}
					onMouseEnter={this.handleMouseEnter}
					onMouseLeave={this.handleMouseLeave}
					onClick={this.handleClick}
					faIcon={icon}
					text={text}
					disabled={this.state.pending}
				/>
			</AuthAction>
		);
	}
}

/*
 * isFollowing, follow, and unfollow should all be provided by the container (team or user, etc...)
 */
FollowButton.propTypes = {
	canFollow: PropTypes.bool,
	isFollowing : PropTypes.bool,
	follow : PropTypes.func,
	unfollow : PropTypes.func,
	type : PropTypes.oneOf(['minimal', 'slim', 'normal']),
	userId: PropTypes.number,
	style: PropTypes.object,
	hasReceivedFollowing : PropTypes.bool.isRequired, // if we have ever fetched the list for the auth user
	getAuthUserFollowing : PropTypes.func  // how to fetch the list for the auth user
};

FollowButton.defaultProps = {
	type : 'normal'
};

export default FollowButton;
