"use strict";
import _ from 'lodash';

import { getApi } from '../../../libs/api';
const api = getApi({name: 'scoreStream'});
import { actions as collectionActions } from '../../actions/collections';
import * as listsRedux from '../lists';
import { handleApiError } from "../../../libs/errorHandler";

export const propsToDiff = {};

export const getInitialState = function getInitialState() {
	return _.keyBy(_.get(window, ['gData', 'redux', 'collections', 'gameVenueAssessmentCollection', 'list'], []), 'gameVenueAssessmentId');
};

export const addGameVenueAssessment = function addGameVenueAssessment (params, callback) {
	return (dispatch, getState) => {
		api.post({
			method : 'games.venues.assessments.add',
			params : _.pick(params, ['gameId', 'venueId', 'assessmentValue', 'message']),
			callback : function (err, result) {
				if (err && callback) { return callback(err); }
				if (err && !callback) { return handleApiError(err); }

				const state = getState();

				const gameId = params.gameId;
				const venueId = params.venueId;
				const creatorUserId = state.authUser.user.userId;

				let gameVenueAssessmentIds = _.get(state, ['lists', 'gameVenueAssessmentIdsForGame', gameId]);

				// if we have a current list of gameVenueAssessmentIds for this game then we need to modify and
				// set it with our new vals
				if (gameVenueAssessmentIds) {
					gameVenueAssessmentIds = gameVenueAssessmentIds.splice(0);

					// if it has a length, we need to pull out any old potential value for this user
					// assessments work were and you don't update, so we remove ID 5 and add ID 6
					if (gameVenueAssessmentIds.length) {
						let assessments = gameVenueAssessmentIds.map((id) => {
							return state.collections.gameVenueAssessment[id];
						});
						assessments = _.compact(assessments);

						const match = _.find(assessments, {
							gameId : gameId,
							venueId : venueId,
							creatorUserId : creatorUserId
						});

						if (match) {
							gameVenueAssessmentIds = _.pull(gameVenueAssessmentIds, match.gameVenueAssessmentId);
						}
					}

					// Put our new ID in the list of
					gameVenueAssessmentIds.push(result.gameVenueAssessmentIds[0]);

					// Set the list to its new array of values
					dispatch(listsRedux.actions.receiveLists({
						list : 'gameVenueAssessmentIdsForGame',
						listId : gameId,
						itemIds : gameVenueAssessmentIds,
					}));
				}

				// invalidate the gameVenueStatus for the game
				dispatch(collectionActions.invalidate({collection : 'gameVenueStatus', ids : [params.gameId]}));

				// update our collections
				dispatch(collectionActions.receiveCollections(result.collections));

				if (callback) {
					return callback(null, result);
				}
			}
		});
	};
};
