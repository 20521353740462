import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../../../common/components/modal.jsx';

const styles = {
	modal : {
		content : {
			margin : "30px auto",
			height : '90%',
			maxWidth : 460,
			padding : 10,
		}
	},
	iframe : {
		width : '100%',
		height : '100%',
		border : 'none',
	},
};

class DisplayMobileWebNotificationModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			url : props.url || null,
		};

		['_onClose'].forEach((m) => {
			this[m] = this[m].bind(this);
		});
	}

	show (url) {
		this.setState({
			url,
		});
	}

	_onClose () {
		this.setState({
			url : null,
		});
	}

	render () {
		return (
			<Modal
				style={styles.modal}
				isOpen={!!this.state.url}
				onClose={this._onClose}
			>
				<iframe
					style={styles.iframe}
					src={this.state.url}
				>
				</iframe>
			</Modal>
		);
	}
}
DisplayMobileWebNotificationModal.propTypes = {
	url : PropTypes.string,
};

export default DisplayMobileWebNotificationModal;