
/* 
	FILE AUTOMATICALLY GENERATED BY RUNNING npm run build buildCachedData
	DO NOT EDIT THIS FILE
	QUESTIONS: Ask Ryan
*/

module.exports = {
  "collections": {
    "gameSegmentCollection": {
      "list": [
        {
          "isEndOfSegment": false,
          "boxDisplay": "1",
          "minDisplay": "Q1",
          "shortDisplay": "1st Qtr",
          "longDisplay": "1st Quarter",
          "gameSegmentId": 10010,
          "boxGameSegmentId": 10010,
          "endGameSegmentId": 10015,
          "gameSegmentCategory": "quarter"
        },
        {
          "isEndOfSegment": true,
          "minDisplay": "End Q1",
          "shortDisplay": "End 1st Qtr",
          "longDisplay": "End of the 1st Quarter",
          "gameSegmentId": 10015,
          "boxGameSegmentId": 10010,
          "endGameSegmentId": 10015,
          "gameSegmentCategory": "quarter"
        },
        {
          "isEndOfSegment": false,
          "boxDisplay": "2",
          "minDisplay": "Q2",
          "shortDisplay": "2nd Qtr",
          "longDisplay": "2nd Quarter",
          "gameSegmentId": 10020,
          "boxGameSegmentId": 10020,
          "endGameSegmentId": 10025,
          "gameSegmentCategory": "quarter"
        },
        {
          "isEndOfSegment": true,
          "boxDisplay": "H",
          "minDisplay": "Hlf",
          "shortDisplay": "Half",
          "longDisplay": "Halftime",
          "gameSegmentId": 10025,
          "boxGameSegmentId": 10020,
          "endGameSegmentId": 10025,
          "gameSegmentCategory": "quarter"
        },
        {
          "isEndOfSegment": false,
          "boxDisplay": "3",
          "minDisplay": "Q3",
          "shortDisplay": "3rd Qtr",
          "longDisplay": "3rd Quarter",
          "gameSegmentId": 10030,
          "boxGameSegmentId": 10030,
          "endGameSegmentId": 10035,
          "gameSegmentCategory": "quarter"
        },
        {
          "isEndOfSegment": true,
          "minDisplay": "End Q3",
          "shortDisplay": "End 3rd Qtr",
          "longDisplay": "End of the 3rd Quarter",
          "gameSegmentId": 10035,
          "boxGameSegmentId": 10030,
          "endGameSegmentId": 10035,
          "gameSegmentCategory": "quarter"
        },
        {
          "isEndOfSegment": false,
          "boxDisplay": "4",
          "minDisplay": "Q4",
          "shortDisplay": "4th Qtr",
          "longDisplay": "4th Quarter",
          "gameSegmentId": 10040,
          "boxGameSegmentId": 10040,
          "endGameSegmentId": 10045,
          "gameSegmentCategory": "quarter"
        },
        {
          "isEndOfSegment": true,
          "minDisplay": "End Q4",
          "shortDisplay": "End 4th Qtr",
          "longDisplay": "End of the 4th Quarter",
          "gameSegmentId": 10045,
          "boxGameSegmentId": 10040,
          "endGameSegmentId": 10045,
          "gameSegmentCategory": "quarter"
        },
        {
          "isEndOfSegment": false,
          "boxDisplay": "1",
          "minDisplay": "H1",
          "shortDisplay": "1st Half",
          "longDisplay": "1st Half",
          "gameSegmentId": 11010,
          "boxGameSegmentId": 11010,
          "endGameSegmentId": 11015,
          "gameSegmentCategory": "half"
        },
        {
          "isEndOfSegment": true,
          "minDisplay": "End H1",
          "shortDisplay": "End 1st Half",
          "longDisplay": "End of the 1st Half",
          "gameSegmentId": 11012,
          "boxGameSegmentId": 11010,
          "endGameSegmentId": 11015,
          "gameSegmentCategory": "half"
        },
        {
          "isEndOfSegment": true,
          "boxDisplay": "H",
          "minDisplay": "Hlf",
          "shortDisplay": "Half",
          "longDisplay": "Halftime",
          "gameSegmentId": 11015,
          "boxGameSegmentId": 11010,
          "endGameSegmentId": 11015,
          "gameSegmentCategory": "half"
        },
        {
          "isEndOfSegment": false,
          "boxDisplay": "2",
          "minDisplay": "H2",
          "shortDisplay": "2nd Half",
          "longDisplay": "2nd Half",
          "gameSegmentId": 11020,
          "boxGameSegmentId": 11020,
          "endGameSegmentId": 11025,
          "gameSegmentCategory": "half"
        },
        {
          "isEndOfSegment": true,
          "minDisplay": "End H2",
          "shortDisplay": "End 2nd Half",
          "longDisplay": "End of the 2nd Half",
          "gameSegmentId": 11025,
          "boxGameSegmentId": 11020,
          "endGameSegmentId": 11025,
          "gameSegmentCategory": "half"
        },
        {
          "isEndOfSegment": false,
          "boxDisplay": 1,
          "minDisplay": "S1",
          "shortDisplay": "1st Set",
          "longDisplay": "1st Set",
          "gameSegmentId": 12010,
          "boxGameSegmentId": 12010,
          "endGameSegmentId": 12015,
          "gameSegmentCategory": "game"
        },
        {
          "isEndOfSegment": true,
          "boxDisplay": 1,
          "minDisplay": "End S1",
          "shortDisplay": "End 1st Set",
          "longDisplay": "End of the 1st Set",
          "gameSegmentId": 12015,
          "boxGameSegmentId": 12010,
          "endGameSegmentId": 12015,
          "gameSegmentCategory": "game"
        },
        {
          "isEndOfSegment": false,
          "boxDisplay": 2,
          "minDisplay": "S2",
          "shortDisplay": "2nd Set",
          "longDisplay": "2nd Set",
          "gameSegmentId": 12020,
          "boxGameSegmentId": 12020,
          "endGameSegmentId": 12025,
          "gameSegmentCategory": "game"
        },
        {
          "isEndOfSegment": true,
          "boxDisplay": 2,
          "minDisplay": "End S2",
          "shortDisplay": "End 2nd Set",
          "longDisplay": "End of the 2nd Set",
          "gameSegmentId": 12025,
          "boxGameSegmentId": 12020,
          "endGameSegmentId": 12025,
          "gameSegmentCategory": "game"
        },
        {
          "isEndOfSegment": false,
          "boxDisplay": 3,
          "minDisplay": "S3",
          "shortDisplay": "3rd Set",
          "longDisplay": "3rd Set",
          "gameSegmentId": 12030,
          "boxGameSegmentId": 12030,
          "endGameSegmentId": 12035,
          "gameSegmentCategory": "game"
        },
        {
          "isEndOfSegment": true,
          "boxDisplay": 3,
          "minDisplay": "End S3",
          "shortDisplay": "End 3rd Set",
          "longDisplay": "End of the 3rd Set",
          "gameSegmentId": 12035,
          "boxGameSegmentId": 12030,
          "endGameSegmentId": 12035,
          "gameSegmentCategory": "game"
        },
        {
          "isEndOfSegment": false,
          "boxDisplay": 4,
          "minDisplay": "S4",
          "shortDisplay": "4th Set",
          "longDisplay": "4th Set",
          "gameSegmentId": 12040,
          "boxGameSegmentId": 12040,
          "endGameSegmentId": 12045,
          "gameSegmentCategory": "game"
        },
        {
          "isEndOfSegment": true,
          "boxDisplay": 4,
          "minDisplay": "End S4",
          "shortDisplay": "End 4th Set",
          "longDisplay": "End of the 4th Set",
          "gameSegmentId": 12045,
          "boxGameSegmentId": 12040,
          "endGameSegmentId": 12045,
          "gameSegmentCategory": "game"
        },
        {
          "isEndOfSegment": false,
          "boxDisplay": 5,
          "minDisplay": "S5",
          "shortDisplay": "5th Set",
          "longDisplay": "5th Set",
          "gameSegmentId": 12050,
          "boxGameSegmentId": 12050,
          "endGameSegmentId": 12055,
          "gameSegmentCategory": "game"
        },
        {
          "isEndOfSegment": true,
          "boxDisplay": 5,
          "minDisplay": "End S5",
          "shortDisplay": "End 5th Set",
          "longDisplay": "End of the 5th Set",
          "gameSegmentId": 12055,
          "boxGameSegmentId": 12050,
          "endGameSegmentId": 12055,
          "gameSegmentCategory": "game"
        },
        {
          "boxDisplay": "F",
          "minDisplay": "F",
          "shortDisplay": "End Match",
          "longDisplay": "End of the Match",
          "gameSegmentId": 12099,
          "boxGameSegmentId": 12099,
          "gameSegmentCategory": "game"
        },
        {
          "deprecated": true,
          "isEndOfSegment": false,
          "boxDisplay": 1,
          "minDisplay": 1,
          "shortDisplay": "1st Inn",
          "longDisplay": "1st Inning",
          "gameSegmentId": 13010,
          "boxGameSegmentId": 13010,
          "endGameSegmentId": 13018,
          "gameSegmentCategory": "inning"
        },
        {
          "boxDisplay": 1,
          "minDisplay": "T1",
          "shortDisplay": "Top 1st Inn",
          "longDisplay": "Top of the 1st Inning",
          "gameSegmentId": 13012,
          "boxGameSegmentId": 13010,
          "endGameSegmentId": 13018,
          "gameSegmentCategory": "inning"
        },
        {
          "boxDisplay": 1,
          "minDisplay": "B1",
          "shortDisplay": "Bot 1st Inn",
          "longDisplay": "Bottom of the 1st Inning",
          "gameSegmentId": 13017,
          "boxGameSegmentId": 13010,
          "endGameSegmentId": 13018,
          "gameSegmentCategory": "inning"
        },
        {
          "deprecated": true,
          "isEndOfSegment": true,
          "boxDisplay": 1,
          "minDisplay": "End 1",
          "shortDisplay": "End 1st Inn",
          "longDisplay": "End of the 1st Inning",
          "gameSegmentId": 13015,
          "boxGameSegmentId": 13010,
          "endGameSegmentId": 13018,
          "gameSegmentCategory": "inning"
        },
        {
          "isEndOfSegment": true,
          "boxDisplay": 1,
          "minDisplay": "End 1",
          "shortDisplay": "End 1st Inn",
          "longDisplay": "End of the 1st Inning",
          "gameSegmentId": 13018,
          "boxGameSegmentId": 13010,
          "endGameSegmentId": 13018,
          "gameSegmentCategory": "inning"
        },
        {
          "deprecated": true,
          "isEndOfSegment": false,
          "boxDisplay": 2,
          "minDisplay": 2,
          "shortDisplay": "2nd Inn",
          "longDisplay": "2nd Inning",
          "gameSegmentId": 13020,
          "boxGameSegmentId": 13020,
          "endGameSegmentId": 13028,
          "gameSegmentCategory": "inning"
        },
        {
          "boxDisplay": 2,
          "minDisplay": "T2",
          "shortDisplay": "Top 2nd Inn",
          "longDisplay": "Top of the 2nd Inning",
          "gameSegmentId": 13022,
          "boxGameSegmentId": 13020,
          "endGameSegmentId": 13028,
          "gameSegmentCategory": "inning"
        },
        {
          "boxDisplay": 2,
          "minDisplay": "B2",
          "shortDisplay": "Bot 2nd Inn",
          "longDisplay": "Bottom of the 2nd Inning",
          "gameSegmentId": 13027,
          "boxGameSegmentId": 13020,
          "endGameSegmentId": 13028,
          "gameSegmentCategory": "inning"
        },
        {
          "deprecated": true,
          "isEndOfSegment": true,
          "boxDisplay": 2,
          "minDisplay": "End 2",
          "shortDisplay": "End 2nd Inn",
          "longDisplay": "End of the 2nd Inning",
          "gameSegmentId": 13025,
          "boxGameSegmentId": 13020,
          "endGameSegmentId": 13028,
          "gameSegmentCategory": "inning"
        },
        {
          "isEndOfSegment": true,
          "boxDisplay": 2,
          "minDisplay": "End 2",
          "shortDisplay": "End 2nd Inn",
          "longDisplay": "End of the 2nd Inning",
          "gameSegmentId": 13028,
          "boxGameSegmentId": 13020,
          "endGameSegmentId": 13028,
          "gameSegmentCategory": "inning"
        },
        {
          "deprecated": true,
          "isEndOfSegment": false,
          "boxDisplay": 3,
          "minDisplay": 3,
          "shortDisplay": "3rd Inn",
          "longDisplay": "3rd Inning",
          "gameSegmentId": 13030,
          "boxGameSegmentId": 13030,
          "endGameSegmentId": 13038,
          "gameSegmentCategory": "inning"
        },
        {
          "boxDisplay": 3,
          "minDisplay": "T3",
          "shortDisplay": "Top 3rd Inn",
          "longDisplay": "Top of the 3rd Inning",
          "gameSegmentId": 13032,
          "boxGameSegmentId": 13030,
          "endGameSegmentId": 13038,
          "gameSegmentCategory": "inning"
        },
        {
          "boxDisplay": 3,
          "minDisplay": "B3",
          "shortDisplay": "Bot 3rd Inn",
          "longDisplay": "Bottom of the 3rd Inning",
          "gameSegmentId": 13037,
          "boxGameSegmentId": 13030,
          "endGameSegmentId": 13038,
          "gameSegmentCategory": "inning"
        },
        {
          "deprecated": true,
          "isEndOfSegment": true,
          "boxDisplay": 3,
          "minDisplay": "End 3",
          "shortDisplay": "End 3rd Inn",
          "longDisplay": "End of the 3rd Inning",
          "gameSegmentId": 13035,
          "boxGameSegmentId": 13030,
          "endGameSegmentId": 13038,
          "gameSegmentCategory": "inning"
        },
        {
          "isEndOfSegment": true,
          "boxDisplay": 3,
          "minDisplay": "End 3",
          "shortDisplay": "End 3rd Inn",
          "longDisplay": "End of the 3rd Inning",
          "gameSegmentId": 13038,
          "boxGameSegmentId": 13030,
          "endGameSegmentId": 13038,
          "gameSegmentCategory": "inning"
        },
        {
          "deprecated": true,
          "isEndOfSegment": false,
          "boxDisplay": 4,
          "minDisplay": 4,
          "shortDisplay": "4th Inn",
          "longDisplay": "4th Inning",
          "gameSegmentId": 13040,
          "boxGameSegmentId": 13040,
          "endGameSegmentId": 13048,
          "gameSegmentCategory": "inning"
        },
        {
          "boxDisplay": 4,
          "minDisplay": "T4",
          "shortDisplay": "Top 4th Inn",
          "longDisplay": "Top of the 4th Inning",
          "gameSegmentId": 13042,
          "boxGameSegmentId": 13040,
          "endGameSegmentId": 13048,
          "gameSegmentCategory": "inning"
        },
        {
          "boxDisplay": 4,
          "minDisplay": "B4",
          "shortDisplay": "Bot 4th Inn",
          "longDisplay": "Bottom of the 4th Inning",
          "gameSegmentId": 13047,
          "boxGameSegmentId": 13040,
          "endGameSegmentId": 13048,
          "gameSegmentCategory": "inning"
        },
        {
          "deprecated": true,
          "isEndOfSegment": true,
          "boxDisplay": 4,
          "minDisplay": "End 4",
          "shortDisplay": "End 4th Inn",
          "longDisplay": "End of the 4th Inning",
          "gameSegmentId": 13045,
          "boxGameSegmentId": 13040,
          "endGameSegmentId": 13048,
          "gameSegmentCategory": "inning"
        },
        {
          "isEndOfSegment": true,
          "boxDisplay": 4,
          "minDisplay": "End 4",
          "shortDisplay": "End 4th Inn",
          "longDisplay": "End of the 4th Inning",
          "gameSegmentId": 13048,
          "boxGameSegmentId": 13040,
          "endGameSegmentId": 13048,
          "gameSegmentCategory": "inning"
        },
        {
          "deprecated": true,
          "isEndOfSegment": false,
          "boxDisplay": 5,
          "minDisplay": 5,
          "shortDisplay": "5th Inn",
          "longDisplay": "5th Inning",
          "gameSegmentId": 13050,
          "boxGameSegmentId": 13050,
          "endGameSegmentId": 13058,
          "gameSegmentCategory": "inning"
        },
        {
          "boxDisplay": 5,
          "minDisplay": "T5",
          "shortDisplay": "Top 5th Inn",
          "longDisplay": "Top of the 5th Inning",
          "gameSegmentId": 13052,
          "boxGameSegmentId": 13050,
          "endGameSegmentId": 13058,
          "gameSegmentCategory": "inning"
        },
        {
          "boxDisplay": 5,
          "minDisplay": "B5",
          "shortDisplay": "Bot 5th Inn",
          "longDisplay": "Bottom of the 5th Inning",
          "gameSegmentId": 13057,
          "boxGameSegmentId": 13050,
          "endGameSegmentId": 13058,
          "gameSegmentCategory": "inning"
        },
        {
          "deprecated": true,
          "isEndOfSegment": true,
          "boxDisplay": 5,
          "minDisplay": "End 5",
          "shortDisplay": "End 5th Inn",
          "longDisplay": "End of the 5th Inning",
          "gameSegmentId": 13055,
          "boxGameSegmentId": 13050,
          "endGameSegmentId": 13058,
          "gameSegmentCategory": "inning"
        },
        {
          "isEndOfSegment": true,
          "boxDisplay": 5,
          "minDisplay": "End 5",
          "shortDisplay": "End 5th Inn",
          "longDisplay": "End of the 5th Inning",
          "gameSegmentId": 13058,
          "boxGameSegmentId": 13050,
          "endGameSegmentId": 13058,
          "gameSegmentCategory": "inning"
        },
        {
          "deprecated": true,
          "isEndOfSegment": false,
          "boxDisplay": 6,
          "minDisplay": 6,
          "shortDisplay": "6th Inn",
          "longDisplay": "6th Inning",
          "gameSegmentId": 13060,
          "boxGameSegmentId": 13060,
          "endGameSegmentId": 13068,
          "gameSegmentCategory": "inning"
        },
        {
          "boxDisplay": 6,
          "minDisplay": "T6",
          "shortDisplay": "Top 6th Inn",
          "longDisplay": "Top of the 6th Inning",
          "gameSegmentId": 13062,
          "boxGameSegmentId": 13060,
          "endGameSegmentId": 13068,
          "gameSegmentCategory": "inning"
        },
        {
          "boxDisplay": 6,
          "minDisplay": "B6",
          "shortDisplay": "Bot 6th Inn",
          "longDisplay": "Bottom of the 6th Inning",
          "gameSegmentId": 13067,
          "boxGameSegmentId": 13060,
          "endGameSegmentId": 13068,
          "gameSegmentCategory": "inning"
        },
        {
          "deprecated": true,
          "isEndOfSegment": true,
          "boxDisplay": 6,
          "minDisplay": "End 6",
          "shortDisplay": "End 6th Inn",
          "longDisplay": "End of the 6th Inning",
          "gameSegmentId": 13065,
          "boxGameSegmentId": 13060,
          "endGameSegmentId": 13068,
          "gameSegmentCategory": "inning"
        },
        {
          "isEndOfSegment": true,
          "boxDisplay": 6,
          "minDisplay": "End 6",
          "shortDisplay": "End 6th Inn",
          "longDisplay": "End of the 6th Inning",
          "gameSegmentId": 13068,
          "boxGameSegmentId": 13060,
          "endGameSegmentId": 13068,
          "gameSegmentCategory": "inning"
        },
        {
          "deprecated": true,
          "isEndOfSegment": false,
          "boxDisplay": 7,
          "minDisplay": 7,
          "shortDisplay": "7th Inn",
          "longDisplay": "7th Inning",
          "gameSegmentId": 13070,
          "boxGameSegmentId": 13070,
          "endGameSegmentId": 13078,
          "gameSegmentCategory": "inning"
        },
        {
          "boxDisplay": 7,
          "minDisplay": "T7",
          "shortDisplay": "Top 7th Inn",
          "longDisplay": "Top of the 7th Inning",
          "gameSegmentId": 13072,
          "boxGameSegmentId": 13070,
          "endGameSegmentId": 13078,
          "gameSegmentCategory": "inning"
        },
        {
          "boxDisplay": 7,
          "minDisplay": "B7",
          "shortDisplay": "Bot 7th Inn",
          "longDisplay": "Bottom of the 7th Inning",
          "gameSegmentId": 13077,
          "boxGameSegmentId": 13070,
          "endGameSegmentId": 13078,
          "gameSegmentCategory": "inning"
        },
        {
          "deprecated": true,
          "isEndOfSegment": true,
          "boxDisplay": 7,
          "minDisplay": "End 7",
          "shortDisplay": "End 7th Inn",
          "longDisplay": "End of the 7th Inning",
          "gameSegmentId": 13075,
          "boxGameSegmentId": 13070,
          "endGameSegmentId": 13078,
          "gameSegmentCategory": "inning"
        },
        {
          "isEndOfSegment": true,
          "boxDisplay": 7,
          "minDisplay": "End 7",
          "shortDisplay": "End 7th Inn",
          "longDisplay": "End of the 7th Inning",
          "gameSegmentId": 13078,
          "boxGameSegmentId": 13070,
          "endGameSegmentId": 13078,
          "gameSegmentCategory": "inning"
        },
        {
          "deprecated": true,
          "isEndOfSegment": false,
          "boxDisplay": 8,
          "minDisplay": 8,
          "shortDisplay": "8th Inn",
          "longDisplay": "8th Inning",
          "gameSegmentId": 13080,
          "boxGameSegmentId": 13080,
          "endGameSegmentId": 13088,
          "gameSegmentCategory": "inning"
        },
        {
          "boxDisplay": 8,
          "minDisplay": "T8",
          "shortDisplay": "Top 8th Inn",
          "longDisplay": "Top of the 8th Inning",
          "gameSegmentId": 13082,
          "boxGameSegmentId": 13080,
          "endGameSegmentId": 13088,
          "gameSegmentCategory": "inning"
        },
        {
          "boxDisplay": 8,
          "minDisplay": "B8",
          "shortDisplay": "Bot 8th Inn",
          "longDisplay": "Bottom of the 8th Inning",
          "gameSegmentId": 13087,
          "boxGameSegmentId": 13080,
          "endGameSegmentId": 13088,
          "gameSegmentCategory": "inning"
        },
        {
          "deprecated": true,
          "isEndOfSegment": true,
          "boxDisplay": 8,
          "minDisplay": "End 8",
          "shortDisplay": "End 8th Inn",
          "longDisplay": "End of the 8th Inning",
          "gameSegmentId": 13085,
          "boxGameSegmentId": 13080,
          "endGameSegmentId": 13088,
          "gameSegmentCategory": "inning"
        },
        {
          "isEndOfSegment": true,
          "boxDisplay": 8,
          "minDisplay": "End 8",
          "shortDisplay": "End 8th Inn",
          "longDisplay": "End of the 8th Inning",
          "gameSegmentId": 13088,
          "boxGameSegmentId": 13080,
          "endGameSegmentId": 13088,
          "gameSegmentCategory": "inning"
        },
        {
          "deprecated": true,
          "isEndOfSegment": false,
          "boxDisplay": 9,
          "minDisplay": 9,
          "shortDisplay": "9th Inn",
          "longDisplay": "9th Inning",
          "gameSegmentId": 13090,
          "boxGameSegmentId": 13090,
          "endGameSegmentId": 13098,
          "gameSegmentCategory": "inning"
        },
        {
          "boxDisplay": 9,
          "minDisplay": "T9",
          "shortDisplay": "Top 9th Inn",
          "longDisplay": "Top of the 9th Inning",
          "gameSegmentId": 13092,
          "boxGameSegmentId": 13090,
          "endGameSegmentId": 13098,
          "gameSegmentCategory": "inning"
        },
        {
          "boxDisplay": 9,
          "minDisplay": "B9",
          "shortDisplay": "Bot 9th Inn",
          "longDisplay": "Bottom of the 9th Inning",
          "gameSegmentId": 13097,
          "boxGameSegmentId": 13090,
          "endGameSegmentId": 13098,
          "gameSegmentCategory": "inning"
        },
        {
          "deprecated": true,
          "isEndOfSegment": true,
          "boxDisplay": 9,
          "minDisplay": "End 9",
          "shortDisplay": "End 9th Inn",
          "longDisplay": "End of the 9th Inning",
          "gameSegmentId": 13095,
          "boxGameSegmentId": 13090,
          "endGameSegmentId": 13098,
          "gameSegmentCategory": "inning"
        },
        {
          "isEndOfSegment": true,
          "boxDisplay": 9,
          "minDisplay": "End 9",
          "shortDisplay": "End 9th Inn",
          "longDisplay": "End of the 9th Inning",
          "gameSegmentId": 13098,
          "boxGameSegmentId": 13090,
          "endGameSegmentId": 13098,
          "gameSegmentCategory": "inning"
        },
        {
          "deprecated": true,
          "isEndOfSegment": false,
          "boxDisplay": 10,
          "minDisplay": 10,
          "shortDisplay": "10th Inn",
          "longDisplay": "10th Inning",
          "gameSegmentId": 13100,
          "boxGameSegmentId": 13100,
          "endGameSegmentId": 13108,
          "gameSegmentCategory": "inning"
        },
        {
          "boxDisplay": 10,
          "minDisplay": "T10",
          "shortDisplay": "Top 10th Inn",
          "longDisplay": "Top of the 10th Inning",
          "gameSegmentId": 13102,
          "boxGameSegmentId": 13100,
          "endGameSegmentId": 13108,
          "gameSegmentCategory": "inning"
        },
        {
          "boxDisplay": 10,
          "minDisplay": "B10",
          "shortDisplay": "Bot 10th Inn",
          "longDisplay": "Bottom of the 10th Inning",
          "gameSegmentId": 13107,
          "boxGameSegmentId": 13100,
          "endGameSegmentId": 13108,
          "gameSegmentCategory": "inning"
        },
        {
          "deprecated": true,
          "isEndOfSegment": true,
          "boxDisplay": 10,
          "minDisplay": "End 10",
          "shortDisplay": "End 10th Inn",
          "longDisplay": "End of the 10th Inning",
          "gameSegmentId": 13105,
          "boxGameSegmentId": 13100,
          "endGameSegmentId": 13108,
          "gameSegmentCategory": "inning"
        },
        {
          "isEndOfSegment": true,
          "boxDisplay": 10,
          "minDisplay": "End 10",
          "shortDisplay": "End 10th Inn",
          "longDisplay": "End of the 10th Inning",
          "gameSegmentId": 13108,
          "boxGameSegmentId": 13100,
          "endGameSegmentId": 13108,
          "gameSegmentCategory": "inning"
        },
        {
          "deprecated": true,
          "isEndOfSegment": false,
          "boxDisplay": 11,
          "minDisplay": 11,
          "shortDisplay": "11th Inn",
          "longDisplay": "11th Inning",
          "gameSegmentId": 13110,
          "boxGameSegmentId": 13110,
          "endGameSegmentId": 13118,
          "gameSegmentCategory": "inning"
        },
        {
          "boxDisplay": 11,
          "minDisplay": "T11",
          "shortDisplay": "Top 11th Inn",
          "longDisplay": "Top of the 11th Inning",
          "gameSegmentId": 13112,
          "boxGameSegmentId": 13110,
          "endGameSegmentId": 13118,
          "gameSegmentCategory": "inning"
        },
        {
          "boxDisplay": 11,
          "minDisplay": "B11",
          "shortDisplay": "Bot 11th Inn",
          "longDisplay": "Bottom of the 11th Inning",
          "gameSegmentId": 13117,
          "boxGameSegmentId": 13110,
          "endGameSegmentId": 13118,
          "gameSegmentCategory": "inning"
        },
        {
          "deprecated": true,
          "isEndOfSegment": true,
          "boxDisplay": 11,
          "minDisplay": "End 11",
          "shortDisplay": "End 11th Inn",
          "longDisplay": "End of the 11th Inning",
          "gameSegmentId": 13115,
          "boxGameSegmentId": 13110,
          "endGameSegmentId": 13118,
          "gameSegmentCategory": "inning"
        },
        {
          "isEndOfSegment": true,
          "boxDisplay": 11,
          "minDisplay": "End 11",
          "shortDisplay": "End 11th Inn",
          "longDisplay": "End of the 11th Inning",
          "gameSegmentId": 13118,
          "boxGameSegmentId": 13110,
          "endGameSegmentId": 13118,
          "gameSegmentCategory": "inning"
        },
        {
          "deprecated": true,
          "isEndOfSegment": false,
          "boxDisplay": 12,
          "minDisplay": 12,
          "shortDisplay": "12th Inn",
          "longDisplay": "12th Inning",
          "gameSegmentId": 13120,
          "boxGameSegmentId": 13120,
          "endGameSegmentId": 13128,
          "gameSegmentCategory": "inning"
        },
        {
          "boxDisplay": 12,
          "minDisplay": "T12",
          "shortDisplay": "Top 12th Inn",
          "longDisplay": "Top of the 12th Inning",
          "gameSegmentId": 13122,
          "boxGameSegmentId": 13120,
          "endGameSegmentId": 13128,
          "gameSegmentCategory": "inning"
        },
        {
          "boxDisplay": 12,
          "minDisplay": "B12",
          "shortDisplay": "Bot 12th Inn",
          "longDisplay": "Bottom of the 12th Inning",
          "gameSegmentId": 13127,
          "boxGameSegmentId": 13120,
          "endGameSegmentId": 13128,
          "gameSegmentCategory": "inning"
        },
        {
          "deprecated": true,
          "isEndOfSegment": true,
          "boxDisplay": 12,
          "minDisplay": "End 12",
          "shortDisplay": "End 12th Inn",
          "longDisplay": "End of the 12th Inning",
          "gameSegmentId": 13125,
          "boxGameSegmentId": 13120,
          "endGameSegmentId": 13128,
          "gameSegmentCategory": "inning"
        },
        {
          "isEndOfSegment": true,
          "boxDisplay": 12,
          "minDisplay": "End 12",
          "shortDisplay": "End 12th Inn",
          "longDisplay": "End of the 12th Inning",
          "gameSegmentId": 13128,
          "boxGameSegmentId": 13120,
          "endGameSegmentId": 13128,
          "gameSegmentCategory": "inning"
        },
        {
          "deprecated": true,
          "isEndOfSegment": false,
          "boxDisplay": 13,
          "minDisplay": 13,
          "shortDisplay": "13th Inn",
          "longDisplay": "13th Inning",
          "gameSegmentId": 13130,
          "boxGameSegmentId": 13130,
          "endGameSegmentId": 13138,
          "gameSegmentCategory": "inning"
        },
        {
          "boxDisplay": 13,
          "minDisplay": "T13",
          "shortDisplay": "Top 13th Inn",
          "longDisplay": "Top of the 13th Inning",
          "gameSegmentId": 13132,
          "boxGameSegmentId": 13130,
          "endGameSegmentId": 13138,
          "gameSegmentCategory": "inning"
        },
        {
          "boxDisplay": 13,
          "minDisplay": "B13",
          "shortDisplay": "Bot 13th Inn",
          "longDisplay": "Bottom of the 13th Inning",
          "gameSegmentId": 13137,
          "boxGameSegmentId": 13130,
          "endGameSegmentId": 13138,
          "gameSegmentCategory": "inning"
        },
        {
          "deprecated": true,
          "isEndOfSegment": true,
          "boxDisplay": 13,
          "minDisplay": "End 13",
          "shortDisplay": "End 13th Inn",
          "longDisplay": "End of the 13th Inning",
          "gameSegmentId": 13135,
          "boxGameSegmentId": 13130,
          "endGameSegmentId": 13138,
          "gameSegmentCategory": "inning"
        },
        {
          "isEndOfSegment": true,
          "boxDisplay": 13,
          "minDisplay": "End 13",
          "shortDisplay": "End 13th Inn",
          "longDisplay": "End of the 13th Inning",
          "gameSegmentId": 13138,
          "boxGameSegmentId": 13130,
          "endGameSegmentId": 13138,
          "gameSegmentCategory": "inning"
        },
        {
          "deprecated": true,
          "isEndOfSegment": false,
          "boxDisplay": 14,
          "minDisplay": 14,
          "shortDisplay": "14th Inn",
          "longDisplay": "14th Inning",
          "gameSegmentId": 13140,
          "boxGameSegmentId": 13140,
          "endGameSegmentId": 13148,
          "gameSegmentCategory": "inning"
        },
        {
          "boxDisplay": 14,
          "minDisplay": "T14",
          "shortDisplay": "Top 14th Inn",
          "longDisplay": "Top of the 14th Inning",
          "gameSegmentId": 13142,
          "boxGameSegmentId": 13140,
          "endGameSegmentId": 13148,
          "gameSegmentCategory": "inning"
        },
        {
          "boxDisplay": 14,
          "minDisplay": "B14",
          "shortDisplay": "Bot 14th Inn",
          "longDisplay": "Bottom of the 14th Inning",
          "gameSegmentId": 13147,
          "boxGameSegmentId": 13140,
          "endGameSegmentId": 13148,
          "gameSegmentCategory": "inning"
        },
        {
          "deprecated": true,
          "isEndOfSegment": true,
          "boxDisplay": 14,
          "minDisplay": "End 14",
          "shortDisplay": "End 14th Inn",
          "longDisplay": "End of the 14th Inning",
          "gameSegmentId": 13145,
          "boxGameSegmentId": 13140,
          "endGameSegmentId": 13148,
          "gameSegmentCategory": "inning"
        },
        {
          "isEndOfSegment": true,
          "boxDisplay": 14,
          "minDisplay": "End 14",
          "shortDisplay": "End 14th Inn",
          "longDisplay": "End of the 14th Inning",
          "gameSegmentId": 13148,
          "boxGameSegmentId": 13140,
          "endGameSegmentId": 13148,
          "gameSegmentCategory": "inning"
        },
        {
          "deprecated": true,
          "isEndOfSegment": false,
          "boxDisplay": 15,
          "minDisplay": 15,
          "shortDisplay": "15th Inn",
          "longDisplay": "15th Inning",
          "gameSegmentId": 13150,
          "boxGameSegmentId": 13150,
          "endGameSegmentId": 13158,
          "gameSegmentCategory": "inning"
        },
        {
          "boxDisplay": 15,
          "minDisplay": "T15",
          "shortDisplay": "Top 15th Inn",
          "longDisplay": "Top of the 15th Inning",
          "gameSegmentId": 13152,
          "boxGameSegmentId": 13150,
          "endGameSegmentId": 13158,
          "gameSegmentCategory": "inning"
        },
        {
          "boxDisplay": 15,
          "minDisplay": "B15",
          "shortDisplay": "Bot 15th Inn",
          "longDisplay": "Bottom of the 15th Inning",
          "gameSegmentId": 13157,
          "boxGameSegmentId": 13150,
          "endGameSegmentId": 13158,
          "gameSegmentCategory": "inning"
        },
        {
          "deprecated": true,
          "isEndOfSegment": true,
          "boxDisplay": 15,
          "minDisplay": "End 15",
          "shortDisplay": "End 15th Inn",
          "longDisplay": "End of the 15th Inning",
          "gameSegmentId": 13155,
          "boxGameSegmentId": 13150,
          "endGameSegmentId": 13158,
          "gameSegmentCategory": "inning"
        },
        {
          "isEndOfSegment": true,
          "boxDisplay": 15,
          "minDisplay": "End 15",
          "shortDisplay": "End 15th Inn",
          "longDisplay": "End of the 15th Inning",
          "gameSegmentId": 13158,
          "boxGameSegmentId": 13150,
          "endGameSegmentId": 13158,
          "gameSegmentCategory": "inning"
        },
        {
          "deprecated": true,
          "isEndOfSegment": false,
          "boxDisplay": 16,
          "minDisplay": 16,
          "shortDisplay": "16th Inn",
          "longDisplay": "16th Inning",
          "gameSegmentId": 13160,
          "boxGameSegmentId": 13160,
          "endGameSegmentId": 13168,
          "gameSegmentCategory": "inning"
        },
        {
          "boxDisplay": 16,
          "minDisplay": "T16",
          "shortDisplay": "Top 16th Inn",
          "longDisplay": "Top of the 16th Inning",
          "gameSegmentId": 13162,
          "boxGameSegmentId": 13160,
          "endGameSegmentId": 13168,
          "gameSegmentCategory": "inning"
        },
        {
          "boxDisplay": 16,
          "minDisplay": "B16",
          "shortDisplay": "Bot 16th Inn",
          "longDisplay": "Bottom of the 16th Inning",
          "gameSegmentId": 13167,
          "boxGameSegmentId": 13160,
          "endGameSegmentId": 13168,
          "gameSegmentCategory": "inning"
        },
        {
          "deprecated": true,
          "isEndOfSegment": true,
          "boxDisplay": 16,
          "minDisplay": "End 16",
          "shortDisplay": "End 16th Inn",
          "longDisplay": "End of the 16th Inning",
          "gameSegmentId": 13165,
          "boxGameSegmentId": 13160,
          "endGameSegmentId": 13168,
          "gameSegmentCategory": "inning"
        },
        {
          "isEndOfSegment": true,
          "boxDisplay": 16,
          "minDisplay": "End 16",
          "shortDisplay": "End 16th Inn",
          "longDisplay": "End of the 16th Inning",
          "gameSegmentId": 13168,
          "boxGameSegmentId": 13160,
          "endGameSegmentId": 13168,
          "gameSegmentCategory": "inning"
        },
        {
          "deprecated": true,
          "isEndOfSegment": false,
          "boxDisplay": 17,
          "minDisplay": 17,
          "shortDisplay": "17th Inn",
          "longDisplay": "17th Inning",
          "gameSegmentId": 13170,
          "boxGameSegmentId": 13170,
          "endGameSegmentId": 13178,
          "gameSegmentCategory": "inning"
        },
        {
          "boxDisplay": 17,
          "minDisplay": "T17",
          "shortDisplay": "Top 17th Inn",
          "longDisplay": "Top of the 17th Inning",
          "gameSegmentId": 13172,
          "boxGameSegmentId": 13170,
          "endGameSegmentId": 13178,
          "gameSegmentCategory": "inning"
        },
        {
          "boxDisplay": 17,
          "minDisplay": "B17",
          "shortDisplay": "Bot 17th Inn",
          "longDisplay": "Bottom of the 17th Inning",
          "gameSegmentId": 13177,
          "boxGameSegmentId": 13170,
          "endGameSegmentId": 13178,
          "gameSegmentCategory": "inning"
        },
        {
          "deprecated": true,
          "isEndOfSegment": true,
          "boxDisplay": 17,
          "minDisplay": "End 17",
          "shortDisplay": "End 17th Inn",
          "longDisplay": "End of the 17th Inning",
          "gameSegmentId": 13175,
          "boxGameSegmentId": 13170,
          "endGameSegmentId": 13178,
          "gameSegmentCategory": "inning"
        },
        {
          "isEndOfSegment": true,
          "boxDisplay": 17,
          "minDisplay": "End 17",
          "shortDisplay": "End 17th Inn",
          "longDisplay": "End of the 17th Inning",
          "gameSegmentId": 13178,
          "boxGameSegmentId": 13170,
          "endGameSegmentId": 13178,
          "gameSegmentCategory": "inning"
        },
        {
          "isEndOfSegment": false,
          "boxDisplay": 1,
          "minDisplay": "P1",
          "shortDisplay": "1st Per",
          "longDisplay": "1st Period",
          "gameSegmentId": 14010,
          "boxGameSegmentId": 14010,
          "endGameSegmentId": 14015,
          "gameSegmentCategory": "period"
        },
        {
          "isEndOfSegment": true,
          "boxDisplay": 1,
          "minDisplay": "End P1",
          "shortDisplay": "End 1st Per",
          "longDisplay": "End of the 1st Period",
          "gameSegmentId": 14015,
          "boxGameSegmentId": 14010,
          "endGameSegmentId": 14015,
          "gameSegmentCategory": "period"
        },
        {
          "isEndOfSegment": false,
          "boxDisplay": 2,
          "minDisplay": "P2",
          "shortDisplay": "2nd Per",
          "longDisplay": "2nd Period",
          "gameSegmentId": 14020,
          "boxGameSegmentId": 14020,
          "endGameSegmentId": 14025,
          "gameSegmentCategory": "period"
        },
        {
          "isEndOfSegment": true,
          "boxDisplay": 2,
          "minDisplay": "End P2",
          "shortDisplay": "End 2nd Per",
          "longDisplay": "End of the 2nd Period",
          "gameSegmentId": 14025,
          "boxGameSegmentId": 14020,
          "endGameSegmentId": 14025,
          "gameSegmentCategory": "period"
        },
        {
          "isEndOfSegment": false,
          "boxDisplay": 3,
          "minDisplay": "P3",
          "shortDisplay": "3rd Per",
          "longDisplay": "3rd Period",
          "gameSegmentId": 14030,
          "boxGameSegmentId": 14030,
          "endGameSegmentId": 14035,
          "gameSegmentCategory": "period"
        },
        {
          "isEndOfSegment": true,
          "boxDisplay": 3,
          "minDisplay": "End P3",
          "shortDisplay": "End 3rd Per",
          "longDisplay": "End of the 3rd Period",
          "gameSegmentId": 14035,
          "boxGameSegmentId": 14030,
          "endGameSegmentId": 14035,
          "gameSegmentCategory": "period"
        },
        {
          "isEndOfSegment": false,
          "boxDisplay": "Match",
          "minDisplay": "Match",
          "shortDisplay": "Match",
          "longDisplay": "Match",
          "gameSegmentId": 15010,
          "boxGameSegmentId": 15010,
          "endGameSegmentId": 15015,
          "gameSegmentCategory": "match"
        },
        {
          "isEndOfSegment": true,
          "minDisplay": "End Match",
          "shortDisplay": "End Match",
          "longDisplay": "End of Match",
          "gameSegmentId": 15015,
          "boxGameSegmentId": 15010,
          "endGameSegmentId": 15015,
          "gameSegmentCategory": "match"
        },
        {
          "isEndOfSegment": false,
          "boxDisplay": "OT",
          "minDisplay": "OT",
          "shortDisplay": "1st OT",
          "longDisplay": "1st Overtime",
          "gameSegmentId": 19650,
          "boxGameSegmentId": 19650,
          "endGameSegmentId": 19655,
          "gameSegmentCategory": "genericOvertime"
        },
        {
          "isEndOfSegment": true,
          "minDisplay": "End 1OT",
          "shortDisplay": "End 1st OT",
          "longDisplay": "End of the 1st Overtime",
          "gameSegmentId": 19655,
          "boxGameSegmentId": 19650,
          "endGameSegmentId": 19655,
          "gameSegmentCategory": "genericOvertime"
        },
        {
          "isEndOfSegment": false,
          "boxDisplay": "2OT",
          "minDisplay": "2OT",
          "shortDisplay": "2nd OT",
          "longDisplay": "2nd Overtime",
          "gameSegmentId": 19660,
          "boxGameSegmentId": 19660,
          "endGameSegmentId": 19665,
          "gameSegmentCategory": "genericOvertime"
        },
        {
          "isEndOfSegment": true,
          "minDisplay": "End 2OT",
          "shortDisplay": "End 2nd OT",
          "longDisplay": "End of the 2nd Overtime",
          "gameSegmentId": 19665,
          "boxGameSegmentId": 19660,
          "endGameSegmentId": 19665,
          "gameSegmentCategory": "genericOvertime"
        },
        {
          "isEndOfSegment": false,
          "boxDisplay": "3OT",
          "minDisplay": "3OT",
          "shortDisplay": "3rd OT",
          "longDisplay": "3rd Overtime",
          "gameSegmentId": 19670,
          "boxGameSegmentId": 19670,
          "endGameSegmentId": 19675,
          "gameSegmentCategory": "genericOvertime"
        },
        {
          "isEndOfSegment": true,
          "minDisplay": "End 3OT",
          "shortDisplay": "End 3rd OT",
          "longDisplay": "End of the 3rd Overtime",
          "gameSegmentId": 19675,
          "boxGameSegmentId": 19670,
          "endGameSegmentId": 19675,
          "gameSegmentCategory": "genericOvertime"
        },
        {
          "isEndOfSegment": false,
          "boxDisplay": "4OT",
          "minDisplay": "4OT",
          "shortDisplay": "4th OT",
          "longDisplay": "4th Overtime",
          "gameSegmentId": 19680,
          "boxGameSegmentId": 19680,
          "endGameSegmentId": 19685,
          "gameSegmentCategory": "genericOvertime"
        },
        {
          "isEndOfSegment": true,
          "minDisplay": "End 4OT",
          "shortDisplay": "End 4th OT",
          "longDisplay": "End of the 4th Overtime",
          "gameSegmentId": 19685,
          "boxGameSegmentId": 19680,
          "endGameSegmentId": 19685,
          "gameSegmentCategory": "genericOvertime"
        },
        {
          "isEndOfSegment": false,
          "boxDisplay": "5OT",
          "minDisplay": "5OT",
          "shortDisplay": "5th OT",
          "longDisplay": "5th Overtime",
          "gameSegmentId": 19690,
          "boxGameSegmentId": 19690,
          "endGameSegmentId": 19695,
          "gameSegmentCategory": "genericOvertime"
        },
        {
          "isEndOfSegment": true,
          "minDisplay": "End 5OT",
          "shortDisplay": "End 5th OT",
          "longDisplay": "End of the 5th Overtime",
          "gameSegmentId": 19695,
          "boxGameSegmentId": 19690,
          "endGameSegmentId": 19695,
          "gameSegmentCategory": "genericOvertime"
        },
        {
          "isEndOfSegment": false,
          "boxDisplay": "6OT",
          "minDisplay": "6OT",
          "shortDisplay": "6th OT",
          "longDisplay": "6th Overtime",
          "gameSegmentId": 19700,
          "boxGameSegmentId": 19700,
          "endGameSegmentId": 19705,
          "gameSegmentCategory": "genericOvertime"
        },
        {
          "isEndOfSegment": true,
          "minDisplay": "End 6OT",
          "shortDisplay": "End 6th OT",
          "longDisplay": "End of the 6th Overtime",
          "gameSegmentId": 19705,
          "boxGameSegmentId": 19700,
          "endGameSegmentId": 19705,
          "gameSegmentCategory": "genericOvertime"
        },
        {
          "boxDisplay": "UN",
          "minDisplay": "UN",
          "shortDisplay": "Unknown",
          "longDisplay": "Unknown",
          "gameSegmentId": 19444,
          "boxGameSegmentId": 19444,
          "gameSegmentCategory": "generic"
        },
        {
          "boxDisplay": "T",
          "minDisplay": "T",
          "shortDisplay": "Total",
          "longDisplay": "Total",
          "gameSegmentId": 19888,
          "boxGameSegmentId": 19888,
          "gameSegmentCategory": "generic"
        },
        {
          "boxDisplay": "F",
          "minDisplay": "F",
          "shortDisplay": "Final",
          "longDisplay": "Final",
          "gameSegmentId": 19999,
          "boxGameSegmentId": 19999,
          "gameSegmentCategory": "generic"
        }
      ]
    }
  }
};
