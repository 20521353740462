import createAction from 'redux-actions/es/createAction';
import handleActions from 'redux-actions/es/handleActions';

const SET_IS_ROUTED = 'routing/SET';

export const actions = {
	setRouted: createAction(SET_IS_ROUTED),
};

export default handleActions({
	[SET_IS_ROUTED]: (state, action) => ({
		...state,
		isRouted: action.payload,
	}),
}, {
	isRouted: false,
});
