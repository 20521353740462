/* global window:false */
import './publicPath';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import Provider from 'react-redux/lib/components/Provider';
import loadPolyfills from '../../common/libs/loadPolyfills';
import configureStore from '../redux/configureStore';
import { actions as routingActions } from '../redux/modules/routing';
//import { setIsRouted } from '../../common/libs/routing';
import Main from './components/main.jsx';
import MainLegacy from './components/mainLegacyPages.jsx';

const store = configureStore();

const IS_EMBEDDED = !!window.location.href.match(/\/(iframes|embed)/);

function renderApp (container) {
	store.dispatch(routingActions.setRouted(true));

	const render = (Component, node) => {
		ReactDOM.render((
			<Provider store={store}>
				<Component
					isEmbedded={IS_EMBEDDED}
				/>
			</Provider>
		), node);
	};

	render(Main, container);

	if (module.hot) {
		module.hot.accept('./components/main.jsx', () => {
			const Main = require('./components/main.jsx').default;
			render(Main, container)
		});
	}
}

function initSSUi () {
	Promise.all([
		import('../../common/libs/domEvents'),
		import('../ssUi/ssUi'),
		//import('../components/modalRoot.jsx'), /* MOVED TO legacy header */
	]).then(([
		{ trigger },
		{ default: createSSUi },
		//{ default: ModalRoot },
	]) => {
		window.ssUi = window.ssUi || {};
		const uiFuncs = createSSUi({ store });
		Object.keys(uiFuncs)
			.forEach(key => window.ssUi[key] = uiFuncs[key]);
		trigger(window, 'ssUiLoad');

		function render(Component, node) {
			ReactDOM.render((
				<Provider store={store}>
					<Component />
				</Provider>
			), node);
		}

		/*
		XXX - Moved into Header

		const modalContainer = document.createElement('div');
		document.body.appendChild(modalContainer)
		render(ModalRoot, modalContainer);

		if (module.hot) {
			module.hot.accept('../components/modalRoot.jsx', () => {
				const ModalRoot = require('../components/modalRoot.jsx').default;
				render(ModalRoot, modalContainer)
			});
		}
		*/

		const headerRoot = document.getElementById('scorestream-header-root');
		if (headerRoot) {
			render(MainLegacy, headerRoot);

			if (module.hot) {
				module.hot.accept('./components/mainLegacyPages.jsx', () => {
					const Header = require('./components/mainLegacyPages.jsx').default;
					render(Header, headerRoot);
				});
			}
		}
	});
}

loadPolyfills()
	.then(() => {
		const realtimeAppPageContainer = document.getElementById('react-root');
		if (realtimeAppPageContainer) {
			renderApp(realtimeAppPageContainer);
		} else {
			initSSUi();
		}
	});
