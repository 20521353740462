import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

function LoginButton({ showLogin }) {
	return (
		<Button
			variant="outlined"
			onClick={showLogin}
			style={{
				margin: `5px 4px`,
				borderColor: 'white',
				color: 'white',
			}}
		>
			Login
		</Button>
	);
}
LoginButton.propTypes = {
	showLogin : PropTypes.func.isRequired,
};

export default LoginButton;