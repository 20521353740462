'use strict';
import _ from 'lodash';
import * as user from './user';

//requests can be deduped better if all reqs to 'users.get' go through the users lib
//this way, if we request a supplement, a subsequent request for the user will be filtered out
export const get = p => {
	return user.get(_.assign({
		includeUserSupplements: true
	}, p));
};

export const getInitialState = function getInitialState() {
	const supplements = _.get(window, ['gData', 'redux', 'collections', 'userSupplementCollection', 'list'], null);
	if (supplements) {
		return _.keyBy(supplements, 'userId');
	} else {
		return {};
	}
};
