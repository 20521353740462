'use strict';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

const svgs = require.context('../../images/users/', false, /\.svg$/);

const icons = {
	400: svgs('./allstar.svg'),
	500: svgs('./mvp.svg'),
	600: svgs('./veteran.svg'),
	800: svgs('./legend.svg')
};

function getStyles (fontSize) {
	return {
		400: {
			left: '-7%',
			top: '-22%',
		},
		500: {
			left: '-6%',
			top: '-22%',
		},
		600: {
			left: (fontSize <= 10) ? '-17%' : '-13%',
			top: '-25%',
		},
		800: {
			left: '-5%',
			bottom: 0
		}
	};
}

const scaling = {
	400: 1.93,
	500: 2,
	600: 2.46,
	800: 2
};

class UserRankIcon extends React.Component {
	constructor (props) {
		super(props);
		const index = Math.floor(props.userRank.userRankId / 100) * 100;
		const styles = getStyles(props.fontSize);
		this.state = {
			style: _.assignIn({
				position: 'absolute',
				width: props.fontSize * scaling[index],
			}, styles[index], props.style),
		};
	}
	
	componentWillReceiveProps (nextProps) {
		if (nextProps.fontSize !== this.props.fontSize) {
			const index = Math.floor(nextProps.userRank.userRankId / 100) * 100;
			const styles = getStyles(nextProps.fontSize);
			this.setState({
				style: _.assignIn({
					position: 'absolute',
					width: nextProps.fontSize * scaling[index],
				}, styles[index], nextProps.style),
			});
		}
	}
	
	render () {
		const index = Math.floor(this.props.userRank.userRankId / 100) * 100;
		if (!icons[index] || this.props.isSuspended) {
			return null;
		}
		
		const src = icons[index];
		const { style, } = this.state;
		return (
			<img
				src={src}
				style={style}
			/>
		);
	}
}
UserRankIcon.propTypes = {
	isSuspended: PropTypes.bool,
	userRank: PropTypes.object.isRequired,
	fontSize: PropTypes.number.isRequired,
	style: PropTypes.object
};

export default UserRankIcon;
