/* global window:false*/
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import AutoSuggest from 'react-autosuggest';
import withStyles from "@material-ui/core/styles/withStyles";
import { handleError } from "../libs/errorHandler";
import { getApi } from "../libs/api";
import { SearchCache } from "./omniSearch/searchCache";
import getTheme from './omniSearch/getTheme';
import container from '../containers/omniSearch';
import TeamSuggestion from './omniSearch/teamSuggestion.jsx';
import UserSuggestion from './omniSearch/userSuggestion.jsx';
import TypeMoreSuggestion from './omniSearch/typeMoreSuggestion.jsx';
import MessageSuggestion from './omniSearch/messageSuggestion.jsx';
import TeamTierFilters from './omniSearch/teamTierFilters.jsx';
import SearchModes from './omniSearch/searchModes.jsx';
import { renderInputComponent } from "./omniSearch/renderInputComponent/default.jsx";
import { safeWithRouter } from '../libs/routing';
const ssApi = getApi({name: 'stream'});

const MIN_SEARCH_LENGTH = 3;
const SEARCH_COUNT = 24;
export const SEARCH_MODES = {
	user: 'user',
	team: 'team',
};

function getSuggestionValue(suggestion) {
	const { team, user, typeMore, message } = suggestion;

	if (team) {
		return `${team.minTeamName} ${team.mascot1}`;
	} else if (user) {
		return user.displayName;
	} else if (typeMore) {
		return 'typemore';
	} else if (message) {
		return message;
	}
}

/**
 * This is because we use "suggestions" to highlight messages like "type more characters" and sometimes they show on click no matter what.
 * @returns {boolean}
 */
function alwaysRenderSuggestions() {
	return true;
}

const TEAM_CACHE = new SearchCache();
const USER_CACHE = new SearchCache();

class OmniSearch extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			value: "",
			teamTierId: 1,
			suggestions: [],
			activeSearchMode: props.searchModes[0],
		};

		this.requests = [];

		this.onFocus = this.onFocus.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.onChange = this.onChange.bind(this);
		this.setTeamTier = this.setTeamTier.bind(this);
		this.setActiveSearchMode = this.setActiveSearchMode.bind(this);

		this.refresh = this.refresh.bind(this);
		this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
		this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
		this.renderSuggestionsContainer = this.renderSuggestionsContainer.bind(this);
		this.renderSuggestion = this.renderSuggestion.bind(this);
		this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
		this.defaultOnSelect = this.defaultOnSelect.bind(this);
	}

	defaultOnSelect({ team, user, href }) {
		const { history } = this.props;

		if (team) {
			if (history) {
				history.push(team.relativeUrl);
			} else {
				window.location = team.url;
			}
		} else if (user) {
			if (history) {
				history.push(user.relativeUrl);
			} else {
				window.location = user.url;
			}
		} else if (href) {
			window.location = href;
		}
	}

	onSuggestionSelected(evt, { suggestion }) {
		//console.log('onSuggestionSelected', suggestion);
		const { onSelect, receiveCollections } = this.props;

		if (suggestion.team) {
			receiveCollections({
				teamCollection: {
					list: [suggestion.team]
				},
				teamPictureCollection: {
					list : _.compact([suggestion.mascotPicture, suggestion.backgroundPicture])
				},
			});
		} else if (suggestion.user) {
			receiveCollections({
				userCollection: { list: [suggestion.user]}
			});
		}

		if (onSelect) {
			onSelect(suggestion);
		} else {
			this.defaultOnSelect(suggestion);
		}
	}

	setTeamTier({ teamTierId }) {
		// We caching the results for whatever the active tier is, not all the individual tiers
		TEAM_CACHE.clear();

		this.setState({
			teamTierId,
			//suggestions: [{ message: 'Switching options, searching...'}],
		}, this.refresh);
	}

	setActiveSearchMode({ searchMode }) {
		this.setState({
			activeSearchMode: searchMode,
			suggestions: [{ message: 'Switching search mode...' }],
		}, this.refresh);
	}

	renderSuggestionsContainer({containerProps, children}) {
		const {
			teamTierMap,
			searchModes,
			showFilterTeamTiersForTeams,
			classes,
		} = this.props;
		const {
			activeSearchMode,
			teamTierId,
		} = this.state;

		const ttFilter = (activeSearchMode === 'team' && showFilterTeamTiersForTeams);

		return (
			<div {...containerProps}>
				<SearchModes
					searchModes={searchModes}
					activeSearchMode={activeSearchMode}
					setActiveSearchMode={this.setActiveSearchMode}
				/>

				{ttFilter ?
					<TeamTierFilters
						teamTierId={teamTierId}
						teamTierMap={teamTierMap}
						setTeamTier={this.setTeamTier}
					>
						{children}
					</TeamTierFilters> :
					<div className={classes.plainSuggestionsList}>
						{children}
					</div>
				}
			</div>
		);
	}

	renderSuggestion(suggestion) {
		const { colorMap, organizationMap, showTeamMascot} = this.props;

		if (suggestion.team) {
			return (
				<TeamSuggestion
					colorMap={colorMap}
					organizationMap={organizationMap}
					showMascot={showTeamMascot}
					{...suggestion}
				/>
			);
		} else if (suggestion.user) {
			return (
				<UserSuggestion
					{...suggestion}
				/>
			);
		} else if (suggestion.typeMoreType) {
			return (
				<TypeMoreSuggestion
					{...suggestion}
				/>
			);
		} else if (suggestion.message) {
			return (
				<MessageSuggestion
					{...suggestion}
				/>
			);
		}
	}

	componentWillMount() {
		this.cancelRequests();
	}

	onBlur(evt) {
		const { onBlur } = this.props;

		onBlur(evt);

		this.cancelRequests();
		TEAM_CACHE.clear();
		USER_CACHE.clear();
	}

	onFocus(evt) {
		const { onFocus } = this.props;
		onFocus(evt);
	}

	onChange(evt) {
		this.setState({
			value: evt.target.value || ""
		});
	}

	request({ method, params, callback }) {
		const req = ssApi.post({
			method,
			params,
			callback: (err, result) => {
				this.requests = this.requests.filter((r) => r !== req);
				callback(err, result);
			}
		});

		this.requests.push(req);
	}

	cancelRequests() {
		if (!this.requests.length) { return; }

		this.requests.forEach((req) => {
			if (req.abort) {
				req.abort();
			}
		});

		this.requests = [];
	}

	getTeams({ value }) {
		const {
			ignoreMinimumLengthForTeams,
			authUserLocation,
			teamSearchParams
		} = this.props;

		const {
			teamTierId
		} = this.state;

		if (!ignoreMinimumLengthForTeams && (value.length < MIN_SEARCH_LENGTH)) {
			return this.setState({
				isSearching: false,
				suggestions: [{
					typeMoreType: "teams",
					message: "Type more characters to search teams"
				}],
			});
		}
		const cacheKey = value + `~` + teamTierId;
		const cached = TEAM_CACHE.get(cacheKey);

		if (cached) {
			return this.setState({
				isSearching: false,
				suggestions: cached,
			});
		}


		this.setState({
			isSearching: true,
		});

		this.request({
			method: 'teams.search',
			params: {
				teamName: (ignoreMinimumLengthForTeams && value.length < MIN_SEARCH_LENGTH) ? undefined : value,
				teamTierId,
				location: authUserLocation,
				count: SEARCH_COUNT,
				...teamSearchParams,
			},
			callback: (err, r) => {
				if (err) {
					this.setState({ isSearching: false });
					return handleError(err);
				}

				const teams = r.collections.teamCollection.list;
				const total = r.total;
				let suggestions = [];

				if (teams.length) {
					const teamPictures = r.collections.teamPictureCollection.list;
					const teamPictureMap = _.keyBy(teamPictures, 'teamPictureId');

					suggestions = teams.map((team) => {
						return {
							team,
							mascotPicture : teamPictureMap[_.get(team, ['mascotTeamPictureIds', 0])],
							backgroundPicture: teamPictureMap[_.get(team, ['backgroundTeamPictureIds', 0])]
						};
					});
				} else {
					suggestions.push({
						message: "No results",
					});
				}

				if (
					teamTierId === 1 &&
					total > teams.length &&
					value.length >= MIN_SEARCH_LENGTH
				) {
					suggestions.push({
						message: `See all ${total} results`,
						href: `/search?q=${value}`,
					});
				} else if (total > teams.length) {
					suggestions.push({
						message: `Showing ${SEARCH_COUNT} of ${total} - type more to refine your results`,
					});
				}

				TEAM_CACHE.set(cacheKey, suggestions);

				this.setState({
					isSearching: false,
					suggestions,
				});
			}
		});
	}

	getUsers({ value }) {
		if (value.length < MIN_SEARCH_LENGTH) {
			return this.setState({
				isSearching: false,
				suggestions: [{
					typeMoreType: "users",
					message: "Type more characters to search users"
				}],
			});
		}

		const cached = USER_CACHE.get(value);

		if (cached) {
			return this.setState({
				isSearching: false,
				suggestions: cached,
			});
		}

		const { authUserLocation } = this.props;
		this.setState({
			isSearching: true,
		});

		this.request({
			method: 'users.search',
			params: {
				name: value,
				location: authUserLocation,
				count: SEARCH_COUNT,
			},
			callback: (err, r) => {
				if (err) {
					this.setState({ isSearching: false });
					return handleError(err);
				}

				const users = r.collections.userCollection.list;
				const total = r.total;

				let suggestions = [];
				if (users.length) {
					suggestions = users.map((user) => {
						return {
							user,
						};
					});

					if (total > users.length) {
						suggestions.push({
							message: `Showing ${SEARCH_COUNT} of ${total} - type more to refine your results`,
						});
					}

				} else {
					suggestions.push({
						message: "No Results"
					});
				}

				USER_CACHE.set(value, suggestions);

				this.setState({
					isSearching: false,
					suggestions,
				});
			}
		});
	}

	ensureData() {
		if (this._dataEnsured) { return; }

		const {
			organizationMap,
			loadOrganizations,
			teamTierMap,
			loadTeamTiers,
			authUserLocation,
			loadAuthUserLocation,
		} = this.props;

		if (!_.keys(organizationMap).length) {
			loadOrganizations();
		}

		if (!_.keys(teamTierMap).length) {
			loadTeamTiers();
		}

		if (!authUserLocation) {
			loadAuthUserLocation();
		}

		this._dataEnsured = true;
	}

	onSuggestionsFetchRequested({ value }) {
		this.cancelRequests();
		this.ensureData();

		const { activeSearchMode } = this.state;

		if (activeSearchMode === 'team') {
			this.getTeams({
				value
			});
		} else if (activeSearchMode === 'user') {
			this.getUsers({
				value
			});
		}
	}

	refresh() {
		const { value } = this.state;

		this.onSuggestionsFetchRequested({
			value,
		});
	}

	onSuggestionsClearRequested() {
		this.setState({
			suggestions: [],
		});
	}

	render () {
		const {
			value,
			suggestions,
			isSearching,
		} = this.state;
		const {
			renderInputComponent,
			placeholder,
			classes,
			style,
		} = this.props;

		const inputProps = {
			value,
			placeholder,
			onFocus: this.onFocus,
			onBlur: this.onBlur,
			onChange: this.onChange,
			//Special Props that are not meant to just be flayed out on the input
			omniSearchProps: {
				isSearching,
				style,
			},
		};

		return (
			<AutoSuggest
				theme={classes}
				renderInputComponent={renderInputComponent}
				inputProps={inputProps}
				suggestions={suggestions}
				onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
				onSuggestionsClearRequested={this.onSuggestionsClearRequested}
				renderSuggestionsContainer={this.renderSuggestionsContainer}
				renderSuggestion={this.renderSuggestion}
				onSuggestionSelected={this.onSuggestionSelected}
				getSuggestionValue={getSuggestionValue}
				shouldRenderSuggestions={alwaysRenderSuggestions}
				highlightFirstSuggestion={false}
				focusInputOnSuggestionClick={false}
			/>
		);
	}
}
OmniSearch.propTypes = {
	style: PropTypes.object,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	onSelect: PropTypes.func,
	searchModes: PropTypes.array,
	teamSearchParams: PropTypes.object,
	showFilterTeamTiersForTeams: PropTypes.bool,
	ignoreMinimumLengthForTeams: PropTypes.bool,
	renderInputComponent: PropTypes.func,
	placeholder: PropTypes.string,
	// container
	organizationMap: PropTypes.object,
	colorMap: PropTypes.object,
	teamTierMap: PropTypes.object,
	authUserLocation: PropTypes.object,
	loadOrganizations: PropTypes.func,
	loadTeamTiers: PropTypes.func,
	loadAuthUserLocation: PropTypes.func,
	receiveCollections: PropTypes.func,
	showTeamMascot: PropTypes.bool,
	// safeWithRouter
	history: PropTypes.object,
	//withStyles
	classes: PropTypes.object,
};
OmniSearch.defaultProps = {
	renderInputComponent: renderInputComponent,
	onFocus: _.noop,
	onBlur: _.noop,
	searchModes: _.values(SEARCH_MODES),
	showFilterTeamTiersForTeams: true,
	ignoreMinimumLengthForTeams: true,
	placeholder: "Search...",
	teamSearchParams: {},
	showTeamMascot: true,
};

const Connected = container(OmniSearch);
const Styled = withStyles(getTheme)(Connected);
export default safeWithRouter(Styled);
