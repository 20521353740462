"use strict";
import delayedGet from '../../helpers/delayedGet';
import _get from 'lodash/get';
import keyBy from 'lodash/keyBy';

export const getInitialState = function getInitialState() {
	return keyBy(_get(window, ['gData', 'redux', 'collections', 'squadCollection', 'list'], []), 'squadId')
};

export const get = function getSquads (p) {
	return delayedGet({
		key: 'squad',
		method: 'squads.get',
		idsKey: 'squadIds',
		ids: p.squadIds
	});
};
