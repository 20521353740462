import React from 'react';
import PropTypes from 'prop-types';
import withStyles from "@material-ui/core/styles/withStyles";
import ProfileImageCircle from '../users/profileImageCircle.jsx';

function getStyles() {
	return {
		container : {
			display: 'flex',
			alignItems: 'center',
			padding: 5,
		},
		displayName: {
			marginLeft: 10,
			fontSize: 14,
		},
		fullName: {
			marginLeft: 5,
			fontSize: 13,
			opacity: 0.7,
			flex: 1,
		}
	};
}

function UserSuggestion({ user, classes }) {
	return (
		<div className={classes.container}>
			<ProfileImageCircle
				user={user}
				size={40}
			/>
			<div className={classes.displayName}>
				{user.displayName}
			</div>
			<div className={classes.fullName}>
				{user.fullName}
			</div>
		</div>
	);
}

UserSuggestion.propTypes = {
	user: PropTypes.object.isRequired,
	classes: PropTypes.object,
};

const Styled = withStyles(getStyles)(UserSuggestion);
export default React.memo(Styled);