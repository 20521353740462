import isFunction from 'lodash/isFunction';
import isObject from "lodash/isObject";
import isArray from "lodash/isArray";

let ERROR_HANDLER = defaultErrorHandler;

/**
 * Default Error handler shows what your error handlers function signature should look like basically
 * @param err
 * @param {object} [options]
 */
function defaultErrorHandler(err, options) {
	const title = options && options.title || "Unknown Error";
	console.error(title, err);
}

export function getErrorHandler() {
	return ERROR_HANDLER;
}

/**
 * Our generic error handler, different clients, all build should use this and then each build can register what it wants to do on errors
 * We used to always assume that we wanted to show a sweetAlert, well that is not true!
 * @param err
 * @param [options]
 * @param {string} [options.title] - Title for the error?
 */

export function handleError(err, options) {
	// why u call me with no error! da fuq
	if (!err) { return; }

	//console.error(err);

	if (isFunction(ERROR_HANDLER)) {
		ERROR_HANDLER(err, options);
	}
}

/**
 * Short hand for generic error from APIs, just preserves the title basically...
 * @param err
 */
export function handleApiError(err) {
	handleError(err, {
		title : 'API Problem',
	});
}

export function registerErrorHandler(func) {
	ERROR_HANDLER = func;
}

export function parseErrorIntoString(error) {
	let errorString = '';

	if (isArray(error)) {
		errorString = error.map(parseErrorIntoString).join(' ');
	} else if (isObject(error)) {
		if (error.message) {
			errorString = error.message;
		} else {
			errorString = JSON.stringify(error);
		}
	} else {
		errorString = error;
	}

	return errorString;
}