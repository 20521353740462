export function SearchCache() {
	this._store = {};
	this._lastKey = undefined;
	this._lastValue = undefined;
}

SearchCache.prototype.set = function set(key, value) {
	this._lastKey = key;
	this._lastValue = value;
	this._store[key] = value;
};
SearchCache.prototype.get = function get(key) {
	return this._store[key];
};
SearchCache.prototype.clear = function clear() {
	this._store = [];

	// even when we clear, we always preserve just the last result so if you type three letters, click away and come back its ultra fast
	if (this._lastKey && this._lastValue) {
		this.set(this._lastKey, this._lastValue);
	}
};