"use strict";
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import * as sharedStyles from '../../libs/sharedStyles';

const styles = {
	base : _.assignIn({
		borderRadius: 2,
		backgroundColor: '#fff',
		padding: '15px 32px',
		fontSize : '16px',
		outline: 'none',
		color: sharedStyles.vars.colors.ssBlue,
		borderColor : sharedStyles.vars.colors.ssBlue,
		borderWidth : '1px',
		borderStyle : 'solid',
		transition : '.2s',
		whiteSpace: 'nowrap',
		':hover' : {
			backgroundColor: sharedStyles.vars.colors.ssHoverBlue,
			color: '#fff',
		}
	}, sharedStyles.noSelect),
	outline : { }, //- base style is outline
	solid : {
		backgroundColor : sharedStyles.vars.colors.ssBlue,
		color : '#fff',
		':hover' : {
			backgroundColor : sharedStyles.vars.colors.ssHoverBlue,
			borderColor: sharedStyles.vars.colors.ssHoverBlue,
		}
	},
	faIcon : { 
		paddingRight : 6,
	}
};


class BaseButton extends React.Component {
	constructor(props) {
		super(props);
		this._onClick = this._onClick.bind(this);
	}

	_onClick (evt) {
		if (this.props.disabled || !this.props.onClick) {
			return;
		}

		return this.props.onClick(evt);
	}

	render () {
		//- base -> outline / solid -> custom
		const buttonStyle = _.assignIn({}, styles.base, styles[this.props.type] || {}, this.props.style);
		if (this.props.disabled) {
			delete buttonStyle[':hover'];
		}

		let faIcon;
		if (this.props.faIcon) {
			const iconStyle = (this.props.text || this.props.children) ? styles.faIcon : undefined;
			faIcon = (<span style={iconStyle} className={`fa fa-${this.props.faIcon}`}></span>);
		}

		return (
			<button
				style={buttonStyle}
				disabled={this.props.disabled}
				onClick={this._onClick}
				onMouseEnter={this.props.onMouseEnter}
				onMouseLeave={this.props.onMouseLeave}
			>
				{faIcon}
				{this.props.text}
				{this.props.children}
			</button>
		);
	}
}

BaseButton.propTypes = {
	children : PropTypes.node,
	style: PropTypes.object,
	type : PropTypes.string,
	faIcon : PropTypes.string,
	text : PropTypes.string,
	disabled: PropTypes.bool,
	onClick: PropTypes.func,
	onMouseEnter: PropTypes.func,
	onMouseLeave: PropTypes.func,
};
BaseButton.defaultProps = {
	disabled: false,
	onClick: _.noop,
	onMouseEnter: _.noop,
	onMouseLeave: _.noop
};
export default Radium(BaseButton);
