import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import withStyles from '@material-ui/core/styles/withStyles';
import { vars } from '../../../common/libs/sharedStyles';
import logoSrc from '../../../common/images/logos/scorestream-white-full.png';

function makeClasses(theme) {
	return {
		footer : {
			position: 'relative',
			padding : `40px 20px 20px 20px`,

			background: vars.colors.ssDarkBlue,
			borderTop: `1px solid ${vars.colors.ssBlue}`,
			color: '#fff',
			fontWeight: 200,
			fontSize: '13px',
			justifyContent: 'space-around',
			display: 'block',

			textAlign: 'center',
			[theme.breakpoints.up('md')] : {
				textAlign: 'left',
				display : 'flex',
			},
			'& h6': {
				fontSize: 17,
			},
		},
		catchPhrase: {
			fontSize: 18,
			fontFamily: `"Open Sans Condensed", sans-serif`,
			marginTop: 4,
			marginBottom: 6,
		},
		copyright : {
			marginBottom: 30,
		},
		linkList: {
			marginBottom: 20,
			'& a': {
				color: `rgba(255, 255, 255, .85)`,
				fontSize: 14,
				marginRight: 10,
				display: 'block',
				marginBottom: 3,
				'&:hover': {
					color: '#fff',
				}
			}
		},
		socialIcons: {
			marginTop: 10,
			[theme.breakpoints.up('md')] : {
				marginTop: 20,
				marginBottom: 40,
			},
			'& a': {
				marginRight: 5,
				display: 'inline-block',
				width: 36,
				height: 36,
			},
			'& span': {
				color: 'white',
				fontSize: 28,
			}
		}
	};
}

class Footer extends React.Component {
	shouldComponentUpdate(np) {
		return np.location !== this.props.location;
	}

	render() {
		const {classes, location} = this.props;

		const { pathname } = location;

		if (pathname.match(/\/game\//)) {
			return <div/>;
		}

		return (
			<footer className={classes.footer}>
				<div>
					<img src={logoSrc} alt="ScoreStream Logo" />
					<div className={classes.catchPhrase}>
						Fan driven scores, pictures and conversations
					</div>
					<div className={classes.socialIcons}>
						<a href="https://twitter.com/ScoreStream" title="ScoreStream on Twitter"><span className="fa fa-twitter"/></a>
						<a href="https://www.facebook.com/ScoreStream" title="ScoreStream on Facebook"><span className="fa fa-facebook"/></a>
						<a href="https://www.instagram.com/scorestream/" title="ScoreStream on Instagram"><span className="fa fa-instagram"/></a>
						<a href="https://www.pinterest.com/scorestream/" title="ScoreStream on Pinterest"><span className="fa fa-pinterest"/></a>
					</div>
					<div className={classes.copyright}>
						© {new Date().getYear() + 1900} ScoreStream Inc.
					</div>
				</div>
				<div>
					<h6>Our Company</h6>
					<nav className={classes.linkList}>
						<a href="mailto:info@scorestream.com">info@scorestream.com</a>
						<a href="mailto:press@scorestream.com">press@scorestream.com</a>
						<a href="/about">About</a>
						<a href="/faq">F.A.Q.</a>
						<a href="/misc/privacyPolicy">Privacy Policy</a>
						<a href="/misc/termsOfService">Terms and Conditions</a>
						<a href="http://blog.scorestream.com/">Blog</a>
					</nav>
				</div>
				<div>
					<h6>Downloads & Tools</h6>
					<nav className={classes.linkList}>
						<a href="https://itunes.apple.com/us/app/scorestream/id514772744?at=11lMjr&ct=/frontPage&pt=1114044">ScoreStream for iOS</a>
						<a href="https://play.google.com/store/apps/details?id=com.scorestream.scorestream">ScoreStream for Android</a>
						<a href="/widgetManager">Widget Manager</a>
						<a href="/sportfx">Download SportFX</a>
						<a href="/search">Team Search</a>
					</nav>
				</div>
				<div>
					<h6>Partner With Us</h6>
					<nav className={classes.linkList}>
						<a href="mailto:partner@scorestream.com">partner@scorestream.com</a>
						<a href="mailto:advertise@scorestream.com">advertise@scorestream.com</a>
						<a href="/partners">Partner Opportunities</a>
						<a href="/make-a-scoreboard-widget">Get a Scoreboard Widget</a>
					</nav>
				</div>
			</footer>
		);
	}
}
Footer.propTypes = {
	classes: PropTypes.object,
	location: PropTypes.object,
};

const Styled = withStyles(makeClasses)(Footer);
const Routed = withRouter(Styled);
export default Routed;
