import React from 'react';
import PropTypes from 'prop-types';
import useWindowSize from "../../../../common/hooks/windowSize";
import withStyles from '@material-ui/core/es/styles/withStyles';
import AppleLogo from '../../../../common/mui/icons/appleLogo.jsx';
import AndroidLogo from '@material-ui/icons/Android';

function makeClasses(theme) {
	return {
		nav: {
			marginTop: 13,
			marginRight: 20,
			'& a': {
				fontSize: 12,
				[theme.breakpoints.up('md')] : {
					fontSize: 13,
				},
				[theme.breakpoints.up('lg')] : {
					fontSize: 14,
				},
				padding: `2px 5px`,
				margin: `0 3px`,
				color: 'rgba(255, 255, 255, .85)',
				transition: '.1s ease',
				'&:hover': {
					color: '#fff',
				}
			}
		},
		icon: {
			verticalAlign: 'text-top',
			fontSize: 16,
		}
	};
}

function NoAuthNav({ classes }) {
	const { innerWidth } = useWindowSize();

	return (
		<nav className={classes.nav}>
			{innerWidth > 1080 && <a href="/app" alt="Get the App">Get the App <span><AppleLogo classes={{root: classes.icon}}/>&<AndroidLogo classes={{root: classes.icon}}/></span></a>}
			{innerWidth > 1180 && <a href="/explore/high-school-sports" alt="Explore High School Sports">High School Sports</a>}
			{innerWidth > 1280 && <a href="/make-a-scoreboard-widget" alt="Make a Scoreboard Widget">Scoreboard Widgets</a>}
			{innerWidth > 1380 && <a href="/about" alt="About">About</a>}
			{innerWidth > 1480 && <a href="/become-a-team-general-manager" alt="Become a Team General Manager">Become a GM</a>}
		</nav>
	);
}
NoAuthNav.propTypes = {
	classes: PropTypes.object,
};

export default withStyles(makeClasses)(NoAuthNav);