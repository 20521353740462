
export const SUGGESTIONS_MAX_HEIGHT = Math.max(window.innerHeight / 2, 300);

export default function getTheme() {
	return {
		container: {
			position: 'relative',
		},
		containerOpen: {},
		input: {},
		inputOpen: {},
		inputFocused: {},
		suggestionsContainer: {
			display: 'none',
		},
		suggestionsContainerOpen: {
			zIndex: 2,
			position: 'absolute',
			width: '100%',
			display: 'block',
			background: '#FFF',
			color: '#111',
			boxShadow: '0px 5px 10px 0px rgba(0, 0, 0, 0.2)',
		},
		suggestionsList: {
			listStyleType: 'none',
			margin: 0,
			padding: 0,
		},
		plainSuggestionsList: {
			maxHeight: SUGGESTIONS_MAX_HEIGHT,
			overflowY: 'auto',
		},
		suggestion: {
			cursor: 'pointer',
			transition: `0.1s`
		},
		suggestionFirst: {},

		suggestionHighlighted: {
			backgroundColor: '#1B95E5',
			color: 'white',
		},
		//sectionContainer: {},
		//sectionContainerFirst: {},
		//sectionTitle: {},
	};
}