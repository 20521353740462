'use strict';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

const styles = {
	circle: {
		borderRadius: '50%',
		borderStyle: 'solid',
		borderWidth: '1px',
		position: 'relative',
		overflow: 'hidden'
	},
	text: {
		fontFamily: 'Open Sans Condensed',
		fontWeight: 700,
		textAlign: 'center',
		width: '100%',
		position: 'absolute',
		top: '50%',
		transform: 'translateY(-50%)'
	},
	shine: {
		borderRadius: '50%',
		width: '200%',
		height: '200%',
		position: 'absolute',
		top: '-145%',
		left: '-50%',
		backgroundColor: 'rgba(255, 255, 255, 0.3)'
	}
};

function GMBadge (props) {
	const {color1, color2} = props;

	let mainColor = color1;
	let secondaryColor = color2;

	// flip them if white is on the outside
	if (secondaryColor.colorId === 1000 && mainColor.colorId !== 1000) {
		mainColor = color2;
		secondaryColor = color1;
	}

	let insideColor, outlineColor, textColor;

	if (mainColor.isDark && secondaryColor.isDark) {
//		mainColor = mainColor.lightColor;
		secondaryColor = secondaryColor.darkColor;
	} else if (mainColor.isLight && secondaryColor.isLight) {
		mainColor = mainColor.darkColor;
		secondaryColor = secondaryColor.lightColor;
	}

	insideColor = `rgb(${mainColor.r}, ${mainColor.g}, ${mainColor.b})`;
	outlineColor = `rgb(${secondaryColor.r}, ${secondaryColor.g}, ${secondaryColor.b})`;
	textColor = `rgb(${secondaryColor.r}, ${secondaryColor.g}, ${secondaryColor.b})`;

	return (
		<div style={_.assignIn({}, styles.circle, props.style, {
			borderColor: outlineColor,
			backgroundColor: insideColor,
			width: props.size,
			height: props.size
		})}>
			<div style={_.assignIn({
				color: textColor,
				fontSize: Math.ceil(props.size * 0.6)
			}, styles.text)}>
				GM
			</div>
			<div style={styles.shine}></div>
		</div>
	);
}

GMBadge.propTypes = {
	color1: PropTypes.object.isRequired,
	color2: PropTypes.object.isRequired,
	size: PropTypes.any,
	style: PropTypes.object
};

export default GMBadge;
