import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import InfiniteScroll from './infiniteScroll.jsx';
import paginatorContainer from '../containers/paginator';

import * as api from '../libs/api';
import styles from '../libs/paginatorStyles';
import defaultMessageRenderer from './paginator/defaultMessageRenderer.jsx';
import { isInIframe } from '../libs/browsers';


/**
 * Default funcs basically here to describe how to use and implmenent for different modules
 */
function defaultRenderFunc(p) {
	const items = p.items;
	const total = p.total;

	return (
		<div>
			<div>Total: {total}</div>
			{items.map((item, i) => (
				<div key={i}>Item {i}- {JSON.stringify(item)}</div>
			))}
		</div>
	);
}

function defaultResultFormatter(result) {
	// Find the array associated with the result (e.g. you searched for gamePosts and there are gonna be collections
	// but there will be one array of ids associated with the response

	const itemsKey = _.findKey(result, _.isArray);
	const items = result[itemsKey];
	const total = (items && result.total) ? result.total : 0;

	if (!items) {
		console.warn("Could not determine a default items array value for the response", result);
	}

	return {
		items: items || [],
		total: total
	};
}

function defaultErrorHandler(err) {
	console.warn("Error in Paginator: ", err);
}


/**
 * Goal: A component that can be instantiated with a few flexible parameters and can be used on multiple pages
 * without having to hook up all the container foo.
 *
 * 2nd: To be able to use global state ideally, but fall back to component state
 *
 * Params To get a thing
 * how to format the result
 * where to store the result
 *
 * take the result and render it through some formatter
 *
 * dispatch generic collection thing
 * dispatch result, total and where to store it
 */
class Paginator extends React.PureComponent {
	constructor(props) {
		super(props);
		this.apiRequester = api.getApi({name : this.props.serviceName});
		this.state = {
			error : null,
			fetching  : false
		};

		this._loadMore = this._loadMore.bind(this);
		this.renderContent = this.renderContent.bind(this);
	}

	_loadMore () {
		if (!this.props.hasMore) { return; }
		if (this.state.fetching) { return; }
		if (this.state.error) { return; }

		this.setState({
			fetching : true
		});

		const method = this.props.method;

		const params = _.assignIn({
			offset : (this.props.items.length),
			count : this.props.count
		}, this.props.params);

		// can set loading state?
		this.apiRequester.post({
			method : method,
			params : params,
			callback : (err, result) => {
				if (err) {
					this.props.errorHandler(err);

					this.setState({
						error : err,
						fetching : false,
					});
				} else {
					if (this.props.receiveCollections) {
						this.props.receiveCollections(result.collections);
					}

					const formattedResult = this.props.resultFormatter(result);
					this.props.onData(formattedResult);
					this.props.storeState(formattedResult);

					this.setState({
						fetching : false,
					});
				}
			}
		});
	}

	renderContent () {
		return (
			<div style={this.props.style}>
				<this.props.renderFunc
					fetching={this.state.fetching}
					items={this.props.items}
					total={this.props.total}
				/>
				<this.props.messageRenderer
					fetching={this.state.fetching}
					hasMore={this.props.hasMore}
					items={this.props.items}
					total={this.props.total}
					//other params should be spread to the container
					onClick={this._loadMore}
				/>
			</div>
		);
	}

	componentDidMount() {
		this._loadMore();
	}

	render () {
		if (this.state.error) {
			return (
				<div style={styles.hasError}>
					<div>We encountered an error loading this data. Try reloading.</div>
				</div>
			);
		}

		if (this.state.fetching && _.isNull(this.props.total)) {
			// initial fetch
			return this.renderContent();
		} else {
			return (
				<InfiniteScroll
					nextPage={this._loadMore}
					threshold={this.props.scrollOffset}
					parentIsScrollingContainer={this.props.parentIsScrollingContainer}
					render={this.renderContent}
				/>
			);
		}
	}
}

Paginator.propTypes = {
	storeState : PropTypes.func.isRequired, // provided form container
	receiveCollections : PropTypes.func,

	method : PropTypes.string.isRequired,
	params : PropTypes.object.isRequired,
	count : PropTypes.number,
	hasMore: PropTypes.bool,
	style: PropTypes.object,
	serviceName: PropTypes.string,

	renderFunc : PropTypes.func,
	messageRenderer: PropTypes.func,
	resultFormatter : PropTypes.func,
	errorHandler : PropTypes.func,
	onData : PropTypes.func,

	// mapped from container
	items : PropTypes.array,
	total : PropTypes.number,

	// html
	scrollOffset : PropTypes.number,
	parentIsScrollingContainer : PropTypes.bool,
};

Paginator.defaultProps = {
	serviceName : 'scoreStream',
	count : 10,

	renderFunc : defaultRenderFunc,
	messageRenderer: defaultMessageRenderer,
	resultFormatter : defaultResultFormatter,
	errorHandler : defaultErrorHandler,
	onData : _.noop,
	params: {},
	scrollOffset : 400
};

export default paginatorContainer(Paginator);
