import _get from 'lodash/get';
import keyBy from 'lodash/keyBy';

import delayedGet from '../../helpers/delayedGet';

export const propsToDiff = [
	'totalAwayGamePoints',
	'totalHomeGamePoints',
];

export const getInitialState = function getInitialState () {
	return keyBy(_get(window, ['gData', 'redux', 'collections', 'gameMetricCollection', 'list'], []), 'gameId');
};

export const get = function getGameMetrics (p) {
	return delayedGet({
		key: 'gameMetric',
		method: 'games.metrics.search',
		idsKey: 'gameIds',
		ids: p.gameIds,
		idsReceiveKey: 'gameMetricIds',
	});
};
