import _ from 'lodash';

export function getInitialState () {
	const resources = _.get(window, ['gData', 'redux', 'collections', 'resourceCollection']);
	if (resources) {
		return _.keyBy(resources.list, 'resourceId');
	} else {
		return {};
	}
}
export const propsToDiff = {};
