'use strict';
import React from 'react';
import _ from 'lodash';

import { config } from './config';
import { getAccessToken } from './accessToken';

const openSym = Symbol('socketOpen');
const closeSym = Symbol('socketClose');
const errorSym = Symbol('socketError');
let rpcId = 1;
let connects = 0;
let failures = 0;
const listeners = new Map();
const callbacks = new Map();
let sendQueue = [];
let failed = false;
let socket = null;
let connectedComponents = [];
let _disconnectTimeout = null;

function onMessage (msg) {
	if (msg.data === 'ping') { return console.log('ping'); }

	const data = (_.isString(msg.data)) ?
			JSON.parse(msg.data) :
			msg.data || {};
	const callback = callbacks.get(data.id) || _.noop;

	if (data.error) { return callback(data.error); }

	if (data.result) {
		const { result } = data;
		if (result.paramErrors) { return callback(result.paramErrors); }
		callback(null, result);
	} else if (data.method) {
		const _listeners = listeners.get(data.method);
		if (_listeners) {
			_listeners.forEach(cb => cb(data.params));
		}
	}
}

/**
 * Submit a message / api request over the socket
 * @param {Object} p
 * @param {String} p.method (e.g. games.streams.watch)
 * @param {Object} p.params (e.g. { 'gameIds' : [123] }
 * @param {Function} p.callback - Executed when the server responds with a matching id over the socket
 * @example
 */
export function send (p) {
	if (!(_.get(socket, 'readyState') === WebSocket.OPEN)) {
		return sendQueue.push(p);
	}

	p.id = rpcId++;
	if (undefined === p.params) { p.params = {}; }

	if (p.callback) {
		callbacks.set(p.id, p.callback);
		delete p.callback;
	}

	// append our accessToken in
	const accessToken = getAccessToken();
	if (accessToken && !p.params.accessToken) {
		p.params.accessToken = accessToken;
	}
	if (!p.params.apiKey) {
		p.params.apiKey = 'a20bd983-0147-437a-ab6d-49afeb883d33';
	}
	socket.send(JSON.stringify(p));
}

/**
 * Set a callback to listen to all messages of the specific method / event
 * @param {String} method
 * @param {Function} callback - Whenever we see this event we execute every function that is listening
 */
export function on (method, callback) {
	const _listeners = listeners.get(method);
	if (!_listeners) {
		listeners.set(method, [callback]);
	} else {
		_listeners.push(callback);
	}

}

/**
 * Remove a listener of an event
 * Note, if using this scoped object remember to use a variable to store your this.function.bind(this)
 * since bind creates an annoymous function. Otherwise the off won't match your original callback.
 * @param {String} method
 * @param {Function} callback - Whenever we see this event we execute every function that is listening
 */
export function off (method, callback) {
	const _listeners = listeners.get(method);
	if (_listeners) {
		if (callback) {
			listeners.set(method, _listeners.filter(cb => (cb !== callback)));
		} else {
			listeners.delete(method);
		}
	}
}

export function connect () {
	if (!window.WebSocket) { return console.warn('WebSockets not supported.'); }
	if (_disconnectTimeout) {
		window.clearTimeout(_disconnectTimeout);
		_disconnectTimeout = null;
	}
	if (failed) { return console.warn('Too many errors, refresh the page to try again.'); }
	if (socket) {
		if (socket.readyState === WebSocket.OPEN) {
			window.setTimeout(() => onMessage({data: {method: openSym}}), 0);
		}
		console.log('already connected.');
		return;
	}

	socket = new WebSocket(config.scoreStream.secureWebsocketHost);
	socket.onmessage = onMessage;
	socket.onopen = () => {
		if (failures > 0) {
			console.log(`
				Looks like we lost our connection and had to re-establish it
				Reseting failure count back to 0
				We failed to connect ${failures} times...
			`);
			failures = 0;
		}

		// Always check accessToken on the socket if we are logged in
		const accessToken = getAccessToken();
		if (accessToken) {
			send({
				method : 'users.checkAccessToken',
				params : {
					accessToken,
				},
				callback : (err, r) => {
					if (err) {
						return handleError('access token was invalid... do something???', err);
					} else if (r) {
						return console.info('access token was valid for user ', r.userId);
					}
				}
			})
		}

		onMessage({data: {method: openSym}});
	};
	//TODO- exponential backoff for reconnection
	socket.onclose = () => {
		console.log("Socket has been Closed");
		window.setTimeout(connect, (failures * 1000)); //crappy reconnect backoff
		onMessage({data: {method: closeSym}});
	};
	socket.onerror = evt => {
		failures++;
		console.warn(`SOCKET ONERROR --- # Failures: ${failures}`);

		if (failures >= 25) {
			failed = true;
			socket.close();
			onMessage({data: {
				method: errorSym,
				params: evt,
			}});
		}
	};

	on('connected', () => {
		connects += 1;
		sendQueue.forEach(send);
	});
}

export function disconnect () {
	if (socket) {
		delete socket.onclose;
		socket.close();
	}
	rpcId = 1;
	connects = 0;
	failures = 0;
	listeners.clear();
	callbacks.clear();
	sendQueue = [];
	failed = false;
	socket = null;
}
function deferredDisconnect () {
	if (_disconnectTimeout) { return; }
	_disconnectTimeout = window.setTimeout(() => {
		_disconnectTimeout = null;
		disconnect();
	}, 0);
}


/**
 * Higher order component that wraps a base component and allows access to the scorestream socket api.
 * The wrapped component will receive a 'socket' prop with ['on', 'off', 'send'] methods.
 * Subscriptions made using 'props.on' are automatically cleaned up on unmount.
 * On unmount, if there are no longer any mounted components using the socket, it will be disconnected and reset.
 * Components can define ['onSocketOpen', 'onSocketClose', onSocketError'] methods to listen for socket lifecycle events
 * @param Component
 * @returns {ExtendedComponent}
 */
export function connectComponent (Component) {
	return class SocketConnectedComponent extends React.PureComponent {
		constructor (props) {
			super(props);
			this._listeners = new Map();
			this.socket = {
				on: this.on.bind(this),
				off: this.off.bind(this),
				send
			};
		}

		_onSocketEvt (key, evt) {
			const openHandler = _.get(this, ['_component', key]);
			if (openHandler) {
				openHandler(evt);
			}
		}

		on (method, callback) {
			const listeners = this._listeners.get(method);
			if (!listeners) {
				this._listeners.set(method, [callback]);
			} else {
				listeners.push(callback);
			}
			on(method, callback);
		}

		off (method, callback) {
			const listeners = this._listeners.get(method);
			if (listeners) {
				this._listeners.set(method, listeners.filter(listener => (listener !== callback)));
			}
			off(method, callback);
		}

		componentDidMount () {
			connect();
			connectedComponents.push(this);
			const { on } = this.socket;
			on(openSym, evt => this._onSocketEvt('onSocketOpen', evt));
			on(closeSym, evt => this._onSocketEvt('onSocketClose', evt));
			on(errorSym, evt => this._onSocketEvt('onSocketError', evt));
		}

		componentWillUnmount () {
			this._listeners.forEach((listeners, key) => {
				listeners.forEach(listener => off(key, listener));
			});
			connectedComponents = connectedComponents.filter(c => (c !== this));
			if (connectedComponents.length === 0) {
				deferredDisconnect();
			}
		}

		render () {
			return (
				<Component
					{...this.props}
					ref={c => this._component = c}
					socket={this.socket}
				/>
			);
		}
	};
}

export function isConnected () {
	return (_.get(socket, 'readyState') === WebSocket.OPEN);
}
