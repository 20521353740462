import once from 'lodash/once';

import { receive as receiveCollections } from '../actions/collections';

export const importLevelsOfPlayAndOrgs = once(dispatch => {
	import(
		/* webpackChunkName: "cachedLevelsOfPlay" */
		'../../../../cachedData/levelsOfPlay'
	).then(cached => dispatch(receiveCollections(cached.collections)));
});
