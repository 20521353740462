import _ from 'lodash';
import delayedGet from '../../helpers/delayedGet';

function getInitialState() {
	const playlists = _.get(window, ['gData', 'redux', 'collections', 'playlistCollection', 'list'], null);
	if (playlists) {
		return _.keyBy(playlists, 'playlistId');
	} else {
		return {};
	}
}

export { getInitialState };

export const get = function getPlaylists (p) {
	return delayedGet({
		key: 'playlist',
		method: 'playlists.get',
		idsKey: 'playlistIds',
		ids: p.playlistIds
	});
};
