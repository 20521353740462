import get from 'lodash/get';

export function isSafari () {
	const ua = get(navigator, 'userAgent', '');

	return (ua.indexOf('Safari') > -1 && ua.indexOf('Chrome') === -1);
}

export function getQueryParams () {
	var match;
	var pl = /\+/g;  // Regex for replacing addition symbol with a space
	var search = /([^&=]+)=?([^&]*)/g;
	var decode = function (s) { return decodeURIComponent(s.replace(pl, " ")); };
	var query  = window.location.search.substring(1);

	var urlParams = {};
	while ((match = search.exec(query)) !== null) {
		urlParams[decode(match[1])] = decode(match[2]);
	}
	return urlParams;
}

export function isInIframe () {
	return typeof window !== 'undefined' &&
			window.top !== window.self;
}