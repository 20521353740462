"use strict";
import _ from 'lodash';
import delayedGet from './../../helpers/delayedGet';

export const propsToDiff = {};

export const getInitialState = function getInitialState() {
	return _.keyBy(_.get(window, ['gData', 'redux', 'collections', 'teamSupplementCollection', 'list'], []), 'teamSupplementId');
};

export const get = (p) => {
	return delayedGet({
		key: 'teamSupplement',
		method: 'teams.get',
		idsKey: 'teamIds',
		ids: p.teamIds,
		extraParams : {
			includeTeamSupplements : true
		}
	});
};
