import collections from '../../../common/redux/modules/collections';
import lists from '../../../common/redux/modules/lists';
import authUser from '../../../common/redux/modules/authUser';
import infiniteScroll from '../../../common/redux/modules/infiniteScroll';
import modal from '../../../common/redux/modal';
import routing from './routing';
import app from './app';

import routes from './routes';

export default {
	app,
	authUser,
	collections,
	infiniteScroll,
	lists,
	modal,
	routes,
	routing,
};
