
/* 
	FILE AUTOMATICALLY GENERATED BY RUNNING npm run build buildCachedData
	DO NOT EDIT THIS FILE
	QUESTIONS: Ask Ryan
*/

module.exports = {
  "collections": {
    "sportCollection": {
      "list": [
        {
          "sportId": 1,
          "sportName": "football",
          "longDisplay": "Football",
          "abbreviationDisplay": "FB",
          "gameSegmentTypeIds": [
            1
          ],
          "gameSegmentTypes": [
            "quarter"
          ],
          "defaultGameSegmentTypeId": 1,
          "defaultGameSegmentType": "quarter",
          "scoringOptionIds": [
            1,
            2,
            3,
            4,
            5
          ],
          "graphicIconSvg": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/svgs/football-icon.svg",
          "graphicIcon32x32DarkRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/football-32x32Dark.png",
          "graphicIcon32x32LightRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/football-32x32Light.png",
          "graphicIcon128x128DarkRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/football-128x128Dark.png",
          "graphicIcon128x128LightRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/football-128x128Light.png",
          "graphicBanner1920x1008": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/backgrounds/football-banner1920x1008.jpg",
          "graphicBanner1168x300": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/backgrounds/football-banner1168x300.jpg",
          "graphicBanner640x300": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/backgrounds/football-banner640x300.jpg"
        },
        {
          "sportId": 2,
          "sportName": "basketball",
          "longDisplay": "Basketball",
          "abbreviationDisplay": "BKB",
          "gameSegmentTypeIds": [
            1,
            2
          ],
          "gameSegmentTypes": [
            "quarter",
            "half"
          ],
          "defaultGameSegmentTypeId": 1,
          "defaultGameSegmentType": "quarter",
          "scoringOptionIds": [
            6,
            7,
            8
          ],
          "graphicIconSvg": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/svgs/basketball-icon.svg",
          "graphicIcon32x32DarkRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/basketball-32x32Dark.png",
          "graphicIcon32x32LightRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/basketball-32x32Light.png",
          "graphicIcon128x128DarkRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/basketball-128x128Dark.png",
          "graphicIcon128x128LightRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/basketball-128x128Light.png",
          "graphicBanner1920x1008": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/backgrounds/basketball-banner1920x1008.jpg",
          "graphicBanner1168x300": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/backgrounds/basketball-banner1168x300.jpg",
          "graphicBanner640x300": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/backgrounds/basketball-banner640x300.jpg"
        },
        {
          "sportId": 3,
          "sportName": "baseball",
          "longDisplay": "Baseball",
          "abbreviationDisplay": "BSB",
          "gameSegmentTypeIds": [
            3,
            4,
            5,
            6,
            7,
            8,
            9
          ],
          "gameSegmentTypes": [
            "inning-3",
            "inning-4",
            "inning-5",
            "inning-6",
            "inning-7",
            "inning-8",
            "inning-9"
          ],
          "defaultGameSegmentTypeId": 7,
          "defaultGameSegmentType": "inning-7",
          "scoringOptionIds": [
            9,
            10,
            11,
            12
          ],
          "graphicIconSvg": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/svgs/baseball-icon.svg",
          "graphicIcon32x32DarkRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/baseball-32x32Dark.png",
          "graphicIcon32x32LightRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/baseball-32x32Light.png",
          "graphicIcon128x128DarkRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/baseball-128x128Dark.png",
          "graphicIcon128x128LightRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/baseball-128x128Light.png",
          "graphicBanner1920x1008": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/backgrounds/baseball-banner1920x1008.jpg",
          "graphicBanner1168x300": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/backgrounds/baseball-banner1168x300.jpg",
          "graphicBanner640x300": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/backgrounds/baseball-banner640x300.jpg"
        },
        {
          "sportId": 4,
          "sportName": "softball",
          "longDisplay": "Softball",
          "abbreviationDisplay": "SFTB",
          "gameSegmentTypeIds": [
            3,
            4,
            5,
            6,
            7,
            8,
            9
          ],
          "gameSegmentTypes": [
            "inning-3",
            "inning-4",
            "inning-5",
            "inning-6",
            "inning-7",
            "inning-8",
            "inning-9"
          ],
          "defaultGameSegmentTypeId": 7,
          "defaultGameSegmentType": "inning-7",
          "scoringOptionIds": [
            9,
            10,
            11,
            12
          ],
          "graphicIconSvg": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/svgs/softball-icon.svg",
          "graphicIcon32x32DarkRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/softball-32x32Dark.png",
          "graphicIcon32x32LightRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/softball-32x32Light.png",
          "graphicIcon128x128DarkRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/softball-128x128Dark.png",
          "graphicIcon128x128LightRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/softball-128x128Light.png",
          "graphicBanner1920x1008": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/backgrounds/softball-banner1920x1008.jpg",
          "graphicBanner1168x300": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/backgrounds/softball-banner1168x300.jpg",
          "graphicBanner640x300": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/backgrounds/softball-banner640x300.jpg"
        },
        {
          "sportId": 5,
          "sportName": "hockey",
          "longDisplay": "Hockey",
          "abbreviationDisplay": "HCKY",
          "gameSegmentTypeIds": [
            13
          ],
          "gameSegmentTypes": [
            "period"
          ],
          "defaultGameSegmentTypeId": 13,
          "defaultGameSegmentType": "period",
          "scoringOptionIds": [
            13
          ],
          "graphicIconSvg": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/svgs/hockey-icon.svg",
          "graphicIcon32x32DarkRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/hockey-32x32Dark.png",
          "graphicIcon32x32LightRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/hockey-32x32Light.png",
          "graphicIcon128x128DarkRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/hockey-128x128Dark.png",
          "graphicIcon128x128LightRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/hockey-128x128Light.png",
          "graphicBanner1920x1008": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/backgrounds/hockey-banner1920x1008.jpg",
          "graphicBanner1168x300": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/backgrounds/hockey-banner1168x300.jpg",
          "graphicBanner640x300": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/backgrounds/hockey-banner640x300.jpg"
        },
        {
          "sportId": 6,
          "sportName": "volleyball",
          "longDisplay": "Volleyball",
          "abbreviationDisplay": "VB",
          "gameSegmentTypeIds": [
            10,
            11,
            12
          ],
          "gameSegmentTypes": [
            "game-1",
            "game-3",
            "game-5"
          ],
          "defaultGameSegmentTypeId": 11,
          "defaultGameSegmentType": "game-3",
          "scoringOptionIds": [
            21
          ],
          "graphicIconSvg": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/svgs/volleyball-icon.svg",
          "graphicIcon32x32DarkRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/volleyball-32x32Dark.png",
          "graphicIcon32x32LightRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/volleyball-32x32Light.png",
          "graphicIcon128x128DarkRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/volleyball-128x128Dark.png",
          "graphicIcon128x128LightRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/volleyball-128x128Light.png",
          "graphicBanner1920x1008": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/backgrounds/volleyball-banner1920x1008.jpg",
          "graphicBanner1168x300": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/backgrounds/volleyball-banner1168x300.jpg",
          "graphicBanner640x300": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/backgrounds/volleyball-banner640x300.jpg"
        },
        {
          "sportId": 7,
          "sportName": "soccer",
          "longDisplay": "Soccer",
          "abbreviationDisplay": "SOC",
          "gameSegmentTypeIds": [
            2
          ],
          "gameSegmentTypes": [
            "half"
          ],
          "defaultGameSegmentTypeId": 2,
          "defaultGameSegmentType": "half",
          "scoringOptionIds": [
            13
          ],
          "graphicIconSvg": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/svgs/soccer-icon.svg",
          "graphicIcon32x32DarkRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/soccer-32x32Dark.png",
          "graphicIcon32x32LightRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/soccer-32x32Light.png",
          "graphicIcon128x128DarkRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/soccer-128x128Dark.png",
          "graphicIcon128x128LightRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/soccer-128x128Light.png",
          "graphicBanner1920x1008": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/backgrounds/soccer-banner1920x1008.jpg",
          "graphicBanner1168x300": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/backgrounds/soccer-banner1168x300.jpg",
          "graphicBanner640x300": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/backgrounds/soccer-banner640x300.jpg"
        },
        {
          "sportId": 8,
          "sportName": "lacrosse",
          "longDisplay": "Lacrosse",
          "abbreviationDisplay": "LAX",
          "gameSegmentTypeIds": [
            1,
            2
          ],
          "gameSegmentTypes": [
            "quarter",
            "half"
          ],
          "defaultGameSegmentTypeId": 1,
          "defaultGameSegmentType": "quarter",
          "scoringOptionIds": [
            13
          ],
          "graphicIconSvg": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/svgs/lacrosse-icon.svg",
          "graphicIcon32x32DarkRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/lacrosse-32x32Dark.png",
          "graphicIcon32x32LightRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/lacrosse-32x32Light.png",
          "graphicIcon128x128DarkRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/lacrosse-128x128Dark.png",
          "graphicIcon128x128LightRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/lacrosse-128x128Light.png",
          "graphicBanner1920x1008": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/backgrounds/lacrosse-banner1920x1008.jpg",
          "graphicBanner1168x300": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/backgrounds/lacrosse-banner1168x300.jpg",
          "graphicBanner640x300": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/backgrounds/lacrosse-banner640x300.jpg"
        },
        {
          "sportId": 9,
          "sportName": "rugby",
          "longDisplay": "Rugby",
          "abbreviationDisplay": "RGBY",
          "gameSegmentTypeIds": [
            2
          ],
          "gameSegmentTypes": [
            "half"
          ],
          "defaultGameSegmentTypeId": 2,
          "defaultGameSegmentType": "half",
          "scoringOptionIds": [
            22,
            23,
            24,
            25
          ],
          "graphicIconSvg": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/svgs/rugby-icon.svg",
          "graphicIcon32x32DarkRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/rugby-32x32Dark.png",
          "graphicIcon32x32LightRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/rugby-32x32Light.png",
          "graphicIcon128x128DarkRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/rugby-128x128Dark.png",
          "graphicIcon128x128LightRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/rugby-128x128Light.png",
          "graphicBanner1920x1008": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/backgrounds/rugby-banner1920x1008.jpg",
          "graphicBanner1168x300": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/backgrounds/rugby-banner1168x300.jpg",
          "graphicBanner640x300": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/backgrounds/rugby-banner640x300.jpg"
        },
        {
          "sportId": 10,
          "sportName": "waterpolo",
          "longDisplay": "Water Polo",
          "abbreviationDisplay": "WP",
          "gameSegmentTypeIds": [
            1
          ],
          "gameSegmentTypes": [
            "quarter"
          ],
          "defaultGameSegmentTypeId": 1,
          "defaultGameSegmentType": "quarter",
          "scoringOptionIds": [
            13
          ],
          "graphicIconSvg": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/svgs/waterpolo-icon.svg",
          "graphicIcon32x32DarkRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/waterpolo-32x32Dark.png",
          "graphicIcon32x32LightRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/waterpolo-32x32Light.png",
          "graphicIcon128x128DarkRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/waterpolo-128x128Dark.png",
          "graphicIcon128x128LightRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/waterpolo-128x128Light.png",
          "graphicBanner1920x1008": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/backgrounds/waterpolo-banner1920x1008.jpg",
          "graphicBanner1168x300": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/backgrounds/waterpolo-banner1168x300.jpg",
          "graphicBanner640x300": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/backgrounds/waterpolo-banner640x300.jpg"
        },
        {
          "sportId": 11,
          "sportName": "fieldhockey",
          "longDisplay": "Field Hockey",
          "abbreviationDisplay": "FHKY",
          "gameSegmentTypeIds": [
            1,
            2
          ],
          "gameSegmentTypes": [
            "quarter",
            "half"
          ],
          "defaultGameSegmentTypeId": 1,
          "defaultGameSegmentType": "quarter",
          "scoringOptionIds": [
            13
          ],
          "graphicIconSvg": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/svgs/fieldhockey-icon.svg",
          "graphicIcon32x32DarkRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/fieldhockey-32x32Dark.png",
          "graphicIcon32x32LightRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/fieldhockey-32x32Light.png",
          "graphicIcon128x128DarkRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/fieldhockey-128x128Dark.png",
          "graphicIcon128x128LightRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/fieldhockey-128x128Light.png",
          "graphicBanner1920x1008": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/backgrounds/fieldhockey-banner1920x1008.jpg",
          "graphicBanner1168x300": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/backgrounds/fieldhockey-banner1168x300.jpg",
          "graphicBanner640x300": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/backgrounds/fieldhockey-banner640x300.jpg"
        },
        {
          "sportId": 12,
          "sportName": "ultimatefrisbee",
          "longDisplay": "Ultimate Frisbee",
          "abbreviationDisplay": "ULT",
          "gameSegmentTypeIds": [
            1,
            2
          ],
          "gameSegmentTypes": [
            "quarter",
            "half"
          ],
          "defaultGameSegmentTypeId": 2,
          "defaultGameSegmentType": "half",
          "scoringOptionIds": [
            13
          ],
          "graphicIconSvg": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/svgs/ultimatefrisbee-icon.svg",
          "graphicIcon32x32DarkRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/ultimatefrisbee-32x32Dark.png",
          "graphicIcon32x32LightRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/ultimatefrisbee-32x32Light.png",
          "graphicIcon128x128DarkRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/ultimatefrisbee-128x128Dark.png",
          "graphicIcon128x128LightRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/ultimatefrisbee-128x128Light.png",
          "graphicBanner1920x1008": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/backgrounds/ultimatefrisbee-banner1920x1008.jpg",
          "graphicBanner1168x300": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/backgrounds/ultimatefrisbee-banner1168x300.jpg",
          "graphicBanner640x300": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/backgrounds/ultimatefrisbee-banner640x300.jpg"
        },
        {
          "sportId": 13,
          "sportName": "wrestling",
          "longDisplay": "Wrestling",
          "abbreviationDisplay": "WRSL",
          "gameSegmentTypeIds": [
            14
          ],
          "gameSegmentTypes": [
            "match-1"
          ],
          "defaultGameSegmentTypeId": 14,
          "defaultGameSegmentType": "match-1",
          "scoringOptionIds": [
            14,
            15,
            16,
            17,
            18,
            19,
            20
          ],
          "graphicIconSvg": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/svgs/wrestling-icon.svg",
          "graphicIcon32x32DarkRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/wrestling-32x32Dark.png",
          "graphicIcon32x32LightRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/wrestling-32x32Light.png",
          "graphicIcon128x128DarkRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/wrestling-128x128Dark.png",
          "graphicIcon128x128LightRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/wrestling-128x128Light.png",
          "graphicBanner1920x1008": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/backgrounds/wrestling-banner1920x1008.jpg",
          "graphicBanner1168x300": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/backgrounds/wrestling-banner1168x300.jpg",
          "graphicBanner640x300": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/backgrounds/wrestling-banner640x300.jpg"
        },
        {
          "sportId": 14,
          "sportName": "netball",
          "longDisplay": "Netball",
          "abbreviationDisplay": "NTBL",
          "gameSegmentTypeIds": [
            1
          ],
          "gameSegmentTypes": [
            "quarter"
          ],
          "defaultGameSegmentTypeId": 1,
          "defaultGameSegmentType": "quarter",
          "scoringOptionIds": [
            13
          ],
          "graphicIconSvg": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/svgs/netball-icon.svg",
          "graphicIcon32x32DarkRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/netball-32x32Dark.png",
          "graphicIcon32x32LightRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/netball-32x32Light.png",
          "graphicIcon128x128DarkRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/netball-128x128Dark.png",
          "graphicIcon128x128LightRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/netball-128x128Light.png",
          "graphicBanner1920x1008": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/backgrounds/netball-banner1920x1008.jpg",
          "graphicBanner1168x300": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/backgrounds/netball-banner1168x300.jpg",
          "graphicBanner640x300": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/backgrounds/netball-banner640x300.jpg"
        },
        {
          "sportId": 15,
          "sportName": "handball",
          "longDisplay": "Handball",
          "abbreviationDisplay": "HDBL",
          "gameSegmentTypeIds": [
            2
          ],
          "gameSegmentTypes": [
            "half"
          ],
          "defaultGameSegmentTypeId": 2,
          "defaultGameSegmentType": "half",
          "scoringOptionIds": [
            13
          ],
          "graphicIconSvg": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/svgs/handball-icon.svg",
          "graphicIcon32x32DarkRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/handball-32x32Dark.png",
          "graphicIcon32x32LightRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/handball-32x32Light.png",
          "graphicIcon128x128DarkRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/handball-128x128Dark.png",
          "graphicIcon128x128LightRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/handball-128x128Light.png",
          "graphicBanner1920x1008": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/backgrounds/handball-banner1920x1008.jpg",
          "graphicBanner1168x300": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/backgrounds/handball-banner1168x300.jpg",
          "graphicBanner640x300": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/backgrounds/handball-banner640x300.jpg"
        },
        {
          "sportId": 16,
          "sportName": "flagfootball",
          "longDisplay": "Flag Football",
          "abbreviationDisplay": "FLAG",
          "gameSegmentTypeIds": [
            1,
            2
          ],
          "gameSegmentTypes": [
            "quarter",
            "half"
          ],
          "defaultGameSegmentTypeId": 1,
          "defaultGameSegmentType": "quarter",
          "scoringOptionIds": [
            1,
            2,
            3,
            4,
            5
          ],
          "graphicIconSvg": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/svgs/flagfootball-icon.svg",
          "graphicIcon32x32DarkRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/flagfootball-32x32Dark.png",
          "graphicIcon32x32LightRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/flagfootball-32x32Light.png",
          "graphicIcon128x128DarkRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/flagfootball-128x128Dark.png",
          "graphicIcon128x128LightRaster": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/icons/flagfootball-128x128Light.png",
          "graphicBanner1920x1008": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/backgrounds/flagfootball-banner1920x1008.jpg",
          "graphicBanner1168x300": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/backgrounds/flagfootball-banner1168x300.jpg",
          "graphicBanner640x300": "https://cdn-scorestream-com.s3.amazonaws.com/cdn/images/sportGraphics/backgrounds/flagfootball-banner640x300.jpg"
        }
      ]
    }
  }
};
