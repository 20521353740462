import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import nl2br from 'react-nl2br';

import { Link } from '../libs/routing';

import * as sharedStyles from '../libs/sharedStyles';

// Key defines an entityType can be handled
// link property defines if it should be a link or a bold
const DEFAULT_CONFIG = {
	url : {
		link : true,
	},
	user : {
		link : true,
	},
	team : {
		link : true,
	},
	dateTime : {
		format : 'LT',
	}
};

const NO_LINKS_CONFIG = {
	url : {
		link : false,
	},
	user : {
		link : false,
	},
	team : {
		link : false,
	},
	dateTime : {
		format : 'LT',
	}
};

const styles = {
	bold :  {
		fontWeight : 600,
	}
};

const getLinkStyle = _.memoize((color) => {
	return {
		color : color,
		':hover' : {
			color : color,
		},
	};
});

function EntitizedText (props) {
	let {
		entities,
	} = props;

	const {
		text,
		entityTypeConfig,
		linkColor,
		style,
	} = props;

	entities = _.sortBy(entities, 'offset');
	let endIndex = 0;
	const pieces = [];

	entities.forEach((entity) => {
		const {
			entityType,
			offset,
			length,
			replaceText
		} = entity;

		const entityConfig = entityTypeConfig[entityType];

		if (!entityConfig) {
			console.warn(`do not know how to handle entityType ${entityType}`, entity);
			return;
		}

		const beforeText = text.substr(endIndex, offset - endIndex);
//		console.log(beforeText, endIndex, offset - endIndex);
		const entityText = (replaceText) ? replaceText : text.substr(offset, length);
		endIndex = offset + length;

//		console.log(entityText, offset, length);

		if (beforeText) {
			pieces.push(
				<span key={'before-' + endIndex}>
					{nl2br(beforeText)}
				</span>
			);
		}

		if (!entityText) {
			console.warn(`no entity text ${offset}, ${length}`, entity);
			return;
		}

		if (entityType === 'dateTime') {
			const display = moment.utc(entity.dateTime).local().format(entityConfig.format) || entityText;
			return pieces.push(
				<span key={'entity-' + offset}>
					{display}
				</span>
			);
		}


		if (!entityConfig.link) {
			return pieces.push(
				<span key={'entity-' + offset} style={styles.bold}>
					{entityText}
				</span>
			);
		}

		const linkStyle = getLinkStyle(linkColor);

		if (entityType === 'url') {
			pieces.push(
				<a
					key={'entity-' + offset}
					href={entity.url}
					target="_blank"
					style={linkStyle}
				>
					{entityText}
				</a >
			);
		} else if (entityType === 'user') {
			pieces.push(
				<Link
					key={'entity-' + offset}
					to={`/user/${entity.userId}`}
					style={linkStyle}
				>
					{entityText}
				</Link>
			);
		} else if (entityType === 'team') {
			pieces.push(
				<Link
					key={'entity-' + offset}
					to={`/team/${entity.teamId}`}
					style={linkStyle}
				>
					{entityText}
				</Link>
			);
		}
	});

	if (endIndex < text.length) {
		pieces.push(
			<span key='end'>
				{nl2br(text.substr(endIndex, text.length))}
			</span>
		);
	}

//	console.log(pieces);

	return (
		<span style={style}>
			{pieces}
		</span>	
	);
}

EntitizedText.propTypes = {
	text : PropTypes.string,
	entities : PropTypes.array,
	entityTypeConfig : PropTypes.object,
	linkColor : PropTypes.string,
	style : PropTypes.object,
};

EntitizedText.defaultProps = {
	text : '',
	entities : [],
	entityTypeConfig : DEFAULT_CONFIG,
	linkColor : sharedStyles.vars.colors.ssBlueHighlight,
};

export const noLinksConfig = NO_LINKS_CONFIG;

export default React.memo(EntitizedText);
