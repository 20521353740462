
/* 
	FILE AUTOMATICALLY GENERATED BY RUNNING npm run build buildCachedData
	DO NOT EDIT THIS FILE
	QUESTIONS: Ask Ryan
*/

module.exports = {
  "collections": {
    "userRankCollection": {
      "list": [
        {
          "userRankId": 100,
          "display": "New Recruit",
          "abbreviationDisplay": "NR",
          "pointsRangeStart": 0,
          "nextUserRankId": 210
        },
        {
          "userRankId": 210,
          "display": "Rookie 1",
          "abbreviationDisplay": "R1",
          "pointsRangeStart": 50,
          "nextUserRankId": 220
        },
        {
          "userRankId": 220,
          "display": "Rookie 2",
          "abbreviationDisplay": "R2",
          "pointsRangeStart": 163,
          "nextUserRankId": 230
        },
        {
          "userRankId": 230,
          "display": "Rookie 3",
          "abbreviationDisplay": "R3",
          "pointsRangeStart": 276,
          "nextUserRankId": 310
        },
        {
          "userRankId": 310,
          "display": "Pro 1",
          "abbreviationDisplay": "P1",
          "pointsRangeStart": 500,
          "nextUserRankId": 320
        },
        {
          "userRankId": 320,
          "display": "Pro 2",
          "abbreviationDisplay": "P2",
          "pointsRangeStart": 1000,
          "nextUserRankId": 330
        },
        {
          "userRankId": 330,
          "display": "Pro 3",
          "abbreviationDisplay": "P3",
          "pointsRangeStart": 1500,
          "nextUserRankId": 410
        },
        {
          "userRankId": 410,
          "display": "Allstar 1",
          "abbreviationDisplay": "A1",
          "pointsRangeStart": 2500,
          "nextUserRankId": 420
        },
        {
          "userRankId": 420,
          "display": "Allstar 2",
          "abbreviationDisplay": "A2",
          "pointsRangeStart": 4375,
          "nextUserRankId": 430
        },
        {
          "userRankId": 430,
          "display": "Allstar 3",
          "abbreviationDisplay": "A3",
          "pointsRangeStart": 6250,
          "nextUserRankId": 510
        },
        {
          "userRankId": 510,
          "display": "MVP 1",
          "abbreviationDisplay": "M1",
          "pointsRangeStart": 10000,
          "nextUserRankId": 520
        },
        {
          "userRankId": 520,
          "display": "MVP 2",
          "abbreviationDisplay": "M2",
          "pointsRangeStart": 32500,
          "nextUserRankId": 530
        },
        {
          "userRankId": 530,
          "display": "MVP 3",
          "abbreviationDisplay": "M3",
          "pointsRangeStart": 55000,
          "nextUserRankId": 610
        },
        {
          "userRankId": 610,
          "display": "Veteran 1",
          "abbreviationDisplay": "V1",
          "pointsRangeStart": 100000,
          "nextUserRankId": 620
        },
        {
          "userRankId": 620,
          "display": "Veteran 2",
          "abbreviationDisplay": "V2",
          "pointsRangeStart": 325000,
          "nextUserRankId": 630
        },
        {
          "userRankId": 630,
          "display": "Veteran 3",
          "abbreviationDisplay": "V3",
          "pointsRangeStart": 550000,
          "nextUserRankId": 810
        },
        {
          "userRankId": 810,
          "display": "Legend 1",
          "abbreviationDisplay": "L1",
          "pointsRangeStart": 1000000,
          "nextUserRankId": 820
        },
        {
          "userRankId": 820,
          "display": "Legend 2",
          "abbreviationDisplay": "L2",
          "pointsRangeStart": 2000000,
          "nextUserRankId": 830
        },
        {
          "userRankId": 830,
          "display": "Legend 3",
          "abbreviationDisplay": "L3",
          "pointsRangeStart": 3000000
        }
      ]
    }
  }
};
