import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

function getStyles (props) {
	const styles = {
		outer: {
			paddingBottom: 4
		},
		link: {
			marginLeft: 6,
			fontWeight: 200,
			color: '#555',
		},
		icon: {
			color: '#999',
			marginLeft: 3
		}
	};

	if (props.colorTheme === 'light') {
		return _.merge({}, styles, {
			link : {
				color : '#FFF',
			},
			icon : {
				color  : '#D8D8D8'
			}
		})
	}

	// dark
	return styles;
}

function Location (props) {
	let locationString = [props.city, props.state]
		.filter(v => !!(v && v.length))
		.join(', ');

	if (props.country && props.country !== 'US') {
		locationString += ` ${props.country}`;
	}

	const styles = getStyles(props);

	return (
		<div style={styles.outer}>
			<i style={styles.icon} className="fa fa-map-marker" />
			<span style={styles.link}>{locationString}</span>
		</div>
	);
}
Location.propTypes = {
	city: PropTypes.string,
	state: PropTypes.string,
	country : PropTypes.string,
	colorTheme : PropTypes.oneOf(['dark', 'light'])
};

Location.defaultProps = {
	colorTheme : 'dark'
};

export default Location;
