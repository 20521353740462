'use strict';
import _ from 'lodash';

function getInitialState () {
	const teamPictures = _.get(window, ['gData', 'redux', 'collections', 'teamPictureCollection']);
	if (teamPictures) {
		return _.keyBy(teamPictures.list, 'teamPictureId');
	} else {
		return {};
	}
}

const propsToDiff = {};
export default {getInitialState, propsToDiff};