/*globals gData:true */

let _config;

// MICROSERVICES SHOULD STuFF THIS HERE
if (window.gData && gData && gData.config) {
	_config = gData.config;
} else {
	console.warn("It would be alot better if gData.config was defined from our common module");
	console.log("Attempting to deterime environment and config");

	var backup = require('../../../cachedData/backupConfig.js');

	var env;
	if (window.gData && gData && gData.env) {
		env = gData.env;
	} else if (window.location.hostname === 'localhost') {
		env = 'local';
	} else if (window.location.hostname.match(/dev.+scorestream/)) {
		env = 'development';
	} else {
		env = 'production';
	}

	console.log("Determined env to be " + env);
	
	_config = backup[env];
}



export const config = _config;
