'use strict';
import cookies from 'js-cookie';
import _ from 'lodash';
import { config } from './config';

let EXPLICIT_ACCESS_TOKEN;

export function setExplicitAccessToken(accessToken) {
	EXPLICIT_ACCESS_TOKEN = accessToken;
}

function getEnv () {
	return _.get('window', ['gData', 'env'], 'unknown');
}

function getLimitedAccessTokenKey() {
	const env = getEnv();
	var prefix = (env === 'production') ? '' : `${env}-`;
	return `${prefix}laToken`;
}

export function setLimitedAccessToken (limitedAccessToken) {
	const limitedAccessTokenKey = getLimitedAccessTokenKey();
	const cookieOptions = {};
	if (getEnv() !== 'local') { cookieOptions.domain = '.scorestream.com'; }
	cookies.set(limitedAccessTokenKey, limitedAccessToken, cookieOptions);
}

export function getLimitedAccessToken () {
	const key = getLimitedAccessTokenKey();
	return cookies.get(key) || null;
}

function getAccessTokenKey () {
	const { env } = config;
	const prefix = (env === 'production') ? '' : `${env}-`;
	return `${prefix}accessToken`;
}

export function getAccessToken () {
	if (EXPLICIT_ACCESS_TOKEN) {
		return EXPLICIT_ACCESS_TOKEN;
	}

	const key = getAccessTokenKey();
	const accessToken = cookies.get(key);
	return accessToken || null;
}

export function setAccessToken (accessToken) {
	const accessTokenKey = getAccessTokenKey();
	if (_.get(window, ['gData', 'env']) === 'local') {
		cookies.set(accessTokenKey, accessToken, {expires: 365});
	} else {
		cookies.set(accessTokenKey, accessToken, {expires: 365, domain: '.scorestream.com'});
	}
}

export function removeLimitedAccessTokenCookie () {
	const cookieOptions = {};
	if (_.get(window, ['gData', 'env']) === 'local') {
		cookieOptions.domain = '.scorestream.com';
	}
	const limitedAccessTokenKey = getLimitedAccessTokenKey();
	cookies.remove(limitedAccessTokenKey, cookieOptions);
}
