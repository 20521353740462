import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

function getBorderSize (props) {
	let borderSize = 2;
	if (props.size > 120) {
		borderSize = 3;
	}
	if (props.size > 160) {
		borderSize = 5;
	}
	return borderSize;
}

class ProfileImage extends React.PureComponent {
	constructor (props) {
		super(props);
		const borderSize = getBorderSize(props);
		const style = _.assignIn({
			display: 'inline-block',
			borderRadius: '50%',
			border: `${borderSize}px solid #fff`,
			boxSizing: 'border-box',
			backgroundColor: '#fff',
			backgroundImage: `url(${props.user.imageThumbnailUrl})`,
			backgroundPosition : 'center',
			backgroundSize: 'cover',
			boxShadow : '0px 1px 3px 0px rgba(0,0,0,0.2)',
		}, props.style);
		style.height = props.size;
		style.width = props.size;
		this.state = {style};
	}
	componentWillReceiveProps (nextProps) {
		if (nextProps.style !== this.props.style ||
				nextProps.size !== this.props.size ||
				_.get(nextProps, 'user.imageThumbnailUrl') !== this.props.user.imageThumbnailUrl) {
			const borderSize = getBorderSize(nextProps);
			const style = _.assignIn({
				display: 'inline-block',
				borderRadius: '50%',
				border: `${borderSize}px solid #fff`,
				boxSizing: 'border-box',
				backgroundColor: '#fff',
				backgroundImage: `url(${nextProps.user.imageThumbnailUrl})`,
				backgroundPosition : 'center',
				backgroundSize: 'cover',
				boxShadow : '0px 1px 3px 0px rgba(0,0,0,0.2)',
			}, nextProps.style);
			style.height = nextProps.size;
			style.width = nextProps.size;
			this.setState({style});
		}
	}

	render () {
		return (
			<div style={this.state.style}/>
		);
	}
}

ProfileImage.propTypes = {
	user: PropTypes.object.isRequired,
	size: PropTypes.number.isRequired,
	style : PropTypes.object,
};

export default ProfileImage;
