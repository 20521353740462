"use strict";
import { createAction, handleActions } from 'redux-actions';
import update from 'immutability-helper';
import { handleError } from "../../libs/errorHandler";

const STORE = 'infiniteScroll/storeState';
const CLEAR = 'infiniteScroll/clearState';

export default handleActions({
	[CLEAR] : (state, action) => {
		const stateKey = action.payload.stateKey;
		const statePath = stateKey.path;
//		const stateId = stateKey.id;

		return update(state, {
			[statePath] : { $set : undefined }
		});
	},
	[STORE]: {
		next: (state, action) => {
			const newState = action.payload.state;
			const stateKey = action.payload.stateKey;
			const statePath = stateKey.path;
			const stateId = stateKey.id;

			const existing = state[statePath];

			if (existing && existing.id === stateId) {
				const oldItems = state[statePath].items;
				const newItemsUniq = newState.items.filter((item) => {
					return (oldItems.indexOf(item) < 0)
				});

				return update(state, {
					[statePath]: {
						items: { $push: newItemsUniq }
					}
				});
			} else {
				return update(state, {
					[statePath] : {$set: {
						items: newState.items,
						total: newState.total,
						id: stateId
					}}
				});
			}
		},
		throw: (state, action) => {
			handleError('Problem storing infinite scroll state', action.payload);
			return state;
		}
	},
}, {});

const actions = {
	storeState : createAction(STORE),
	clear : createAction(CLEAR),
};

export { actions };
export const clear = actions.clear;
