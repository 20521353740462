import get from 'lodash/get';

const mqXs = 554;
const mqSm = 768;
const mqMd = 992;
const mqLg = 1200;

export const vars = {
	navBarHeight: (!get(window, 'gData.iframeLayout')) ? 46 : 0, //should match the realtime css
	colors: {
		ssBlue: '#1B95E5',
		ssHoverBlue : '#2684C8',
		ssBlueHighlight: '#38B0FF',
		ssDarkBlue: '#096098',
		ssDarkerBlue: '#074978',
		ssSubtleBlue : '#f3fbff',
		ssYellow: '#ffc20f',
		ssLighterYellow:'#fbcf20',
		ssDarkYellow: '#eba90f',
		ssGreen: '#2b9263',
		ssRed : '#C52E2B',
		ssNotificationRed : '#FF1B19',
		ssLightGray: '#c2c6ca',
		ssLighterGray : '#dee3e8',
		ssLightestGray : '#ecf1f6',
		ssGray: '#67727b',
		ssMediumGray: '#1b2c39',
		ssDarkGray: '#021524',
		assessments : {
			neutral : '#67727b', //gray
			positive : '#2B9263',
			negative : '#FF500F'
		}
	},
	cards: {
		backgroundColor : '#F5F8FA',
		borderColor : '#88BBDF'
	},
	text: {
		default : '#333'
	},
	thirdParty: {
		twitterColor: "#4099ff",
		facebookColor: "#3b5a97",
	}
};

export const menus = {
	container: {
		backgroundColor: '#fff',
		border: '1px solid #333',
		boxShadow: '0 3px 8px rgba(0, 0, 0, .3)',
	},
	item: {
		padding: 8,
		whiteSpace: 'nowrap',
		':hover': {
			cursor: 'pointer',
			backgroundColor: '#dae6ef',
		},
	},
};

export const routes = {
	user: {
		headerHeight: 360,
	}
};

export const mq = {
	xs : `@media (min-width: ${mqXs}px)`,
	sm : `@media (min-width: ${mqSm}px)`,
	md : `@media (min-width: ${mqMd}px)`,
	lg : `@media (min-width: ${mqLg}px)`,
	minWidth : {
		xs : mqXs,
		sm : mqSm,
		md : mqMd,
		lg : mqLg,
	}
};

export function hexToRGBA (hex, opacity) {
	let hexVal = hex.replace('#', '');
	if (hexVal.length === 3) {
		const [a, b, c] = hexVal.split('');
		hexVal = [a, a, b, b, c, c].join('');
	}
	const r = parseInt(hexVal.substring(0, 2), 16);
	const g = parseInt(hexVal.substring(2, 4), 16);
	const b = parseInt(hexVal.substring(4, 6), 16);
	return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export const singleLineHideOverflowText = {
	lineHeight : 1.25, /* so the hanging parts of g's j's and shit don't get cut off */
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
};

export const noSelect = {
	userSelect: 'none',
	WebkitUserSelect: 'none',
	MozUserSelect: 'none',
	msUserSelect: 'none',
	/* weird ones... */
	WebkitTouchCallout: 'none', /* iOS Safari */
	KhtmlUserSelect: 'none', /* Konqueror HTML */
};
