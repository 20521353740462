/**
 * @param hex {string} - hex value to change luminosity of e.g. #fa2a02
 * @param lum {number} - value -1 thru 1 to darken or lighten by. Positive numbers lighten, negative darkers
 * @returns {string}
 */
export const applyColorLuminance = function applyColorLuminance(hex, lum) {

	// validate hex string
	hex = String(hex).replace(/[^0-9a-f]/gi, '');
	if (hex.length < 6) {
		hex = hex[0]+hex[0]+hex[1]+hex[1]+hex[2]+hex[2];
	}
	lum = lum || 0;

	// convert to decimal and change luminosity
	var rgb = "#", c, i;
	for (i = 0; i < 3; i++) {
		c = parseInt(hex.substr(i*2,2), 16);
		c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
		rgb += ("00"+c).substr(c.length);
	}

	return rgb;
};

export const hextToRgbA = function hexToRgbA(hex, alpha){
	var c;
	if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
		c= hex.substring(1).split('');
		if(c.length== 3){
			c= [c[0], c[0], c[1], c[1], c[2], c[2]];
		}
		c= '0x'+c.join('');

		return `rgba(${[(c>>16)&255, (c>>8)&255, c&255].join(',')},${alpha || 1})`;
	}

	throw new Error('Bad Hex');
};

function componentToHex (c) {
	const hex = c.toString(16);
	return hex.length === 1 ? "0" + hex : hex;
}

export function rgbToHex ({r, g, b}) {
	return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}