"use strict";
import _ from 'lodash';
import { connect } from 'react-redux';
import { showModal, MODAL_TYPES } from '../../common/redux/modal';
import { setMenuIsVisible } from "../redux/modules/app";

// should everything just assume plurality or should we switch and ensure its one or the other and then behave in one way or the other.
function mapStateToProps(state) {
	return {
		authUser : _.get(state, 'authUser.user'),
		menuIsVisible: _.get(state, 'app.menuIsVisible'),
	};
}

function mapDispatchToProps (dispatch) {
	return {
		showLogin: () => dispatch(showModal({ modalType: MODAL_TYPES.AUTH_MODAL })),
		showMenu: () => dispatch(setMenuIsVisible(true)),
		hideMenu: () => dispatch(setMenuIsVisible(false)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps);
