"use strict";
import { createAction, handleActions } from 'redux-actions';
import _ from 'lodash';
import update from 'immutability-helper';
import waterfall from 'async/waterfall';
import mapSeries from 'async/mapSeries';

import { handleError, handleApiError } from "../../../libs/errorHandler";
import { getApi } from '../../../libs/api';
const api = getApi({name: 'scoreStream'});
import { get as _getTeamSubscriptions } from '../collections/teamSubscription';
import { receive as receiveCollections } from '../../actions/collections';

const ADD = 'realtime/teamSubscriptions/ADD';
const REMOVE = 'realtime/teamSubscriptions/REMOVE';
const RECEIVE = 'realtime/teamSubscriptions/RECEIVE';
const FETCHING = 'realtime/teamSubscriptions/FETCHING';
const UPDATE = 'realtime/teamSubscriptions/UPDATE';

function getInitialState() {
	const items = _.get(window, ['gData', 'authUserTeamSubscriptions']);

	if (items) {
		return {
			fetching: false,
			hasReceived: true,
			map : _.keyBy(items, 'teamId')
		};
	} else {
		return {
			fetching: false,
			hasReceived: false,
			map : {}
		};
	}
}

export default handleActions({
	[FETCHING] : function (state) {
		return _.assignIn({}, state, {
			fetching : true
		});
	},
	[RECEIVE]: {
		next: (state, action) => {
			const userTeamSubscriptions = action.payload;
			return {
				fetching : false,
				hasReceived : true,
				map : _.keyBy(userTeamSubscriptions, 'teamId')
			};
		},
		throw: (state, action) => {
			handleError('Problem getting teamSubscriptions list', action.payload);
			return state;
		}
	},
	[UPDATE]: (state, action) => {
		const userTeamSubscriptions = action.payload;

		const updates = userTeamSubscriptions.reduce((acc, sub) => {
			acc[sub.teamId] = {$set: sub};
			return acc;
		}, {});

		return update(state, {
			map : updates
		});
	},
	[ADD]: {
		next: (state, action) => {
			const userTeamSubscriptions = action.payload;
			const addMap = _.keyBy(userTeamSubscriptions, 'teamId');

			return update(state, {
				map : {$merge : addMap}
			});
		},
		throw: (state, action) => {
			handleError('Error teamSubscriptions user', action.payload);
			return state;
		}
	},
	[REMOVE]: {
		next: (state, action) => {
			const teamIds = action.payload;

			const removeMap = teamIds.reduce(function (hash, teamId) {
				hash[teamId] = null;
				return hash;
			}, {});

			return update(state, {
				map : {$merge : removeMap}
			});
		},
		throw: (state, action) => {
			handleError('Error remove team Subscriptions for user', action.payload);
			return state;
		}
	},
}, getInitialState());


const addTeamSubscriptionsAction = createAction(ADD);
const removeTeamSubscriptionsAction = createAction(REMOVE);
const receiveTeamSubscriptions = createAction(RECEIVE);
const fetchingTeamSubscriptions = createAction(FETCHING);
const updateTeamSubscriptions = createAction(UPDATE);

export const getTeamSubscriptions = (p = {}) => (dispatch, getState) => {
	const callback = p.callback;
	const state = getState();

	if (state.authUser.teamSubscriptions.fetching) { return console.log('already fetching team subscriptions'); }
	if (state.authUser.teamSubscriptions.hasReceived) { return console.info('already fetched team subs once'); }

	if (!_.get(state.authUser.user, 'userId')) {
		return dispatch(receiveTeamSubscriptions(Error('Need to be logged in to get teamSubscriptions')));
	}

	dispatch(fetchingTeamSubscriptions({}));

	waterfall([
		callback => api.post({
			method: 'users.teams.subscriptions.search',
			params: {
				teamIds: [state.authUser.user.teamId],
				sortBy : 'interest'
			},
			callback
		})
	], (err, result) => {
		if (err) {
			if (callback) {
				callback(err);
			} else {
				handleError(err);
			}

			return dispatch(receiveTeamSubscriptions(Error('Unable to get teamSubscriptions')));
		}

		const list = result.collections.userTeamSubscriptionCollection.list;
		dispatch(receiveCollections(result.collections));
		dispatch(receiveTeamSubscriptions(list));

		if (callback) {
			return callback(null, list);
		}
	});
};

export const addTeamSubscriptions = ({
	teamIds,
	callback = _.noop,
	showError = true
}) => {
	return function (dispatch) {
		mapSeries(teamIds, function (teamId, callback) {
			api.post({
				method: 'users.teams.subscriptions.add',
				params: {
					teamId
				},
				callback: callback
			});
		}, function (err, results) {
			if (err) {
				if (showError) {
					handleApiError(err);
				}
				return callback(err);
			}

			// TODO merge collections or something???
			results.forEach(function (result) {
				dispatch(receiveCollections(result.collections));
				dispatch(addTeamSubscriptionsAction(result.collections.userTeamSubscriptionCollection.list));

			});
			dispatch(_getTeamSubscriptions({teamIds}));
			callback(null, results);
		});
	};
};
export const updateTeamSubscription = ({
	userTeamSubscriptionId,
	sportNotificationBehavior,
	sportNotificationSettings,
	callback,
}) => {
	return function (dispatch) {
		api.post({
			method: 'users.teams.subscriptions.update',
			params: {
				userTeamSubscriptionId,
				sportNotificationBehavior,
				sportNotificationSettings,
			},
			callback: (err, r) => {
				if (err) {
					if (callback) {
						return callback(err);
					}
					return handleApiError(err);
				}

				dispatch(receiveCollections(r.collections));
				dispatch(updateTeamSubscriptions(_.get(r, 'collections.userTeamSubscriptionCollection.list')));

				if (callback) {
					return callback(null, r);
				}
			}
		});
	};
};

export const removeTeamSubscriptions = ({
	teamIds,
	callback = _.noop,
	showError = true
}) => {
	return function (dispatch) {
		mapSeries(teamIds, function (teamId, callback) {
			api.post({
				method: 'users.teams.unsubscribe',
				params: {teamId},
				callback: callback
			});
		}, function (err, result) {
			if (err) {
				if (showError) {
					handleApiError(err);
				}
				return callback(err);
			}

			dispatch(removeTeamSubscriptionsAction(teamIds));
			dispatch(_getTeamSubscriptions({teamIds}));
			callback(null, result);
		});
	};
};
