'use strict';
import PropTypes from 'prop-types';
import React from 'react';
import Clearfix from 'react-bootstrap/lib/Clearfix';
import FlatButton from '../buttons/flat.jsx';

const styles = {
	container : {
		height : '90%',
	},
	closeButton : {
		float : 'right',
	},
};

class ModalContent extends React.PureComponent {
	render () {
		const { children, hideClose, onClose, title, } = this.props;
		return (
			<div style={styles.container}>
				<div style={styles.titleContainer}>
					{!hideClose && (
						<FlatButton
							style={styles.closeButton}
							faIcon="times"
							text="Close"
							onClick={onClose}
						/>
					)}
					{title && <div style={styles.title}>{title}</div>}
					<Clearfix />
				</div>
				{children}
			</div>
		);
	}
}
ModalContent.propTypes = {
	children: PropTypes.node,
	hideClose: PropTypes.bool,
	onClose: PropTypes.func,
	title: PropTypes.string,
};

export default ModalContent;
