"use strict";
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import ProfileImageCircle from './profileImageCircle.jsx';
import UserRankForBadge from './userRankForBadge.jsx';

class ProfileImageBadge extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			style: _.assignIn({
				height: props.size,
				width: props.size,
				position: 'relative',
				overflow: 'visible'
			}, props.style),
		};
	}

	componentWillReceiveProps (nextProps) {
		if (nextProps.style !== this.props.style || nextProps.size !== this.props.size) {
			this.setState({
				style: _.assignIn({
					height: nextProps.size,
					width: nextProps.size,
					position: 'relative',
					overflow: 'visible'
				}, nextProps.style),
			});
		}
	}

	render () {
		return (
			<div style={this.state.style}>
				<ProfileImageCircle size={this.props.size} user={this.props.user} />
				<UserRankForBadge size={this.props.size} userRank={this.props.userRank} isSuspended={!!this.props.user.suspended} />
			</div>
		);
	}
}
ProfileImageBadge.propTypes = {
	user: PropTypes.object.isRequired,
	userRank: PropTypes.object,
	size: PropTypes.number.isRequired,
	style: PropTypes.object
};

export default ProfileImageBadge;
