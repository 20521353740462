'use strict';
import _ from 'lodash';
import update from 'immutability-helper';
import { handleActions } from 'redux-actions';

import { handleError } from "../../../libs/errorHandler";
import { RECEIVE, INVALIDATE } from "../../actions/collections";

import alternateTeamName from './alternateTeamName';
import color from './color';
import composite from './composite';
import * as compositeSupplement from './compositeSupplement';
import * as game from './game';
import * as gameFan from './gameFan';
import * as gameMetric from './gameMetric';
import * as gamePost from './gamePost';
import * as gameScore from './gameScore';
import * as gameScoreAssessment from './gameScoreAssessment';
import gameSegment from './gameSegment';
import gameSegmentType from './gameSegmentType';
import * as gameVenueAssessment from './gameVenueAssessment';
import * as gameVenueStatus from './gameVenueStatus';
import levelOfPlay from './levelOfPlay';
import * as location from './location';
import * as locationSupplement from './locationSupplement';
import organization from './organization';
import * as playlist from './playlist';
import scheduleCategory from './scheduleCategory';
import * as scheduleCategoryToLevelOfPlayMapping from './scheduleCategoryToLevelOfPlayMapping';
import sport from './sport';
import * as squad from './squad';
import stoppageStatus from './stoppageStatus';
import * as team from './team';
import * as teamSupplement from './teamSupplement';
import teamPicture from './teamPicture';
import * as teamSubscription from './teamSubscription';
import teamTier from './teamTier';
import * as teamOverride from './teamOverride';
import trustLevel from './trustLevel';
import * as user from './user';
import * as userNotification from './userNotification';
import userRank from './userRank';
import * as userSupplement from './userSupplement';
import * as userTeamRelationship from './userTeamRelationship';
import userWidget from './userWidget';
import * as venue from './venue';
import * as venueToTeamMapping from './venueToTeamMapping';
import * as resource from './resource';
import userWidgetPlan from './userWidgetPlan';

//collection handlers should export:
// [array] propsToDiff - if this is undef, incoming collection items WILL NOT be merged
// [function] formatter - Modify the entities before they are stored in state
// {function} getInitialState
const collectionHandlers = {
	alternateTeamName,
	color,
	composite,
	compositeSupplement,
	game,
	gameFan,
	gameMetric,
	gamePost,
	gameScore,
	gameScoreAssessment,
	gameSegment,
	gameSegmentType,
	gameVenueAssessment,
	gameVenueStatus,
	levelOfPlay,
	location,
	locationSupplement,
	organization,
	playlist,
	resource,
	scheduleCategory,
	scheduleCategoryToLevelOfPlayMapping,
	sport,
	squad,
	stoppageStatus,
	team,
	teamSupplement,
	teamPicture,
	teamSubscription,
	teamTier,
	teamOverride,
	trustLevel,
	user,
	userNotification,
	userRank,
	userSupplement,
	userTeamRelationship,
	userWidget,
	userWidgetPlan,
	venue,
	venueToTeamMapping
};

/**
 * Returns the update 'query' for the given 'state.collections.*' entry
 * @param {object} state - existing state.collections.* entry for the key
 * @param {string} key - prop name from state.collections (e.g. user, game, etc)
 * @param {object} collection - Content from api 'result.collections[key]'
 * @param {bool} meta.forceMerge - If true, collections will be merged regardless of handler's 'propsToDiff'
 * @returns {*}
 */
function getUpdates (state, key, collection, meta = {}) {
	const forceMerge = meta.forceMerge;
	const forceSet = meta.forceSet;

	const handler = collectionHandlers[key];
	const { list } = collection;
	if (!list.length) { return null; }
	return list.reduce((result, entity) => {
		const entityId = entity[`${key}Id`]; //e.g. user[userId]
		const existingEntity = state[key][entityId];

		if (!forceMerge && !forceSet) {
			if (existingEntity && !forceMerge && !forceSet && !shouldUpdate(existingEntity, entity, handler.propsToDiff)) {
				return result;
			}
		}

		const $verb = (!existingEntity || forceSet) ? '$set' : '$merge';
		const formatter = handler.formatter || _.identity;

		return _.assign(result, {
			[entityId]: {[$verb]: formatter(entity)}
		});
	}, {});
}

const collectionReducer = handleActions({
	[RECEIVE]: {
		next: (state, action) => {
			if (!_.isObject(action.payload)) {
				console.error("did not pass collections object result into receiveCollections", action);
				handleError("did not pass collections object result into receiveCollections", action);
			}

			const updates = _.reduce(action.payload, (result, collection, collectionName) => {
				const key = collectionName.replace(/Collection$/, '');
				if (!collectionHandlers[key]) {
					return result;
				}

				const updates = getUpdates(state, key, action.payload[collectionName], action.meta);
				if (!updates) {
					return result;
				}
				return _.assign(result, {[key]: updates});
			}, {});
			return update(state, updates);
		},
		throw: (state, action) => {
			handleError("Problem receiving collections", action.payload);
			return state;
		}
	},
	[INVALIDATE]: {
		next: (state, action) => {
			const {collection, ids} = action.payload;

			const updates = ids.reduce(function (obj, id) {
				obj[id] = {$set : undefined};
				return obj;
			}, {});

			return update(state, {
				[collection] : updates
			});
		},
		throw: (state, action) => {
			handleError("Problem invalidating collections", action.payload);
			return state;
		}
	}
}, _.mapValues(collectionHandlers, lib => lib.getInitialState()));
export default collectionReducer;
/**
 * Check whether a specific entity should be updated
 * @param existingEntity - Existing state entry at the corresponding 'collections.[entityType].[entityId]' address
 * @param newEntity - value from the collection being processed
 * @param propsToDiff - List of props to check before updating, if values are the same for all keys (or there are no keys) then the update will continue. If this is falsy the update will stop
 * @returns {boolean}
 */
function shouldUpdate (existingEntity, newEntity, propsToDiff) {
	if (!propsToDiff) { return false; }
	return !_.every(propsToDiff, propName => existingEntity[propName] === newEntity[propName]);
}
