"use strict";
import _ from 'lodash';
import { handleApiError } from "../../../libs/errorHandler";
import { getApi } from '../../../libs/api';
const api = getApi({name: 'scoreStream'});
import delayedGet from '../../helpers/delayedGet';
import genericActionCallback from '../../helpers/genericActionCallback';
import cleanAddParams from '../../helpers/cleanAddParams';
import { actions as collectionActions } from '../../actions/collections';


export const propsToDiff = {};

export const getInitialState = function getInitialState() {
	return _.keyBy(_.get(window, ['gData', 'redux', 'collections', 'locationCollection', 'list'], []), 'locationId');
};

export const get = function getLocations (params) {
	return delayedGet({
		key: 'location',
		method: 'locations.get',
		idsKey: 'locationIds',
		ids: params.locationIds
	});
};

/**
 * Take this white list of API params and only use key/value pairs that match
 * @type {string[]}
 */
const addUpdateParams = [
	'locationId', //update only
	'longitude',
	'latitude',
	'address',
	'city',
	'alternateCity',
	'country',
	'state',
	'postalCode',
];

export const add = function addLocation (params, callback) {
	return dispatch => {
		api.post({
			method : 'locations.add',
			params : _.pick(cleanAddParams(params), addUpdateParams),
			callback : genericActionCallback(dispatch, callback)
		});
	};
};

export const update = function updateLocation (params, callback) {

	return dispatch => {
		api.post({
			method : 'locations.update',
			params : _.pick(params, addUpdateParams),
			callback : function (err, result) {
				if (err && callback) { return callback(err); }
				if (err && !callback) { return handleApiError(err); }

				dispatch(collectionActions.receiveCollections(result.collections, {forceSet : true}));

				// XXX location supplement isn't good anymore, need to clear it out and it is not returned as a result of an update
				// maybe updates should return supplements because those are the cases where we care about things like that
				dispatch(collectionActions.invalidate({
					collection : 'locationSupplement',
					ids : [params.locationId]
				}));

				if (callback) { return callback(null, result); }
			}
		});
	};
};
