/*global window:false*/
import { createAction, handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import update from 'immutability-helper';
import waterfall from 'async/waterfall';
import series from 'async/series';
import get from 'lodash/get';
import pick from 'lodash/pick';
import partialRight from 'lodash/partialRight';
import assignIn from 'lodash/assignIn';

import { confirm } from '../../../common/libs/alerts';
import { config } from '../../../common/libs/config';
import { handleError } from "../../libs/errorHandler";
import following from './authUser/following';
import teamSubscriptions from './authUser/teamSubscriptions';
import generalManagers from './authUser/generalManagers';
import shareSettingsForGames from './authUser/shareSettingsForGames';
import notificationInfo from './authUser/notificationInfo';
import location from './authUser/location';


import { getApi } from '../../libs/api';
const api = getApi({name: 'scoreStream', protocol: 'https'});
import * as accessToken from '../../libs/accessToken';
import { actions as collectionActions } from '../actions/collections';

const LOAD = 'realtime/authUser/LOAD';
const LOGIN = 'realtime/authUser/LOGIN';
export const actions = {
	load: createAction(LOAD),
	login: createAction(LOGIN),
};

function getInitialState () {
	if (get(window, 'gData.authUser')) {
		return assignIn({}, window.gData.authUser);
	} else {
		return null;
	}
}

const authUserReducer = handleActions({
	[LOAD]: {
		next: (state, action) => {
			return update(state, {$set: action.payload});
		},
		throw: (state, action) => {
			handleError('Error with user login load', action.payload);
			return state;
		}
	}
}, getInitialState());

function loginCallback (dispatch, p, callback) {
	const token = p.accessToken;
	//- not sure this has everything that our regular authUser object has, shouldn't it have the supplement merged in or something?
	const authUser = p.collections.userCollection.list[0];
	accessToken.setAccessToken(token);
	accessToken.removeLimitedAccessTokenCookie();

	//- redirect login if they were just on the home page
	if (window.location.pathname === '/' || window.location.pathname === 'logout') {
		window.location = authUser.url;
		return;
	}

	dispatch(actions.load(authUser));
	dispatch(collectionActions.receiveCollections(p.collections));
	callback();
}

function fbStatusChangeCallback (response, dispatch, callback) {
	if (response.status === 'connected') {
		api.post({
			method: 'users.login',
			params: {
				facebookAuthData: response.authResponse.accessToken,
				socialMakeUserIfNotOne: true
			},
			noModal: true,
			callback: (err, res) => {
				if (err || res.failure) {
					dispatch(actions.load(Error('Error logging in with facebook')));
					return callback(err);
				}
				loginCallback(dispatch, pick(res, ['accessToken', 'collections']), callback);
			}
		});
	} else if (response.status === 'not_authorized') {
		console.log("User is logged in but not authorized Facebook for this app");
	} else {
		console.log("User is not logged into to Facebook");
	}
}

/*
 done w/ fb nonsense
 */
function setupFacebook(callback) {
	const d = document;
	const s = 'script';
	const id = 'facebook-jssdk';
	var js, fjs = d.getElementsByTagName(s)[0];
	if (d.getElementById(id)) {
		return callback();
	}
	js = d.createElement(s); js.id = id;
	js.src = "//connect.facebook.net/en_US/sdk.js";
	fjs.parentNode.insertBefore(js, fjs);

	/*
 		XXX - Don't get the facebook stuff unless a facebook login is happening
 		Inject facebook stuff
 	*/
	window.fbAsyncInit = function() {
		window.FB.init({
			appId: (get(window, ['gData', 'env']) === 'local') ? '583268281779577' : '275358649201039',
			cookie: true,
			xfbml: true,
			version: 'v2.6'
		});

		callback();
	};
}

export function facebookLogin (callback) {
	return function(dispatch) {
		setupFacebook(() => {
			window.FB.login(partialRight(fbStatusChangeCallback, dispatch, callback), {
				scope: 'email',
				return_scopes: true
			});
		});
	};
}

export function login (username, password, callback) {
	return dispatch => {
		waterfall([
			callback => api.post({
				method: 'users.login',
				params: {
					emailOrUsername: username,
					password,
				},
				callback,
			}),
		], (err, res) => {
			console.log('users.login callback', err, res);
			if (err) {
				if (callback) {
					return callback(err);
				} else {
					return handleError(err, {title: 'Error Logging In'});
				}
			} else if (res.failure) {
				const msg = `Incorrect combination of username/email & password. Please try again`;
				if (callback) {
					return callback(msg);
				} else {
					return handleError(msg);
				}
			}

			loginCallback(dispatch, pick(res, ['accessToken', 'collections']), callback);
		});
	};
}

export function deleteMyAccount () {
	return (dispatch, getState) => {
		const state = getState();
		if (!state.authUser.user) { return console.error('You are not logged in'); }
		series([
			callback => confirm({
				callback,
				message: 'Are you sure you want to permanently remove your account info?',
				title: "We hate to see you leave",
				cancelButtonText: 'No',
				confirmButtonText: 'Yes',
			}),
			callback => api.post({
				method: 'users.delete',
				params: {userId: state.authUser.user.userId},
				callback,
			}),
			() => window.location = `${config.stream.httpsHost}/logout`,
		]);
	};
}

export default combineReducers({
	user: authUserReducer,
	following,
	teamSubscriptions,
	generalManagers,
	shareSettingsForGames,
	notificationInfo,
	location,
});
