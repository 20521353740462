"use strict";
import _ from 'lodash';
import { handleApiError } from "../../../libs/errorHandler";
import { getApi } from '../../../libs/api';
const api = getApi({name: 'scoreStream'});
import * as listsRedux from '../lists';
import { actions as collectionActions } from '../../actions/collections';

export const propsToDiff = {};

export const getInitialState = function getInitialState() {
	return _.keyBy(
		_.get(window, ['gData', 'redux', 'collections', 'teamOverrideCollection', 'list'], []),
		'teamOverrideId'
	);
};

const addUpdateParams = [
	'teamOverrideId',
	'teamId',
	'sportIds',
	'squadIds',
	'overrideData',
	'linkToTeamId',
];

export const add = function addTeamOverride (params, uploads, callback) {
	const formData = new FormData();
	if (uploads && _.keys(uploads).length) {
		_.keys(uploads).forEach(key => {
			formData.append(key, uploads[key]);
		});
	}

	return dispatch => {
		api.post({
			method : 'teams.overrides.add',
			params : _.pick(params, addUpdateParams),
			formData : formData,
			callback : (err, result) => {
				if (err && callback) { return callback(err); }
				if (err && !callback) { return handleApiError(err); }

				const teamOverrideId = _.get(result, ['teamOverrideIds', '0']);

				dispatch(collectionActions.receiveCollections(result.collections));

				dispatch(listsRedux.actions.addToLists({
					list : 'teamOverrideIdsForTeam',
					listId : params.teamId,
					itemId : teamOverrideId,
				}));

				if (callback) { return callback (null, result); }
			}
		});
	};
};

export const update = function updateTeamOverride (params, uploads, callback) {
	const formData = new FormData();
	if (uploads && _.keys(uploads).length) {
		_.keys(uploads).forEach(key => {
			formData.append(key, uploads[key]);
		});
	}

	return (dispatch) => {
		api.post({
			method : 'teams.overrides.update',
			params : _.pick(params, addUpdateParams),
			formData : formData,
			callback : function (err, result) {
				if (err && callback) { return callback(err); }
				if (err && !callback) { return handleApiError(err); }

				dispatch(collectionActions.receiveCollections(result.collections, {forceSet : true}));

				if (callback) { return callback (null, result); }
			}
		});
	};
};

export const remove = function removeTeamOverride (params, callback) {
	return (dispatch, getState) => {
		const state = getState();
		const mapping = _.get(state, ['collections', 'teamOverride', params.teamOverrideId]);

		// XXX - remove from collections, updated to disabled? does it matter?
		api.post({
			method : 'teams.overrides.remove',
			params : _.pick(params, ['teamOverrideId']),
			callback : function (err, results) {
				if (err && callback) { return callback(err); }
				if (err && !callback) { return handleApiError(err); }

				// then the lists
				dispatch(listsRedux.actions.removeFromLists({
					list: 'teamOverrideIdsForTeam',
					listId: mapping.teamId,
					itemId: params.teamOverrideId
				}));

				if (callback) { return callback(null, results); }
			}
		});
	};
};

export const search = function searchTeamOverrides (teamId) {
	return function dispatchSearchTeamOverrides (dispatch) {
		api.post({
			method: 'teams.overrides.search',
			params: {teamId},
			callback: (err, result) => {
				if (err) { return handleApiError(err); }
				dispatch(collectionActions.receiveCollections(result.collections));
			},
		});
	};
};