"use strict";
import _ from 'lodash';
export const propsToDiff = {};

export const getInitialState = function getInitialState() {
	return _.keyBy(
		_.get(
			window,
			['gData', 'redux', 'collections', 'scheduleCategoryToLevelOfPlayMappingCollection', 'list'], []
		),
		'scheduleCategoryToLevelOfPlayMappingId'
	);
};
