"use strict";
import { createAction, handleActions } from 'redux-actions';
import _ from 'lodash';
import waterfall from 'async/waterfall';
import { handleError } from "../../../libs/errorHandler";
import { getApi } from '../../../libs/api';
const api = getApi({name: 'scoreStream'});

const RECEIVE = 'realtime/generalManagers/RECEIVE';
const FETCHING = 'realtime/generalManagers/FETCHING';

function getInitialState() {
	const generalManagers = _.get(window, ['gData', 'authUserGeneralManagers']);

	if (generalManagers) {
		return {
			fetching: false,
			hasReceived: true,
			map : _.keyBy(generalManagers, 'teamId')
		};
	} else {
		return {
			fetching: false,
			hasReceived: false,
			map : {}
		};
	}
}

export default handleActions({
	[FETCHING] : function (state) {
		return _.assignIn({}, state, {
			fetching : true
		});
	},
	[RECEIVE]: {
		next: (state, action) => {
			const generalManagers = action.payload;

			return {
				fetching : false,
				hasReceived : true,
				map : _.keyBy(generalManagers, 'teamId')
			};
		},
		throw: (state, action) => {
			handleError('Problem getting generalManagers list', action.payload);
			return state;
		}
	},
}, getInitialState());


const receiveGeneralManagers = createAction(RECEIVE);
const fetchingGeneralManagers = createAction(FETCHING);

export const getGeneralManagers = () => (dispatch, getState) => {
	const state = getState();

	if (state.authUser.generalManagers.fetching) { return console.log('already fetching user general manager mappings'); }
	if (state.authUser.generalManagers.hasReceived) { return console.info('already fetched gms once'); }

	if (!_.get(state.authUser.user, 'userId')) {
		return dispatch(receiveGeneralManagers(Error('Need to be logged in to get generalManagers')));
	}

	dispatch(fetchingGeneralManagers({}));

	waterfall([
		callback => api.post({
			method: 'teams.generalManagers.search',
			params: {
				userIds: [state.authUser.user.userId],
				count : 9999
			},
			callback
		})
	], (err, result) => {
		if (err) {
			handleError(err);
			return dispatch(receiveGeneralManagers(Error('Unable to get generalManagers')));
		}

		dispatch(receiveGeneralManagers(result.collections.generalManagerCollection.list));
	});
};
