function supportsIntersectionObserver() {
	return (
		'IntersectionObserver' in global &&
		'IntersectionObserverEntry' in global &&
		'intersectionRatio' in IntersectionObserverEntry.prototype
	)
}

function supportsArrayIncludes () {
	return 'includes' in Array.prototype;
}

function supportsObjectAssign () {
	return 'assign' in Object.prototype;
}

function supportsPromiseFinally () {
	return 'finally' in Promise.prototype;
}

export default function loadPolyfills () {
	const polyfills = [];

	//allows deferring image loading until an el is in the viewport
	if (!supportsIntersectionObserver()) {
		polyfills.push(import('intersection-observer'))
	}

	if (!supportsArrayIncludes()) {
		polyfills.push(import('core-js/modules/es7.array.includes'));
	}
	if (!supportsObjectAssign()) {
		polyfills.push(import('core-js/modules/es6.object.assign'));
	}
	if (!supportsPromiseFinally()) {
		polyfills.push(import('core-js/modules/es7.promise.finally'));
	}

	return Promise.all(polyfills);
}