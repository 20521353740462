import _ from 'lodash';
import parallel from 'async/parallel';
import waterfall from 'async/waterfall';

import { getApi } from '../../../libs/api';
const api = getApi({name: 'scoreStream'});
import sweetAlert from '../../../libs/sweetAlert';
import { actions as collectionActions } from '../../actions/collections';
import genericActionCallback from '../../helpers/genericActionCallback';

export function getInitialState() {
	const users = _.get(window, ['gData', 'redux', 'collections', 'userCollection', 'list'], null);
	if (users) {
		return _.keyBy(users, 'userId');
	} else {
		return {};
	}
}

let fetchingHash = {
	regular: {},
	withSupplement: {}
};
let queueTimer = null;
let queue = {
	regular: [],
	withSupplement: []
};

export const get = (p) => {
	let { userIds } = p;
	const {
		includeUserSupplements = false,
		meta = null,
	} = p;

	return dispatch => {
		// filter out ones we are already fetching
		userIds = userIds.filter(userId => {
			if (!includeUserSupplements) { return (!fetchingHash.regular[userId] && !fetchingHash.withSupplement[userId]); }
			return !fetchingHash.withSupplement[userId];
		});
		if (!userIds.length) {
			return console.log("Currently fetching all userIds passed in");
		}
		
		const fetchingKey = (includeUserSupplements) ?
			'withSupplement' :
			'regular';
		fetchingHash[fetchingKey] = userIds.reduce((result, userId) => {
			return _.assign(result, {[userId]: true});
		}, fetchingHash[fetchingKey]);
		
		queue[fetchingKey] = queue[fetchingKey]
			.concat(userIds);
		if (meta) {
			queue[fetchingKey].meta = {
				...(queue[fetchingKey].meta || {}),
				...meta,
			};
		}
		if (queueTimer) { return; }
		queueTimer = window.setTimeout(() => {
			queueTimer = null;

			let regularMeta = queue.regular.meta;
			let withSupMeta = queue.withSupplement.meta;
			parallel([
				callback => {
					const userIds = _.uniq(_.difference(queue.regular, queue.withSupplement));
					if (!userIds.length) { return callback(null, null); }
					queue.regular = [];
					api.post({
						method: 'users.get',
						params: {userIds},
						callback
					});
				},
				callback => {
					const userIds = _.uniq(queue.withSupplement);
					if (!userIds.length) { return callback(null, null); }
					queue.withSupplement = [];
					api.post({
						method: 'users.get',
						params: {userIds, includeUserSupplements: true},
						callback
					});
				}
			], (err, results) => {
				if (err) {
					dispatch({
						type: 'collections/RECEIVE',
						error: true,
						payload: err
					});
				} else {
					results.forEach((result, index) => {
						if (!result) { return; }
						const meta = (index === 0) ?
							regularMeta :
							withSupMeta;
						dispatch(collectionActions.receiveCollections(result.collections, meta));
					});
				}
				results.forEach(result => {
					if (!result) { return; }
					const fetchingKey = (result.collections.userSupplementCollection) ?
						'withSupplement' :
						'regular';
					result.userIds
						.forEach(userId => {
							delete fetchingHash[fetchingKey][userId];
						});
				});
			});
		}, 20);
	};
};

export function unsuspendUser ({userId}) {
	return dispatch => {
		confirm({
			message: 'Lift this user\'s suspension?',
			cancelButtonText: 'No',
			confirmButtonText: 'Yes',
			callback: () => waterfall([
				callback => api.post({
					callback: genericActionCallback(dispatch, callback),
					method: 'users.unsuspend',
					params: {userId},
				}),
			], err => {
				if (err) { return console.error(err); }
				// TODO - remove this from redux...
				sweetAlert({
					title: 'Success',
					type: 'success',
				});
			})
		});
	};
}

export const propsToDiff = ['userRankId', 'suspended'];
