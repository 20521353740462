"use strict";
import React from 'react';
import _ from 'lodash';
import BaseButton from './baseButton.jsx';
import * as sharedStyles from '../../libs/sharedStyles';

const styles = {
	button : {
		padding : "4px 8px",
		minWidth : 70,
		borderColor : 'transparent',
		color : sharedStyles.vars.colors.ssDarkGray,
		backgroundColor : '#FFF',
		fontWeight : 100,
		fontSize : 14,
		':hover' : {
			backgroundColor : sharedStyles.vars.colors.ssBlue,
			borderColor : 'transparent',
			color : '#FFF'
		}
	}
};

function FlatButton (props) {
	const buttonStyle = _.assignIn({}, styles.button, props.style);

	return (
		<BaseButton
			{...props}
			style={buttonStyle}
		/>
	);
}

export default FlatButton;
