import React, { Suspense } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import muiTheme from '../../../common/mui/muiTheme';
import Header from './header.jsx';
import ModalRoot from '../../components/modalRoot.jsx';
const GDPRPrompt = React.lazy(() => import('../../../common/components/gdprPrompt.jsx'));
const Messages = React.lazy(() => import('./messages.jsx'));

export default function MainLegacyPages(props) {
	return (
		<MuiThemeProvider theme={muiTheme}>
			<Header {...props} />
			<ModalRoot />
			<Suspense fallback={<div/>}>
				<Messages />
				<GDPRPrompt/>
			</Suspense>
		</MuiThemeProvider>
	);
}
