import React from 'react';
import PropTypes from 'prop-types';
import InputBase from '@material-ui/core/InputBase';
import withStyles from "@material-ui/core/styles/withStyles";
import SearchIcon from '@material-ui/icons/Search';
import {vars} from "../../../libs/sharedStyles";

function getStyles() {
	return {
		container: {
			width: '100%',
			position: 'relative',
		},
		inputRoot: {
			width: '100%',
		},
		inputInput: {
			padding: `5px 5px 5px 36px`,
			margin: `0 0 0 0`,
			width: '100%',
			backgroundColor: `white`,
			boxShadow: `inset 0 1px 1px rgba(0,0,0,.075)`,
			border: `1px solid ${vars.colors.ssLightGray}`,
			borderRadius: 7,
			transition: `.2s`,
			'&:hover': {
				borderColor: vars.colors.ssGray,
			},
			'&:focus': {
				borderColor: vars.colors.ssBlue,
			},
			'&::placeholder' : {
				fontSize: 14,
				fontWeight: 100,
				color: `rgba(85, 85, 85, 0.8)`
			}
		},
		icon: {
			color: vars.colors.ssGray,
			position: 'absolute',
			left: 3,
			top: 3,
		}
	};
}

function DefaultSearchInput(props) {
	// Demonstrates pulling out props that shouldn't apply to the actual input component, but are accessible by implementations
	const {
		// special props that you need to pull out and not send down to the input
		omniSearchProps,
		classes,
		ref,
		...rest
	} = props;

	const { style } = omniSearchProps;

	return (
		<div className={classes.container} style={style}>
			<SearchIcon
				classes={{
					root: classes.icon
				}}
			/>
			<InputBase
				{...rest}
				inputRef={ref}
				classes={{
					root: classes.inputRoot,
					input: classes.inputInput,
				}}
			/>
		</div>
	);
}
DefaultSearchInput.propTypes = {
	omniSearchProps: PropTypes.object,
	ref: PropTypes.func,
	classes: PropTypes.object,
};
const Input = withStyles(getStyles)(DefaultSearchInput);

export default Input;

export function renderInputComponent(props) {
	return (<Input {...props} />);
}
