'use strict';
import _ from 'lodash';

function getInitialState () {
	const composites = _.get(window, ['gData', 'redux', 'collections', 'compositeCollection']);
	if (composites) {
		return _.keyBy(composites.list, 'compositeId');
	} else {
		return {};
	}
}
const propsToDiff = {};
export default {getInitialState, propsToDiff};
