/* global window: false */
import React from 'react';
import PropTypes from 'prop-types';
import { HEADER_HEIGHT } from "../header.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import CheckIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { vars } from "../../../../common/libs/sharedStyles";
import { Link } from "../../../../common/libs/routing";


// Only bug people every X (2.5) days
const DISMISS_TIME_SECS = 60 * 60 * 24 * 2.2;
const LOCAL_STORAGE_KEY = 'ss-header-profileCompleteCheck-unix';

//TODO  this

function getStyles() {
	return {
		container : {
			zIndex: 101,
			position: 'fixed',
			display: 'flex',
			alignItems: 'center',
			top: HEADER_HEIGHT,
			left: 0,
			width: '100%',
			padding: `5px 10px`,
			background: vars.colors.ssYellow
		},
		link: {
			flex: 1,
			color: vars.colors.ssDarkGray,
			marginLeft: 10,
			marginRight: 20,
			fontSize: 18,
			'&:hover': {
				color: vars.colors.ssDarkerBlue
			}
		},
		iconRoot: {
			color: vars.colors.ssDarkGray,
		}
	};
}

function getNowUnix() {
	return Math.round(new Date().getTime() / 1000);
}

class ProfileCompleteCheck extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			show: false,
			message: '',
		};

		this.dismiss = this.dismiss.bind(this);
	}

	componentWillMount() {
		this.computeTimeout = setTimeout(this.compute.bind(this), 1000);
	}

	componentWillUnmount() {
		if (this.computeTimeout) {
			clearTimeout(this.computeTimeout);
		}
	}

	dismiss () {
		this.setState({
			show: false,
		});

		if (window && window.localStorage) {
			window.localStorage.setItem(LOCAL_STORAGE_KEY, getNowUnix());
		}
	}

	isDismissed() {
		if (window && window.localStorage) {
			const lastDismissUnixString = window.localStorage.getItem(LOCAL_STORAGE_KEY);
			if (!lastDismissUnixString) {
				return false;
			}

			const lastUnix = parseInt(lastDismissUnixString, 10);

			if (lastUnix + DISMISS_TIME_SECS < getNowUnix()) {
				window.localStorage.removeItem(LOCAL_STORAGE_KEY);
				return false;
			} else {
				return true;
			}
		} else {
			// Just randomly show 1/10 if we don't support local storage
			return (Math.random() < 0.1);
		}
	}

	compute() {
		const { authUser } = this.props;

		delete this.computeTimeout;

		if (!authUser || this.isDismissed()) {
			return;
		}

		let message;
		if (!authUser.email) {
			message = `Complete your profile, add your email address`;
		}

		if (authUser.imageUrl.match(/noProfile/)) {
			message = `Complete your profile, add a profile image`;
		}

		if (message) {
			this.setState({
				show: true,
				message,
			});
		}
	}

	render() {
		const { classes, authUser } = this.props;
		const { message, show } = this.state;

		if (!show || !authUser) {
			return null;
		}

		return (
			<div className={classes.container}>
				<Link to={authUser.relativeUrl + "/edit"}>
					<CheckIcon classes={{ root: classes.iconRoot }}/>
				</Link>
				<Link to={authUser.relativeUrl + "/edit"} className={classes.link}>
					{message}
				</Link>
				<Button
					onClick={this.dismiss}
					size="small"
					variant="outlined"
				>
					Dismiss <CloseIcon />
				</Button>
			</div>
		);
	}
}

ProfileCompleteCheck.propTypes = {
	classes: PropTypes.object,
	authUser: PropTypes.object,
};

export default withStyles(getStyles)(ProfileCompleteCheck);
