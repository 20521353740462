import React from 'react';
import PropTypes from 'prop-types';
import withStyles from "@material-ui/core/styles/withStyles";

function getStyles() {
	return {
		container : {
			padding: `16px 5px`,
			textAlign: 'center',
			fontSize: 15,
			background: 'white',
			color: '#999',
		},
	};
}

function TypeMoreSuggestion({ typeMoreType, classes }) {
	return (
		<div className={classes.container}>
			<strong>Type more</strong> characters to search for {typeMoreType}
		</div>
	);
}

TypeMoreSuggestion.propTypes = {
	typeMoreType: PropTypes.string,
	classes: PropTypes.object,
};

const Styled = withStyles(getStyles)(TypeMoreSuggestion);
export default React.memo(Styled);