"use strict";
import _ from 'lodash';
import cached from '../../../../../cachedData/trustLevels';

function getInitialState () {
	return _.keyBy(cached.collections.trustLevelCollection.list, 'trustLevelId');
}

export default {getInitialState};

