'use strict';
import React from 'react';
import { noop, assignIn } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import authUserContainer from '../containers/authUser';
import { showModal, hideModal } from '../redux/modal';

const actionProps = [
	'onScroll',
	'onFocus',
	'onClick',
];

/**
 * Returns a modified version of the provided child component(s) with the event handlers listed in
 * the 'eventTypes' overridden to only fire if the user is logged in
 * If not logged in, a login prompt will be shown instead of firing the associated action
 * If the child component does not have an event listener prop for one of the event types one will be added that
 * checks login state.
 */
class AuthAction extends React.Component {
	constructor (props) {
		super(props);
		this.checkAuth = this._checkAuth.bind(this);
		this.modifyProps = this._modifyProps.bind(this);
	}

	showModal (handler, args) {
		this.props.showModal({
			modalType: 'AUTH_MODAL',
			modalProps: {
				style: {content: {
					maxWidth: 768,
				}},
			},
			contentProps: {
				onLoggedIn: () => {
					this.props.hideModal();
					handler.apply(null, args);
				}
			}
		});
	}

	_checkAuth (handler) {
		return (...args) => {
			if (this.props.authUser) {
				return handler.apply(null, args);
			}
			const [ evt ] = args;
			if (evt && evt.preventDefault) {
				evt.preventDefault();
				evt.stopPropagation();
				evt.persist();
			}
			this.showModal(handler, args);
			return false;
		};
	}

	_modifyProps (component) {
		const propOverrides = this.props.eventTypes.reduce((handlers, eventType) => assignIn(handlers, {
			[eventType]: this.checkAuth(component.props[eventType] || noop)
		}), {});
		return React.cloneElement(component, propOverrides);
	}

	render () {
		return this.modifyProps(React.Children.only(this.props.children));
	}
}
AuthAction.contextTypes = {
	hideAuthModal: PropTypes.func,
	showAuthModal: PropTypes.func,
};
AuthAction.propTypes = {
	authUser: PropTypes.object,
	eventTypes: PropTypes.arrayOf(PropTypes.oneOf(actionProps)).isRequired,
	children: PropTypes.node.isRequired,
	hideModal: PropTypes.func,
	showModal: PropTypes.func,
};
function mapDispatchToProps (dispatch) {
	return {
		showModal: p => dispatch(showModal(p)),
		hideModal: () => dispatch(hideModal({modalType: 'AUTH_MODAL'})),
	};
}
export default authUserContainer(connect(null, mapDispatchToProps)(AuthAction));