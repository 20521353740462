"use strict";
import _ from 'lodash';
import { getApi } from '../../../libs/api';
const api = getApi({name: 'scoreStream'});
import delayedGet from '../../helpers/delayedGet';
import genericActionCallback from '../../helpers/genericActionCallback';
import cleanAddParams from '../../helpers/cleanAddParams';

export const propsToDiff = {};

export const getInitialState = function getInitialState() {
	return _.keyBy(_.get(window, ['gData', 'redux', 'collections', 'venueCollection', 'list'], []), 'venueId');
};

export const get = function getVenues (params) {
	return delayedGet({
		key: 'venue',
		method: 'venues.get',
		idsKey: 'venueIds',
		ids: params.venueIds,
		extraParams: {
			includeLocations: params.includeLocations,
		},
	});
};

const addUpdateParams = [
	'venueId', //update only
	'locationId',
	'name',
	'officialName',
	'alternateNames',
];

export const add = function addVenue (params, callback) {
	// cleanAdParams stripping blank strings and then pick out the values we care about
	// officialName for instance
	return dispatch => {
		api.post({
			method : 'venues.add',
			params : _.pick(cleanAddParams(params), addUpdateParams),
			callback : genericActionCallback(dispatch, callback)
		});
	};
};

export const update = function updateVenue (params, callback) {
	return dispatch => {
		api.post({
			method : 'venues.update',
			params : _.pick(params, addUpdateParams),
			callback : genericActionCallback(dispatch, callback, {forceSet : true})
		});
	};
};
