"use strict";
import update from 'immutability-helper';
import { createAction, handleActions } from 'redux-actions';
import _ from 'lodash';
import waterfall from 'async/waterfall';

import { getApi } from '../../../../common/libs/api';
const api = getApi({name: 'scoreStream'});
import { receive as receiveCollections } from '../../../../common/redux/actions/collections';
import * as reduxInfiniteScroll from '../../../../common/redux/modules/infiniteScroll';

const RECEIVE_ACTIVITY_CARDS = 'routes/user/RECEIVE_ACTIVITY_CARDS';
const RECEIVE_STATS = 'routes/user/RECEIVE_STATS';
const RECEIVE_USER_TEAM_LIST = 'routes/user/RECEIVE_USER_TEAM_LIST';
const RECEIVE_PREVIEW_MEDIA = 'routes/user/RECEIVE_PREVIEW_MEDIA';
const RECEIVE_FOLLOWERS = 'routes/user/RECEIVE_FOLLOWERS';
const RECEIVE_FOLLOWING = 'routes/user/RECEIVE_FOLLOWING';

const SET_USER = 'routes/user/SET_USER';

const fetchingHash = {};

const base = {
	currentUserId : null,
	activityCards : {
		status: 'initial',
		cards: []
	},
	stats : {
		status: 'initial',
		data: null
	},
	userTeamList : {
		status: 'initial',
		items: null
	},
	media : {
		previewStatus : 'initial',
		total : null,
		previewItems : [],
		allItems : [],
		allStatus : 'initial',
		allHasMore : true
	},
	connections : {
		followerStatus: 'initial',
		followingStatus: 'initial',
		followerUserIds: [],
		followingUserIds: []
	}
};

const reducers = handleActions({
	/**
	 * Resets our entire state tree for the user page
	 */
	[SET_USER]: (state, action) => {
		if (!state.currentUserId) {
			return update(state, {
				currentUserId: {$set : action.payload}
			});
		} else {
			// user ha
			return _.assignIn({}, base, {currentUserId: action.payload});
		}
	},
	/**
	 * activity cards
	 */
	[RECEIVE_ACTIVITY_CARDS]: {
		next: (state, action) => update(state, {
			activityCards : {
				status: {$set: 'idle'},
				cards: {$set: action.payload}
			}
		}),
		throw: (state, action) => {
			console.error('Error getting recent activity', action.payload);
			return state;
		}
	},
	/**
	 * stats
	 */
	[RECEIVE_STATS]: {
		next: (state, action) => update(state, {
			stats: {
				status: {$set: 'idle'},
				data: {$set: action.payload}
			}
		}),
		throw: (state, action) => {
			console.error('Error getting list of gm teams', action.payload);
			return state;
		}
	},
	/**
	 * user's teams
	 * userTeamList items is a sorted array of objects
	 * objects will look like
	 * {
	 * 		teamId: teamId
	 * 		teamSubscription : [{object}],
	 * 		generalManager : [{object}]
	 * }
	 *
	 * All the extra stuff is on there because its very reasonable that we may want to use that for display purposes somewhere... I don't want
	 * To have it missing then go backwards and work everything out
	 *
	 * 100% of the items are stored an there is no pagination so there is no reason for an explicit "total"
	 *
	 */
	[RECEIVE_USER_TEAM_LIST] : {
		next: (state, action) => update(state, {
			userTeamList : {
				status: {$set: 'idle'},
				items: {$set: action.payload},
			}
		}),
		throw: (state, action) => {
			console.error('Error getting user team subs', action.payload);
			return state;
		}
	},
	/**
	 * media
	 */
	[RECEIVE_PREVIEW_MEDIA] : {
		next: (state, action) => {
			const gamePostIds = action.payload.gamePostIds;
			const total = action.payload.total;
			return update(state, {
				media : {
					previewStatus: {$set: 'idle'},
					previewItems: {$set: gamePostIds},
					total: {$set: total}
				}
			});
		},
		throw: (state, action) => {
			console.error('Error getting gamePosts / media', action.payload);
			return state;
		}
	},
	/**
	 * Connections : Followers and following
	 */
	[RECEIVE_FOLLOWERS] : {
		next: (state, action) => {
			const { userIds } = action.payload;
			return update(state, {
				connections : {
					followerStatus: {$set: 'idle'},
					followerUserIds: {$set: userIds}
				}
			});
		},
		throw: (state, action) => {
			console.error('Error getting followers', action.payload);
			return state;
		}
	},
	[RECEIVE_FOLLOWING] : {
		next: (state, action) => {
			const { userIds } = action.payload;
			return update(state, {
				connections : {
					followingStatus: {$set: 'idle'},
					followingUserIds: {$set: userIds}
				}
			});
		},
		throw: (state, action) => {
			console.error('Error getting gamePosts / media', action.payload);
			return state;
		}
	},
}, base);

// Combine all - es6 object literal value shorthand
export default reducers;

const setCurrentUser = createAction(SET_USER);

let actions = {
	setCurrentUser : createAction(SET_USER),
	receiveActivityCards : createAction(RECEIVE_ACTIVITY_CARDS),
	receiveStats : createAction(RECEIVE_STATS),
	receiveUserTeamList : createAction(RECEIVE_USER_TEAM_LIST),
	receivePreviewMedia : createAction(RECEIVE_PREVIEW_MEDIA),
	receiveFollowers : createAction(RECEIVE_FOLLOWERS),
	receiveFollowing : createAction(RECEIVE_FOLLOWING),
};

export { setCurrentUser };

// dispatch GET and RECEIVE and do the API work for getting recent items
export const getActivityCards = function getActivityCards (p) {
	const { userId } = p;
	
	return (dispatch) => {
		if (fetchingHash.activityCards) { return;}
		fetchingHash.activityCards = true;

		api.post({
			method : 'users.activity.cards.search',
			params : {
				userId : userId,
				count : 25
			},
			callback : function (err, result) {
				if (err) {
					dispatch({
						type: RECEIVE_ACTIVITY_CARDS,
						error: true,
						payload: err
					});
				} else {
					dispatch(receiveCollections(result.collections));
					dispatch(actions.receiveActivityCards(result.collections.cardCollection.list));
				}
				delete fetchingHash.activityCards;
			}
		});
	};
};

export const getStats = function getStats (p) {
	const { userId } = p;
	return (dispatch) => {
		if (fetchingHash.stats) { return; }
		fetchingHash.stats = true;

		waterfall([
			callback => api.post({
				method: 'users.stats.search',
				params: {userIds: [userId]},
				callback
			})
		], (err, result) => {
			if (err) {
				dispatch({
					type: RECEIVE_STATS,
					error: true,
					payload: err
				});
			} else {
				const stats = result.collections.userStatCollection.list[0].totals;
				dispatch(actions.receiveStats(stats));
			}
			delete fetchingHash.stats;
		});
	};
};

/**
 * Search for User Team Subscriptions and General Manager affiliations for the current user
 * Glue those together and turn them into a new data structure that will get passed to our team list components
 * Using the Teams since we have them now sort the list also
 * Dispatch into the root collection module so all the teams are saved too
 *
 * serId is derived from the currently set user on the user route
 * @returns {Function}
 */
export const getUserTeamList = function getUserTeamList (p) {
	return (dispatch, getState) => {
		const userId = p.userId;
		const currentState = getState();

		if (!userId || _.get(currentState, 'routes.user.currentUserId') !== userId) {
			return console.error("Current UserId is not set");
		}

		if (fetchingHash.userTeamList) {
			return;
		}
		fetchingHash.userTeamList = true;

		api.post({
			method: 'users.teams.subscriptions.search',
			params: {
				userIds: [userId],
				sortBy : 'interest'
			},
			callback: function (err, result) {
				if (err) {
					dispatch({
						type: RECEIVE_USER_TEAM_LIST,
						error: true,
						payload: err
					});
				} else {
					const gms = _.get(result, ['collections','generalManagerCollection', 'list']) || [];
					const teamSubs = _.get(result, ['collections', 'userTeamSubscriptionCollection', 'list']) || [];

					const gmMap = _.keyBy(gms, 'teamId');
					const teamSubMap = _.keyBy(teamSubs, 'teamId');

					// We map the teams result because teams we are a GM for but not subscribed might be in there...
					const items = teamSubs.map(function (teamSub) {
						return {
							teamId: teamSub.teamId,
							teamSubscription: teamSub,
							generalManager: gmMap[teamSub.teamId]
						};
					});

					// oh god WHY!!!
					// if you happen to be a GM but you aren't subbed, pop that up top
					gms.forEach(function (gm) {
						if (!teamSubMap[gm.teamId]) {
							let firstNonGmIndex = _.findIndex(items, function (item) { return !(item.generalManager); });

							if (firstNonGmIndex === -1) {
								firstNonGmIndex === 0;
							}

							items.splice(firstNonGmIndex, 0, {
								teamId : gm.teamId,
								teamSubscription : null,
								generalManager : gm
							});
						}
					});

					dispatch(receiveCollections(result.collections));
					dispatch(actions.receiveUserTeamList(items));
				}
				// we are no longer fetching this
				delete fetchingHash.userTeamList;
			}
		});
	}
};

export const getPreviewMedia = function getPreviewMedia (p) {
	const userId = p.userId;

	return (dispatch) => {
		if (fetchingHash.previewMedia) { return; }
		fetchingHash.previewMedia = true;

		waterfall([
			cb => api.post({
				method: 'games.posts.search',
				params: {
					creatorUserIds: [userId],
					gamePostTypes: ['picture', 'video'],
					count: 5,
					orderBy: 'DESC',
					includeGames: true,
					skipChildGamePosts : true,
					skipParentGamePosts : true,
					skipHidden: true,
				},
				callback: cb
			})
		], (err, result) => {
			if (err) {
				dispatch({
					type: RECEIVE_PREVIEW_MEDIA,
					error: true,
					payload: err
				});
			} else {
				const gamePostIds = _.map(result.collections.gamePostCollection.list, 'gamePostId');
				const total = result.total;

				dispatch(receiveCollections(result.collections));
				dispatch(actions.receivePreviewMedia({gamePostIds: gamePostIds, total: total}));
			}

			delete fetchingHash.previewMedia;
		});
	};
};

export const getFollowers = (p) => function getFollowers (dispatch) {
	const { stateKey, userId, } = p;

	if (fetchingHash.followers) { return; }
	fetchingHash.followers = true;

	waterfall([
		function (callback) {
			api.post({
				method: 'users.followers.search',
				params: {followingUserIds: [userId], count: 10},
				callback
			});
		}
	], (err, result) => {
		if (err) {
			dispatch({
				type: RECEIVE_FOLLOWERS,
				error: true,
				payload: err
			});
		} else {
			dispatch(receiveCollections(result.collections));
			const items = result.userFollowerIds
				.map(userFollowerId => {
					const followerItem = _.find(result.collections.userFollowerCollection.list, {userFollowerId});
					return followerItem.userId;
				});
			dispatch(reduxInfiniteScroll.actions.storeState({
				stateKey,
				state: {items, total: result.total},
			}));
			dispatch(actions.receiveFollowers({
				userIds: _.map(result.collections.userFollowerCollection.list, 'userId')
			}));
		}

		delete fetchingHash.followers;
	});
};

export const getFollowing = (p) => function getFollowing (dispatch) {
	const { stateKey, userId, } = p;

	if (fetchingHash.following) { return; }
	fetchingHash.following = true;

	waterfall([
		cb => api.post({
			method: 'users.followers.search',
			params: {userIds: [userId], count: 10},
			callback: cb
		})
	], (err, result) => {
		if (err) {
			dispatch({
				type: RECEIVE_FOLLOWING,
				error: true,
				payload: err
			});
		} else {
			dispatch(receiveCollections(result.collections));
			const items = result.userFollowerIds
				.map(userFollowerId => {
					const followerItem = _.find(result.collections.userFollowerCollection.list, {userFollowerId});
					return followerItem.followingUserId;
				});
			dispatch(reduxInfiniteScroll.actions.storeState({
				stateKey,
				state: {items, total: result.total},
			}));
			dispatch(actions.receiveFollowing({
				count: result.total,
				userIds: _.map(result.collections.userFollowerCollection.list, 'followingUserId')
			}));
		}

		delete fetchingHash.following;
	});
};
