import keyBy from 'lodash/keyBy';
import get from 'lodash/get';

import delayedGet from './../../helpers/delayedGet';

export const propsToDiff = {};

const getInitialState = function getInitialState() {
	return keyBy(get(window, ['gData', 'redux', 'collections', 'userWidgetCollection', 'list'], []), 'userWidgetId');
};

export const getUserWidgets = ({ userWidgetIds }) => {
	return delayedGet({
		key: 'userWidgetId',
		method: 'users.widgets.get',
		idsKey: 'userWidgetIds',
		ids: userWidgetIds,
	});
};

export default {
	getInitialState,
	propsToDiff,
};
