"use strict";
import _ from "lodash";
import waterfall from "async/waterfall";

import { handleError } from "../../../libs/errorHandler";
import { getApi } from "../../../libs/api";
const api = getApi({ name: "scoreStream" });
import genericActionCallback from "../../helpers/genericActionCallback";
import { actions as collectionActions } from "../../actions/collections";

function getInitialState() {
  const teams = _.get(
    window,
    ["gData", "redux", "collections", "teamCollection", "list"],
    []
  );
  return _.keyBy(teams, "teamId");
}

//wraps get and receive
let fetchingHash = {};
let queueTimer = null;
let queue = [];
let callbackQueue = [];

export const get = (p) => {
  let {
    callback = _.noop,
    forceMerge = false,
    includeTeamSupplements,
    teamIds,
  } = p;

  return (dispatch) => {
    // filter out ones we are already fetching
    teamIds = teamIds.filter((teamId) => !fetchingHash[teamId]);
    if (!teamIds.length) {
      return;
    }

    queue = queue.concat(teamIds);
    callbackQueue = callbackQueue.concat(callback);
    if (queueTimer) {
      return;
    }
    queueTimer = window.setTimeout(() => {
      queueTimer = null;
      const callbacks = callbackQueue.slice();
      callbackQueue = [];
      waterfall(
        [
          (callback) => {
            const teamIds = _.uniq(queue);
            queue = [];
            api.post({
              method: "teams.get",
              params: { includeTeamSupplements, teamIds },
              callback: (err, result) => callback(err, result, teamIds),
            });
          },
        ],
        (err, result, requestedTeamIds) => {
          if (err) {
            dispatch({
              type: "collections/RECEIVE",
              error: true,
              payload: err,
            });
          } else {
            dispatch(
              collectionActions.receiveCollections(result.collections, {
                forceMerge,
              })
            );
          }
          requestedTeamIds.forEach((teamId) => delete fetchingHash[teamId]);
          callbacks.forEach((callback) => callback(err, result));
        }
      );
    }, 20);
  };
};

export const addPost = function addPost(params, callback) {
  const apiParams = _.omit(params, ["showError"]);
  const { showError = true } = params;
  if (apiParams.backgroundPicture) {
    const formData = _.get(apiParams, "formData", new FormData());
    formData.append("backgroundPicture", apiParams.backgroundPicture);
    apiParams.formData = formData;
    delete apiParams.backgroundPicture;
  }
  if (apiParams.video) {
    const formData = _.get(apiParams, "formData", new FormData());
    formData.append("video", apiParams.video);
    apiParams.formData = formData;
    delete apiParams.video;
  }

  apiParams.userNeedsVerificationLevelToPost = 0;
  return (dispatch) => {
    waterfall(
      [
        (callback) =>
          api.post(
            _.assignIn(
              {
                method: "teams.gamePosts.add",
                params: apiParams,
                callback: genericActionCallback(dispatch, callback),
              },
              apiParams
            )
          ),
      ],
      (err, result) => {
        if (err) {
          if (showError) {
            handleError(err, {
              title: "Error submitting post",
            });
          }
          return callback(err);
        }
        callback(null, result);
      }
    );
  };
};

export { getInitialState };
export const propsToDiff = {};
