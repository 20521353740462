import React from 'react';
import PropTypes from 'prop-types';
import withStyles from "@material-ui/core/styles/withStyles";
import MascotOrVarsityLetter from "../teams/mascotOrVarsityLetter.jsx";
import { getLocationString } from "../../libs/location";

function getStyles() {
	return {
		container : {
			padding: 5,
			display: 'flex',
			alignItems: 'center',
		},
		mascotWrap: {
			marginRight: 10,
		},
		infoWrap: {
			textAlign: 'left',
			overflow: 'hidden',
		},
		infoMascot: {
			opacity: 0.8,
			marginLeft: 5,
		},
		infoOrganization: {
			fontSize: 12,
			opacity: 0.7,
			paddingRight: 10,
			wordWrap: 'no-wrap',
		},
		infoLocation: {
			fontSize: 12,
			opacity: 0.7,
		}
	};
}

function TeamSuggestion(props) {
	const {
		team,
		mascotPicture,
		colorMap,
		organizationMap,
		showMascot,
		classes,
	} = props;
	const { minTeamName, mascot1, organizationId, color1Id, color2Id } = team;

	const color1 = colorMap[color1Id];
	const color2 = colorMap[color2Id];
	const organization = organizationMap[organizationId];
	const locationDisplay = getLocationString(team);

	return (
		<div className={classes.container}>
			{showMascot && <div className={classes.mascotWrap}>
				<MascotOrVarsityLetter
					team={team}
					color1={color1}
					color2={color2}
					mascotPicture={mascotPicture}
					fullSize={true}
					width={42}
				/>
			</div>}

			<div className={classes.infoWrap}>
				<div>
					<span>
						{minTeamName}
					</span>

					<span className={classes.infoMascot}>
						{mascot1}
					</span>
				</div>
				<div>
					{organization && <span className={classes.infoOrganization}>
						{organization.display}
					</span>}
					{locationDisplay && <span className={classes.infoLocation}>
						{locationDisplay}
					</span>}
				</div>
			</div>
		</div>
	);
}

TeamSuggestion.propTypes = {
	team: PropTypes.object.isRequired,
	mascotPicture: PropTypes.object,
	colorMap: PropTypes.object.isRequired,
	organizationMap: PropTypes.object.isRequired,
	classes: PropTypes.object,
	showMascot: PropTypes.bool,
};
TeamSuggestion.defaultProps = {
	showTeamMascot: true,
};

const Styled = withStyles(getStyles)(TeamSuggestion);
export default React.memo(Styled);