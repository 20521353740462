"use strict";
import { connect } from 'react-redux';
import _ from 'lodash';
import { get as getUsers } from '../redux/modules/collections/user';
import { followUsers, unfollowUsers, getFollowing } from '../redux/modules/authUser/following';

function mapStateToProps (state, ownProps) {
	// TODO - TEST... close... remove the get thing, this should only take user for but a stopgap, things are passing this a userObject already
	const userId = ownProps.userId || _.get(ownProps, ['user', 'userId']);
	const user = state.collections.user[userId];

	const authUser = _.get(state, ['authUser', 'user']);

	const userSupplement = state.collections.userSupplement[userId];
	const userRank = (user) ? state.collections.userRank[user.userRankId] : null;

	return {
		user,
		userRank,
		userSupplement,
		canFollow : !!(authUser && userId !== authUser.userId),
		isAuthUser: (_.get(authUser, 'userId') === userId),
		isFollowing: state.authUser && !!_.find(state.authUser.following.items, {followingUserId: userId}),
		hasReceivedFollowing : _.get(state, ['authUser', 'following', 'hasReceived']),
	};
}

function mapDispatchToProps (dispatch, ownProps) {
	const userId = ownProps.userId;

	return {
		getUser: () => dispatch(getUsers({userIds : [userId]})),
		//getUserSupplement: () => {}, //TODO
		//getUserRank: () => {}, //TODO
		getAuthUserFollowing : () => dispatch(getFollowing()),
		follow: ({callback}) => dispatch(followUsers({
			callback,
			userIds: [userId]
		})),
		unfollow: ({callback}) => dispatch(unfollowUsers({
			callback,
			userIds: [userId]
		})),
	};
}

export default connect(mapStateToProps, mapDispatchToProps);

export const userKeys = [
	//from mapstatetoprops
	'userId',
	'user',
	'userRank',
	'userSupplement',
	'canFollow',
	'isAuthUser',
	'isFollowing',
	'hasReceivedFollowing',
	'hasBoosterClubUntil',
	//from mapdispatch
	'getUser',
	'getAuthUserFollowing',
	'follow',
	'unfollow',
];
