import PropTypes from 'prop-types';
import React from 'react';

function Loading (props) {
	const { style } = props;

	const styles = {
		icon : {
			fontSize: props.size,
			opacity : .3,
			...style,
		}
	};

	return (
		<div>
			<i className="fa fa-spinner fa-spin" style={styles.icon} />
		</div>
	);
}

Loading.propTypes = {
	size : PropTypes.number,
	style: PropTypes.object,
};

Loading.defaultProps = {
	size : 48,
	style: {},
};

export default React.memo(Loading);
