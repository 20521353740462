import React from 'react';

import styles from '../../libs/paginatorStyles';

export default function defaultMessageRenderer ({ fetching, hasMore, items, total, ...props }) {
	if (fetching) {
		return <div style={styles.loading}>Loading ...</div>;
	} else if (!hasMore && items.length > 0) {
		return <div style={styles.endOfList}>End of List</div>;
	} else if (!hasMore && items.length === 0) {
		return <div style={styles.noResults}>No Results</div>;
	} else if (hasMore && props.onClick) {
		return (
			<div onClick={props.onClick} style={styles.loadMore}>
				Load More
			</div>
		);
	} else {
		return (<div />);
	}
}
