/**
 * @returns {Element}
 */
export default function getPortalContainer() {
	const container = document.getElementById('popupContainer');
	if (container) {
		return container;
	} else {
		const popupContainer = document.createElement('div');
		popupContainer.style.overflow = 'visible';
		popupContainer.setAttribute('class', 'scorestream_ui');
		popupContainer.setAttribute('id', 'popupContainer');
		document.body.appendChild(popupContainer);
		return popupContainer;
	}
};