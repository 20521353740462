import React from 'react';
import PropTypes from 'prop-types';
import withStyles from "@material-ui/core/styles/withStyles";

function getStyles() {
	return {
		container : {
			padding: 10,
			textAlign: 'center',
			fontSize: 12,
		},
		noLink: {
			cursor: 'default',
			background: 'white',
			color: '#777',
		}
	};
}

function MessageSuggestion({ message, href, classes }) {
	const className = (!href) ? `${classes.container} ${classes.noLink}` : classes.container;

	return (
		<div className={className}>
			{message}
		</div>
	);
}

MessageSuggestion.propTypes = {
	message: PropTypes.string.isRequired,
	href: PropTypes.string,
	classes: PropTypes.object,
};

const Styled = withStyles(getStyles)(MessageSuggestion);
export default React.memo(Styled);