import _ from 'lodash';
import { getApi } from '../../../libs/api';
const ssApi = getApi({name: 'stream'});
import { actions as collectionActions } from '../../actions/collections';
import { handleApiError } from "../../../libs/errorHandler";

function getInitialState () {
	// This is if we are doing an ssUI thing
	// Legacy stuff, won't be there in app router and such
	return _.get(window, 'scoreStream.teamTierHash', {});
}

export default { getInitialState };

export const loadTeamTiers = function loadTeamTiers () {
	return _.once(function dispatchLoadTeamTiers(dispatch) {
		ssApi.post({
			method: 'teamTiers.search',
			params: {},
			callback: (err, r) => {
				if (err) { return handleApiError(err); }

				dispatch(collectionActions.receiveCollections(r.collections));
			}
		});
	});
};
