
import { importLevelsOfPlayAndOrgs } from '../../helpers/importCachedData';

function getInitialState () {
	return {};
}

export default {getInitialState};

export const loadLevelsOfPlay = function loadLevelsOfPlay  () {
	return function (dispatch) {
		return importLevelsOfPlayAndOrgs(dispatch);
	};
};

