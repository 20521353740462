'use strict';
import isUndefined from 'lodash/isUndefined';
//built a custom jquery, excluding everything but the ajax module
import $ from '../../../vendor/js/jquery.custom';
const ajax = $.ajax;
import noop from 'lodash/noop';

import { config } from './config';
import { getAccessToken } from './accessToken';

/**
 * Hack shit so Josh can disable API requests on the renderEngine
 * @type {boolean}
 */
let API_REQUESTS_ENABLED = true;
export function disableApiRequests () { API_REQUESTS_ENABLED = false; }
export function enableApiRequests () { API_REQUESTS_ENABLED = true; }

export class RequestAbortedError extends Error {
	constructor (message) {
		super(message);
		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, RequestAbortedError);
		}
	}
}

function getSuccessCallback (callback) {
	return function successCallback (response) {
		if (!response) {
			return callback({'message' : 'Undefined Response'});
		} else if (response.error) {
			return callback(response.error);
		} else if (!response.result) {
			return callback({'message' : 'Missing result'});
		} else if (response.result.paramErrors) {
			return callback(response.result.paramErrors);
		} else if (response.failure === 1) {
			return callback({'message' : 'Response returned failure'});
		} else {
			return callback(null, response.result);
		}
	};
}

function getErrorCallback (callback) {
	return function errorCallback (response) {
		if (response && response.statusText === 'abort') {
			console.debug(`request aborted`);
			return;
		}

		if (response.responseJSON && response.responseJSON.error) {
			return callback(response.responseJSON.error);
		}
		return callback('Fatal error in response');
	};
}

class apiRequester {
	constructor (p) {
		this.domain = p.domain;
		this.uri = p.uri || this.domain + "/api";
		this.name = p.name || ('Unknown Named API ' + this.uri);
	}

	/**
	 *
	 * @param p
	 * @returns {{abort: function}}
	 */
	post (p) {
		const {
			callback = noop,
			method,
			onProgress,
			params = {},
			domain = this.domain,
		} = p;

		if (!API_REQUESTS_ENABLED) {
			return callback(`API Requests have been disabled by the developer, cannot make the request for ${method}`);
		}

		const url = (domain) ? domain + '/api' : '/api';

		const token = getAccessToken();
		if (token && isUndefined(p.accessToken)) {
			params.accessToken = token;
		}
		if (!params.apiKey) {
			params.apiKey = 'a20bd983-0147-437a-ab6d-49afeb883d33';
		}

		const success = getSuccessCallback(callback);
		const error = getErrorCallback(callback);

		const request = JSON.stringify({method, params});
		if (p.formData) {
			p.formData.append('request', request);
		}

		const req = ajax({
			type: 'POST',
			contentType: p.formData ? false : 'application/json',
			processData: !p.formData,
			data: p.formData || request,
			// For on Progress jazz
			xhr : () => {
				const xhr = new window.XMLHttpRequest();

				const cb = function (evt) {
					if (evt && evt.lengthComputable && onProgress) {
						const percent = Math.floor(evt.loaded / evt.total * 100);

						console.log("Upload progress", percent, evt);
						onProgress({
							percentComplete : percent,
							loaded : evt.loaded,
							total : evt.total,
						});
					}
				};

				//Upload progress, request sending to server
				xhr.upload.addEventListener("progress", cb, false);

				xhr.addEventListener("progress", cb, false);

				return xhr;
			},
			success,
			error,
			url,
		});
		const returnPromise = Promise.resolve(req)
			.then(response => new Promise((resolve, reject) => {
				getSuccessCallback((err, result) => {
					if (err) { return reject(err); }
					return resolve(result);
				})(response);
			}), reason => {
				if (reason === req && reason.statusText === 'abort') {
					throw new RequestAbortedError();
				}
				return new Promise((resolve, reject) => {
					getErrorCallback(err => reject(err))(reason);
				});
			})
			.catch((e) => {
				if (!callback) {
					console.debug(`error catch post promise`, e);
				}
			});

		returnPromise.abort = req.abort.bind(req);
		return returnPromise;
	}
}

/* in case you don't what to do the get object for some reason... */
export function post(p) {
	return apiRequester.prototype.post.call(this, p);
}

const savedApiObjects = {};
export function getApi(p) {
	var name = p.name;
	var protocol = p.protocol || "https"; //https really should be always
	var cacheKey = name + '-' + protocol;

	if (savedApiObjects[cacheKey]) {
		return savedApiObjects[cacheKey];
	}

	//e.g. config.scoreStream.httpsHost (put this for grep)
	if (!config[name] || !config[name][protocol + "Host"]) {
		throw new Error("We don't have an interface for communicating with " + name + " over " + protocol);
	}

	var domain = config[name][protocol + "Host"];
	savedApiObjects[cacheKey] = new apiRequester({name, domain});

	return savedApiObjects[cacheKey];
}
