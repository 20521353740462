"use strict";
import keyBy from 'lodash/keyBy';
import get from 'lodash/get';

import { getApi } from '../../../libs/api';
import { actions as collectionActions } from '../../actions/collections';

const api = getApi({name: 'scoreStream'});
const propsToDiff = {};

const getInitialState = function getInitialState() {
	return keyBy(
		get(window, [
			'gData',
			'redux',
			'collections',
			'scheduleCategoryCollection',
			'list'
		], []),
		'scheduleCategoryId'
	);
};


let fetching = false;
export function search () {
	return function dispatchSearchcheduleCategories (dispatch) {
		if (fetching) { return; }
		fetching = true;
		api.post({method: 'scheduleCategories.search'})
			.then(result => {
				dispatch(collectionActions.receiveCollections(result.collections));
			})
			.finally(() => fetching = false);
	};
}

export default {
	getInitialState,
	propsToDiff,
};