import get from 'lodash/get';
import { connect } from 'react-redux';
import * as notificationInfoRedux from '../../common/redux/modules/authUser/notificationInfo';
import * as userNotificationsRedux from '../../common/redux/modules/collections/userNotification';
import { actions as collectionActions } from '../../common/redux/actions/collections';
import * as infiniteScrollRedux from '../../common/redux/modules/infiniteScroll';

function mapStateToProps(state) {
	return {
		authUser : state.authUser.user,
		fetching : state.authUser.notificationInfo.fetching,
		hasReceived : state.authUser.notificationInfo.hasReceived,
		notificationUnreadTotals : state.authUser.notificationInfo.notificationUnreadTotals,
		userNotificationIds : get(state, ['infiniteScroll', 'authUserNotifications', 'items'], []),
		userNotificationMap : state.collections.userNotification,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		getUserNotificationInfo : () => dispatch(notificationInfoRedux.getUserNotificationInfo()),
		receiveUserNotificationInfo : (userNotificationInfo) => dispatch(notificationInfoRedux.receiveUserNotificationInfo(userNotificationInfo)),
		updateAllIsRead : () => dispatch(userNotificationsRedux.updateAllIsRead()),
		updateAllIsHidden : () => dispatch(userNotificationsRedux.updateAllIsHidden()),
		receiveCollections : (collections) => dispatch(collectionActions.receiveCollections(collections)),
		clearInfiniteScroll : () => dispatch(infiniteScrollRedux.clear({stateKey : {id : 'authUserNotifications', path : 'authUserNotifications'}})),
	};
}

export default connect(mapStateToProps, mapDispatchToProps);
