import assignIn from 'lodash/assignIn';

import * as sharedStyles from './sharedStyles';

const baseStyle = {
	marginTop: 10,
	color : '#FFF',
	background : sharedStyles.vars.colors.ssLightGray,
	opacity : 0.5,
	textAlign : 'center',
	fontSize : 14,
	padding : 10
};

export default {
	hasError : assignIn({}, baseStyle, {
		color : sharedStyles.vars.colors.ssRed,
	}),
	loading : assignIn({}, baseStyle, {
		textTransform : 'uppercase',
	}),
	endOfList : assignIn({}, baseStyle, {
		textTransform : 'uppercase',
	}),
	noResults : assignIn({}, baseStyle, {
		textTransform : 'uppercase',
	}),
	loadMore: {
		...baseStyle,
		background: sharedStyles.vars.colors.ssBlueHighlight,
		cursor: 'pointer',
	},
};
