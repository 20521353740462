import keyBy from 'lodash/keyBy';
import get from 'lodash/get';

import { getApi } from '../../../libs/api';
import { actions as collectionActions } from '../../actions/collections';

const api = getApi({name: 'scoreStream'});

export const propsToDiff = {};

const getInitialState = function getInitialState() {
	return keyBy(get(window, ['gData', 'redux', 'collections', 'userWidgetPlanCollection', 'list'], []), 'userWidgetPlanId');
};

let fetching = false;
export const getUserWidgetPlans = () => {
	return dispatch => {
		if (fetching) { return; }
		fetching = true;
		api.post({
			method: 'users.widgets.getPremiumPlans',
		}).then(result=> {
			dispatch(collectionActions.receiveCollections(result.collections));
		}).finally(() => {
			fetching = false;
		});
	};
};

export default {
	getInitialState,
	propsToDiff,
};
