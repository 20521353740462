"use strict";
import _ from 'lodash';
import { connect } from 'react-redux';
import { login, facebookLogin } from '../redux/modules/authUser';

// should everything just assume plurality or should we switch and ensure its one or the other and then behave in one way or the other.
function mapStateToProps(state) {
	return {
		authUser : _.get(state, 'authUser.user'),
	};
}

function mapDispatchToProps (dispatch) {
	return {
		login: (username, password, callback) => dispatch(login(username, password, callback)),
		facebookLogin: callback => dispatch(facebookLogin(callback)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps);
