/* global window:false */
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';
import notificationCenterContainer from '../../../containers/notificationCenter';
import * as sharedStyles from '../../../../common/libs/sharedStyles';
import * as notificationsLib from '../../../libs/notificationCenter';
import { connectComponent } from '../../../../common/libs/socketApi.jsx';
import UserNotificationList from './notificationCenter/list.jsx';

const ACTION_HEIGHT = 44;
const MOBILE_LIST_WINDOW_WIDTH = 480;

function getStyles() {
	return {
		container: {
			position: 'relative',
			width: 36,
			height: 34,
			margin: `0 10px`,
			//display: `inline-block`,
		},
		iconContainer: {
			position: 'relative',
			padding: 7,
			border: `1px solid rgba(255, 255, 255, 0.3)`,
			borderRadius: 5,
			textAlign: 'center',
			cursor: 'pointer',
			transition: '0.2s ease',
			background: `rgba(255, 255, 255, 0)`,
			':hover': {
				border: `1px solid rgba(255, 255, 255, 0.6)`,
				background: `rgba(55, 55, 55, 0.15)`,
			}
		},
		icon: {
			color: 'white',
			fontSize: 18,
		},
		badge: {
			position: 'absolute',
			background: sharedStyles.vars.colors.ssNotificationRed,
			textAlign: 'center',
			fontSize: 10,
			color: '#FFF',
			paddingTop: 2,
			width: 20,
			height: 20,
			borderRadius: 10,
			zIndex: 2,
			top: 20,
			left: 20,
		},
		popOver: {
			display: 'flex',
			flexDirection: 'column',
			zIndex: 1011, /* underneath user popup tooltip */
			background: 'white',
			border: `1px solid rgba(75, 75, 75, 0.2)`,
			boxShadow: `0 4px 9px rgba(0, 0, 0, .20)`,
			minHeight: 120,
			maxHeight: 400, /* overloaded */
			[`@media (max-width: ${MOBILE_LIST_WINDOW_WIDTH - 1}px)`]: {
				width: '94%',
				position: 'fixed',
				top: 42,
				left: '3%',
			},
			[`@media (min-width: ${MOBILE_LIST_WINDOW_WIDTH}px)`]: {
				width: 380,
				position: 'absolute',
				top: 40,
				right: 0,
			},
		},
		actions: {
			display: 'flex',
			padding: `2px 5px`,
			alignItems: 'center',
			height: ACTION_HEIGHT,
			borderBottom: `1px solid ${sharedStyles.vars.colors.ssLightestGray}`,
		},
		list: {
			overflowY: 'scroll',
		},
		header: {
			flex: 1,
			color: sharedStyles.vars.colors.ssMediumGray,
			fontWeight: 100,
		},
		closeIcon : {
			color: sharedStyles.vars.colors.ssMediumGray,
			marginLeft: 8,
			cursor: 'pointer',
			'&:hover': {
				color: sharedStyles.vars.colors.ssBlue,
			}
		}

	};
}

class NotificationCenter extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isDisabled : false,
			listVisible : false,
			unreadCount : 0,
			pendingInfoUpdate : undefined,
			clearInfiniteScrollOnVisible : false,
		};

		['receiveInfo', 'onNotificationClick', 'toggleListVisible', 'onWindowClick', 'clearAllClicked', 'headerClicked', 'markReadOnData'].forEach((m) => {
			this[m] = this[m].bind(this);
		});

		this._setIconEl = (c) => { this._iconEl = c; };
		this._setPopoverEl = (c) => { this._popOverEl = c; };
	}

	componentDidMount () {
		this.setupCheck(this.props, this.state);
	}

	componentWillUnmount() {
		this.removeWindowClickListener();
		this.unsubscribeToInfo();
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.notificationUnreadTotals !== this.props.notificationUnreadTotals) {
			this.calculateUnreadCount(nextProps);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.listVisible && !prevState.listVisible) {
			// just hides immediately otherwise... i think because of syncronous set state
			setTimeout(() => {
				this.addWindowClickListener();
			}, 25);
		} else if (!this.state.listVisible && prevState.listVisible) {
			this.removeWindowClickListener();
		}

		// if the count is changing, clear the list so it searches correct next time

		if (!this.state.listVisible && !this.state.clearInfiniteScrollOnVisible && this.state.unreadCount !== prevState.unreadCount) {
			this.setState({
				clearInfiniteScrollOnVisible : true,
			});
		}

		if (this.state.unreadCount !== prevState.unreadCount) {
			const matches = document.getElementsByClassName('notificationCenterCount');
			for (let i = 0; i < matches.length; i++) {
				const el = matches[i];

				if (!this.state.unreadCount && prevState.unreadCount) {
					el.style.display = 'none';
				} else if (this.state.unreadCount && !prevState.unreadCount) {
					el.style.display = 'block';
				}

				el.innerHTML = this.state.unreadCount;
			}
		}
	}

	markReadOnData () {
		this.props.updateAllIsRead();
	}

	setupCheck(props, state) {
		const { authUser, fetching, hasReceived } = props;
		const { isDisabled } = state;

		if (!authUser && !isDisabled) {
			this.setState({
				isDisabled : true,
			});
			return;
		} else if (isDisabled && !authUser) {
			return;
		}

		// someone logged in?
		if (isDisabled) {
			this.setState({isDisabled : false});
		}

		// haven't ever fetched, time to fetch
		if (!hasReceived && !fetching) {
			this.setup();
		}
	}

	setup() {
		this.props.getUserNotificationInfo();
		this.calculateUnreadCount(this.props);
		this.subscribeToInfo();
	}

	calculateUnreadCount(props) {
		const { notificationUnreadTotals } = props;

		const count = notificationUnreadTotals.reduce((acc, group) => {
			notificationsLib.canRoute(group);
			acc += group.count;
			return acc;
		}, 0);

		this.setState({
			unreadCount : count,
		});
	}

	getListHeight () {
		try {
			return Math.max(300, window.innerHeight -
				this._iconEl.getBoundingClientRect().top -
				this._iconEl.offsetHeight -
				20);

		} catch(e) {
			console.error('problem figuring out window height', e);
			return Math.round(window.innerHeight / 1.8);
		}
	}
	
	getCalculatedStyles() {
		const listHeight = this.getListHeight();

		return {
			popOver: {
				maxHeight: listHeight,
			},
			list: {
				maxHeight : listHeight - ACTION_HEIGHT - 3,
			}
		};
	}

	subscribeToInfo() {
		this.props.socket.on('events.userNotificationInfoUpdate', this.receiveInfo);
	}

	unsubscribeToInfo() {
		this.props.socket.off('events.userNotificationInfoUpdate', this.receiveInfo);
	}

	receiveInfo (r) {
		const info = _.get(r, ['collections', 'userNotificationInfoCollection', 'list', 0]);
		if (info) {
			if (this.state.listVisible) {
				this.setState({
					pendingInfoUpdate : info,
				});
			} else {
				this.props.receiveUserNotificationInfo(info);
			}
		}
	}

	toggleListVisible () {
		const listVisible = !this.state.listVisible;

		const updates = {
			listVisible,
		};

		// being toggled on
		if (listVisible) {
			updates.calculatedStyles = this.getCalculatedStyles();
			updates.unreadCount = 0;

			if (this.state.clearInfiniteScrollOnVisible) {
				this.props.clearInfiniteScroll();
			}
			updates.clearInfiniteScrollOnVisible = false;
		} else {
			const info = this.state.pendingInfoUpdate;
			if (info) {
				this.props.receiveUserNotificationInfo(info);
			}
			updates.pendingInfoUpdate = undefined;

			// clear the old notifications
			const mapUserNotification = userNotificationId => _.assignIn({}, this.props.userNotificationMap[userNotificationId], {
				read: true,
			});
			const userNotifications = _.compact(_.map(this.props.userNotificationIds, mapUserNotification));

			if (userNotifications.length) {
				this.props.receiveCollections({
					userNotificationCollection: {list: userNotifications}
				});
			}
		}


		this.setState(updates);
	}

	onWindowClick(evt) {
		// clicked outside the list
		// only works if browser supports Node.contains()

		if (this._iconEl && this._iconEl.contains && (evt.target === this._iconEl || this._iconEl.contains(evt.target))) {
			return;
		}

		if (this._popOverEl && this._popOverEl.contains && !this._popOverEl.contains(evt.target)) {
			this.toggleListVisible();
		}
	}

	addWindowClickListener() {
		window.addEventListener('mousedown', this.onWindowClick);
	}

	removeWindowClickListener () {
		window.removeEventListener('mousedown', this.onWindowClick);
	}

	onNotificationClick() {
		this.toggleListVisible();
	}

	headerClicked () {
		this.toggleListVisible();
	}

	clearAllClicked() {
		this.toggleListVisible();
		this.props.updateAllIsHidden();
	}

	render () {
		const { hasReceived, classes } = this.props;
		const { listVisible, unreadCount, calculatedStyles } = this.state;

		if (!hasReceived) { return null; }

		const icon = unreadCount ? 'comment' : 'comment-o';

		return (
			<div className={classes.container}>
				<div
					ref={this._setIconEl}
					className={classes.iconContainer}
					onClick={(listVisible) ? undefined : this.toggleListVisible}
				>
					<FontAwesome
						className={classes.icon}
						name={icon}
					/>
					{!!unreadCount &&
						<div className={classes.badge}>
							{unreadCount}
						</div>
					}
				</div>

				{listVisible &&
					<div
						ref={this._setPopoverEl}
						className={classes.popOver}
						style={calculatedStyles.popOver}
					>
						<div className={classes.actions}>
							<div className={classes.header}>
								Notifications
							</div>

							<Button
								onClick={this.clearAllClicked}
								size="small"
							>
								Clear All
							</Button>

							<ClearIcon
								onClick={this.toggleListVisible}
								classes={{root: classes.closeIcon}}
							/>
						</div>
						<div className={classes.list} style={calculatedStyles.list}>
							<UserNotificationList
								onData={this.markReadOnData}
								onNotificationClick={this.onNotificationClick}
								parentIsScrollingContainer={true}
							/>
						</div>
					</div>
				}
			</div>
		);
	}
}

NotificationCenter.propTypes = {
	userNotificationIds : PropTypes.array,
	userNotificationMap : PropTypes.object,
	clearInfiniteScroll : PropTypes.func,
	updateAllIsHidden: PropTypes.func,
	updateAllIsRead: PropTypes.func,
	receiveCollections: PropTypes.func,
	receiveUserNotificationInfo: PropTypes.func,
	getUserNotificationInfo: PropTypes.func,
	hasReceived: PropTypes.bool,
	notificationUnreadTotals: PropTypes.array,
	socket: PropTypes.object,
	classes: PropTypes.object,
};

const Styled = withStyles(getStyles, {name: 'ss-header-notificationCenter'})(NotificationCenter);
const Connected = notificationCenterContainer(Styled);
export default connectComponent(Connected);
