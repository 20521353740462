"use strict";
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import update from 'immutability-helper';

//containers
import userContainer from '../../containers/user';

//container wrapped components
import _FollowButton from '../buttons/followButton.jsx';
const FollowButton = userContainer(_FollowButton);

//components and libs
import * as sharedStyles from '../../libs/sharedStyles';

import ProfileImageBadge from './profileImageBadge.jsx';
import Location from './../attributes/location.jsx';
import { Link } from '../../libs/routing';
import BoosterClub from './boosterClubMegaphone.jsx';

const styles = {
	card: {
		height: 250,
		width: 320,
		background : "#FFF",
	},
	topSection: {
		borderBottom: `6px solid ${sharedStyles.vars.colors.ssDarkBlue}`,
		position: 'relative'
	},
	backgroundPic: {
		position: 'absolute',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		width: '100%',
		height: '100%',
	},
	topSectionSizer: {
		width: '100%',
		paddingBottom: '29%',
	},
	badgeContainer: {
		position: 'relative',
		bottom: 16
	},
	badge: {
		position: 'absolute',
		transform: 'translateY(-50%)',
		left: 12
	},
	name: {
		color: '#000',
		fontSize: 22,
		marginLeft: 92,
	},
	nameInner: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		lineHeight: 'normal',
		fontWeight: 'bold',
	},
	booster: {
		lineHeight: 1,
		top: '-0.25em',
		position: 'relative',
	},
	slogan : {
		marginTop: 10,
		marginBottom : 10,
		whiteSpace: 'pre-line',
		fontWeight: 200,
		color: '#666',
		letterSpacing: -.5,
		lineHeight: 1.2,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		maxHeight: 40,
		WebkitLineClamp: 2,
		WebkitBoxOrient: 'vertical',
	},
	contentContainer: {
		margin: '10px 15px 10px 15px'
	},
	actionContainer : {
		marginTop : 5,
		marginBottom : 5
	},
};

class ProfileCard extends React.PureComponent {
	constructor (props) {
		super(props);
		this.state = {
			bgPicStyle: _.assign({}, styles.backgroundPic, {
				backgroundImage: (props.user) ?
					`url(${props.user.backgroundImageThumbnailUrl})` :
					null,
			}),
			cardStyle: _.assignIn({}, styles.card, props.style)
		};

	}

	componentDidMount() {
		if (!this.props.user && this.props.getUser) {
			this.props.getUser();
		}
	}

	componentWillReceiveProps (nextProps) {
		if (nextProps.style !== this.props.style) {
			this.setState((prevState, props) => update(prevState, {
				cardStyle: {$set: _.assignIn({}, styles.card, props.style)}
			}));
		}
		if (nextProps.user && !this.props.user) {
			this.setState((prevState, props) => update(prevState, {
				bgPicStyle: {
					backgroundImage: {$set: `url(${props.user.backgroundImageThumbnailUrl})`},
				}
			}));
		}
	}

	renderTopSection () {
		const {user, userRank} = this.props;

		const content = (
			<div>
				<div style={styles.topSection}>
					<div style={this.state.bgPicStyle}></div>
					<div style={styles.topSectionSizer}></div>
				</div>

				<div style={styles.badgeContainer}>
					<ProfileImageBadge user={user} userRank={userRank} size={72} style={styles.badge} />
				</div>

				<div style={styles.name}>
					<div style={styles.nameInner}>
						{user.displayName}
					</div>
				</div>
			</div>
		);

		return (this.props.hasLink) ? <Link to={this.props.user.relativeUrl}>{content}</Link> : content;
	}

	render () {
		if (!this.props.user) { return null; }

		const {user, isAuthUser} = this.props;

		const hasLocation = !!(user.state || user.city);

		const slogan = (!user.slogan) ? "" : user.slogan.replace(/\n+/g, '\n').replace(/ +/g, ' ').trim();

		return (
			<div style={this.state.cardStyle}>
				{this.renderTopSection()}

				<div style={styles.contentContainer}>
					<div style={styles.actionContainer}>
						{!isAuthUser && <FollowButton userId={user.userId} type="slim" />}
					</div>

					<div style={styles.slogan}>{slogan}</div>

					{hasLocation && <Location {..._.pick(user, ['city', 'state'])} />}
					<BoosterClub user={user} />
				</div>
			</div>
		);
	}
}
ProfileCard.propTypes = {
	user: PropTypes.object,
	userRank: PropTypes.object,
	getUser : PropTypes.func,
	canFollow: PropTypes.bool.isRequired,
	isAuthUser: PropTypes.bool,
	hasLink: PropTypes.bool,
	style: PropTypes.object,
};

ProfileCard.defaultProps = {
	hasLink: true,
};

export default ProfileCard;
