"use strict";
import mapLimit from 'async/mapLimit';
import waterfall from 'async/waterfall';

import { getApi } from '../libs/api';
const api = getApi({name: 'scoreStream'});
import cachedVersions from '../../../cachedData/versions';
import { receive as receiveCollections } from './actions/collections';

const LIST = [{
	name: 'color',
	method: 'colors.list',
}, {
	name : 'levelsOfPlay',
	method : 'levelsOfPlay.list',
}, {
	name : 'gameSegment',
	method : 'gameSegments.search',
}, {
	name : 'gameSegmentType',
	method : 'gameSegmentTypes.search',
}, {
	name : 'sport',
	method : 'sports.search',
}, {
	name : 'stoppageStatus',
	method : 'stoppageStatuses.search',
}, {
	name : 'userRank',
	method : 'users.ranks.search',
}, {
	name : 'trustLevel',
	method : 'users.trustLevels.search',
}];

export const ensureVersions = function ensureVersions(store) {
	waterfall([
		callback => api.post({
			method: 'versions.get',
			params: {},
			callback: callback
		})
	], function (err, res) {
		if (err) { return console.warn(err); }

		const todo = [];
		LIST.forEach(function (item) {
			const { name, method } = item;

			//levelsOfPlay || colors || stoppageStatuses
			const result = res[name] || res[name + 's'] || res[name + 'es'];
			const cached = cachedVersions[name] || cachedVersions[name + 's'] || cachedVersions[name + 'es']; //TODO || 1;

			if (cached >= result) {
				return;
			}

			todo.push({
				name: name,
				method: method,
				resultVersion: result,
				cachedVersion: cached
			});
		});

		// Go get results for all of the the versions we are out of date on
		mapLimit(todo, 3, function (item, callback) {
			const {name, method, resultVersion} = item;

			// TRY LOCAL STORAGE
			try {
				if (window && window.localStorage) {
					const localRes = window.localStorage.getItem(method);

					if (localRes) {
						const parsed = JSON.parse(localRes);

						if (parsed.version && parsed.version >= resultVersion && parsed.result) {
							console.log(`Using local storage for ${name} ${method} had a local version stored ${parsed.version} vs ${resultVersion}`);
							// RETURN THE RESULT
							return callback(null, parsed.result);
						}
					}
				}
			} catch (e) {
				console.warn("localStorage/JSON problem", e);
			}

			// DIDNT HAVE IN LOCAL STORAGE, FIRE OFF A REQUEST AND TRY TO STORE
			api.post({
				method,
				params : {},
				callback : function (err, result) {
					if (err) { return console.warn("problem with " + name, err); }
					console.log(`Got our update for ${name} firing of its action. and storing in local storage`);

					// STORE IN LOCAL STORAGE
					try {
						if (window && window.localStorage) {
							window.localStorage.setItem(method, JSON.stringify({version : resultVersion, result : result}));
						}
					} catch (e) {
						console.warn("localStorage/JSON problem", e);
					}

					// RETURN THE RESULT
					return callback(null, result);
				}
			});
		}, function (err, results) {
			if (err) { return console.warn("Problem working with cached versions / cachedData", err); }

			// TODO combine the dispatch of multiple API results
			results.forEach(function (result) {
				if (result) {
					store.dispatch(receiveCollections(result.collections));
				}
			});
		});
	});
};