import assignIn from 'lodash/assignIn';
import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import memoize from 'memoize-one';

import * as userRankHelpers from '../../libs/users/userRanks';
import ProfileCardPopup from './profileCardPopup.jsx';
import { Link } from '../../libs/routing';
import userContainer from '../../containers/user';

const styles = {
	wrapper: {
		display : 'inline-block',
		position : 'relative',
		backgroundColor: '#FFF',
		borderRadius : '50%'
	},
	badge: {
		position : 'absolute',
		right : 0,
		bottom : 0,
		textTransform : 'uppercase',
		color : '#FFF',
		borderRadius : '50%',
		fontWeight : 200,
		textAlign : 'center',
		backgroundColor: '#000',
		fontFamily : "'Open Sans Condensed', sans-serif",
		letterSpacing : '.5px',
		textIndent : 1,
	},
	popupWrapper: {
		display: 'inline-block',
	},
	link: {
		display: 'inline-block',
	}
};

const tooltipParams = {
	wrapperProps: {style: styles.popupWrapper},
};

class ProfilePicture extends React.PureComponent {
	constructor (props) {
		super(props);
		this.getPictureStyle = memoize(props => {
			const { size, style, user } = props;
			const url = (size > 64) ? user.imageUrl : user.imageThumbnailUrl;
			return assignIn({}, styles.wrapper, {
				width: size,
				height: size,
				borderRadius: '50%',
				backgroundImage: `url(${url})`,
				backgroundSize: 'cover',
				backgroundPosition: 'center'
			}, style);
		});
		this.getBadgeStyle = memoize(props => {
			const { size, user } = props;
			const badgeSize = Math.round(size * 0.4);
			const fontSize = (badgeSize * .55);
			const lineHeight = badgeSize + 'px';
			const rankColor = userRankHelpers.getUserRankColor({
				userRankId: user.userRankId,
			});
			return assignIn({}, styles.badge, {
				width: badgeSize,
				height: badgeSize,
				lineHeight : lineHeight,
				right: Math.round(size * -0.1), // *0.1 provides offset for badge to stick in bottom right corner
				fontSize: fontSize,
				backgroundColor: rankColor
			});
		});
	}

	componentDidMount () {
		if (!this.props.user && this.props.getUser) {
			this.props.getUser();
		}
	}

	render () {
		const { user, userRank, link, showPopup } = this.props;

		if (!user) {
			return null;
		}

		let pic = (
			<div style={this.getPictureStyle(this.props)}>
				{(userRank &&
						userRankHelpers.shouldShowSmallBadge(user.rankId)) && (
					<div style={this.getBadgeStyle(this.props)}>
						{userRank.abbreviationDisplay}
					</div>
				)}
			</div>
		);

		if (link && user.relativeUrl) {
			pic = (
				<Link to={user.relativeUrl} style={styles.link}>
					{pic}
				</Link>
			);
		}

		if (showPopup) {
			pic = (
				<ProfileCardPopup
					user={user}
					userRank={userRank}
					tooltipParams={tooltipParams}
				>
					{pic}
				</ProfileCardPopup>
			);
		}

		return pic;
	}
}

ProfilePicture.propTypes = {
	user: PropTypes.object,
	userRank : PropTypes.object,
	getUser : PropTypes.func,
	style: PropTypes.object,
	size: PropTypes.number,
	link: PropTypes.bool,
	showPopup : PropTypes.bool
};
ProfilePicture.defaultProps = {
	size : 36,
	link: false,
	showPopup : false
};

const Styled = Radium(ProfilePicture);
export default Styled;

export const Connected = userContainer(Styled);

