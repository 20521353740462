/* global window:false */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import withStyles from '@material-ui/core/styles/withStyles';
import headerContainer from '../../containers/header';
import logoSrc from '../../../common/images/logos/scorestream-white-full.png';
import { renderInputComponent } from "../../../common/components/omniSearch/renderInputComponent/header.jsx";
import MenuButton from './header/menuButton.jsx';
import NotificationCenter from './header/notificationCenter.jsx';
import ProfileCompleteCheck from './header/profileCompleteCheck.jsx';
import OmniSearch from '../../../common/components/omniSearch.jsx';
import NoAuthNav from './header/noAuthNav.jsx';
import LoginButton from './header/loginButton.jsx';
import SignupButton from './header/signupButton.jsx';

export const HEADER_HEIGHT = 46;
const AUTH_SEARCH_MODES = ['team', 'user'];
const NO_AUTH_SEARCH_MODES = ['team'];

const getStyles = (theme) => {
	return {
		header : {
			width: '100%',
			height: HEADER_HEIGHT,
			padding: `0px 10px`,
			color: 'white',
			background: theme.palette.primary.main,
			boxShadow: `0 1px 1px 0 rgba(70,70,70,.25)`,
			position: 'fixed',
			zIndex: 102,
			top: 0,
			display: 'flex',
			justifyContent: 'space-between',
		},
		logo: {
			width: 122,
			height: 24,
			marginTop: 13,
			transition: `.25s`,
			marginRight: 5,
			[theme.breakpoints.up('sm')] : {
				width: 142,
				height: 28,
				marginTop: 11,
				marginRight: 10,
			},
			[theme.breakpoints.up('md')]: {
				marginLeft: 20,
				marginRight: 50,
			}
		},
		searchWrap: {
			marginTop: 6,
			marginRight: 'auto',
			flex: 1,
			transition: `.25s`,
			maxWidth: 48,
			[theme.breakpoints.up('sm')] : {
				paddingRight: 20,
				maxWidth: 300,
			}
		},
		searchWrapFocused: {
			maxWidth: '100%',
			marginRight: 0,
			[theme.breakpoints.up('sm')] : {
				maxWidth: 500,
				marginRight: 'auto',
			}
		},
		rightWrap: {
			transition: `.2s`,
			display: 'flex',
		},
		notificationsWrap: {
			marginTop: 5,
		},
		searchFocusedSide: {
			[theme.breakpoints.down('xs')] : {
				width: 0,
				opacity: 0,
				padding: `0 0 0 0`,
				margin: `0 0 0 0`,
			}
		},
	};
};

class Header extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			searchFocused: false,
		};

		this.onSearchFocus = () => this.setState({ searchFocused: true });
		this.onSearchBlur = () => this.setState({ searchFocused: false });
	}

	render () {
		const {
			authUser,
			classes,
			showLogin,
			menuIsVisible,
			showMenu,
			hideMenu,
		} = this.props;
		const { searchFocused } = this.state;


		return (
			<Fragment>
				<header className={classes.header}>
					<a
						href="/"
						title="ScoreStream - Fan Driven Sports"
						className={clsx({[classes.searchFocusedSide]: searchFocused})}
					>
						<img
							src={logoSrc}
							alt="ScoreStream"
							className={classes.logo}
						/>
					</a>

					<div className={clsx(classes.searchWrap, {
						[classes.searchWrapFocused]: searchFocused,
					})}>
						<OmniSearch
							placeholder={!authUser ? 'Find your team' : undefined}
							onFocus={this.onSearchFocus}
							onBlur={this.onSearchBlur}
							renderInputComponent={renderInputComponent}
							searchModes={authUser ? AUTH_SEARCH_MODES : NO_AUTH_SEARCH_MODES}
						/>
					</div>
					<div className={clsx(classes.rightWrap, {
						[classes.searchFocusedSide]: searchFocused,
					})}>
						{!authUser &&
							<NoAuthNav/>
						}

						{authUser &&
							<div className={classes.notificationsWrap}>
								<NotificationCenter/>
							</div>
						}

						{!authUser &&
							<LoginButton showLogin={showLogin} />
						}

						{!authUser &&
							<SignupButton/>
						}
						<MenuButton
							authUser={authUser}
							showMenu={showMenu}
							hideMenu={hideMenu}
							showLogin={showLogin}
							menuIsVisible={menuIsVisible}
						/>
					</div>
				</header>
				{authUser && <ProfileCompleteCheck authUser={authUser}/>}
			</Fragment>
		);
	}
}
Header.propTypes = {
	classes: PropTypes.object,
	//- passed down from header
	authUser: PropTypes.object,
	showLogin: PropTypes.func,
	menuIsVisible: PropTypes.bool,
	showMenu: PropTypes.func,
	hideMenu: PropTypes.func,
};
const Connected = headerContainer(Header);
export default withStyles(getStyles)(Connected);
