"use strict";
import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from '../toolTip.jsx';
import userContainer from '../../containers/user';
import _ProfileCard from './profileCard.jsx';
const ProfileCard = userContainer(_ProfileCard);

// Why do we need this to make the tooltip not have padding?
const styles = {
	card: {
		margin: '-8px -10px'
	},
};

function ProfileCardPopup (props) {
	const popupContent = (
		<ProfileCard user={props.user} userRank={props.userRank} style={styles.card} />
	);
	return (
		<Tooltip
			{...props.tooltipParams}
			content={popupContent}
		>
			{props.children}
		</Tooltip>
	);
}

ProfileCardPopup.propTypes = {
	children: PropTypes.node.isRequired,
	tooltipParams: PropTypes.object,
	user: PropTypes.object.isRequired,
	userRank : PropTypes.object,
};

export default ProfileCardPopup;
