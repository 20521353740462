"use strict";
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import memoize from 'memoize-one';

const styles = {
	composite: {
		backgroundSize : 'cover',
		backgroundRepeat : 'no-repeat',
		position: 'relative'
	},
	cameraOverlay : {
		color : '#FFF',
		position: 'absolute',
		top : '50%',
		left : '50%',
		opacity : 0.9,
	},
	sizer16x9: {
		width: '100%',
		paddingBottom: '56.25%'
	},
	sizer4x3: {
		width: '100%',
		paddingBottom: '75%'
	},
	sizer1x1: {
		width: '100%',
		paddingBottom: '100%'
	}
};

/**
 * @param composite - composite collection object
 * @returns {x : X% and y : Y% for centering a composite based on area of interest}
 *
 */
function getCompositeBackgroundPosition (composite) {
	//- CSS background position is weird. Its % of pic lines up with % of container
	//- so we have todo some math

	//- Figure out what percentage the height of our window is compared to the total img/vid
	//- we do this assuming the width is 100% and converting to common base of px
	if (!composite.pointOfInterest) {
		return { x : '50%', y : '50%' };
	}

	var pic = (composite.videoCompositeThumbnailUrl) ? {
		width : composite.videoCompositeWidth,
		height : composite.videoCompositeHeight
	} : {
		width : composite.compositePictureWidth,
		height : composite.compositePictureHeight
	};

	//- TODO areaOfInteres
	//- Dont mess with this math, shit is like beautiful mind good
	var r = (pic.width * 0.5625) / pic.height;
	var by = (composite.pointOfInterest.y - (0.5 * r)) / (1 - r);
	by = Math.min(1, Math.max(0, by)); //make sure 'by' is between 0 and 1

	return {
		x: '50%',
		y: `${(by * 100)}%`
	};
}

class Composite extends React.Component {
	constructor (props) {
		super(props);
		this.getCompositeBackgroundPosition = memoize(getCompositeBackgroundPosition);
	}
	render () {
		const props = this.props;
		const composite = props.composite;
		const videoIconSize = props.videoIconSize;
		const style = props.style;

		if (!composite) {
			return <span/>;
		}

		if (!props.inView) {
			return <div style={styles[`sizer${props.aspectRatio}`]}/>;
		}
		// DO THE STYLES FOR THE COMPOSITE ITSELF
		// Area of Interest / URL
		const position = this.getCompositeBackgroundPosition(composite);
		const url = (props.showOverlay || props.isSmall) ?
			composite.compositePictureThumbnailUrl || composite.videoCompositeThumbnailUrl :
			composite.backgroundPictureThumbnailUrl || composite.videoThumbnailUrl;

		const compositeStyle = _.assignIn({}, styles.composite, style, {
			backgroundImage : `url(${url})`,
			backgroundPosition : `${position.x} ${position.y}`,
		});

		// IF THIS IS A VIDEO, CONSTRUCT THE VIDEO ICON OVERLAY
		let iconOverlay;
		if (composite.videoUrl && props.videoIconSize > 0) {
			const containerStyle = _.assignIn({}, styles.cameraOverlay, {
				width: videoIconSize,
				height: videoIconSize,
				marginTop: (videoIconSize / 2 * -1),
				marginLeft: (videoIconSize / 2 * -1),
				fontSize: videoIconSize / 2,
			});

			// THIS is ALL FONT-AWESOME WHICH IS A HARD DEPENDENCY to be included on the page
			iconOverlay = (
				<div style={containerStyle}>
					<span className="fa-stack">
						<i className="fa fa-video-camera fa-stack-1x"></i>
						<i className="fa fa-circle-thin fa-stack-2x"></i>
					</span>
				</div>
			);
		}

		return (
			<div style={compositeStyle}>
				{iconOverlay}
				<div style={styles[`sizer${props.aspectRatio}`]}/>
			</div>
		);
	}
}


Composite.propTypes = {
	composite : PropTypes.object.isRequired,
	videoIconSize : PropTypes.number,
	style : PropTypes.object,
	aspectRatio : PropTypes.oneOf(['16x9', '1x1', '4x3']),
	showOverlay: PropTypes.bool,
	inView: PropTypes.bool,
	isSmall : PropTypes.bool,
};

Composite.defaultProps = {
	videoIconSize : 50,
	aspectRatio : '16x9',
	inView: true,
	isSmall : false,
};

export default Composite;
