import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import withStyles from '@material-ui/core/styles/withStyles';
import MenuList from './menu/menuList.jsx';
import { Connected as ProfilePicture } from '../../../../common/components/users/profilePicture.jsx';

function getStyles() {
	return {
		iconButtonRoot: {
			padding: 10,
			margin: `2px 0`,
		},
		picWrapper: {
			margin: 2,
			padding: `2px 4px`,
			borderRadius: 20,
			transition: `0.2s ease`,
			cursor: 'pointer',
			'&:hover': {
				background: 'rgba(55, 55, 55, .22)'
			}
		}
	};
}

function MenuButton(props) {
	const { menuIsVisible, classes, hideMenu, authUser, showMenu, showLogin } = props;
	return (
		<Fragment>
			{authUser &&
				<div className={classes.picWrapper} onClick={showMenu}>
					<ProfilePicture
						size={36}
						userId={authUser.userId}
					/>
				</div>
			}
			{!authUser &&
				<IconButton
					color="inherit"
					aria-label="menu"
					onClick={showMenu}
					classes={{
						root: classes.iconButtonRoot
					}}
				>
					<MenuIcon/>
				</IconButton>
			}

			<Drawer
				open={menuIsVisible}
				anchor="right"
				onClose={hideMenu}
			>
				<MenuList
					authUser={authUser}
					hideMenu={hideMenu}
					showLogin={showLogin}
				/>
			</Drawer>
		</Fragment>
	);
}
MenuButton.propTypes = {
	menuIsVisible: PropTypes.bool,
	hideMenu: PropTypes.func,
	showMenu: PropTypes.func,
	showLogin: PropTypes.func,
	authUser: PropTypes.object,
	classes: PropTypes.object,
};

export default withStyles(getStyles)(MenuButton);
