import React from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import omit from 'lodash/omit';
import get from 'lodash/get';
import assignIn from 'lodash/assignIn';
import memoize from 'memoize-one';
import routedConnector from '../containers/isRouted';
import * as sharedStyles from './sharedStyles';

function isRouted() {
	// another variant on the hack for distinguishing between components when they are routed or not routed
	return !!document.getElementById('react-root');
}

/**
 * Since we have components that may be mounted in our App with routing, or not this set of HoCs conditional
 * wraps your component in withRouter, the best way to check if will gain access to router props such as history in your component
 * is to check isRouted and history
 * @param Component
 */
export function safeWithRouter(Component) {
	if (isRouted()) {
		return withRouter(Component);
	} else {
		return Component;
	}
}


//this doesn't seem like the correct way to do this
function getClickFunc ({ history, isRouted, to }) {
	return function (evt) {
		// OPen in a new tab if the correct keys were hit (ctrl, option, middle click, etc...)
		if (evt && (evt.ctrlKey || evt.metaKey || (evt.nativeEvent && evt.nativeEvent.which === 2))) {
			// not sure on support of origin... so lets just be safe
			const origin = get(window, ['location', 'origin']);
			if (origin) {
				window.open(origin + to);
			} else {
				window.location = to;
			}
		} else {
			if (isRouted && history) {
				history.push(to);
			} else {
				window.location = to;
			}
		}

		if (evt) {
			evt.preventDefault();
		}
	};
}

const styles = {
	pseudoLink : {
		color : sharedStyles.vars.colors.ssBlue,
		fontWeight : 400,
		cursor : 'pointer',
		':hover' : {
			color : sharedStyles.vars.colors.ssBlueHighlight
		}
	}
};

/**
 * For use when you want a link inside of another interactive element (e.g. an href in a
 */
class ScoreStreamPseudoLink extends React.Component {
	constructor (props) {
		super(props);
		this.getStyle = memoize(styleProp => assignIn({}, styles.pseudoLink, styleProp));
		this.getClickFunc = memoize(props => getClickFunc(props));
	}

	render () {
		return (
			<span
				style={this.getStyle(this.props.style)}
				onClick={this.getClickFunc(this.props)}
			>
				{this.props.children}
			</span>
		);
	}
}

ScoreStreamPseudoLink.propTypes = {
	children: PropTypes.node,
	to : PropTypes.string.isRequired,
	style : PropTypes.object
};
const StyledPseudoLink = Radium(ScoreStreamPseudoLink);
const ConnectedPseudoLink = routedConnector(StyledPseudoLink);
export const PseudoLink = safeWithRouter(ConnectedPseudoLink);

/**
 * Wraps the router Link so we can use our components even outside of our app
 */
function ScoreStreamLink (props) {
	const passDownProps = omit(props, ['to', 'isRouted', 'dispatch', 'innerRef']);
	if (props.isRouted) {
		return (
			<RouterLink
				{...passDownProps}
				to={props.to}
				style={props.style}
				innerRef={props.innerRef}
			>
				{props.children}
			</RouterLink>
		);
	} else {
		return (
			<a
				{...passDownProps}
				href={props.to}
				ref={props.innerRef}
			>
				{props.children}
			</a>
		);
	}
}
ScoreStreamLink.propTypes = {
	to : PropTypes.string.isRequired,
	style : PropTypes.object,
	children: PropTypes.any,
	innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]), /*createRef makes an object, but it could also be a func normal way*/
	isRouted: PropTypes.bool,
};
const StyledLink = Radium(ScoreStreamLink);
const ConnectedLink = routedConnector(StyledLink);
const ReffedLink = (props, ref) => <ConnectedLink {...props} innerRef={ref} />;
export const Link = React.forwardRef(ReffedLink);
