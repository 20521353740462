import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { safeWithRouter } from '../../../../../common/libs/routing';
import Composite from '../../../../../common/components/gamePosts/composite.jsx';
import ProfilePicture from '../../../../../common/components/users/profilePicture.jsx';
import MascotOrVarsityLetter from '../../../../../common/components/teams/mascotOrVarsityLetter.jsx';
import {default as EntitizedText, noLinksConfig } from '../../../../../common/components/entitizedText.jsx';
import { handleApiError } from "../../../../../common/libs/errorHandler";
import { getApi } from '../../../../../common/libs/api';
const ssApi = getApi({name: 'scoreStream'});
const nsApi = getApi({name: 'notificationService'});
import notificationContainer from '../../../../../common/containers/user/notification';
import { notificationFormatter } from '../../../../../common/libs/dateTime/formatters';
import * as sharedStyles from '../../../../../common/libs/sharedStyles';
import { canRoute, getRouteFromUserNotification, loadMobileModal } from '../../../../libs/notificationCenter';


const ICON_SIZE = 50;

const styles = {
	displayUser: {
		marginRight: 3,
	},
};

function getStyles() {
	return {
		container: {
			marginTop: 2,
			marginBottom: 2,
			borderBottom: `1px solid #DDD`,
			color: `#333`
		},
		iconContainer: {
			width: ICON_SIZE,
			height: ICON_SIZE,
			marginRight: 5,
			flex: `0 0 50px`,
		},
		iconGame: {
			width: '100%',
			height: '100%',
			position: 'relative',
		},
		iconGameAway: {
			position: 'absolute',
			top: 0,
			left: 0,
			zIndex: 1,
		},
		iconGameHome: {
			position: 'absolute',
			right: 0,
			bottom: 0,
			zIndex: 2,
		},
		mainContent: {
			marginLeft: 5,
			marginBottom: 2,
			position: 'relative',
			width: '100%',
			display: 'flex',
			flexDirection: 'row',
		},
		textContainer: {
			width: '100%',
			flexGrow: 1,
			fontSize: 13,
			fontWeight: 300,
			/* 25px is for the "details section" which is absolute positioned bottom and is big when it has users in it*/
			/*if we don't like this remove mainContent position relative, and the absolute and bottom position on details */
			marginBottom: 35,
		},
		composite: {
			width: 50,
			height: 50,
			marginLeft: 3,
			marginBottom: 3,
			flex: `0 0 50px`,
			display: 'inline-block',
		},
		// details junk
		details: {
			display: 'flex',
			flexDirection: 'row',
			//ensure its on the bottom in skinny posts
			position: 'absolute',
			bottom: 0,
		},
		displayUsers: {
			verticalAlign: 'middle',
			marginRight: 8,
			paddingRight: 8,
			borderRight: `1px solid ${sharedStyles.vars.colors.ssLighterGray}`,
		},
		displayUsersAdditional: {
			verticalAlign: 'top',
			lineHeight: `20px`,
			color: sharedStyles.vars.colors.ssGray,
			fontWeight: 100,
			fontSize: 10,
		},
		dateTime: {
			fontSize: 12,
			fontWeight: 100,
			color: sharedStyles.vars.colors.ssMediumGray,
			verticalAlign: 'middle',
			lineHeight: `20px`,
		},
		actions: {
			fontSize: 20,
			cursor: 'pointer',
			height: 50,
			padding: `15px 5px`,
			//background : 'darkorange',
			//border : `1px solid #9c0000`,
			flex: `0 0 20px`,
			marginLeft: 5,
		},
		actionsPopup: {
			zIndex: 3,
			position: 'absolute',
			bottom: 10,
			right: 35,
			fontSize: 12,
			width: `100%`,
			padding: `15px 5px`,
			border: `1px solid #BBB`,
			background: 'white',
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-around',
			cursor: 'arrow'
		},
		background: {
			position: 'relative',
			display: 'flex',
			flexDirection: 'row',
			padding: `6px 5px`,
			cursor: 'pointer',
			background: sharedStyles.vars.colors.ssSubtleBlue,
			'&:hover': {
				background: sharedStyles.vars.colors.ssLightestGray,
			},
		},
		backgroundRead: {
			background: '#FFF',
		},
		backgroundHidden: {
			background: '#FFF',
			opacity: 0.5,
			':hover': undefined,
		}
	};
}

function getIconUrlStyle(url) {
	return {
		backgroundImage: `url(${url})`,
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		width : '100%',
		height : '100%',
	};
}

class UserNotificationItem extends React.Component {
	constructor(props) {
		super(props);

		['onClick', 'toggleActions'].forEach((m) => {
			this[m] = this[m].bind(this);
		});

		this.state = {
			actionsVisible : false,
			sendResponse : null,
			forceUnread : props.forceUnread,
			setup : (props.preserveValues) ? false : true,
			isRead : undefined,
			formattedDateTime : undefined,
		};
	}

	setupCheck(props) {
		if (this.state.setup) { return; }

		if (props.userNotification) {
			this.setState({
				setup : true,
				isRead : props.userNotification.isRead,
				formattedDateTime : notificationFormatter(props.userNotification.notificationDateTime),
			});
		}
	}

	componentWillMount () {
		this.setupCheck(this.props);
	}

	componentWillReceiveProps (nextProps) {
		this.setupCheck(nextProps);
	}

	shouldComponentUpdate () {
		return (!this.state.setup || !this.props.preserveValues);
	}

	onClick(evt) {
		evt.preventDefault();
		evt.stopPropagation();

		const { userNotification } = this.props;

		if (this.props.onClick) {
			this.props.onClick(userNotification);
		}

		ssApi.post({
			method : 'users.notifications.click',
			params : {
				userNotificationId : userNotification.userNotificationId,
			},
			callback : (err) => {
				if (err) { return handleApiError(e); }
			}
		});

		if (userNotification.routeUrl) {
			if (userNotification.routeUrl.match(/webNotifications/) || userNotification.displayWebMobileModal) {
				loadMobileModal(userNotification.routeUrl);
			} else {
				window.open(userNotification.routeUrl, '_blank');
			}
			return;
		}
		const route = getRouteFromUserNotification(userNotification);
		if (this.props.isRouted && this.props.history) {
			this.props.history.push(route);
		} else {
			window.location.href = route;
		}
	}

	toggleActions(evt) {
		if (evt) {
			evt.preventDefault();
			evt.stopPropagation();
		}

		this.setState({
			actionsVisible : !this.state.actionsVisible,
		});
	}

	toggleIsRead(userNotificationId, val, evt) {
		evt.preventDefault();
		evt.stopPropagation();

		ssApi.post({
			method: 'users.notifications.update',
			params: _.assignIn({
				userNotificationIds : [userNotificationId],
				isRead: val,
			}),
			callback: (err, r) => {
				if (err) { handleApiError(err); }
				this.toggleActions();
			}
		});
	}

	toggleIsHidden(userNotificationId, val, evt) {
		evt.preventDefault();
		evt.stopPropagation();

		ssApi.post({
			method: 'users.notifications.update',
			params: _.assignIn({
				userNotificationIds : [userNotificationId],
				isHidden: val,
			}),
			callback: (err, r) => {
				if (err) { return handleApiError(err); }
				this.toggleActions();
			}
		});
	}

	sendPushNotification(userNotificationId, evt) {
		evt.preventDefault();
		evt.stopPropagation();

		nsApi.post({
			method : 'pushNotifications.userNotifications.send',
			params : {
				userNotificationIds : [userNotificationId]
			},
			callback : (err, r) => {
				if (err) { return handleApiError(err); }

				console.info(`sent notification`, r)

				this.setState({
					sendResponse: r,
				});
				this.toggleActions();
			}
		});
	}

	renderGameIcon() {
		const {
			classes,
			iconGameAwayTeam, iconGameAwayTeamColor1, iconGameAwayTeamColor2, iconGameAwayTeamMascotPicture,
			iconGameHomeTeam, iconGameHomeTeamColor1, iconGameHomeTeamColor2, iconGameHomeTeamMascotPicture,
		} = this.props;

		const w = Math.round(ICON_SIZE * 0.72);

		return (
			<div className={classes.iconGame}>
				<div className={classes.iconGameAway}>
					<MascotOrVarsityLetter
						width={w}
						team={iconGameAwayTeam}
						color1={iconGameAwayTeamColor1}
						color2={iconGameAwayTeamColor2}
						mascotPicture={iconGameAwayTeamMascotPicture}
						fullSize={true}
					/>
				</div>
				<div className={classes.iconGameHome}>
					<MascotOrVarsityLetter
						width={w}
						team={iconGameHomeTeam}
						color1={iconGameHomeTeamColor1}
						color2={iconGameHomeTeamColor2}
						mascotPicture={iconGameHomeTeamMascotPicture}
						fullSize={true}
					/>
				</div>
			</div>
		);
	}

	render () {
		const {
			classes,
			preserveValues,
			userNotification,
			iconImageUrl,
			iconUser,
			iconTeam, iconTeamColor1, iconTeamColor2, iconTeamMascotPicture,
			iconGame,
			displayUser1,
			displayUser2,
			displayUser3,
			displayUserAdditionalCount,
			displayGamePostComposite,
		} = this.props;

		const {
			setup,
		} = this.state;

		if (!userNotification || !setup) { return null; }
		if (!canRoute(userNotification)) { return null; }
		if (!this.props.showHandOfGodActions && userNotification.isHidden) { return null; }

		const isRead = (preserveValues) ? this.state.isRead : userNotification.isRead;
		const formattedDateTime = (preserveValues) ? this.state.formattedDateTime : notificationFormatter(userNotification.notificationDateTime);

		return (
			<div className={classes.container}>
				<div
					className={clsx(classes.background, {
						[classes.backgroundRead]: isRead,
						[classes.backgroundHidden]: userNotification.isHidden,
					})}
					onClick={this.onClick}
				>
					<div className={classes.iconContainer}>
						{iconImageUrl &&
							<div style={getIconUrlStyle(iconImageUrl)} />
						}
						{iconUser &&
							<ProfilePicture
								user={iconUser}
								size={ICON_SIZE}
							/>
						}
						{iconTeam &&
							<MascotOrVarsityLetter
								width={ICON_SIZE}
								team={iconTeam}
								color1={iconTeamColor1}
								color2={iconTeamColor2}
								mascotPicture={iconTeamMascotPicture}
								fullSize={true}
							/>
						}
						{iconGame &&
							this.renderGameIcon()
						}
					</div>

					<div className={classes.mainContent}>
						<div className={classes.textContainer}>
							<EntitizedText
								text={userNotification.text}
								entities={userNotification.entities}
								entityTypeConfig={noLinksConfig}
							/>

							<div className={classes.details}>
								{displayUser1 &&
									<div className={classes.displayUsers}>
										{displayUser1 && <ProfilePicture user={displayUser1} size={20} style={styles.displayUser} showPopup={true} />}
										{displayUser2 && <ProfilePicture user={displayUser2} size={20} style={styles.displayUser} showPopup={true} />}
										{displayUser3 && <ProfilePicture user={displayUser3} size={20} style={styles.displayUser} showPopup={true} />}
										{displayUserAdditionalCount && <span className={classes.displayUsersAdditional}>+{displayUserAdditionalCount}</span>}
									</div>
								}

								<div className={classes.dateTime}>
									{formattedDateTime}
								</div>
							</div>
						</div>
						{displayGamePostComposite &&
						<div className={classes.composite}>
							<Composite
								aspectRatio="1x1"
								isSmall={true}
								videoIconSize={0}
								showOverlay={false}
								composite={displayGamePostComposite}
							/>
						</div>
						}

						{this.props.showHandOfGodActions &&
							<div className={classes.actions} onClick={this.toggleActions}>︙️</div>
						}

						{this.props.showHandOfGodActions && this.state.actionsVisible &&
						<div
							className={classes.actionsPopup}
							onClick={(evt) => {
								evt.preventDefault();
								evt.stopPropagation();
								return false;
							}}
						>
							<button onClick={this.toggleIsRead.bind(this, userNotification.userNotificationId, !userNotification.isRead)}>
								{userNotification.isRead ? 'Mark Unread' : 'Mark as Read'}
							</button>
							<button onClick={this.toggleIsHidden.bind(this, userNotification.userNotificationId, !userNotification.isHidden)}>
								{userNotification.isHidden ? 'UnHide' : 'Hide'}
							</button>
							<button onClick={this.sendPushNotification.bind(this, userNotification.userNotificationId)}>
								Send Push
							</button>
						</div>
						}
					</div>
				</div>
				{this.props.showHandOfGodActions && this.state.sendResponse &&
					<pre style={{fontSize : 10}}>{JSON.stringify(this.state.sendResponse, null, 1)}</pre>
				}
			</div>
		);
	}
}

UserNotificationItem.propTypes = {
	isRouted: PropTypes.bool,
	userNotification : PropTypes.object.isRequired,
	onClick : PropTypes.func,
	showHandOfGodActions : PropTypes.bool,
	forceUnread : PropTypes.bool,
	preserveValues : PropTypes.bool,
	classes: PropTypes.object,
};

UserNotificationItem.defaultProps = {
	onClick : _.noop,
	showHandOfGodActions : false,
	forceUnread : false,
	preserveValues : true,
};

const Styled = withStyles(getStyles)(UserNotificationItem);
const Connected = notificationContainer(Styled);
export default safeWithRouter(Connected);
