import _ from 'lodash';

export default function cleanAddParams (params) {
	if (!params) { return {}; }

	return _.keys(params).reduce(function (acc, key) {
		// filter out empty strings (text inputs that dont get filled out
		// we dont call add with those because a blank string doesnt meet string validation
		if (_.isString(params[key] && !params[key].length)) {
			return acc;
		}

		acc[key] = params[key];
		return acc;
	}, {});
};