import _ from 'lodash';
import { connect } from 'react-redux';
import * as reduxInfiniteScroll from '../redux/modules/infiniteScroll';
import { actions as collectionActions } from '../redux/actions/collections';

function mapStateToProps(state, ownProps) {
	const {stateKey} = ownProps; //routes.user.media or routes.user.activityCards

	const stateLoc = _.get(state.infiniteScroll, stateKey.path) || {};
	const componentState = (stateLoc.id === stateKey.id) ? stateLoc : {};

	const items = componentState.items || [];
	const total = _.isNumber(componentState.total) ? componentState.total : null;
	const hasMore = (_.isNull(total) || items.length < total);

	return {
		status,
		items,
		total,
		hasMore
	};
}



function mapDispatchToProps(dispatch, ownProps) {
	return {
		receiveCollections: collections => dispatch(collectionActions.receiveCollections(collections)),
		storeState: state => dispatch(reduxInfiniteScroll.actions.storeState({
			stateKey: ownProps.stateKey,
			state: state
		}))
	};
}

export default connect(mapStateToProps, mapDispatchToProps);