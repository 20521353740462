"use strict";
import { createAction, handleActions } from 'redux-actions';
import _ from 'lodash';
import update from 'immutability-helper';

import { getApi } from '../../../libs/api';
import { handleError, handleApiError } from "../../../libs/errorHandler";

const ssApi = getApi({name: 'scoreStream'});

const FETCHING = 'realtime/userNotificationInfo/FETCHING';
const RECEIVE = 'realtime/userNotificationInfo/RECEIVE';

function getInitialState() {
	const info = _.get(window, ['gData', 'authUserUserNotificationInfo']); // singular object, not array

	if (info) {
		return {
			hasReceived : true,
			fetching : false,
			notificationUnreadTotals : info.notificationUnreadTotals,
		};
	} else {
		return {
			hasReceived : false,
			fetching : false,
			notificationUnreadTotals : [],
		};
	}
}

export default handleActions({
	[FETCHING] : (state) => {
		return update(state, {
			fetching : {$set: true},
		});
	},
	[RECEIVE] : (state, action) => {
		const userNotificationInfo = action.payload;
		return {
			fetching : false,
			hasReceived : true,
			notificationUnreadTotals : userNotificationInfo.notificationUnreadTotals,
		};
	},
}, getInitialState());


const actions = {
	fetching : createAction(FETCHING),
	receive : createAction(RECEIVE),
};

export const receiveUserNotificationInfo = actions.receive;

export const getUserNotificationInfo = function getUserNotificationInfo () {
	return function (dispatch, getState) {
		const state = getState();

		if (state.authUser.notificationInfo.fetching) { return console.log('already fetching user notification info'); }
		if (!_.get(state.authUser.user, 'userId')) { return handleError(`need to be logged in to call this method`); }

		dispatch(actions.fetching());

		ssApi.post({
			method : 'users.notifications.info.get',
			params : {},
			callback : (err, r) => {
				if (err) {
					dispatch(actions.receive({ notificationUnreadTotals : [] }));
					return handleApiError(err);
				}

				const userNotificationInfo = _.get(r, ['collections', 'userNotificationInfoCollection', 'list', 0]);
				if (!userNotificationInfo) {
					dispatch(actions.receive({ notificationUnreadTotals : [] }));
					return handleError(`searched userNotificationInfo but didn't get a response`);
				}

				dispatch(actions.receive(userNotificationInfo));
			}
		});
	}
};
