"use strict";
import _ from 'lodash';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { get as getTeam } from '../redux/modules/collections/team';
import { get as getTeamSupplement } from '../redux/modules/collections/teamSupplement';
import { addTeamSubscriptions, removeTeamSubscriptions, getTeamSubscriptions } from '../redux/modules/authUser/teamSubscriptions';

const makeMapStateToProps = () => {
	const getEnhancedTeamObject = createSelector(
		[],
		() => {}
	);
	return function mapStateToProps (state, props) {
		return getEnhancedTeamObject(state, props);
	};
};

function mapStateToProps(state, ownProps) {
	const teamId = ownProps.teamId;
	const team = state.collections.team[teamId];

	let color1, color2, organization, teamSupplement, mascotPicture, backgroundPicture;
	if (team) {
		color1 = state.collections.color[team.color1Id];
		color2 = state.collections.color[team.color2Id];
		organization = state.collections.organization[team.organizationId];
		mascotPicture = state.collections.teamPicture[_.get(team, ['mascotTeamPictureIds', '0'])];
		backgroundPicture = state.collections.teamPicture[_.get(team, ['backgroundTeamPictureIds', '0'])];
		teamSupplement = state.collections.teamSupplement[team.teamId];
	}

	return {
		teamId,
		team,
		color1,
		color2,
		organization,
		mascotPicture,
		backgroundPicture,
		teamSupplement,

		// nomenclatures are normalized the same as user followers so UI pieces like the follow button can work any which way
		canFollow : !!_.get(state, ['authUser', 'user']),
		isFollowing : !!_.get(state, ['authUser', 'teamSubscriptions', 'map', teamId]),
		hasReceivedFollowing : _.get(state, ['authUser', 'teamSubscriptions', 'hasReceived']),
	};
}

function mapDispatchToProps(dispatch, ownProps) {
	const teamId = ownProps.teamId;

	return {
		getTeam: (p) => dispatch(getTeam({
			includeTeamSupplements: p && p.includeTeamSupplements,
			teamIds: [teamId]
		})),
		getTeamSupplement: () => dispatch(getTeamSupplement({teamIds: [teamId]})),

		// nomenclatures are normalized the same as user followers so UI pieces like the follow button can work any which way
		getAuthUserFollowing : () => dispatch(getTeamSubscriptions()),
		follow: ({callback}) => dispatch(addTeamSubscriptions({
			callback,
			teamIds: [teamId]
		})),
		unfollow: ({callback}) => dispatch(removeTeamSubscriptions({
			callback,
			teamIds: [teamId]
		})),
	};
}


export default connect(mapStateToProps, mapDispatchToProps);
