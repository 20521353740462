
/* 
	FILE AUTOMATICALLY GENERATED BY RUNNING npm run build buildCachedData
	DO NOT EDIT THIS FILE
	QUESTIONS: Ask Ryan
*/

module.exports = {
  "collections": {
    "stoppageStatusCollection": {
      "list": [
        {
          "stoppageStatusId": 10,
          "minDisplay": "DLYD",
          "longDisplay": "Delayed",
          "description": "The game has been temporarily suspended. All parties have the intention of resuming the contest within a reasonable period of time. Players, staff, and officials remain on site.",
          "shouldHaveResumeDate": true,
          "isFinalStatus": false,
          "isNoContest": false,
          "shouldRetainScore": true,
          "shouldHaveWinner": false
        },
        {
          "stoppageStatusId": 20,
          "minDisplay": "PPD",
          "longDisplay": "Postponed",
          "description": "The game has been suspended but will still be played at a later date. Postponements ideally come with a date time to resume play. Games can be postponed before the game has begun or during competition.",
          "shouldHaveResumeDate": true,
          "isFinalStatus": false,
          "isNoContest": false,
          "shouldRetainScore": true,
          "shouldHaveWinner": false
        },
        {
          "stoppageStatusId": 30,
          "minDisplay": "CXLD",
          "longDisplay": "Cancelled",
          "description": "The contest has been cancelled, play will not begin or resume. There will be no victor declared and the game will not affect standings in anyway.",
          "shouldHaveResumeDate": false,
          "isFinalStatus": true,
          "isNoContest": true,
          "shouldRetainScore": false,
          "shouldHaveWinner": false
        },
        {
          "stoppageStatusId": 40,
          "minDisplay": "FF",
          "longDisplay": "Forfeited",
          "description": "One team is declared victor by forfeit. If there is a score at the time of forfeit it is not taken into account. Winner and loser both have their records affected.",
          "shouldHaveResumeDate": false,
          "isFinalStatus": true,
          "isNoContest": false,
          "shouldRetainScore": false,
          "shouldHaveWinner": true
        },
        {
          "stoppageStatusId": 50,
          "minDisplay": "CLLD",
          "longDisplay": "Called",
          "description": "The game is prematurally terminated with the current score acting as the final score.",
          "shouldHaveResumeDate": false,
          "isFinalStatus": true,
          "isNoContest": false,
          "shouldRetainScore": true,
          "shouldHaveWinner": true
        },
        {
          "stoppageStatusId": 60,
          "minDisplay": "MRCY",
          "longDisplay": "Mercy Rule",
          "description": "The game is prematurally terminated due in accordance to league rules surrounding the \"Mercy Rule\". The current score acts as the final score of the contest.",
          "shouldHaveResumeDate": false,
          "isFinalStatus": true,
          "isNoContest": false,
          "shouldRetainScore": true,
          "shouldHaveWinner": true
        }
      ]
    }
  }
};
