import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import MediaQuery from 'react-responsive';
import ReactModal from 'react-modal';
import ModalContent from './modal/content.jsx';
import * as sharedStyles from '../libs/sharedStyles';
import getPortalContainer from '../libs/getPortalContainer';

// just stole this from somewhere, does cool transition stuff
import '!!style-loader!css-loader!sass-loader!../scss/modal.scss';

import { trigger } from '../libs/domEvents';

let styles = {
	titleContainer: {
		marginLeft : 10,
		marginBottom : 10,
		minHeight: 20 /* button float rihgt and no title doesnt have any height */
	},
	title : {
		fontSize : 16,
		fontWeight : 100,
		color : sharedStyles.vars.colors.ssDarkGray,
		paddingTop : 5,
	},
	/* keyed based on how react-modal wants it */
	modal : {
		overlay : {
			zIndex: 1050,
			backgroundColor: 'rgba(0, 0, 0, 0.5)',
		},
		content : {
			top : 0,
			left : 0,
			right : 0,
			bottom : 0,
			backgroundColor: '#fff',
			width : '85%',
			margin : "30px auto",
			position : "relative",
		}
	},
};

styles.modalSmall = _.merge({}, styles.modal, {
	overlay : {

	},
	content : {
		width : '100%',
		margin : "20px 0px",
		padding : 10
	}
});

/**
 * Throws warning otherwise, has to do with screen ready accessability
 */
let appEl = document.getElementById('react-root');
if (!appEl) {
	const loginPopup = document.getElementById('loginPopup');
	if (loginPopup && loginPopup.previousElementSibling) {
		appEl = loginPopup.previousElementSibling;
	}

	if (!appEl) {
		appEl = getPortalContainer();
	}
}
ReactModal.setAppElement(appEl);

class Modal extends React.Component {
	constructor (props) {
		super(props);
		this.handleAfterOpen = this.handleAfterOpen.bind(this);
		this.handleRequestClose = this.handleRequestClose.bind(this);
		this.handleScroll = this.handleScroll.bind(this);
	}

	proxyScrollEvents () {
		//this might be a bit hacky
		//fire scroll events from the overlay onto 'window'
		//this is used to close tooltips on scroll when they're rendered in a modal
		if (this._modal) {
			this._modal
				.portal
				.overlay
				.addEventListener('scroll', this.handleScroll);
		}
	}

	removeScrollProxy () {
		if (this._modal && this._modal.portal.overlay) {
			this._modal
				.portal
				.overlay
				.removeEventListener('scroll', this.handleScroll);
		}
	}

	handleAfterOpen () {
		this.proxyScrollEvents();

		if (this.props.onOpen) {
			this.props.onOpen();
		}
	}

	handleRequestClose () {
		this.removeScrollProxy();
		if (this.props.onClose) {
			this.props.onClose();
		}
	}

	handleScroll () {
		trigger(window, 'proxyScroll');
	}

	getChildContext () {
		return {
			isRenderedInModal: true,
		};
	}

	componentWillUnmount () {
		this.removeScrollProxy();
	}

	render() {
		const {
			isOpen,
			children,
			hideClose,
			style,
			title,
			closeOnOverlayClick,
			contentLabel,
		} = this.props;
		const onClose = this.handleRequestClose;

		if (this.context.skipModals) {
			//early exit for subtrees w/ disabled modals
			return null;
		}

		/** If the our MQ matches small, then we load up ReactModal with different styles so there is less padding and stuff **/
		/* XXX TODO would like to do this with a JS variable and create the style object programatically... but this is fine for now */
		return (
			<div>
				<MediaQuery maxWidth={sharedStyles.mq.minWidth.sm}>
					<ReactModal
						style={_.merge({}, ReactModal.defaultStyles, styles.modalSmall, style)}
						isOpen={isOpen}
						onAfterOpen={this.handleAfterOpen}
						onRequestClose={onClose}
						ref={c => this._modal = c}
						shouldCloseOnOverlayClick={closeOnOverlayClick}
						overlayClassName="scorestream_ui"
						contentLabel={contentLabel}
					>
						<ModalContent
							hideClose={hideClose}
							onClose={onClose}
							title={title}
						>
							{children}
						</ModalContent>
					</ReactModal>
				</MediaQuery>
				<MediaQuery minWidth={sharedStyles.mq.minWidth.sm + 1}>
					<ReactModal
						style={_.merge({}, ReactModal.defaultStyles, styles.modal, style)}
						isOpen={isOpen}
						onAfterOpen={this.handleAfterOpen}
						onRequestClose={onClose}
						ref={c => this._modal = c}
						shouldCloseOnOverlayClick={closeOnOverlayClick}
						overlayClassName="scorestream_ui"
						contentLabel={contentLabel}
					>
						<ModalContent
							hideClose={hideClose}
							onClose={onClose}
							title={title}
						>
							{children}
						</ModalContent>
					</ReactModal>
				</MediaQuery>
			</div>
		);
	}
}

Modal.propTypes = {
	children : PropTypes.node,
	hideClose: PropTypes.bool,
	isOpen : PropTypes.bool,
	onClose : PropTypes.func.isRequired,
	closeOnOverlayClick : PropTypes.bool,
	style: PropTypes.object,
	title: PropTypes.string,
	contentLabel : PropTypes.string,
	onOpen: PropTypes.func,
};
Modal.defaultProps = {
	isOpen : false,
	closeOnOverlayClick : true,
	contentLabel : "ScoreStream Modal Window",
};
Modal.contextTypes = {
	skipModals: PropTypes.bool,
};
Modal.childContextTypes = {
	isRenderedInModal: PropTypes.bool,
};
export default Modal;
