import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import throttle from 'lodash/throttle';

import { isInIframe } from '../libs/browsers';

/**
* We've forked the original repo and for now I'm just copying this over...
* Orig: https://github.com/wuakitv/react-component-infinite-scroll
* My Fork: https://github.com/countravioli/react-component-infinite-scroll
*
* This is kind of irrelevant now because now our InfiniteScroll can scroll on parent Element or based on window
*/

/**
 * Note: InfiniteScroll WILL NOT attach scroll handlers when loaded in a frame
 * or when the window is too tall (would just end up calling 'loadMore' a ton right away)
 * B/c of this, most places thaty use infinitescroll should probably also have
 */
class InfiniteScroll extends React.Component {
	attachScrollEvents () {
		const scrollContainer = this.getScrollNode();
		if (scrollContainer === window &&
				(isInIframe() || /**todo- check window height*/false)) {
			return;
		}
		scrollContainer.addEventListener('scroll', this.throttledScroll, false);
		scrollContainer.addEventListener('resize', this.throttledScroll, false);
		this.hasScrollEvents = true;
	}

	removeScrollEvents () {
		const scrollContainer = this.getScrollNode();
		scrollContainer.removeEventListener('scroll', this.throttledScroll, false);
		scrollContainer.removeEventListener('resize', this.throttledScroll, false);
	}

	componentDidMount() {
		this.throttledScroll = throttle(this.onScroll.bind(this), 500);
		this.attachScrollEvents();
	}

	getScrollNode () {
		if (this.props.parentIsScrollingContainer) {
			return ReactDOM.findDOMNode(this).parentNode;
		} else {
			return window;
		}
	}

	componentDidUpdate () {
		if (this.hasScrollEvents) {
			this.throttledScroll();
		}
	}

	componentWillUnmount() {
		this.throttledScroll.cancel();
		this.removeScrollEvents();
	}

	onScroll() {
		const scrollContainer = this.getScrollNode();
		let bottom = 0;

		if (scrollContainer === window) {
			bottom = scrollContainer.scrollY + scrollContainer.innerHeight;
		} else {
			bottom = scrollContainer.scrollTop + scrollContainer.offsetHeight;
		}

		const node = ReactDOM.findDOMNode(this);
		const nodeBottom = node.offsetTop + node.offsetHeight;
		const delta = nodeBottom - bottom;

		if (delta < this.props.threshold) {
			this.props.nextPage();
		}
	}

	render() {
		return this.props.render();
	}
}

InfiniteScroll.propTypes = {
	nextPage: PropTypes.func.isRequired,
	parentIsScrollingContainer : PropTypes.bool,
	render: PropTypes.func.isRequired,
	threshold: PropTypes.number,
};

InfiniteScroll.defaultProps = {
	threshold : 600,
	parentIsScrollingContainer : false, /* by default is the thing we use to scroll / load more elements */
};

export default InfiniteScroll;
