import React from 'react';
import PropTypes from 'prop-types';
import withStyles from "@material-ui/core/styles/withStyles";
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import LoadingIcon from '@material-ui/icons/Loop';
import { vars } from '../../../libs/sharedStyles';

const classStyles = (theme) => {
	return {
		search: {
			position: 'relative',
			width: `100%`,
		},
		searchIcon: {
			width: theme.spacing.unit * 5,
			height: '100%',
			position: 'absolute',
			pointerEvents: 'none',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		inputRoot: {
			color: 'inherit',
			width: '100%',
		},
		inputInput: {
			width: '100%',
			padding: `7px 7px 7px 40px`,
			transition: `.3s`,
			border: 'none',
			backgroundColor: 'transparent',
			[theme.breakpoints.up('sm')] : {
				backgroundColor: vars.colors.ssDarkBlue,
				border: `1px solid ${vars.colors.ssDarkerBlue}`,
				borderRadius: 11,
			},
			'&:hover': {
				backgroundColor: vars.colors.ssDarkerBlue,
			},
			'&:focus': {
				backgroundColor: vars.colors.ssDarkerBlue,
			},
			'&::placeholder' : {
				opacity: 0,
				[theme.breakpoints.up('sm')] : {
					opacity: .8,
				},
				fontSize: 14,
				fontWeight: 100,
				color: 'white',
			}
		},
		iconSpin: {
			animation: 'ss-header-omnisearch-spin 1.5s infinite linear',
			// Backward compatible logic between JSS v9 and v10.
			// To remove with the release of Material-UI v4
			animationName: 'ss-header-omnisearch-spin',
		},
		'@keyframes ss-header-omnisearch-spin': {
			'0%': {transform: 'rotate(0deg)'},
			'100%': {transform: 'rotate(360deg)'}
		}
	};
};

function OmniSearchDefaultInput(props) {
	const {classes, isSearching, ...rest} = props;

	return (
		<div className={classes.search}>
			<div className={classes.searchIcon}>
				{!isSearching && <SearchIcon/>}
				{isSearching && <LoadingIcon
					classes={{
						root: classes.iconSpin
					}}
				/>}
			</div>

			<InputBase
				{...rest}
				classes={{
					root: classes.inputRoot,
					input: classes.inputInput
				}}
			/>
		</div>
	);
}

OmniSearchDefaultInput.propTypes = {
	isSearching: PropTypes.bool,
	//value: PropTypes.string,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	classes: PropTypes.object,
};
OmniSearchDefaultInput.defaultProps = {
	placeholder: "Search...",
};

const Styled = withStyles(classStyles)(OmniSearchDefaultInput);
const Input = React.memo(Styled);

export default Input;

export function renderInputComponent(props) {
	const { omniSearchProps, ...rest} = props;
	// ref hack for react-autosuggest > react-autowhatever since we aren't setting the ref property on the inputElement,
	// this will pass it through to the input element mounted by the material-ui compononent
	return (
		<Input
			{...rest}
			isSearching={omniSearchProps.isSearching}
			ref={undefined}
			inputRef={props.ref}
		/>
	);
}
renderInputComponent.propTypes = {
	omniSearchProps: PropTypes.object,
	ref: PropTypes.func,
};
