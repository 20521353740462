import createAction from 'redux-actions/es/createAction';
import handleActions from 'redux-actions/es/handleActions';

const SET_MENU_IS_VISIBLE = 'app/SET_MENU_IS_VISIBLE';

export const actions = {
	setMenuIsVisible: createAction(SET_MENU_IS_VISIBLE),
};

export default handleActions({
	[SET_MENU_IS_VISIBLE]: (state, action) => ({
		...state,
		menuIsVisible: action.payload,
	}),
}, {
	menuIsVisible: false,
});

export const setMenuIsVisible = actions.setMenuIsVisible;