/*global ga:false,window:false */
"use strict";
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';

/**
* Tracking uses globally defined window.ga google analytics
* doesn't fire pageview the first time because that happens auto-magically
* then whenever the location changes from the router, we fire page views
*/
class Tracking extends React.Component {
	constructor (props) {
		super(props);

		this.location = null;
		this.first = true;
	}

	_check (props) {
		if (props.location !== this.location) {
			this.location = props.location;

			// first time we get a location change we dont do anything because that is the
			// first page load and the regular google analytics thingy handles that.
			if (this.first) {
				console.log("First page load, don't send tracking for " + this.location.pathname);
				this.first = false;
				return;
			}

			if (window && window.ga) {
				// TODO... should it work the same way when someone hits "back"
				ga('send', 'pageview', this.location.pathname);
			} else {
				console.error("Using Tracking but ga() is not defined. Make sure you have the google analytics source required and setup");
			}
		}
	}

	componentWillMount () {
		this._check(this.props);
	}

	componentWillReceiveProps (nextProps) {
		this._check(nextProps);
	}

	render () {
		return null;
	}
}

Tracking.propTypes = {
	location: PropTypes.object,
};

export default withRouter(Tracking);
