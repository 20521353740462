'use strict';
import sweetAlert from './sweetAlert';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import noop from 'lodash/noop';
import get from 'lodash/get';

export function confirm(p) {
	const callback = p.callback || noop;
	const onCancel = p.onCancel || noop;

	//console.log("THINS TO CONFIRM", p);

	sweetAlert({
		title: p.title || 'Are you sure?',
		text: p.msg || p.message || 'Do you want to proceed?',
		type: 'warning',
		showCancelButton: true,
		confirmButtonText: p.confirmButtonText || 'Yes, do it',
		cancelButtonText: p.cancelButtonText || 'Cancel',
	}).then(
		() => callback(),
		() => onCancel()
	).catch(noop);
}

/**
 * Sweet Alert error style
 * @param p
 * @param {string} p.title
 * @param p.error - error object, array or string
 * @param {number} [p.timer]
 */
export function errorAlert(p) {
	const { title, error, timer = 2000 } = p;

	let errorString;
	let errorHtml;

	if (isArray(error)) {
		errorHtml = '';
		error.forEach(function (err) {
			let s = err;
			if (isObject(err)) {
				if (err.message) { 
					s = err.message;
				} else {
					s = JSON.stringify(err);
				}
			}
			errorHtml += s + '<br/>';
		});
	} else if (isObject(error)) {
		if (error.message) { 
			errorString = error.message;
		} else {
			errorString = JSON.stringify(error);
		}
	} else {
		errorString = error;
	}	

	const sweetParams = {
		title,
		html: errorHtml,
		text: errorString,
		type: 'error',
	};

	if (timer) {
		sweetParams.timer = timer;
	}

	sweetAlert(sweetParams)
		.catch(noop);
}

export function errorHandler(err, options) {
	if (!err) { return; }

	errorAlert({
		title: get(options, ['title'], 'We had a Problem'),
		error: err
	});
}
