const MASCOT_SIZES = [35, 44, 90, 135, 300, 720, 1280];
const BACKGROUND_SIZES = [480, 720, 1200, 2000];

/**
 * Look over the possible sizes for the type of team picture and return the most appropriate version (smallest file size)
 * @param {object} teamPicture - teamPicture collection object
 * @param {number} size - describes the max in one direction a picture is adjusted to, should be treated as width basically
 * @param {object} [options] - Adjust the behavior of the routine
 * @param {boolean} [options.dpiAdjust] - Used if the picture is "important" adjusts the target size based on the device DPI
 * @returns {*}
 */
export function getUrlForSize(teamPicture, size, options) {
	if (!teamPicture) { return ''; }

	let targetSize = size;
	if (options && options.dpiAdjust && window.devicePixelRatio) {
		targetSize = size * Math.round(window.devicePixelRatio);
	}

	const sizes = (teamPicture.type === 'mascot') ? MASCOT_SIZES :
		(teamPicture.type === 'background') ? BACKGROUND_SIZES : [];


	let url = '';
	for (let i = 0; i < sizes.length; i++) {
		if (!url && (sizes[i] >= targetSize || i === sizes.length)) {
			url = teamPicture[`max${sizes[i]}Url`] || '';
		}
	}

	if (!url && teamPicture.thumbnailUrl) {
		return teamPicture.thumbnailUrl;
	}

	return url;
}