import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';

import UserNotificationItem from './item.jsx';
import Paginator from '../../../../../common/components/paginator.jsx';
import * as sharedStyles from '../../../../../common/libs/sharedStyles';


const styles = {
	loading : {
		padding : `8px 0px`,
		textAlign : 'center',
		color : sharedStyles.vars.colors.ssLighterGray,
		fontSize : 16,
		fontWeight : 100,
		lineHeight : `24px`,
	},
	generic : {
		padding : `24px 12px`,
		textAlign : 'center',
		color : sharedStyles.vars.colors.ssGray,
		fontSize : 16,
		fontWeight : 100,
		lineHeight : `24px`,
	},
};


function messageRenderer ({ fetching, hasMore, items, ...props }) {
	if (fetching) {
		return (
			<div {...props} style={styles.loading}>
				<div><FontAwesome name="comment" spin={true}/></div>
				<div>loading more...</div>
			</div>
		);
	} else if (hasMore) {
		return !!props.onClick && (
			<div {...props} style={styles.generic}>
				<div><FontAwesome name="comment"/></div>
				<div>Load More</div>
			</div>
		);
	} else if (items.length > 0) {
		return (
			<div {...props} style={styles.generic}>
				<div><FontAwesome name="comment"/></div>
				<div>No More Notifications</div>
			</div>
		);
	} else if (items.length === 0) {
		return (
			<div {...props} style={styles.generic}>
				<div><FontAwesome name="comment-o"/></div>
				<div>No Notifications</div>
			</div>
		);
	}
}

const STATE_KEY = {id: 'authUserNotifications', path: 'authUserNotifications'};

class UserNotificationList extends React.PureComponent {
	constructor(props) {
		super(props);

		this.renderFunc = this.renderFunc.bind(this);
		this.renderItem = this.renderItem.bind(this);
	}
	renderItem(userNotificationId, i) {
		const {
			onNotificationClick,
			preserveValues,
		} = this.props;

		return (
			<UserNotificationItem
				key={i}
				userNotificationId={userNotificationId}
				onClick={onNotificationClick}
				preserveValues={preserveValues}
			/>
		);
	}

	renderFunc(p) {
		return p.items.map(this.renderItem);
	}

	render () {
		const {
			count,
			parentIsScrollingContainer,
			onData,
		} = this.props;

		return (
			<Paginator
				method="users.notifications.search"
				onData={onData}
				count={count}
				stateKey={STATE_KEY}
				messageRenderer={messageRenderer}
				renderFunc={this.renderFunc}
				parentIsScrollingContainer={parentIsScrollingContainer}
			/>
		);
	}
}

UserNotificationList.propTypes = {
	initialUnreadCount : PropTypes.number,
	count : PropTypes.number,
	onNotificationClick : PropTypes.func,
	parentIsScrollingContainer : PropTypes.bool,
	onData : PropTypes.func,
	preserveValues : PropTypes.bool,
};

UserNotificationList.defaultProps = {
	count : 50,
	onNotificationClick : _.noop,
	parentIsScrollingContainer : false,
	onData : _.noop,
};

export default UserNotificationList;
