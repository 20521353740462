/* globals process:false */
"use strict";
import assignIn from 'lodash/assignIn';
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';

import * as versions from '../../common/redux/versions';
import reducers from './modules';

const loggerMiddleware = createLogger({collapsed : true});
const rootReducer = combineReducers(assignIn({}, reducers));

let store;

export default function configureStore (initialState) {
	if (store) {
		console.warn("Redux store should probably be a singleton, but we're creating a new one. Something is probably wrong here.");
	}
	let middleware = [thunkMiddleware];
	if (process.env.NODE_ENV === 'development') {
		middleware = [...middleware, loggerMiddleware];
	}
	const finalCreateStore = compose(
		applyMiddleware(...middleware),
		window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
	)(createStore);
	store = finalCreateStore(rootReducer, initialState);

	if (module.hot) {
		module.hot.accept('./modules', () => {
			const nextReducer = require('./modules').default;
			store.replaceReducer(nextReducer);
		});
	}

	// the right way is to have every module that cares about an org, sport, etc.. be able to call
	// the get collection item API for that item if we dont have it
	setTimeout(function () {
		versions.ensureVersions(store);
	}, 1000);

	return store;
}

export function getStore () {
	return store;
}
