
/* 
	FILE AUTOMATICALLY GENERATED BY RUNNING npm run build buildCachedData
	DO NOT EDIT THIS FILE
	QUESTIONS: Ask Ryan
*/

module.exports = {
  "colors": 4,
  "levelsOfPlay": 3394,
  "gameSegments": 7,
  "scorePhraseGenerator_v1": 16,
  "stoppageStatuses": 3,
  "teamTiers": 848,
  "sports": 6,
  "gameSegmentTypes": 2,
  "scoringOptions": 1,
  "userRanks": 1,
  "trustLevels": 1
};
