export const getLocationString = function getLocationString(obj) {
	obj = obj || {};
	const city = obj.city;
	const state = obj.state;
	const country = obj.country;

	if (country && country !== "US") {
		if (city && state) {
			return `${city}, ${state} ${country}`;
		} else if (state) {
			return `${state} - ${country}`;
		} else if (city) {
			return `${city} - ${country}`;
		}
	} else {
		if (city && state) {
			return `${city}, ${state}`;
		} else if (state) {
			return state;
		} else if (city) {
			return city;
		}
	}

	return "";
};