// a bit of a cyclical refereence, actions is undefined at the time this loads up so we get it below
import _ from 'lodash';

import { getApi } from '../../libs/api';
import { handleApiError } from "../../libs/errorHandler";
import { actions as collectionActions } from '../actions/collections';

const fetchingHashes = {};
const queueTimers = {};
const callbacksPerKey = {};

function getFetchingHash(key) {
	if (!fetchingHashes[key]) {
		fetchingHashes[key] = {};
	}

	return fetchingHashes[key];
}

function getQueueTimer(key) {
	if (!queueTimers[key]) {
		queueTimers[key] = null;
	}

	return queueTimers[key];
}

function addQueueCallback(key, callback) {
	if (!callback) { return; }

	if (!callbacksPerKey[key]) {
		callbacksPerKey[key] = [];
	}

	callbacksPerKey[key].push(callback);
}

function getCallbacks(key) {
	const ret = callbacksPerKey[key];
	if (!ret) { return []; }

	delete callbacksPerKey[key];

	return ret;
}

function debug() {
	return;
}

/**
 * Perform a delayedGet which queues up all requested Ids for a short period of time, collapsing the request and then dispatching and processing all of them at once
 * E.G. 10 game cards mount and all need their games, all cards probably make a request for just their game. This collapses it all together
 * @param p.key {String} - Just for storage, should be used once per implementation.
 * @param p.method {String} - Api Method
 * @param p.idsKey {String} - The string that is the key for the ids for this collections teamIds, sportIds, gamePostIds, etc...
 * @param p.ids {Array} - Array we are requesting... commonly jsut one id
 * @param [p.api] {String} - defaults to scoreStream, but can be used to request to different micros
 * @param [p.extraParams] {Object} - stuff anything else in the request you might need, should be unique per 'key' e.g. "compositesOnly" : true to implement delayed get for pics
 * @returns {Function}
 */
export default function delayedGet (p) {
	const {
		api = 'scoreStream',
		extraParams,
		ids,
		idsKey,
		key,
		method,
		callback,
	} = p;
	const idsReceiveKey = p.idsReceiveKey || idsKey; //if we are doing a get for something like a status/supplement we get with the parentId, however the result collection ids that is important is for that status or supplement

	return dispatch => {
		let fetchingHash = getFetchingHash(key);
		let queueTimer = getQueueTimer(key);
		addQueueCallback(key, callback);

		const newIds = ids.filter(function (id) {
			if (!fetchingHash[id]) {
				fetchingHash[id] = id;
				return true;
			} else {
				return false;
			}
		});

		if (!newIds.length) { return debug(`No new ids to fetch ${ids} / ${newIds}`); }

		// if we already have a pending timer, just chill
		if (queueTimer) { return debug(`[OK] Already have a queueTimer for ${key} ${method} ${queueTimer} adding ${idsKey}:${newIds}`); }

		debug(`+++ CREATE QUEUE TIMER FOR ${key} ${idsKey}:${newIds}`);

		// set the timer to fetch
		const newTimer = setTimeout(function () {
			queueTimers[key] = null;
			const callbacks = getCallbacks(key);

			const apiRequester = getApi({name: api});
			const idsToFetch = _.values(fetchingHash);

			debug(`Delayed get request for ${method} ${idsKey}:${idsToFetch}`);

			apiRequester.post({
				method,
				params: _.assignIn({}, extraParams, {
					[idsKey]: idsToFetch
				}),
				callback : function (err, result) {
					if (err) {
						if (callback) {
							return callback(err);
						} else {
							return handleApiError(err);
						}
					}

					dispatch(collectionActions.receiveCollections(result.collections));

					result[idsReceiveKey].forEach(resultId => delete fetchingHash[resultId]);

					idsToFetch.forEach((id) => {
						delete fetchingHash[id];
					});

					callbacks.forEach((callback) => {
						return callback(null, result);
					});
				}
			});
		}, 25);

		debug(`Created new timer ${newTimer} for ${key}`);
		queueTimers[key] = newTimer;
	};
}