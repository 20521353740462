'use strict';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

const _styles = {
	logoWrapper : {
		position: 'relative',
	},
	logoInner : {
		width : '100%',
		height : '100%',
		backgroundSize : 'contain',
		backgroundPosition : 'center',
		backgroundRepeat : 'no-repeat',
	},
	logoImg : {
		width : '100%',
		height : '100%',
		opacity : 0,
	},
	//- takes up the correct spacign
	varsitySizer : {
		position: 'relative',
	},
	//- shifts the varsity letter around inside the sizer
	varsityWrapper : {
		textAlign: 'center',
	},
	varsityOuter : {
		color : '#000',
		fontFamily: 'collegiatessoutsideregular',
		textShadow: '0 0 0 #282828',
		position: 'absolute'
	},
	varsityInner : {
		color : '#FFF',
		fontFamily: 'collegiatessinsideregular',
		position: 'absolute'
	},
};

function VarsityLetter (props) {
	const {letter, color1, color2, width, style, fullSize} = props;

	const fontSize = Math.round(width * 1.1);
	const boxSize = width;

	//- Special case our W and M
	const isThinner = (letter === 'W');
	const isThin = (letter === 'M');
	const thin = {
		marginLeft: Math.round(width * -.06),
		transform: 'scale(.95 , 1)',
	};
	const thinner = {
		marginLeft: Math.round(width * -.12),
		transform: 'scale(.7 , 1)',
	};
	const thinStyle = (isThinner) ? thinner : (isThin) ? thin : {};

	//- We dont want white outlines
	//- Clone because we change color which effects things in the stack (trust me it does)
	const outerColor = _.clone(color2);
	if (color2.colorId === 1000) {
		outerColor.r = 210; 
		outerColor.g = 210; 
		outerColor.b = 210; 
	}

	//- Added for the new sportfx
	const marginTop = (fullSize) ? '-10%' : '-3px';

	const styles = {
		sizer: _.assignIn({}, _styles.varsitySizer, style, {
			height: boxSize,
			width: boxSize
		}),
		wrapper: _.assignIn({}, _styles.varsityWrapper, thinStyle, {
			fontSize: fontSize,
			lineHeight : `${fontSize}px`,
			marginTop : marginTop,
			height: boxSize,
			width: boxSize,
		}),
		outer: _.assignIn({}, _styles.varsityOuter, {
			color: `rgb(${outerColor.r}, ${outerColor.g}, ${outerColor.b})`,
			height: boxSize,
			width: boxSize
		}),
		inner: _.assignIn({}, _styles.varsityInner, {
			color: `rgb(${color1.r}, ${color1.g}, ${color1.b})`,
			height: boxSize,
			width: boxSize
		})
	};

	return (
		<div style={styles.sizer}>
			<div style={styles.wrapper}>
				<div style={styles.outer}>{letter}</div>
				<div style={styles.inner}>{letter}</div>
			</div>
		</div>
	);
}

VarsityLetter.propTypes = {
	width : PropTypes.number.isRequired,
	letter : PropTypes.string.isRequired,
	color1 : PropTypes.object,
	color2 : PropTypes.object,
	style : PropTypes.object,
	fullSize : PropTypes.bool,
};

VarsityLetter.defaultProps = {
	width: 50
};

export default VarsityLetter;
