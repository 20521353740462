import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { StyleRoot } from 'radium';
import Dialog from '@material-ui/core/Dialog';
import { safeWithRouter } from "../../common/libs/routing";
import { MODAL_TYPES } from "../../common/redux/modal";
import connector from '../containers/modalRoot';
import Modal from '../../common/components/modal.jsx';
import Loading from '../../common/components/loading.jsx';
import Messages from '../app/components/messages.jsx';

//lazy load modal contents when they're used
const AuthModal = React.lazy(() => import(
	/* WebpackChunkName: "authModal" */
	'./modalRoot/authModal.jsx'
));
const ReportGameModal = React.lazy(() => import(
	/* WebpackChunkName: "reportGameModal" */
	'./modalRoot/reportGameModal.jsx'
));
const ReportGamePostModal = React.lazy(() => import(
	/* WebpackChunkName: "reportGamePostModal" */
	'./modalRoot/reportGamePostModal.jsx'
));
const ReportUserModal = React.lazy(() => import(
	/* WebpackChunkName: "reportUserModal" */
	'./modalRoot/reportUserModal.jsx'
));
const GamePostFullPage = React.lazy(() => import(
	/* WebpackChunkName: "gamePostModal" */
	'./modalRoot/gamePostModal.jsx'
));
const GameVenue = React.lazy(() => import(
	/* WebpackChunkName: "gameVenueModal" */
	'./gameVenue/main.jsx'
));
const GameVenueManagement = React.lazy(() => import(
	/* WebpackChunkName: "gameVenueMgmtModal" */
	'./modalRoot/gameVenueManagementModal.jsx'
));

const styles = {
	loading: {
		textAlign: 'center',
		padding: 50,
	}
};

/*
 these are modals that can be triggered through various actions,
 if you have a modal that might be opened by various components and you
 don't want to render it repeatedly, you can add it here and listen for an
 open action from the store to trigger the central renderer
 see: http://stackoverflow.com/a/35641680
 */
const MODAL_COMPONENTS = { //maps action types to implementations
	[MODAL_TYPES.AUTH_MODAL]: AuthModal,
	[MODAL_TYPES.REPORT_GAME]: ReportGameModal,
	[MODAL_TYPES.REPORT_GAME_POST]: ReportGamePostModal,
	[MODAL_TYPES.REPORT_USER]: ReportUserModal,
	[MODAL_TYPES.GAME_POST]: GamePostFullPage,
	[MODAL_TYPES.GAME_VENUE]: GameVenue,
	[MODAL_TYPES.GAME_VENUE_MANAGEMENT]: GameVenueManagement,
};

const DIALOG_OVERRIDES = {
	[MODAL_TYPES.AUTH_MODAL]: 1,
};

class ModalRoot extends React.Component {
	constructor (props) {
		super(props);
		this.handleClose = this.handleClose.bind(this);
	}

	componentWillMount() {
		/** This is a backwards compat hack for old pages, show login popup is not part of old code anymore */
		window.ssShowLoginPopup = (p) => {
			const params = p || {};
			const { forceLogin } = params;

			this.props.showModal({
				modalType: MODAL_TYPES.AUTH_MODAL,
				modalProps: {
					disableBackdropClick: forceLogin,
					disableEscapeKeyDown: forceLogin,
				},
				contentProps: {
					forceLogin,
				}
			});
		};
	}

	componentWillUnmount() {
		delete window.ssShowLoginPopup;
	}

	handleClose () {
		const { hideModal, modalProps = {} } = this.props;
		const onClose = modalProps.onClose || hideModal;
		onClose();
	}

	render () {
		const {
			modalType,
			modalProps = {},
			contentProps = {},
			// on defined if routed
			// had to move this here because withRouter doesn't trigger reflows if there is a shouldComponentUpdate somewhere in the tree
			// think it has to do with this : https://github.com/ReactTraining/react-router/issues/5037
			location,
		} = this.props;

		const isOpen = !!(modalType && MODAL_COMPONENTS[modalType]);

		if (!isOpen) { return null; }

		const SpecificModal = MODAL_COMPONENTS[modalType];

		if (DIALOG_OVERRIDES[modalType]) {
			return (
				<Dialog
					open={true}
					onClose={this.handleClose}
					onRendered={modalProps.onOpen}
					{...modalProps}
				>
					<Suspense fallback={<Loading style={styles.loading}/>}>
						<SpecificModal
							{...contentProps}
							location={location}
							onClose={this.handleClose}
						/>
						{/* WE IMPORT MESSAGES SO SNACKBARS WILL SHOW UP ON TOP OF OUR MODAL AND NOT BELOW */}
						<Messages />
					</Suspense>
				</Dialog>
			);
		} else {
			return (
				<StyleRoot>
					<Modal
						isOpen={true}
						{...modalProps}
						onClose={this.handleClose}
					>
						<Suspense fallback={<Loading style={styles.loading}/>}>
							<SpecificModal
								{...contentProps}
								location={location}
								onClose={this.handleClose}
							/>
							{/* WE IMPORT MESSAGES SO SNACKBARS WILL SHOW UP ON TOP OF OUR MODAL AND NOT BELOW */}
							<Messages />
						</Suspense>
					</Modal>
				</StyleRoot>
			);
		}
	}
}
ModalRoot.propTypes = {
	contentProps: PropTypes.object,
	hideModal: PropTypes.func,
	showModal: PropTypes.func,
	modalType: PropTypes.string,
	modalProps: PropTypes.shape({
		onClose: PropTypes.func,
		onOpen: PropTypes.func,
		// any other props to pass to the Modal or Dialog Component
	}),
};
export default safeWithRouter(connector(ModalRoot));
