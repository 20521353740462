"use strict";
import _ from 'lodash';
import delayedGet from '../../helpers/delayedGet';
import { handleApiError } from "../../../libs/errorHandler";
import { getApi } from '../../../libs/api';
const api = getApi({name: 'scoreStream'});
import * as infiniteScrollRedux from '../infiniteScroll';
import * as userNotificationInfoxRedux from '../authUser/notificationInfo';

export const getInitialState = function getInitialState() {
	return _.keyBy(_.get(window, ['gData', 'redux', 'collections', 'userNotificationCollection', 'list'], []), 'userNotificationId');
};

export const propsToDiff = ['isRead', 'isHidden', 'text', 'route', 'subRoute', 'notificationDateTime'];

export const get = function getUserNotifications (p) {
	return delayedGet({
		key: 'userNotification',
		method: 'users.notifications.get',
		idsKey: 'userNotificationIds',
		ids: p.userNotificationIds
	});
};

export const updateAllIsRead = function updateAllIsRead () {
	return function (dispatch, getState) {
		api.post({
			method: 'users.notifications.updateAll',
			params: {
				isRead: true,
			},
			callback: (err) => {
				if (err) { return handleApiError(err); }

				userNotificationInfoxRedux.receiveUserNotificationInfo({
					notificationUnreadTotals : []
				});
			}
		});
	}
};

export const updateAllIsHidden = function updateAllIsHidden () {
	return function (dispatch) {
		api.post({
			method: 'users.notifications.updateAll',
			params: {
				isHidden: true,
			},
			callback: (err) => {
				if (err) { return handleApiError(err); }

				userNotificationInfoxRedux.receiveUserNotificationInfo({
					notificationUnreadTotals : []
				});

				dispatch(infiniteScrollRedux.clear({stateKey : {id : 'authUserNotifications', path : 'authUserNotifications'}}));
			}
		});
	}
};

