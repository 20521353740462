'use strict';
import moment from 'moment';

export const minDurationAgo = function minDurationAgo(timestamp) {
	if (!timestamp) {
		return "";
	}

	var now = moment().utc();
	var then = moment(timestamp + 'Z');

	if (!timestamp || !now || !then) { return ""; }

	var diff = now.diff(then);
	var dur = moment.duration(diff);

	var years = dur.years();
	var months = dur.months();
	var days = dur.days();
	var hours = dur.hours();
	var minutes = dur.minutes();

	if (years > 0) {
		const yrs = (years === 1) ? 'year' : 'years';
		return `${years} ${yrs}`;
	} else if (months > 0) {
		const mnth = (months === 1) ? 'month' : 'months';
		return `${months} ${mnth}`;
	} else if (days > 0) {
		const dys = (days === 1) ? 'day' : 'days';
		return `${days} ${dys}`;
	} else if (hours > 0) {
		const hrs = (hours === 1) ? 'hour' : 'hours';
		return `${hours} ${hrs}`;
	} else if (minutes > 0) {
		const mns = (minutes === 1) ? 'minute' : 'minutes';
		return `${minutes} ${mns}`;
	} else {
		return "Just Now!";
	}
};

const defaultFormat = "MMMM DD, YYYY hh:mm A";
const notificationCalendar = {
	nextDay : defaultFormat,
	nextWeek : defaultFormat,
	lastWeek : defaultFormat,
	sameElse : defaultFormat,
};

export const notificationFormatter = function minDurationAgo(timestamp) {
	if (!timestamp) {
		return "";
	}

	const now = moment().utc();
	const then = moment.utc(timestamp);

	if (!now || !then) { return ""; }

	const diffInHours = now.diff(then, 'hours');

	if (diffInHours < 20) {
		if (diffInHours > 0) {
			return `${diffInHours} hours ago`;
		} else {
			const diffInMinutes = now.diff(then, 'minutes');

			if (diffInMinutes > 0) {
				return `${diffInMinutes} minutes ago`;
			} else {
				return "Just now!";
			}
		}
	} else {
		return then.local().calendar(null, notificationCalendar);
	}
};
