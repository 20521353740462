import { actions as collectionActions } from '../actions/collections';
import { handleApiError } from "../../libs/errorHandler";

/**
 * Utility method for common handling API responses from redux actions (like updating a game, etc...)
 * @param dispatch {Function}- pass through the dispatch function from our action
 * @param callback {Function} - pass through what is most likely an optional callback (add a cheer, maybe the UI cares maybe not
 * @param [meta] {Object} - meta like forceMerge, forceSet for the receiveCollections reducer
 * @returns {Function}
 */
export default function genericActionCallback(dispatch, callback, meta) {
	return function (err, result) {
		if (err && callback) {
			return callback(err);
		}

		if (err && !callback) {
			return handleApiError(err);
		}

		// Most actions are going to result in a payload of collections make sure our stuff is up to date
		// Force merge by default
		if (result && result.collections) {
			dispatch(collectionActions.receiveCollections(result.collections, meta));
		}

		if (callback) {
			return callback(null, result);
		}
	};
};
