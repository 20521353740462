import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import uniq from 'lodash/uniq';
import waterfall from 'async/waterfall';
import pick from 'lodash/pick';

import { getApi } from '../../../libs/api';
const api = getApi({name: 'scoreStream'});
import { actions as collectionActions } from '../../actions/collections';


export function getInitialState() {
	const teams = get(window, 'gData.redux.collections.teamSubscriptionCollection.list', []);
	return keyBy(teams, 'teamSubscriptionId');
}

//wraps get and receive
let fetchingHash = {};
let queueTimer = null;
let queue = [];
function getTeamSubscriptions ({ teamIds, addUsersToStore }) {
	return dispatch => {
		// filter out ones we are already fetching
		teamIds = teamIds.filter(teamId => !fetchingHash[teamId]);
		if (!teamIds.length) { return; }

		queue = queue.concat(teamIds);
		if (queueTimer) { return; }
		queueTimer = window.setTimeout(() => {
			queueTimer = null;
			waterfall([
				callback => {
					const teamIds = uniq(queue);
					queue = [];
					api.post({
						method: 'teams.subscriptions.search',
						params: {teamIds},
						callback
					});
				}
			], (err, result) => {
				if (err) {
					dispatch({
						type: 'collections/RECEIVE',
						error: true,
						payload: err
					});
				} else {
					const collections = pick(result.collections, [
						'teamSubscriptionCollection'
					]);
					//team pages really don't need to cache every user that comes back,
					//but game pages do, perhaps this is unnecessary and we should just
					// always cache, but for nw it's optional
					if (addUsersToStore) {
						collections.userCollection = result.collections.userCollection;
					}
					dispatch(collectionActions.receiveCollections(collections));
				}
				teamIds.forEach(teamId => delete fetchingHash[teamId]);
			});
		}, 20);
	};
}
export { getTeamSubscriptions as get };
export const propsToDiff = ['subscribedUserIds'];
