"use strict";
import update from 'immutability-helper';
import { createAction, handleActions } from 'redux-actions';
import moment from 'moment';
import series from 'async/series';
import parallel from 'async/parallel';
import isUndefined from 'lodash/isUndefined';
import uniq from 'lodash/uniq';
import get from 'lodash/get';
import noop from 'lodash/noop';
import assignIn from 'lodash/assignIn';
import sweetAlert from 'sweetalert2';

import { getApi } from '../../../../common/libs/api';
const api = getApi({name: 'scoreStream'});
import { receive as receiveCollections } from '../../../../common/redux/actions/collections';
import { get as getTeam } from '../../../../common/redux/modules/collections/team';
import genericActionCallback from '../../../../common/redux/helpers/genericActionCallback';

const RECEIVE_GAMES_UPCOMING = 'routes/team/RECEIVE_GAMES_UPCOMING';
const RECEIVE_GAMES_PAST = 'routes/team/RECEIVE_GAMES_PAST';
const SET_OFFSET_UPCOMING = 'routes/team/SET_OFFSET_UPCOMING';
const SET_OFFSET_PAST = 'routes/team/SET_OFFSET_PAST';
const RECEIVE_PREVIEW_MEDIA = 'routes/team/RECEIVE_PREVIEW_MEDIA';
const RECEIVE_MEDIA = 'routes/team/RECEIVE_MEDIA';
const SET_MEDIA_FETCHING = 'routes/team/SET_MEEDIA_FETCHING';
const SET_TEAM = 'routes/team/SET_TEAM';
const RECEIVE_GMS = 'routes/team/RECEIVE_GMS';
const RECEIVE_GAMES_RAIL = 'routes/team/RECEIVE_GAMES_RAIL';
const SET_PICS_CHANGEABLE = 'routes/team/SET_PICS_CHANGEABLE';

const fetchingHash = {};

function getInitialState () {
	return {
		currentTeamId: null,
		offsetUpcoming: 0,
		offsetPast: 0,
		pastGames: {
			gameIds: null,
			total: 0,
		},
		upcomingGames: {
			gameIds: null,
			total: 0,
		},
		media : {
			fetching: false,
			previewItems: [],
			teamPosts: {items: null, total: 0},
			gamePosts: {items: null, total: 0},
		},
		generalManagerList: null,
		gamesForRail: {
			upcoming: null,
			past: null,
		},
		picturesChangeable: false,
	};
}

const reducers = handleActions({
	[SET_TEAM]: (state, action) => {
		if (!state.currentTeamId) {
			return update(state, {
				currentTeamId: {$set : action.payload}
			});
		} else {
			// user ha
			return assignIn(getInitialState(), {currentTeamId: action.payload});
		}
	},
	[RECEIVE_GAMES_UPCOMING]: (state, action) => {
		const gameIds = (isUndefined(action.payload.offset)) ?
			uniq(state.gameIds.concat(action.payload.gameIds)) :
			action.payload.gameIds;
		return update(state, {
			upcomingGames: {$set: {
				gameIds,
				total: action.payload.total,
			}},
		});
	},
	[RECEIVE_GAMES_PAST]: (state, action) => {
		return update(state, {
			pastGames: {$set: {
				gameIds: action.payload.gameIds,
				total: action.payload.total,
			}},
		});
	},
	[SET_OFFSET_UPCOMING]: (state, action) => update(state, {
		offsetUpcoming: {$set: action.payload},
	}),
	[SET_OFFSET_PAST]: (state, action) => update(state, {
		offsetPast: {$set: action.payload},
	}),
	[RECEIVE_PREVIEW_MEDIA] : {
		next: (state, action) => {
			const gamePostIds = action.payload;
			return update(state, {
				media : {
					previewItems: {$set: gamePostIds},
				}
			});
		},
		throw: (state, action) => {
			console.error('Error getting gamePosts / media', action.payload);
			return state;
		}
	},
	[RECEIVE_MEDIA]: {
		next: (state, action) => {
			const { teamPosts, gamePosts } = action.payload;
			const updates = {media: {}};
			if (teamPosts) {
				updates.media.teamPosts = {$apply: val => ({
					items: (val.items) ?
						val.items.concat(teamPosts.gamePostIds) :
						teamPosts.gamePostIds,
					total: teamPosts.total,
				})};
			}
			if (gamePosts) {
				updates.media.gamePosts = {$apply: val => ({
					items: (val.items) ?
						val.items.concat(gamePosts.gamePostIds) :
						gamePosts.gamePostIds,
					total: gamePosts.total,
				})};
			}
			return update(state, updates);
		},
		throw: (state, action) => {
			console.error('Error getting gamePosts / media', action.payload);
			return state;
		}
	},
	[SET_MEDIA_FETCHING]: (state, action) => update(state, {
		media: {
			fetching: {$set: action.payload}
		}
	}),
	[RECEIVE_GMS]: (state, action) => update(state, {
		generalManagerList: {$set: action.payload},
	}),
	[RECEIVE_GAMES_RAIL]: {
		next: (state, action) => update(state, {
			gamesForRail: {$set: action.payload},
		}),
		throw: (state, action) => {
			console.error('Error getting games for rail', action.payload);
			return state;
		}
	},
	[SET_PICS_CHANGEABLE]: (state, action) => update(state, {
		picturesChangeable: {$set: action.payload},
	}),
}, getInitialState());
export default reducers;

let actions = {
	receiveGamesUpcoming: createAction(RECEIVE_GAMES_UPCOMING),
	receiveGamesPast: createAction(RECEIVE_GAMES_PAST),
	setOffsetUpcoming: createAction(SET_OFFSET_UPCOMING),
	setOffsetPast: createAction(SET_OFFSET_PAST),
	receivePreviewMedia : createAction(RECEIVE_PREVIEW_MEDIA),
	receiveMedia : createAction(RECEIVE_MEDIA),
	setMediaFetching: createAction(SET_MEDIA_FETCHING),
	receiveGms: createAction(RECEIVE_GMS),
	setCurrentTeam : createAction(SET_TEAM),
	setPicturesChangeable: createAction(SET_PICS_CHANGEABLE),
	receiveGamesRail: createAction(RECEIVE_GAMES_RAIL),
};

export const setCurrentTeam = actions.setCurrentTeam;

export function getUpcomingGames (p) {
	const {
		callback = noop,
		teamId,
		squadIds,
		sportNames,
		offset = 0,
		count = 2,
	} = p;
	const dateFormat = 'YYYY-MM-DD HH:mm:ss';
	const now = moment().utc();
	const future = now.clone().add(1, 'years').format(dateFormat);

	return function dispatchGetGames (dispatch, getState) {
		api.post({
			method: 'games.search',
			params: {
				offset, count,
				squadIds: squadIds || undefined,
				afterDateTime: now.format(dateFormat),
				beforeDateTime: future,
				sportNames: sportNames,
				teamIds: [teamId],
				orderBy: 'ASC',
			},
			callback: (err, result) => {
				if (err) {
					return console.error(err);
				}
				if (!isUndefined(p.offset)) {
					dispatch(actions.setOffsetUpcoming(p.offset));
				}
				const state = getState();
				let gameIds = result.gameIds;
				if (offset && state.routes.team.upcomingGames.gameIds) {
					gameIds = uniq(state.routes.team.upcomingGames.gameIds.concat(gameIds));
				}
				dispatch(receiveCollections(result.collections));
				dispatch(actions.receiveGamesUpcoming({
					gameIds,
					offset,
					total: result.total,
				}));
				callback();
			}
		});
	};
}

export function getPastGames (p) {
	const {
		teamId,
		squadIds,
		sportNames,
		offset = 0,
		count = 10,
	} = p;
	const dateFormat = 'YYYY-MM-DD HH:mm:ss';
	const now = moment().utc();
	const past = now.clone().subtract(10, 'years').format(dateFormat);

	return function dispatchGetGames (dispatch, getState) {
		api.post({
			method: 'games.search',
			params: {
				offset, count,
				squadIds: squadIds || undefined,
				afterDateTime: past,
				beforeDateTime: now.format(dateFormat),
				sportNames,
				teamIds: [teamId],
				orderBy: 'DESC',
			},
			callback: (err, result) => {
				if (err) {
					return console.error(err);
				}
				if (!isUndefined(p.offset)) {
					dispatch(actions.setOffsetPast(p.offset));
				}
				const state = getState();
				let gameIds = result.gameIds;
				if (offset && state.routes.team.pastGames.gameIds) {
					gameIds = uniq(state.routes.team.pastGames.gameIds.concat(gameIds));
				}
				dispatch(receiveCollections(result.collections));
				dispatch(actions.receiveGamesPast({
					gameIds,
					offset,
					total: result.total,
				}));
			}
		});
	};
}

const savingHash = {};
export function addPicture (teamId, type, file, callback = noop) {
	if (savingHash[teamId]) { return; }
	else { savingHash[teamId] = true; }

	const formData = new window.FormData();
	formData.append('picture', file);

	return function dispatchAddPicture (dispatch) {
		series([
			callback => api.post({
				callback,
				method: 'teams.pictures.add',
				params: {
					type,
					teamId,
					sportsName: 'all',
				},
				formData,
			}),
			callback => {
				dispatch(getTeam({
					callback,
					forceMerge: true,
					teamIds: [teamId],
				}));
			}
		], err => {
			delete savingHash[teamId];
			callback(err);
		});
	};
}

export function removePicture (pictureId, teamId) {
	if (savingHash[pictureId]) { return; }


	return function dispatchAddPicture (dispatch) {
		sweetAlert({
			title: 'Are you sure?',
			text: 'Are you sure you want to remove this picture? It is not recoverable.',
			type: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, remove it!',
		})
			.then(() => {
				savingHash[pictureId] = true;
				series([
					callback => api.post({
						callback,
						method: 'teams.pictures.update',
						params: {
							teamPictureId: pictureId,
							displayLevel: 0,
						},
					}),
					callback => {
						dispatch(getTeam({
							callback,
							forceMerge: true,
							teamIds: [teamId],
						}));
					}
				], err => {
					delete savingHash[teamId];
					genericActionCallback(err);
				});
			})
			.catch(noop);
	};
}

export function loadMedia (teamId) {
	return function dispatchLoadMedia (dispatch, getState) {
		const state = getState();
		if (state.routes.team.media.fetching) { return; }
		dispatch(actions.setMediaFetching(true));

		const commonParams = {
			count: 10,
			gamePostTypes: ['picture', 'video'],
			orderBy: 'DESC',
			skipChildGamePosts: false,
			skipParentGamePosts: true,
			minCurationRating : 600,
			teamIds: [teamId],
		};

		const teamPostsOffset = get(state, 'routes.team.media.teamPosts.items.length', 0);
		const totalTeamPosts = get(state, 'routes.team.media.teamPosts.total', 0);
		const gamePostsOffset = get(state, 'routes.team.media.gamePosts.items.length', 0);
		const totalGamePosts = get(state, 'routes.team.media.gamePosts.total', 0);

		const jobs = {};
		if (!state.routes.team.media.teamPosts.items || totalTeamPosts > teamPostsOffset) {
			jobs.teamPosts = callback => api.post({
				method: 'teams.gamePosts.search',
				params: {
					...commonParams,
					offset: teamPostsOffset,
				},
				callback,
			});
		}

		if (!state.routes.team.media.gamePosts.items || totalGamePosts > gamePostsOffset) {
			jobs.gamePosts = callback => api.post({
				method: 'games.posts.search',
				params: {
					...commonParams,
					offset: gamePostsOffset,
				},
				callback,
			});
		}

		if (!Object.keys(jobs).length) {
			return console.warn('Already fetched all of the media for team', teamId);
		}

		parallel(jobs, (err, results) => {
			dispatch(actions.setMediaFetching(false));
			if (err) {
				return dispatch({
					type: RECEIVE_MEDIA,
					error: true,
					payload: err
				});
			}

			const payload = {};
			if (results.teamPosts) {
				dispatch(receiveCollections(results.teamPosts.collections));
				payload.teamPosts = {
					gamePostIds: results.teamPosts.gamePostIds,
					total: results.teamPosts.total,
				};
			}
			if (results.gamePosts) {
				dispatch(receiveCollections(results.gamePosts.collections));
				payload.gamePosts = {
					gamePostIds: results.gamePosts.gamePostIds,
					total: results.gamePosts.total,
				};
			}
			dispatch(actions.receiveMedia(payload));
		});
	};
}

export function getPreviewMedia (teamId) {
	return (dispatch) => {
		if (fetchingHash.previewMedia) { return; }
		fetchingHash.previewMedia = true;
		const now = moment().utc();
		const dateFormat = 'YYYY-MM-DD HH:mm:ss';

		parallel([
			cb => api.post({
				method: 'games.posts.search',
				params: {
					teamIds: [teamId],
					gamePostTypes: ['picture', 'video'],
					count: 5,
					minUserCurationRating: 600,
					orderBy: 'DESC',
					includeGames: true,
					skipChildGamePosts : true,
					skipParentGamePosts : true,
				},
				callback: cb
			}),
			cb => api.post({
				method: 'teams.gamePosts.search',
				params: {
					teamIds: [teamId],
					gamePostTypes: ['picture', 'video'],
					count: 5,
					minUserCurationRating: 600,
					orderBy: 'DESC',
					skipChildGamePosts : true,
					skipParentGamePosts : true,
				},
				callback: cb
			}),
		], (err, results) => {
			if (err) {
				dispatch({
					type: RECEIVE_PREVIEW_MEDIA,
					error: true,
					payload: err
				});
			} else {
				const gamePostIds = results[0].collections.gamePostCollection.list
					.concat(results[1].collections.gamePostCollection.list)
					.sort((a, b) => (a.userCurationRating !== b.userCurationRating) ?
						b.userCurationRating - a.userCurationRating :
						(a.postedDateTime > b.postedDateTime) ?
							-1 :
							(a.postedDateTime < b.postedDateTime) ? 1 : 0)
					.map(post => post.gamePostId)
					.slice(0, 5);

				dispatch(receiveCollections(results[0].collections));
				dispatch(receiveCollections(results[1].collections));
				dispatch(actions.receivePreviewMedia(gamePostIds));
			}

			delete fetchingHash.previewMedia;
		});
	};
}

export function getGms (teamId) {
	return function dispatchGetGms (dispatch) {
		api.post({
			method: 'teams.generalManagers.search',
			params: {
				teamIds: [teamId],
				includeUsers: true,
			},
			callback: genericActionCallback(dispatch, (err, result) => {
				if (err) { return; }
				dispatch(actions.receiveGms(result.collections.generalManagerCollection.list));
			}),
		});
	};
}

export function getGamesForRail ({teamId, sportNames, squadIds}) {
	return function dispatchGetGamesForRail (dispatch) {
		const now = moment().utc();
		const dateFormat = 'YYYY-MM-DD HH:mm:ss';
		parallel({
			upcoming: callback => api.post({
				callback,
				method: 'games.search',
				params: {
					teamIds: [teamId],
					count: 1,
					offset: 0,
					afterDateTime: now.clone()
						.subtract(5, 'hours')
						.format(dateFormat),
					beforeDateTime: now.clone()
						.add(1, 'years')
						.format(dateFormat),
					sportNames,
					squadIds: squadIds || undefined,
					orderBy: 'ABS',
				},
			}),
			past: callback => api.post({
				callback,
				method: 'games.search',
				params: {
					teamIds: [teamId],
					count: 5,
					offset: 0,
					afterDateTime: now.clone()
						.subtract(2, 'years')
						.format(dateFormat),
					beforeDateTime: now.clone()
						.format(dateFormat),
					sportNames,
					squadIds: squadIds || undefined,
					orderBy: 'ABS',
				},
			}),

		}, (err, results) => {
			if (err) {
				return dispatch({
					type: RECEIVE_GAMES_RAIL,
					error: true,
					payload: err
				});
			}
			if (window.top === window.self) {
				dispatch(receiveCollections(results.upcoming.collections));
			}
			dispatch(receiveCollections(results.past.collections));
			const gamesToStore = {
				past: results.past.collections.gameCollection.list
					.filter(game => now.clone().subtract(5, 'hours').isAfter(game.startDateTime) ||
						game.lastScore.gameSegmentId === 19999)
					.slice(0, 3)
					.map(game => game.gameId),
			};
			if (window.top === window.self) {
				gamesToStore.upcoming = results.upcoming.collections.gameCollection.list
					.filter(game => game.lastScore.gameSegmentId !== 19999)
					.map(game => game.gameId);
			}
			dispatch(actions.receiveGamesRail(gamesToStore));
		});
	};
}

export function setPicturesChangeable (changeable) {
	return dispatch => dispatch(actions.setPicturesChangeable(changeable));
}
