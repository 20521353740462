
/* 
	FILE AUTOMATICALLY GENERATED BY RUNNING npm run build buildCachedData
	DO NOT EDIT THIS FILE
	QUESTIONS: Ask Ryan
*/

module.exports = {
  "collections": {
    "colorCollection": {
      "list": [
        {
          "colorId": 1000,
          "name": "White",
          "r": 255,
          "g": 255,
          "b": 255,
          "a": 255,
          "lightColor": {
            "r": 255,
            "g": 255,
            "b": 255
          },
          "darkColor": {
            "r": 198,
            "g": 198,
            "b": 198
          },
          "isLight": true,
          "foregroundColor": {
            "r": 40,
            "g": 40,
            "b": 40
          },
          "foregroundShadowColor": {
            "r": 255,
            "g": 255,
            "b": 255,
            "a": 120
          }
        },
        {
          "colorId": 1050,
          "name": "Platinum",
          "r": 229,
          "g": 228,
          "b": 226,
          "a": 255,
          "lightColor": {
            "r": 255,
            "g": 255,
            "b": 253
          },
          "darkColor": {
            "r": 173,
            "g": 172,
            "b": 171
          },
          "isLight": true,
          "foregroundColor": {
            "r": 40,
            "g": 40,
            "b": 40
          },
          "foregroundShadowColor": {
            "r": 255,
            "g": 255,
            "b": 255,
            "a": 120
          }
        },
        {
          "colorId": 1040,
          "name": "Ash Grey",
          "r": 196,
          "g": 200,
          "b": 203,
          "a": 255,
          "lightColor": {
            "r": 251,
            "g": 255,
            "b": 255
          },
          "darkColor": {
            "r": 142,
            "g": 146,
            "b": 149
          },
          "isLight": true,
          "foregroundColor": {
            "r": 40,
            "g": 40,
            "b": 40
          },
          "foregroundShadowColor": {
            "r": 255,
            "g": 255,
            "b": 255,
            "a": 120
          }
        },
        {
          "colorId": 1030,
          "name": "Silver",
          "r": 172,
          "g": 182,
          "b": 188,
          "a": 255,
          "lightColor": {
            "r": 246,
            "g": 255,
            "b": 255
          },
          "darkColor": {
            "r": 119,
            "g": 129,
            "b": 135
          },
          "isLight": true,
          "foregroundColor": {
            "r": 40,
            "g": 40,
            "b": 40
          },
          "foregroundShadowColor": {
            "r": 255,
            "g": 255,
            "b": 255,
            "a": 120
          }
        },
        {
          "colorId": 1020,
          "name": "Gray",
          "r": 128,
          "g": 128,
          "b": 128,
          "a": 255,
          "lightColor": {
            "r": 208,
            "g": 208,
            "b": 208
          },
          "darkColor": {
            "r": 79,
            "g": 79,
            "b": 79
          },
          "isDark": true,
          "foregroundColor": {
            "r": 255,
            "g": 255,
            "b": 255
          },
          "foregroundShadowColor": {
            "r": 0,
            "g": 0,
            "b": 0,
            "a": 120
          }
        },
        {
          "colorId": 1330,
          "name": "Dark Gray",
          "r": 80,
          "g": 80,
          "b": 80,
          "a": 255,
          "lightColor": {
            "r": 155,
            "g": 155,
            "b": 155
          },
          "darkColor": {
            "r": 35,
            "g": 35,
            "b": 35
          },
          "isDark": true,
          "foregroundColor": {
            "r": 255,
            "g": 255,
            "b": 255
          },
          "foregroundShadowColor": {
            "r": 0,
            "g": 0,
            "b": 0,
            "a": 120
          }
        },
        {
          "colorId": 1010,
          "name": "Black",
          "r": 20,
          "g": 20,
          "b": 20,
          "a": 255,
          "lightColor": {
            "r": 85,
            "g": 85,
            "b": 85
          },
          "darkColor": {
            "r": 0,
            "g": 0,
            "b": 0
          },
          "isDark": true,
          "foregroundColor": {
            "r": 255,
            "g": 255,
            "b": 255
          },
          "foregroundShadowColor": {
            "r": 0,
            "g": 0,
            "b": 0,
            "a": 120
          }
        },
        {
          "colorId": 1060,
          "name": "Midnight Blue",
          "r": 13,
          "g": 37,
          "b": 76,
          "a": 255,
          "lightColor": {
            "r": 91,
            "g": 105,
            "b": 151
          },
          "darkColor": {
            "r": 0,
            "g": 0,
            "b": 42
          },
          "isDark": true,
          "foregroundColor": {
            "r": 255,
            "g": 255,
            "b": 255
          },
          "foregroundShadowColor": {
            "r": 0,
            "g": 0,
            "b": 0,
            "a": 120
          }
        },
        {
          "colorId": 1080,
          "name": "Navy",
          "r": 30,
          "g": 30,
          "b": 128,
          "a": 255,
          "lightColor": {
            "r": 119,
            "g": 99,
            "b": 210
          },
          "darkColor": {
            "r": 0,
            "g": 0,
            "b": 80
          },
          "isDark": true,
          "foregroundColor": {
            "r": 255,
            "g": 255,
            "b": 255
          },
          "foregroundShadowColor": {
            "r": 0,
            "g": 0,
            "b": 0,
            "a": 120
          }
        },
        {
          "colorId": 1090,
          "name": "Royal",
          "r": 41,
          "g": 0,
          "b": 173,
          "a": 255,
          "lightColor": {
            "r": 142,
            "g": 87,
            "b": 255
          },
          "darkColor": {
            "r": 0,
            "g": 0,
            "b": 119
          },
          "isDark": true,
          "foregroundColor": {
            "r": 255,
            "g": 255,
            "b": 255
          },
          "foregroundShadowColor": {
            "r": 0,
            "g": 0,
            "b": 0,
            "a": 120
          }
        },
        {
          "colorId": 1100,
          "name": "Blue",
          "r": 27,
          "g": 68,
          "b": 255,
          "a": 255,
          "lightColor": {
            "r": 157,
            "g": 143,
            "b": 255
          },
          "darkColor": {
            "r": 0,
            "g": 22,
            "b": 196
          },
          "isDark": true,
          "foregroundColor": {
            "r": 255,
            "g": 255,
            "b": 255
          },
          "foregroundShadowColor": {
            "r": 0,
            "g": 0,
            "b": 0,
            "a": 120
          }
        },
        {
          "colorId": 1140,
          "name": "Cornflower Blue",
          "r": 91,
          "g": 146,
          "b": 229,
          "a": 255,
          "lightColor": {
            "r": 180,
            "g": 227,
            "b": 255
          },
          "darkColor": {
            "r": 3,
            "g": 96,
            "b": 173
          },
          "isLight": true,
          "foregroundColor": {
            "r": 40,
            "g": 40,
            "b": 40
          },
          "foregroundShadowColor": {
            "r": 255,
            "g": 255,
            "b": 255,
            "a": 120
          }
        },
        {
          "colorId": 1150,
          "name": "Sky Blue",
          "r": 119,
          "g": 181,
          "b": 254,
          "a": 255,
          "lightColor": {
            "r": 201,
            "g": 255,
            "b": 255
          },
          "darkColor": {
            "r": 55,
            "g": 128,
            "b": 197
          },
          "isLight": true,
          "foregroundColor": {
            "r": 40,
            "g": 40,
            "b": 40
          },
          "foregroundShadowColor": {
            "r": 255,
            "g": 255,
            "b": 255,
            "a": 120
          }
        },
        {
          "colorId": 1200,
          "name": "Columbia Blue",
          "r": 190,
          "g": 212,
          "b": 233,
          "a": 255,
          "lightColor": {
            "r": 235,
            "g": 255,
            "b": 255
          },
          "darkColor": {
            "r": 136,
            "g": 157,
            "b": 177
          },
          "isLight": true,
          "foregroundColor": {
            "r": 40,
            "g": 40,
            "b": 40
          },
          "foregroundShadowColor": {
            "r": 255,
            "g": 255,
            "b": 255,
            "a": 120
          }
        },
        {
          "colorId": 1190,
          "name": "Blue White",
          "r": 220,
          "g": 226,
          "b": 253,
          "a": 255,
          "lightColor": {
            "r": 248,
            "g": 254,
            "b": 255
          },
          "darkColor": {
            "r": 165,
            "g": 171,
            "b": 196
          },
          "isLight": true,
          "foregroundColor": {
            "r": 40,
            "g": 40,
            "b": 40
          },
          "foregroundShadowColor": {
            "r": 255,
            "g": 255,
            "b": 255,
            "a": 120
          }
        },
        {
          "colorId": 1170,
          "name": "Powder Blue",
          "r": 176,
          "g": 224,
          "b": 230,
          "a": 255,
          "lightColor": {
            "r": 215,
            "g": 255,
            "b": 255
          },
          "darkColor": {
            "r": 122,
            "g": 169,
            "b": 175
          },
          "isLight": true,
          "foregroundColor": {
            "r": 40,
            "g": 40,
            "b": 40
          },
          "foregroundShadowColor": {
            "r": 255,
            "g": 255,
            "b": 255,
            "a": 120
          }
        },
        {
          "colorId": 1160,
          "name": "Turquoise",
          "r": 64,
          "g": 224,
          "b": 208,
          "a": 255,
          "lightColor": {
            "r": 129,
            "g": 255,
            "b": 255
          },
          "darkColor": {
            "r": 0,
            "g": 168,
            "b": 154
          },
          "isLight": true,
          "foregroundColor": {
            "r": 40,
            "g": 40,
            "b": 40
          },
          "foregroundShadowColor": {
            "r": 255,
            "g": 255,
            "b": 255,
            "a": 120
          }
        },
        {
          "colorId": 1180,
          "name": "Aqua",
          "r": 10,
          "g": 255,
          "b": 255,
          "a": 255,
          "lightColor": {
            "r": 77,
            "g": 255,
            "b": 255
          },
          "darkColor": {
            "r": 0,
            "g": 197,
            "b": 198
          },
          "isLight": true,
          "foregroundColor": {
            "r": 40,
            "g": 40,
            "b": 40
          },
          "foregroundShadowColor": {
            "r": 255,
            "g": 255,
            "b": 255,
            "a": 120
          }
        },
        {
          "colorId": 1130,
          "name": "Carolina Blue",
          "r": 10,
          "g": 136,
          "b": 206,
          "a": 255,
          "lightColor": {
            "r": 133,
            "g": 217,
            "b": 255
          },
          "darkColor": {
            "r": 0,
            "g": 86,
            "b": 151
          },
          "isDark": true,
          "foregroundColor": {
            "r": 255,
            "g": 255,
            "b": 255
          },
          "foregroundShadowColor": {
            "r": 0,
            "g": 0,
            "b": 0,
            "a": 120
          }
        },
        {
          "colorId": 1120,
          "name": "Teal",
          "r": 10,
          "g": 113,
          "b": 152,
          "a": 255,
          "lightColor": {
            "r": 116,
            "g": 192,
            "b": 235
          },
          "darkColor": {
            "r": 0,
            "g": 65,
            "b": 101
          },
          "isDark": true,
          "foregroundColor": {
            "r": 255,
            "g": 255,
            "b": 255
          },
          "foregroundShadowColor": {
            "r": 0,
            "g": 0,
            "b": 0,
            "a": 120
          }
        },
        {
          "colorId": 1340,
          "name": "Green Blue",
          "r": 10,
          "g": 102,
          "b": 102,
          "a": 255,
          "lightColor": {
            "r": 104,
            "g": 180,
            "b": 180
          },
          "darkColor": {
            "r": 0,
            "g": 54,
            "b": 55
          },
          "isDark": true,
          "foregroundColor": {
            "r": 255,
            "g": 255,
            "b": 255
          },
          "foregroundShadowColor": {
            "r": 0,
            "g": 0,
            "b": 0,
            "a": 120
          }
        },
        {
          "colorId": 1320,
          "name": "Forest Green",
          "r": 0,
          "g": 59,
          "b": 72,
          "a": 255,
          "lightColor": {
            "r": 85,
            "g": 132,
            "b": 146
          },
          "darkColor": {
            "r": 0,
            "g": 16,
            "b": 29
          },
          "isDark": true,
          "foregroundColor": {
            "r": 255,
            "g": 255,
            "b": 255
          },
          "foregroundShadowColor": {
            "r": 0,
            "g": 0,
            "b": 0,
            "a": 120
          }
        },
        {
          "colorId": 1310,
          "name": "Dark Green",
          "r": 13,
          "g": 64,
          "b": 6,
          "a": 255,
          "lightColor": {
            "r": 90,
            "g": 138,
            "b": 76
          },
          "darkColor": {
            "r": 0,
            "g": 24,
            "b": 0
          },
          "isDark": true,
          "foregroundColor": {
            "r": 255,
            "g": 255,
            "b": 255
          },
          "foregroundShadowColor": {
            "r": 0,
            "g": 0,
            "b": 0,
            "a": 120
          }
        },
        {
          "colorId": 1300,
          "name": "Dartmouth Green",
          "r": 0,
          "g": 112,
          "b": 60,
          "a": 255,
          "lightColor": {
            "r": 101,
            "g": 192,
            "b": 133
          },
          "darkColor": {
            "r": 0,
            "g": 63,
            "b": 16
          },
          "isDark": true,
          "foregroundColor": {
            "r": 255,
            "g": 255,
            "b": 255
          },
          "foregroundShadowColor": {
            "r": 0,
            "g": 0,
            "b": 0,
            "a": 120
          }
        },
        {
          "colorId": 1350,
          "name": "Kelly Green",
          "r": 0,
          "g": 158,
          "b": 96,
          "a": 255,
          "lightColor": {
            "r": 118,
            "g": 242,
            "b": 174
          },
          "darkColor": {
            "r": 0,
            "g": 105,
            "b": 48
          },
          "isDark": true,
          "foregroundColor": {
            "r": 255,
            "g": 255,
            "b": 255
          },
          "foregroundShadowColor": {
            "r": 0,
            "g": 0,
            "b": 0,
            "a": 120
          }
        },
        {
          "colorId": 1290,
          "name": "Green",
          "r": 78,
          "g": 174,
          "b": 71,
          "a": 255,
          "lightColor": {
            "r": 164,
            "g": 255,
            "b": 149
          },
          "darkColor": {
            "r": 0,
            "g": 120,
            "b": 16
          },
          "isDark": true,
          "foregroundColor": {
            "r": 255,
            "g": 255,
            "b": 255
          },
          "foregroundShadowColor": {
            "r": 0,
            "g": 0,
            "b": 0,
            "a": 120
          }
        },
        {
          "colorId": 1360,
          "name": "Bright Green",
          "r": 56,
          "g": 197,
          "b": 46,
          "a": 255,
          "lightColor": {
            "r": 154,
            "g": 255,
            "b": 130
          },
          "darkColor": {
            "r": 0,
            "g": 142,
            "b": 0
          },
          "isLight": true,
          "foregroundColor": {
            "r": 40,
            "g": 40,
            "b": 40
          },
          "foregroundShadowColor": {
            "r": 255,
            "g": 255,
            "b": 255,
            "a": 120
          }
        },
        {
          "colorId": 1370,
          "name": "Light Green",
          "r": 80,
          "g": 200,
          "b": 120,
          "a": 255,
          "lightColor": {
            "r": 161,
            "g": 255,
            "b": 194
          },
          "darkColor": {
            "r": 0,
            "g": 145,
            "b": 70
          },
          "isLight": true,
          "foregroundColor": {
            "r": 40,
            "g": 40,
            "b": 40
          },
          "foregroundShadowColor": {
            "r": 255,
            "g": 255,
            "b": 255,
            "a": 120
          }
        },
        {
          "colorId": 1380,
          "name": "Maize",
          "r": 251,
          "g": 236,
          "b": 93,
          "a": 255,
          "lightColor": {
            "r": 255,
            "g": 255,
            "b": 115
          },
          "darkColor": {
            "r": 191,
            "g": 180,
            "b": 31
          },
          "isLight": true,
          "foregroundColor": {
            "r": 40,
            "g": 40,
            "b": 40
          },
          "foregroundShadowColor": {
            "r": 255,
            "g": 255,
            "b": 255,
            "a": 120
          }
        },
        {
          "colorId": 1390,
          "name": "Yellow",
          "r": 246,
          "g": 235,
          "b": 20,
          "a": 255,
          "lightColor": {
            "r": 255,
            "g": 255,
            "b": 60
          },
          "darkColor": {
            "r": 185,
            "g": 180,
            "b": 0
          },
          "isLight": true,
          "foregroundColor": {
            "r": 40,
            "g": 40,
            "b": 40
          },
          "foregroundShadowColor": {
            "r": 255,
            "g": 255,
            "b": 255,
            "a": 120
          }
        },
        {
          "colorId": 1400,
          "name": "Gold",
          "r": 242,
          "g": 200,
          "b": 0,
          "a": 255,
          "lightColor": {
            "r": 255,
            "g": 251,
            "b": 79
          },
          "darkColor": {
            "r": 181,
            "g": 146,
            "b": 0
          },
          "isLight": true,
          "foregroundColor": {
            "r": 40,
            "g": 40,
            "b": 40
          },
          "foregroundShadowColor": {
            "r": 255,
            "g": 255,
            "b": 255,
            "a": 120
          }
        },
        {
          "colorId": 1410,
          "name": "Vegas Gold",
          "r": 208,
          "g": 178,
          "b": 57,
          "a": 255,
          "lightColor": {
            "r": 255,
            "g": 253,
            "b": 130
          },
          "darkColor": {
            "r": 150,
            "g": 126,
            "b": 0
          },
          "isLight": true,
          "foregroundColor": {
            "r": 40,
            "g": 40,
            "b": 40
          },
          "foregroundShadowColor": {
            "r": 255,
            "g": 255,
            "b": 255,
            "a": 120
          }
        },
        {
          "colorId": 1420,
          "name": "Light Orange",
          "r": 250,
          "g": 173,
          "b": 24,
          "a": 255,
          "lightColor": {
            "r": 255,
            "g": 239,
            "b": 99
          },
          "darkColor": {
            "r": 188,
            "g": 121,
            "b": 0
          },
          "isLight": true,
          "foregroundColor": {
            "r": 40,
            "g": 40,
            "b": 40
          },
          "foregroundShadowColor": {
            "r": 255,
            "g": 255,
            "b": 255,
            "a": 120
          }
        },
        {
          "colorId": 1430,
          "name": "Bright Orange",
          "r": 255,
          "g": 122,
          "b": 0,
          "a": 255,
          "lightColor": {
            "r": 255,
            "g": 205,
            "b": 99
          },
          "darkColor": {
            "r": 190,
            "g": 69,
            "b": 0
          },
          "isLight": true,
          "foregroundColor": {
            "r": 40,
            "g": 40,
            "b": 40
          },
          "foregroundShadowColor": {
            "r": 255,
            "g": 255,
            "b": 255,
            "a": 120
          }
        },
        {
          "colorId": 1440,
          "name": "Orange",
          "r": 223,
          "g": 97,
          "b": 8,
          "a": 255,
          "lightColor": {
            "r": 255,
            "g": 178,
            "b": 95
          },
          "darkColor": {
            "r": 160,
            "g": 44,
            "b": 0
          },
          "isDark": true,
          "foregroundColor": {
            "r": 255,
            "g": 255,
            "b": 255
          },
          "foregroundShadowColor": {
            "r": 0,
            "g": 0,
            "b": 0,
            "a": 120
          }
        },
        {
          "colorId": 1450,
          "name": "Burnt Orange",
          "r": 221,
          "g": 72,
          "b": 20,
          "a": 255,
          "lightColor": {
            "r": 255,
            "g": 155,
            "b": 97
          },
          "darkColor": {
            "r": 157,
            "g": 0,
            "b": 0
          },
          "isDark": true,
          "foregroundColor": {
            "r": 255,
            "g": 255,
            "b": 255
          },
          "foregroundShadowColor": {
            "r": 0,
            "g": 0,
            "b": 0,
            "a": 120
          }
        },
        {
          "colorId": 1460,
          "name": "Red Orange",
          "r": 239,
          "g": 60,
          "b": 57,
          "a": 255,
          "lightColor": {
            "r": 255,
            "g": 149,
            "b": 130
          },
          "darkColor": {
            "r": 174,
            "g": 0,
            "b": 10
          },
          "isDark": true,
          "foregroundColor": {
            "r": 255,
            "g": 255,
            "b": 255
          },
          "foregroundShadowColor": {
            "r": 0,
            "g": 0,
            "b": 0,
            "a": 120
          }
        },
        {
          "colorId": 1470,
          "name": "Cherry",
          "r": 238,
          "g": 41,
          "b": 68,
          "a": 255,
          "lightColor": {
            "r": 255,
            "g": 138,
            "b": 142
          },
          "darkColor": {
            "r": 173,
            "g": 0,
            "b": 24
          },
          "isDark": true,
          "foregroundColor": {
            "r": 255,
            "g": 255,
            "b": 255
          },
          "foregroundShadowColor": {
            "r": 0,
            "g": 0,
            "b": 0,
            "a": 120
          }
        },
        {
          "colorId": 1480,
          "name": "Red",
          "r": 209,
          "g": 41,
          "b": 41,
          "a": 255,
          "lightColor": {
            "r": 255,
            "g": 132,
            "b": 112
          },
          "darkColor": {
            "r": 145,
            "g": 0,
            "b": 0
          },
          "isDark": true,
          "foregroundColor": {
            "r": 255,
            "g": 255,
            "b": 255
          },
          "foregroundShadowColor": {
            "r": 0,
            "g": 0,
            "b": 0,
            "a": 120
          }
        },
        {
          "colorId": 1490,
          "name": "Raspberry",
          "r": 227,
          "g": 11,
          "b": 92,
          "a": 255,
          "lightColor": {
            "r": 255,
            "g": 125,
            "b": 168
          },
          "darkColor": {
            "r": 164,
            "g": 0,
            "b": 47
          },
          "isDark": true,
          "foregroundColor": {
            "r": 255,
            "g": 255,
            "b": 255
          },
          "foregroundShadowColor": {
            "r": 0,
            "g": 0,
            "b": 0,
            "a": 120
          }
        },
        {
          "colorId": 1500,
          "name": "Scarlet",
          "r": 189,
          "g": 13,
          "b": 24,
          "a": 255,
          "lightColor": {
            "r": 255,
            "g": 114,
            "b": 94
          },
          "darkColor": {
            "r": 127,
            "g": 0,
            "b": 0
          },
          "isDark": true,
          "foregroundColor": {
            "r": 255,
            "g": 255,
            "b": 255
          },
          "foregroundShadowColor": {
            "r": 0,
            "g": 0,
            "b": 0,
            "a": 120
          }
        },
        {
          "colorId": 1510,
          "name": "Crimson",
          "r": 182,
          "g": 38,
          "b": 48,
          "a": 255,
          "lightColor": {
            "r": 255,
            "g": 124,
            "b": 119
          },
          "darkColor": {
            "r": 121,
            "g": 0,
            "b": 0
          },
          "isDark": true,
          "foregroundColor": {
            "r": 255,
            "g": 255,
            "b": 255
          },
          "foregroundShadowColor": {
            "r": 0,
            "g": 0,
            "b": 0,
            "a": 120
          }
        },
        {
          "colorId": 1520,
          "name": "Cardinal",
          "r": 135,
          "g": 6,
          "b": 25,
          "a": 255,
          "lightColor": {
            "r": 226,
            "g": 98,
            "b": 92
          },
          "darkColor": {
            "r": 79,
            "g": 0,
            "b": 0
          },
          "isDark": true,
          "foregroundColor": {
            "r": 255,
            "g": 255,
            "b": 255
          },
          "foregroundShadowColor": {
            "r": 0,
            "g": 0,
            "b": 0,
            "a": 120
          }
        },
        {
          "colorId": 1530,
          "name": "Burgundy",
          "r": 114,
          "g": 28,
          "b": 48,
          "a": 255,
          "lightColor": {
            "r": 199,
            "g": 105,
            "b": 118
          },
          "darkColor": {
            "r": 61,
            "g": 0,
            "b": 0
          },
          "isDark": true,
          "foregroundColor": {
            "r": 255,
            "g": 255,
            "b": 255
          },
          "foregroundShadowColor": {
            "r": 0,
            "g": 0,
            "b": 0,
            "a": 120
          }
        },
        {
          "colorId": 1540,
          "name": "Purple",
          "r": 125,
          "g": 40,
          "b": 125,
          "a": 255,
          "lightColor": {
            "r": 208,
            "g": 119,
            "b": 206
          },
          "darkColor": {
            "r": 72,
            "g": 0,
            "b": 75
          },
          "isDark": true,
          "foregroundColor": {
            "r": 255,
            "g": 255,
            "b": 255
          },
          "foregroundShadowColor": {
            "r": 0,
            "g": 0,
            "b": 0,
            "a": 120
          }
        },
        {
          "colorId": 1550,
          "name": "Royal Purple",
          "r": 59,
          "g": 1,
          "b": 96,
          "a": 255,
          "lightColor": {
            "r": 138,
            "g": 79,
            "b": 174
          },
          "darkColor": {
            "r": 27,
            "g": 0,
            "b": 61
          },
          "isDark": true,
          "foregroundColor": {
            "r": 255,
            "g": 255,
            "b": 255
          },
          "foregroundShadowColor": {
            "r": 0,
            "g": 0,
            "b": 0,
            "a": 120
          }
        },
        {
          "colorId": 1560,
          "name": "Mauve",
          "r": 224,
          "g": 176,
          "b": 255,
          "a": 255,
          "lightColor": {
            "r": 255,
            "g": 236,
            "b": 255
          },
          "darkColor": {
            "r": 168,
            "g": 123,
            "b": 198
          },
          "isLight": true,
          "foregroundColor": {
            "r": 40,
            "g": 40,
            "b": 40
          },
          "foregroundShadowColor": {
            "r": 255,
            "g": 255,
            "b": 255,
            "a": 120
          }
        },
        {
          "colorId": 1570,
          "name": "Pink",
          "r": 241,
          "g": 169,
          "b": 191,
          "a": 255,
          "lightColor": {
            "r": 255,
            "g": 235,
            "b": 255
          },
          "darkColor": {
            "r": 184,
            "g": 116,
            "b": 137
          },
          "isLight": true,
          "foregroundColor": {
            "r": 40,
            "g": 40,
            "b": 40
          },
          "foregroundShadowColor": {
            "r": 255,
            "g": 255,
            "b": 255,
            "a": 120
          }
        },
        {
          "colorId": 1240,
          "name": "Peach",
          "r": 239,
          "g": 188,
          "b": 114,
          "a": 255,
          "lightColor": {
            "r": 255,
            "g": 246,
            "b": 169
          },
          "darkColor": {
            "r": 180,
            "g": 135,
            "b": 63
          },
          "isLight": true,
          "foregroundColor": {
            "r": 40,
            "g": 40,
            "b": 40
          },
          "foregroundShadowColor": {
            "r": 255,
            "g": 255,
            "b": 255,
            "a": 120
          }
        },
        {
          "colorId": 1220,
          "name": "Beige",
          "r": 245,
          "g": 245,
          "b": 220,
          "a": 255,
          "lightColor": {
            "r": 255,
            "g": 255,
            "b": 231
          },
          "darkColor": {
            "r": 189,
            "g": 189,
            "b": 165
          },
          "isLight": true,
          "foregroundColor": {
            "r": 40,
            "g": 40,
            "b": 40
          },
          "foregroundShadowColor": {
            "r": 255,
            "g": 255,
            "b": 255,
            "a": 120
          }
        },
        {
          "colorId": 1230,
          "name": "Natural",
          "r": 238,
          "g": 227,
          "b": 199,
          "a": 255,
          "lightColor": {
            "r": 255,
            "g": 254,
            "b": 226
          },
          "darkColor": {
            "r": 182,
            "g": 172,
            "b": 145
          },
          "isLight": true,
          "foregroundColor": {
            "r": 40,
            "g": 40,
            "b": 40
          },
          "foregroundShadowColor": {
            "r": 255,
            "g": 255,
            "b": 255,
            "a": 120
          }
        },
        {
          "colorId": 1250,
          "name": "Tan",
          "r": 210,
          "g": 184,
          "b": 135,
          "a": 255,
          "lightColor": {
            "r": 255,
            "g": 252,
            "b": 200
          },
          "darkColor": {
            "r": 154,
            "g": 131,
            "b": 85
          },
          "isLight": true,
          "foregroundColor": {
            "r": 40,
            "g": 40,
            "b": 40
          },
          "foregroundShadowColor": {
            "r": 255,
            "g": 255,
            "b": 255,
            "a": 120
          }
        },
        {
          "colorId": 1260,
          "name": "Chestnut",
          "r": 137,
          "g": 118,
          "b": 95,
          "a": 255,
          "lightColor": {
            "r": 219,
            "g": 197,
            "b": 172
          },
          "darkColor": {
            "r": 86,
            "g": 69,
            "b": 48
          },
          "isDark": true,
          "foregroundColor": {
            "r": 255,
            "g": 255,
            "b": 255
          },
          "foregroundShadowColor": {
            "r": 0,
            "g": 0,
            "b": 0,
            "a": 120
          }
        },
        {
          "colorId": 1270,
          "name": "Brown",
          "r": 150,
          "g": 75,
          "b": 0,
          "a": 255,
          "lightColor": {
            "r": 241,
            "g": 151,
            "b": 80
          },
          "darkColor": {
            "r": 94,
            "g": 27,
            "b": 0
          },
          "isDark": true,
          "foregroundColor": {
            "r": 255,
            "g": 255,
            "b": 255
          },
          "foregroundShadowColor": {
            "r": 0,
            "g": 0,
            "b": 0,
            "a": 120
          }
        },
        {
          "colorId": 1280,
          "name": "Olive",
          "r": 128,
          "g": 128,
          "b": 0,
          "a": 255,
          "lightColor": {
            "r": 213,
            "g": 208,
            "b": 92
          },
          "darkColor": {
            "r": 75,
            "g": 79,
            "b": 0
          },
          "isDark": true,
          "foregroundColor": {
            "r": 255,
            "g": 255,
            "b": 255
          },
          "foregroundShadowColor": {
            "r": 0,
            "g": 0,
            "b": 0,
            "a": 120
          }
        }
      ]
    }
  }
};
