'use strict';
import _ from 'lodash';
import update from 'immutability-helper';
import { createAction, handleActions } from 'redux-actions';
import { handleError } from "../../../libs/errorHandler";
import { getApi } from '../../../libs/api';
import { actions as collectionActions } from '../../actions/collections';

const INVALIDATE_ALL = 'lists/INVALIDATE_ALL';
const INVALIDATE = 'lists/INVALIDATE';
const RECEIVE = 'lists/RECEIVE';
const ADD_TO = 'lists/ADD_TO';
const SPLICE = 'lists/SPLICE';
const REMOVE_FROM = 'lists/REMOVE_FROM';
const SORT = 'lists/SORT';

export const singletonListId = '__global';

// don't duplicate api posts for searching
const CURRENTLY_FETCHING = {};

export const actions = {
	receiveLists: createAction(RECEIVE),
	addToLists : createAction(ADD_TO),
	splice: createAction(SPLICE),
	sort: createAction(SORT),
	removeFromLists : createAction(REMOVE_FROM),
	invalidate : createAction(INVALIDATE),
	invalidateAll : createAction(INVALIDATE_ALL)
};

//TODO infinite scroll???

//collection handlers should export:
// [array] propsToDiff - if this is undef, incoming collection items WILL NOT be merged
// [function] formatter - Modify the entities before they are stored in state
// {function} getInitialState
const listSearchHandlers = {
	/**
	 * get a list of all the venue mappings for a team
	 */
	scheduleCategoryToLevelOfPlayMappings : {
		api : 'scoreStream',
		method : 'scheduleCategories.toLevelOfPlayMappings.search',
		paramFormatter : () => { return {}; },
		list : 'scheduleCategoryToLevelOfPlayMappings',
		resultKey : 'scheduleCategoryToLevelOfPlayMappingIds'
	},

	/**
	 * get a list of all the venue mappings for a team
	 */
	teamOverrideIdsForTeam : {
		api : 'scoreStream',
		method : 'teams.overrides.search',
		paramFormatter : (listId) => { return {teamIds : [listId]}; },
		list : 'teamOverrideIdsForTeam',
		resultKey : 'teamOverrideIds'
	},

	/**
	 * get a list of all the venue mappings for a team
	 */
	venueToTeamMappingIdsForTeam : {
		api : 'scoreStream',
		method : 'teams.venueToTeamMappings.search',
		paramFormatter : (listId) => { return {teamIds : [listId]}; },
		list : 'venueToTeamMappingIdsForTeam',
		resultKey : 'venueToTeamMappingIds'
	},
	/**
	 * Get a list of all the venue assessments for a game
	 */
	gameVenueAssessmentIdsForGame : {
		api : 'scoreStream',
		method : 'games.venues.assessments.search',
		paramFormatter : (listId) => { return {gameIds : [listId]}; },
		list : 'gameVenueAssessmentIdsForGame',
		resultKey : 'gameVenueAssessmentIds'
	},
	/**
	 * 	List all the game posts for a Game. Admins get hidden posts too
	 * 	This only deals with top level game posts on the game, not children
	 */
	gamePostIdsForGame : {
		api : 'scoreStream',
		method : 'games.posts.search',
		paramFormatter : (listId) => {
			//const hasGameHandOfGod = _.get(state, ['authUser', 'user', 'permissions', 'gameHandOfGod'], false);

			return {
				gameIds : [listId],
				//skipHidden : !(hasGameHandOfGod),
				orderBy : 'ASC',
				//skipChildGamePosts : true,
				count : 9999,
				gamePostTypes : ['picture', 'video', 'chat']
			};
		},
		list : 'gamePostIdsForGame',
		//resultKey : 'gamePostIds'
		resultFormatter (res) {
			const gamePosts = _.get(res, ['collections', 'gamePostCollection', 'list'], []);
			// filter out gamePosts that have parents, this list is only top level
			// the skipChildGamePosts paramter doesn't work... otherwise we could use that + resultKey
			const filteredIds = _.map(_.filter(gamePosts, (gamePost) => !(gamePost.parentGamePostId)), 'gamePostId');
			return _.intersection(res.gamePostIds, filteredIds);
		}
	},

	/**
	 * List all the game scores for a Game, Admins get branches too
	 */
	gameScoreIdsForGame : {
		api : 'scoreStream',
		method : 'games.scores.search',
		paramFormatter : (listId, state) => {
			const hasGameHandOfGod = _.get(state, ['authUser', 'user', 'permissions', 'gameHandOfGod'], false);

			return {
				gameIds : [listId],
				includeUserBranches : hasGameHandOfGod,
				count : 9999,
			};
		},
		list : 'gameScoreIdsForGame',
		resultKey : 'gameScoreIds'
	},
	/**
	 * Get all of the gameScoreAssessments for a game BY the authUser
	 */
	'authUser-gameScoreAssessmentsForGame' : {
		api : 'scoreStream',
		method : 'games.scores.assessments.search',
		paramFormatter : (listId, state) => {
			const authUserId = _.get(state, ['authUser', 'user', 'userId']);

			return {
				gameIds : [listId],
				creatorUserIds : [authUserId],
			};
		},
		list : 'gameScoreIdsForGame',
		resultKey : 'gameScoreIds'
	},
	'authUser-gamePostIdsWithComposites' : {
		api : 'scoreStream',
		method : 'games.posts.search',
		paramFormatter : (listId, state) => {
			const authUserId = _.get(state, ['authUser', 'user', 'userId']);
			return {
				creatorUserIds : [authUserId],
				gamePostTypes : ['video', 'picture'],
				skipHidden : true,
				skipChildGamePosts : true,
				count : 10,
				offset : 0,
			};
		},
		list : 'authUser-gamePostIdsWithComposites',
		resultKey : 'gamePostIds',
	},
	gameFanIdsForGame: {
		api : 'scoreStream',
		method : 'games.fans.search',
		paramFormatter : listId => ({
			gameIds : [listId],
			count : 200,
			offset: 0,
		}),
		list : 'gameFanIdsForGame',
		resultKey : 'gameFanIds'
	},
	gamePostIdsForTeam: {
		api: 'scoreStream',
		method: 'teams.gamePosts.search',
		paramFormatter: _.identity,
		list: 'gamePostIdsForTeam',
		//resultKey: 'gamePostIds',
		resultFormatter (res) {
			const gamePosts = _.get(res, ['collections', 'gamePostCollection', 'list'], []);
			// filter out gamePosts that have parents, this list is only top level
			// the skipChildGamePosts paramter doesn't work... otherwise we could use that + resultKey
			return _.map(_.filter(gamePosts, (gamePost) => !(gamePost.parentGamePostId)), 'gamePostId');
		}
	},
	gamesForTeam: {
		api: 'scoreStream',
		method: 'games.search',
		paramFormatter: listId => {
			const separated = listId.split('-');
			const teamId = separated[0];
			let squadIds;
			if (separated[1]) {
				squadIds = separated[1].split(',');
			}
			return {
				teamIds: [teamId],
				squadIds
			};
		},
		list: 'gamesForTeam',
		resultKey: 'gameIds',
	},
	trending: {
		api: 'scoreStream',
		method: 'trending.search',
		paramFormatter: () => ({}),
		list: 'trending',
		resultFormatter: result => result.collections.cardCollection.list,
	},
	mutedUsers: {
		api: 'scoreStream',
		method: 'users.muted.search',
		paramFormatter: () => ({}),
		list: 'mutedUsers',
		resultKey: 'mutedUserIds',
	},
	/** Real **/
	//venueToTeamMappingIdsForVenue

	//activity-cardIdsForUser
	//recommended-cardIdsForUser

	//following-userFollowerIdsForUser
	//follower-userFollowerIdsForUser

	//userTeamSubscriptionIdsForUser
	//userTeamSubscriptionIdsForTeam

	//userGameSubscriptionIdsForUser
	//userGameSubscriptionIdsForGame

	//generalManagerIdsForTeam
	//generalManagerIdsForUser

	//externalContentIdsForGame

	//composite-gamePostIdsForUser
	//gamePostIdsForUser


	//teamAlternateGameLocationIdsForTeam

	/** Hypothetical **/
	//teamIdsForLocation
	//venueIdsForLocation

	//squadIdsForOrganization
	//squadIdsForTeam
	//sportIdsForTeam


};

function getInitialState() {
	return _.mapValues(listSearchHandlers, function () {
		return {};
	});
}

const collectionReducer = handleActions({
	[INVALIDATE_ALL] : (state, action) => getInitialState(),
	[INVALIDATE] : {
		next: (state, action) => {
			const {list, listId} = action.payload;

			return update(state, {
				[list] : {
					[listId] : {$set : undefined}
				}
			});
		},
		throw: (state, action) => {
			handleError("problem invalidating list", action.payload);
			return state;
		}
	},
	[RECEIVE]: {
		next: (state, action) => {
			const { list, listId, offset } = action.payload;
			let { itemIds } = action.payload;
			if (state[list][listId]) {
				if (state[list][listId].length > offset) {
					itemIds = update(state[list][listId], {$splice: [
						[offset, 0].concat(itemIds),
					]});
				} else {
					itemIds = state[list][listId].concat(itemIds);
				}
			}

			return update(state, {
				[list] : {
					[listId] : {$set : itemIds}
				}
			});
		},
		throw: (state, action) => {
			handleError("Problem receiving list", action.payload);
			return state;
		}
	},
	[ADD_TO] : {
		next: (state, action) => {
			const {list, listId, itemId} = action.payload;

			const storedList = _.get(state, [list, listId]);
			if (!storedList) {
				return state;
			}

			return update(state, {
				[list] : {
					[listId] : {$push : [itemId]}
				}
			});
		},
		throw: (state, action) => {
			handleError("Problem adding to list", action.payload);
			return state;
		}
	},
	[SPLICE] : {
		next: (state, action) => {
			const {list, listId, itemId, index} = action.payload;

			const storedList = _.get(state, [list, listId]);
			if (!storedList) {
				return state;
			}

			return update(state, {
				[list] : {
					[listId] : {$splice : [[index, 0, itemId]]}
				}
			});
		},
		throw: (state, action) => {
			handleError("Problem splicing list", action.payload);
			return state;
		}
	},
	[SORT]: {
		next: (state, action) => {
			const { list, listId, sortFunc, } = action.payload;

			const storedList = _.get(state, [list, listId]);
			if (!storedList) {
				return state;
			}

			const isSorted = _.every(storedList, (listItem, index) => {
				return index === (storedList.length - 1) || sortFunc(listItem, storedList[index + 1]) <= 0;
			});
			if (isSorted) {
				return state;
			}

			return update(state, {
				[list] : {
					[listId] : {$set: storedList.slice(0).sort(sortFunc)}
				}
			});
		},
		throw: (state, action) => {
			handleError("Problem sorting list", action.payload);
			return state;
		}
	},
	[REMOVE_FROM] :{
		next: (state, action) => {
			const {list, listId, itemId} = action.payload;

			const storedList = _.get(state, [list, listId]);
			if (!storedList) {
				return state;
			}

			return update(state, {
				[list] : {
					[listId] : {$set : _.without(storedList, itemId)}
				}
			});
		},
		throw: (state, action) => {
			handleError("Problem removing from list", action.payload);
			return state;
		}
	}
}, getInitialState());

export default collectionReducer;

export const search = function (p, callback) {
	const { apiParams, list, listId, } = p;

	return function (dispatch, getState) {
		const state = getState();

		const def = listSearchHandlers[list];

		if (!def) {
			return handleError("unknown list attempting to be searched for");
		}

		if (CURRENTLY_FETCHING[list + listId]) {
			return; // console.info(`Already fetching ${list} ${listId}`);
		}

		const api = def.api || 'scoreStream';
		const method = def.method;
		const params = apiParams || def.paramFormatter(listId, state);
		const resultKey = def.resultKey;
		const resultFormatter = def.resultFormatter;

		const apiRequester = getApi({name : api});

		CURRENTLY_FETCHING[list + listId] = true;

		apiRequester.post({
			method : method,
			params : params,
			callback : function (err, result) {
				if (err) {
					if (callback) { callback(err); }
					return handleError("Problem searching up a list", err, api, method, params, resultKey);
				}
				delete CURRENTLY_FETCHING[list + listId];

				const itemIds = (resultKey) ? result[resultKey] : resultFormatter(result);

				dispatch(collectionActions.receiveCollections(result.collections));

				dispatch(actions.receiveLists({
					list : list,
					listId : listId,
					itemIds : itemIds,
					total : result.total || itemIds.length,
					offset: params.offset,
				}));
				if (callback) {
					callback(null, result);
				}
			}
		});
	};
};
