"use strict";
import React, { Fragment, Suspense } from 'react';
import PropTypes from 'prop-types';
import StyleRoot from 'radium/lib/components/style-root';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import StickyContainer from 'react-sticky/lib/Container';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import '../../../../vendor/js/modernizrCustom';
import muiTheme from '../../../common/mui/muiTheme';
import Tracking from '../../components/tracking.jsx';
import Header, { HEADER_HEIGHT } from './header.jsx';
const HeaderFrontPage = React.lazy(() => import(
	/* webpackChunkName: "HeaderFrontPage" */
	'./headerFrontPage.jsx'
));
import Footer from './footer.jsx';
import Messages from './messages.jsx';
import ModalRoot from '../../components/modalRoot.jsx';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import ErrorBoundary from '../../../common/components/errorBoundary.jsx';

// TODO - where should this be?
import '!!style-loader!css-loader?sourceMap!sass-loader?sourceMap!../../../common/scss/fonts/nimbusSanNovConBla.scss';
//import '../../../common/scss/fontawesome-4.7.0.scss';
//add fonts -- might be able to reference these in components, but not sure

const FrontPage = React.lazy(() => import(
	/* webpackChunkName: "FrontPage" */
	'../routes/frontPage/index.jsx'
));
const TeamPage = React.lazy(() => import(
	/* webpackChunkName: "TeamPage" */
	'../routes/team/index.jsx'
));
const GamePage = React.lazy(() => import(
	/* webpackChunkName: "GamePage" */
	'../routes/game/index.jsx'
));
const UserPage = React.lazy(() => import(
	/* webpackChunkName: "UserPage" */
	'../routes/user/index.jsx'
));
const GamePostPage = React.lazy(() => import(
	/* webpackChunkName: "GamePostPage" */
	'../routes/gamePost/index.jsx'
));
const PlaylistPage = React.lazy(() => import(
	/* webpackChunkName: "PlaylistPage" */
	'../routes/playlist/index.jsx'
));
const NotificationsPage = React.lazy(() => import(
	/* webpackChunkName: "NotificationsPage" */
	'../routes/notifications/index.jsx'
));
const UserWidgetsPage = React.lazy(() => import(
	/* webpackChunkName: "UserWidgetsPage" */
	'../routes/userWidgets/index.jsx'
));
const WidgetCreatorsPage = React.lazy(() => import(
	/* webpackChunkName: "WidgetCreatorsPage" */
	'../routes/widgetCreators/index.jsx'
));
//const TeamSchedulerPage = React.lazy(() => import(
	/* webpackChunkName: "TeamSchedulerPage" */
//	'../routes/teamScheduler/index.jsx'
//));
const InternalPage = React.lazy(() => import(
	/* webpackChunkName: "InternalPage" */
	'../routes/internal/index.jsx'
));
const NoMatchPage = React.lazy(() => import(
	/* webpackChunkName: "NoMatchPage" */
	'../routes/noMatchRoute'
));
const HandOfGod = React.lazy(() => import(
	/* webpackChunkName: "HandOfGod" */
	'../../components/handOfGod.jsx'
));
const GDPRPrompt = React.lazy(() => import(
	/* webpackChunkName: "GDPRPrompt" */
	'../../../common/components/gdprPrompt.jsx'
));
const EmbedHeader = React.lazy(() => import(
	/* webpackChunkName: "EmbedHeader" */
	'./embedHeader.jsx'
));

function makeClasses() {
	return {
		'@global': {
			a : {
				textDecoration: 'none',
				outline: 'none',
				// Overload some things that bootstrap is targeting causing problems
				'&:focus': {
					color: 'unset',
					textDecoration: 'none',
					outline: 'none',
				},
				'&:hover': {
					color: 'unset',
					textDecoration: 'none',
				}
			},
			ul: {
				margin: `0 0 0 0`,
				padding: `0 0 0 0`,
			},
			li: {
				listStyle: 'none',
			}
		},
		appStyle: {
			background: '#e2e2e2',
			fontFamily: `'Open Sans',sans-serif`,
		},
		bodyStyle: {
			marginTop: HEADER_HEIGHT,
			minHeight: window.innerHeight - 100,
		}
	}
}

function App(props) {
	const { classes, isEmbedded } = props;

	return (
		<StyleRoot>
			<MuiThemeProvider theme={muiTheme}>
				<MuiPickersUtilsProvider utils={MomentUtils}>
					<StickyContainer className={classes.appStyle}>
						<BrowserRouter>
							<Fragment>
								{!isEmbedded &&
									<ErrorBoundary>
										<Suspense fallback={<div/>}>
											<Switch>
												<Route exact path={["/", "/frontPage"]} component={HeaderFrontPage} />
												<Route component={Header} />
											</Switch>
										</Suspense>
									</ErrorBoundary>
								}
								{isEmbedded &&
									<Suspense fallback={<div/>}>
										<EmbedHeader/>
									</Suspense>
								}

								<div
									className={classes.bodyStyle}
									style={isEmbedded ? { marginTop: 0} : undefined}
								>
									<ErrorBoundary
										message="There was a problem loading the page content. Please refresh or try another page. Contact feedback@scorestream.com if the problem persists"
									>
										<Suspense fallback={<div/>}>
											<Switch>
												{/*
													Don't Worry about
													"Invalid prop `component` of type `object` supplied to `Route`, expected `function`"
													I expect react-router-dom validation to catch up
												*/}
												<Route exact path={["/", "/frontPage"]} component={FrontPage} />
												<Route path="/user/:userUrlId" component={UserPage} />
												<Route path="/game/:gameUrlId" component={GamePage} />
												<Route path="/gamePost/:gamePostUrlId" component={GamePostPage} />
												<Route path="/team/:teamUrlId/:genderSport?/:level?" component={TeamPage} />
												<Route path="/iframes/team/:teamUrlId/:genderSport?/:level?" component={TeamPage} />
												<Route path="/embed/team/:teamUrlId/:genderSport?/:level?" component={TeamPage} />
												<Route path="/internal" component={InternalPage} />
												<Route path="/notifications" component={NotificationsPage} />
												<Route path="/playlist/:playlistUrlId" component={PlaylistPage} />
												<Route path="/userWidgets" component={UserWidgetsPage} />
												<Route path="/widgetCreatorsApp" component={WidgetCreatorsPage} />
												{/*<Route path="/teamScheduler" component={TeamSchedulerPage} />*/}
												<Route component={NoMatchPage} />
											</Switch>
										</Suspense>
									</ErrorBoundary>
								</div>

								<ErrorBoundary hidden={true}>
									<Suspense fallback={<div/>}>
										{!isEmbedded && <HandOfGod/>}
										{!isEmbedded && <GDPRPrompt/>}
										<Messages />
										<Tracking />
									</Suspense>
								</ErrorBoundary>

								<ErrorBoundary>
									{!isEmbedded && <Footer />}
								</ErrorBoundary>
								<ModalRoot/>
							</Fragment>
						</BrowserRouter>
					</StickyContainer>
				</MuiPickersUtilsProvider>
			</MuiThemeProvider>
		</StyleRoot>
	);
}
App.propTypes = {
	// Whether or not this is an "iframe/embed view which makes it basically our site in a box
	isEmbedded: PropTypes.bool,
	//withStyles
	classes: PropTypes.object,
};

export default withStyles(makeClasses)(App);
