import _ from 'lodash';
import { connect } from 'react-redux';
import { receive  as receiveCollections } from "../redux/actions/collections";
import { loadOrganizations } from '../redux/modules/collections/organization';
import { loadTeamTiers } from '../redux/modules/collections/teamTier';
import { loadAuthUserLocation } from '../redux/modules/authUser/location';

function mapStateToProps(state) {
	return {
		organizationMap: state.collections.organization,
		colorMap: state.collections.color,
		teamTierMap: state.collections.teamTier,
		authUserLocation: _.get(state, 'authUser.location.location'),
	};
}

function mapDispatchToProps(dispatch) {
	return {
		loadTeamTiers: () => dispatch(loadTeamTiers()),
		loadOrganizations: () => dispatch(loadOrganizations()),
		loadAuthUserLocation: () => dispatch(loadAuthUserLocation()),
		receiveCollections: (p) => dispatch(receiveCollections(p)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps);
